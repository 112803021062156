<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  // import Multiselect from 'vue-multiselect'
  // import databaseUtils from '@utils/database_utils'
  const timeUtils = require ('@common/time_utils');
  const geoUtils = require ('@common/geo_utils');
  const status = require ('@common/status');

  const apiErrorCode = status.apiErrorCode;

  export default {
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        uploadProgress: 0,
        imageSrc: null,
        imageBlob: null,
        isLoading: true,

        form: {
          automatic_transfer: true,
          price_range: "Até R$ 500,00",
          person_type: "Pessoa Física",
          physical_products: true,
        },
        defaultForm: {
          automatic_transfer: true,
          price_range: "Até R$ 500,00",
          person_type: "Pessoa Física",
          physical_products: true,
        },

        startLoading: false,
        iuguInfo: null,
        iuguNotFound: false,

        address: '',
        addressList: [],
        lastSearchTime: null,
        term: '',
        addressValue: '',
        changeAddress: false,

        addressSelected: {},
        stateList: status,
        inputFile: null,
        form2: {}


      }
    },
    validations: {

    },
    computed: {
      ...authComputed
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      if(this.itemId) {
        await await this.loadForm();
      }
      this.isLoading = false;
    },
    beforeMount: async function() {

    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        this.iuguNotFound = false;
        this.iuguInfo = {};
        this.isLoading = true;
        this.inputFile = null;

        let configP = firebase.firestore().collection('companiesPaymentConfig').doc(this.itemId).get();

        let result = await firebase.functions().httpsCallable('companiesPayments-getIuguInfo')({
          id: this.itemId,
          lang: this.$i18n.locale
        });
        console.log('obj.data', result.data);
        console.log('obj.data', JSON.stringify(result.data));

        if(!result.data || !result.data.isOk || !result.data.data) {
          if(result.data && !result.data.isOk && result.data.errorCode===apiErrorCode.notFound) {
            this.iuguNotFound = true;
            this.isLoading = false;
            return;
          }

          this.isLoading = false;
          return this.$showError();
        }




          this.iuguInfo = result.data.data ||{};
         // this.form = { ..._.cloneDeep(this.defaultForm), ...iuguInfo};

        //  this.iuguInfo=


        let config = (await configP).data() || {};

        if(config.submission) {
          let s = [];

          for(let i in config.submission) {
            s.push(config.submission[i]);
          }
          s= _.sortBy(s, 'createdAt');
          if(s.length>0) {
            this.form = s[s.length-1];

            this.addressSelected = s[s.length-1].addressGotap || {};
          }
          else {
            this.form = {
              auto_withdraw: this.iuguInfo.auto_withdraw || false,
              auto_advance: this.iuguInfo.auto_advance || false,
              ..._.cloneDeep(this.defaultForm)
            };
          }
        }
        else {
          this.form = {
            auto_withdraw: this.iuguInfo.auto_withdraw || false,
            auto_advance: this.iuguInfo.auto_advance || false,
            ..._.cloneDeep(this.defaultForm)
          };
        }

        //  console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
       // let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;


        try {
        //  await firebase.firestore().collection('companies').doc(this.itemId).set(toUpdate,{merge: true});
          this.$showSuccess();
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      async createIugu() {

        if(!this.currentCompany || !this.currentCompany.availableForIugu) {
          this.$showError({title:this.$t('paymentOptions.alertNotAvailableForIugu'), text:this.$t('all.contactSupport') });
          return;
        }


        this.isLoading = true;

        let ret = await firebase.functions().httpsCallable('companiesPayments-createIugu')({
          id: this.itemId,
          lang: this.$i18n.locale
        });
        if(!ret || !ret.data || !ret.data.isOk) {
          console.error(ret);
          this.$showError({text: ((ret||{}).data ||{}).msg});
          this.isLoading = false;
        }
        else {
          this.$showSuccess();
          await this.loadForm();
        }
      },

      async createIuguVerify() {

        let form = _.cloneDeep(this.form);
        let submissionId = firebase.firestore().collection('companiesPaymentConfig').doc().id;

        form.cnpj = (form.cnpj || '').replace(/[^\d]/g, '');
        form.cpf = (form.cpf || '').replace(/[^\d]/g, '');
        form.resp_cpf = (form.resp_cpf || '').replace(/[^\d]/g, '');

        // if(this.iuguInfo.last_verification_request_status==='accepted') {
        //   return this.$showError({title: this.$t('inputError.previousSend')});
        // }

        if(form.person_type==='Pessoa Física') {
          delete form.cnpj;
          delete form.company_name;
          delete form.resp_name;
          delete form.resp_cpf;
          if(!form.name) {
            return this.$showError({title: this.$t('inputError.name')});
          }
          if(!form.cpf) {
            return this.$showError({title: this.$t('inputError.cpf')});
          }
        }
        else if(form.person_type==='Pessoa Jurídica'){
          delete form.name;
          delete form.cpf;
          if(!form.company_name) {
            return this.$showError({title: this.$t('inputError.companyName')});
          }
          if(!form.cnpj) {
            return this.$showError({title: this.$t('inputError.cnpj')});
          }
        }
        else {
          return this.$showError({title: this.$t('inputError.companyName')});
        }

        if(!form.price_range) {return this.$showError({title: this.$t('inputError.priceRange')});}
        if(!form.business_type) {return this.$showError({title: this.$t('inputError.businessType')});}
        if(!form.telephone) {return this.$showError({title: this.$t('inputError.phone')});}


        form.physical_products = !!form.physical_products;
        form.automatic_transfer = !!form.automatic_transfer;

        if(!this.addressSelected || !this.addressSelected.city || !this.addressSelected.state || !this.addressSelected.zipcode
          || !this.addressSelected.street || !this.addressSelected.number) {
          return this.$showError({title: this.$t('inputError.completeAddress')});
        }
        form.address = this.addressSelected.street + ', '+this.addressSelected.number+ (this.addressSelected.complement?', '+this.addressSelected.complement:'');
        form.state = this.addressSelected.state;
        form.city = this.addressSelected.city;
        form.cep = this.addressSelected.zipcode;

        if(!form.bank || !form.bank_ag || !form.account_type || !form.bank_cc) {
          return this.$showError({title: this.$t('inputError.completeBank')});
        }


        this.tryingToUpdate = true;
        console.log('form para submissão no iugu', form);

        let toUpdate = {};
        toUpdate = {
          submission: {}
        };
        toUpdate['submission'][submissionId] = {
          ...form,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          addressGotap: this.addressSelected || {},
        };

        await firebase.firestore().collection('companiesPaymentConfig').doc(this.itemId).set(toUpdate,{merge: true});


        delete form.createdAt;
        delete form.addressGotap;
        delete form.auto_advance;
        delete form.auto_withdraw;

        form.bank = this.stateList.bankList[form.bank];

        form.id = this.itemId;
        form.lang = this.$i18n.locale;

        let ret = await firebase.functions().httpsCallable('companiesPayments-verificationIugu')(form);
        if(!ret || !ret.data || !ret.data.isOk) {
          if(ret && ret.data) {
            this.$showError({text: ret.data.msg +' '+JSON.stringify(ret.data.data)});
            console.error(ret.data.data);
          }
          else {
            this.$showError({});
            console.error(ret);
          }

          this.tryingToUpdate = false;
        }
        else {
          this.$showSuccess();
          this.tryingToUpdate = false;
          await this.loadForm();
        }

        this.tryingToUpdate = false;
      },
      async createIuguVerifyBank() {

        let form = _.cloneDeep(this.form2);
        let submissionId = firebase.firestore().collection('companiesPaymentConfig').doc().id;


        if(!form.bank || !form.bank_ag || !form.account_type || !form.bank_cc) {
          return this.$showError({title: this.$t('inputError.completeBank')});
        }


        this.tryingToUpdate = true;

        let toUpdate = {};
        toUpdate = {
          submissionBank: {}
        };
        toUpdate['submissionBank'][submissionId] = {
          ...form,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        };

        await firebase.firestore().collection('companiesPaymentConfig').doc(this.itemId).set(toUpdate,{merge: true});


        let form2 = {
          id: this.itemId,
          lang: this.$i18n.locale,
          agency: form.bank_ag,
          account: form.bank_cc,
          account_type: form.account_type==='Corrente'?'cc':'cp',
          bank: form.bank
        };

        let fileBase64 = null;
        if(this.inputFile) {
          fileBase64 = await new Promise((resolve, reject)=>{
            let reader = new FileReader();
            reader.onload = async (e) => {
              resolve(e.target.result);
            };
            reader.onerror = async (e) => {
              reject(e);
            };
            reader.readAsDataURL(this.inputFile);
          });
        }

        if(fileBase64) {
          form2.document = fileBase64;
        }


        console.log('form para submissão no iugu2', form2);

        let ret = await firebase.functions().httpsCallable('companiesPayments-verificationIuguBank')(form2);
        if(!ret || !ret.data || !ret.data.isOk) {
          console.error(ret);
          this.$showError({text: ((ret||{}).data ||{}).msg});
          this.tryingToUpdate = false;
        }
        else {
          this.$showSuccess();
          this.tryingToUpdate = false;
          await this.loadForm();
        }

        this.tryingToUpdate = false;
      },
      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },

      async getListAddress(input){
        if(!input) {
          this.lastSearchTime = Date.now();
          this.addressList = Promise.resolve([]);
          return;
        }
        let resolveLocal = null;
        let rejectLocal = null;
        this.addressList = new Promise((resolve, reject)=>{
          resolveLocal = resolve;
          rejectLocal = reject;
        });

        let time = Date.now();
        this.lastSearchTime = time;
        await timeUtils.delay(650);

        if(this.lastSearchTime !== time) { // ignore that search
          return null;
        }

        console.log('search: ', input);
        let result = await firebase.functions().httpsCallable('maps-search')({term:input, language: this.$i18n.locale});
        if(this.lastSearchTime !== time) { // ignore that search
          return null;
        }

        if(result.data && !result.data.length) {
          this.$showError({title: this.$t('form.address.emptyResult')});
          rejectLocal();
        }
        else {
          resolveLocal(result.data || []);
          this.addressList = Promise.resolve(result.data || []);
        }
      },
      async addressSelect(input) {
        console.log('Select ', input);
        this.changeAddress = true;
        if(!input || !input.place_id) {
          this.address = '';
          return
        }
        this.address = input.description;
        let result = await firebase.functions().httpsCallable('maps-details')({placeId:input.place_id, language: this.$i18n.locale});

        if(!result.data) {
          this.address = '';
          return;
        }

        this.addressSelected = geoUtils.googleAddress2db(result.data, this.addressSelected);
        console.log('this.form', this.form);
        this.address = input.description;
      },
      addressWarning() {
        this.$showError({title: this.$t('form.address.fieldWarning')});
        this.$refs.addressInput.$el.querySelector('input').focus();
      },
      inputDocChange(e) {
        console.log('doc change');
        this.inputFile = e.target.files[0];
      },
      async changeGotapOptions(form) {
        this.tryingToUpdate = true;
        form.id = this.itemId;
        form.lang = this.$i18n.locale;


        if(!this.iuguInfo['can_receive?']) {
          this.tryingToUpdate = false;
          return this.$showError({title: this.$t('inputError.needCanReceive')});
        }

        let ret = await firebase.functions().httpsCallable('companiesPayments-changeGotapOptions')(form);
        if(!ret || !ret.data || !ret.data.isOk) {
          console.error(ret);
          this.$showError({text: ((ret||{}).data ||{}).msg});
        }
        else {
          this.$showSuccess();
          await this.loadForm();
        }
        this.tryingToUpdate = false;
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!currentCompany || !currentCompany.availableForIugu">
          <p>
            {{$t('paymentOptions.notAvailableForIugu')}}
          </p>
        </div>
        <div v-if="!isLoading && currentCompany && currentCompany.availableForIugu" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <!--
                    <div :class="$style.imagePreview" @click="tryChangeImage()" >
                      <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
                      <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
                      <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>

                    </div>
                    <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                               type-content="modal"
                               :max-width="180"
                               :max-height="180"
                               :aspect-ratio="0"
                               @changeBlob="changeBlob"
                    ></FcmEditor> -->

          <MdTabs>
            <MdTab id="info" :md-label="$t('paymentOptions.infoTitle')">
              <div v-if="!iuguNotFound" :class="$style.body">
                <div>
                  <BaseButton
                    v-if="!iuguInfo.gotapReceiveSubAccount"
                    :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
                    :disabled="tryingToUpdate"
                    @click="changeGotapOptions({gotapReceiveSubAccount:true})"
                  >
                    <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                    <span v-else>
                      {{$t('paymentOptions.receiveInSubAccount')}}
                    </span>
                  </BaseButton>
                  <BaseButton
                    v-if="iuguInfo.gotapReceiveSubAccount"
                    :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
                    :disabled="tryingToUpdate"
                    @click="changeGotapOptions({gotapReceiveSubAccount:false})"
                  >
                    <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                    <span v-else>
                      {{$t('paymentOptions.receiveInMasterAccount')}}
                    </span>
                  </BaseButton>
                </div>
                <div class="formItemSubSection">{{$t('paymentOptions.iuguInfo').toUpperCase()}}</div>
                <table>
                  <tr>
                    <td>{{$t('iugu.name')}}</td>
                    <td>{{iuguInfo.name}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.can_receive')}}</td>
                    <td>{{iuguInfo['can_receive?']?$t('all.yes'): $t('all.no')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.is_verified')}}</td>
                    <td>{{iuguInfo['is_verified?']?$t('all.yes'): $t('all.no')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.last_verification_request_status')}}</td>
                    <td>{{iuguInfo.last_verification_request_status}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.last_verification_request_data')}}</td>
                    <td>{{iuguInfo.last_verification_request_data}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.last_verification_request_feedback')}}</td>
                    <td>{{iuguInfo.last_verification_request_feedback}}</td>
                  </tr>
                  <tr></tr><tr></tr>
                  <tr>
                    <td>{{$t('iugu.balance')}}</td>
                    <td>{{iuguInfo.balance}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.balance_in_protest')}}</td>
                    <td>{{iuguInfo.balance_in_protest}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.balance_available_for_withdraw')}}</td>
                    <td>{{iuguInfo.balance_available_for_withdraw}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.protected_balance')}}</td>
                    <td>{{iuguInfo.protected_balance}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.payable_balance')}}</td>
                    <td>{{iuguInfo.payable_balance}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.receivable_balance')}}</td>
                    <td>{{iuguInfo.receivable_balance}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.commission_balance')}}</td>
                    <td>{{iuguInfo.commission_balance}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.volume_last_month')}}</td>
                    <td>{{iuguInfo.volume_last_month}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.volume_this_month')}}</td>
                    <td>{{iuguInfo.volume_this_month}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.taxes_paid_last_month')}}</td>
                    <td>{{iuguInfo.taxes_paid_last_month}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.taxes_paid_this_month')}}</td>
                    <td>{{iuguInfo.taxes_paid_this_month}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.has_bank_address')}}</td>
                    <td>{{iuguInfo['has_bank_address?']?$t('all.yes'): $t('all.no')}}</td>
                  </tr>
                  <tr></tr><tr></tr>
                  <tr>
                    <td>{{$t('iugu.configuration.auto_withdraw')}}</td>
                    <td>{{iuguInfo.configuration.auto_withdraw?$t('all.yes'): $t('all.no')}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('iugu.commissions_percent')}}</td>
                    <td>{{iuguInfo.commissions.percent}}</td>
                  </tr>
                </table>
              </div>
              <div v-else >
                {{$t('paymentOptions.needCreateIugu')}}
              </div>
            </MdTab>
            <MdTab v-if="iuguNotFound" id="create" :md-label="$t('paymentOptions.createIuguTitle')" >
              <div v-if="iuguNotFound" :class="$style.body">
                <BaseButton
                  :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
                  :disabled="tryingToUpdate"
                  @click="createIugu"
                >
                  <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                  <span v-else>
                  {{$t('paymentOptions.createIugu')}}
                </span>
                </BaseButton>
              </div>
              <div v-else >
                {{$t('paymentOptions.createIuguPrevious')}}
              </div>
            </MdTab>
            <MdTab id="form" :md-label="$t('paymentOptions.formTitle')" >
              <div v-if="!iuguNotFound" :class="$style.body">
                <div v-if="!iuguInfo.is_verified && iuguInfo.last_verification_request_status!=='pending'">
                  <h4>{{$t('iugu.accountVerification.title').toUpperCase() + ' ('+iuguInfo.last_verification_request_status+')'}}</h4>
                  <BaseInputText
                    v-model="form.price_range"
                    :label="$t('iugu.accountVerification.price_range')"
                  >
                  </BaseInputText>
                  <BaseInputText
                    v-model="form.business_type"
                    :label="$t('iugu.accountVerification.business_type')"
                  >
                  </BaseInputText>
                  <MdField :class="$style.formItemText">
                    <label >{{$t('iugu.accountVerification.person_type')}}</label>
                    <MdSelect v-model="form.person_type" name="access">
                      <MdOption key="Pessoa Física" value="Pessoa Física">{{$t('iugu.accountVerification.person_typeFisica')}}</MdOption>
                      <MdOption key="Pessoa Jurídica" value="Pessoa Jurídica">{{$t('iugu.accountVerification.person_typeJuridica')}}</MdOption>
                    </MdSelect>
                  </MdField>
                  <BaseInputText
                    v-if="form.person_type==='Pessoa Jurídica'"
                    v-model="form.company_name"
                    :label="$t('iugu.accountVerification.company_name')"
                  >
                  </BaseInputText>
                  <BaseInputText
                    v-if="form.person_type==='Pessoa Jurídica'"
                    v-model="form.cnpj"
                    v-mask="['##.###.###/####-##']"
                    :label="$t('iugu.accountVerification.cnpj')"
                  >
                  </BaseInputText>
                  <BaseInputText
                    v-if="form.person_type==='Pessoa Jurídica'"
                    v-model="form.resp_name"
                    :label="$t('iugu.accountVerification.resp_name')"
                  >
                  </BaseInputText>
                  <BaseInputText
                    v-if="form.person_type==='Pessoa Jurídica'"
                    v-model="form.resp_cpf"
                    v-mask="['###.###.###-##']"
                    :label="$t('iugu.accountVerification.resp_cpf')"
                  >
                  </BaseInputText>


                  <BaseInputText
                    v-if="form.person_type==='Pessoa Física'"
                    v-model="form.name"
                    :label="$t('iugu.accountVerification.name')"
                  >
                  </BaseInputText>
                  <BaseInputText
                    v-if="form.person_type==='Pessoa Física'"
                    v-model="form.cpf"
                    v-mask="['###.###.###-##']"
                    :label="$t('iugu.accountVerification.cpf')"
                  >
                  </BaseInputText>
                  <BaseInputText
                    v-model="form.telephone"
                    :label="$t('iugu.accountVerification.telephone')"
                  >
                  </BaseInputText>



                  <div class="formItem">
                    <div class="formItemDesc">
                      <div class="formItemHead">{{$t('iugu.accountVerification.physical_products')}}</div>
                    </div>
                    <div class="formItemAction">
                      <MdSwitch v-model="form.physical_products" class="md-primary" :value="true"></MdSwitch>
                      <div class="formItemControl"></div>
                    </div>
                  </div>
                  <div class="formItem">
                    <div class="formItemDesc">
                      <div class="formItemHead">{{$t('iugu.accountVerification.automatic_transfer')}}</div>
                    </div>
                    <div class="formItemAction">
                      <MdSwitch v-model="form.automatic_transfer" class="md-primary" :value="true"></MdSwitch>
                      <div class="formItemControl"></div>
                    </div>
                  </div>

                  <MdField>
                    <label >{{$t('iugu.accountVerification.bank')}}</label>
                    <MdSelect v-model="form.bank">
                      <MdOption v-for="(sl, slIndex) of stateList.bankList" :key="slIndex" :value="slIndex">{{sl}}</MdOption>
                    </MdSelect>
                  </MdField>
                  <MdField :class="$style.formItemText">
                    <label >{{$t('iugu.accountVerification.account_type')}}</label>
                    <MdSelect v-model="form.account_type" name="account_type">
                      <MdOption key="Corrente" value="Corrente">{{$t('iugu.accountVerification.account_typeCorrente')}}</MdOption>
                      <MdOption key="Poupança" value="Poupança">{{$t('iugu.accountVerification.account_typePoupanca')}}</MdOption>
                    </MdSelect>
                  </MdField>
                  <BaseInputText
                    v-model="form.bank_ag"
                    :label="$t('iugu.accountVerification.bank_ag')"
                  >
                  </BaseInputText>
                  <BaseInputText
                    v-model="form.bank_cc"
                    :label="$t('iugu.accountVerification.bank_cc')"
                  >
                  </BaseInputText>



                  <br>
                  <MdAutocomplete ref="addressInput" v-model="address" :md-options="addressList" :value="addressValue" autocomplete="no" @md-changed="getListAddress" @md-opened="getListAddress" @md-selected="addressSelect" >
                    <label>{{$t('form.address.search')}}</label>
                    <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item.description }}</template>
                  </MdAutocomplete>


                  <MdField :class="[$style.addressItemLock]" :event="''" @click.native="addressWarning">
                    <label>{{$t('form.address.street')}}</label>
                    <MdInput v-model="addressSelected.street" type="text" :disabled="true" ></MdInput>
                  </MdField>
                  <MdField :class="[$style.addressItemLock]" :event="''" @click.native="addressWarning">
                    <label>{{$t('form.address.district')}}</label>
                    <MdInput v-model="addressSelected.neighborhood" type="text" :disabled="true" ></MdInput>
                  </MdField>
                  <div :class="$style.addressCityState">
                    <MdField :class="[$style.addressItemLock, $style.addressCity]" :event="''" @click.native="addressWarning">
                      <label>{{$t('form.address.city')}}</label>
                      <MdInput v-model="addressSelected.city" type="text" :disabled="true" ></MdInput>
                    </MdField>
                    <MdField :class="[$style.addressItemLock, $style.addressState]" :event="''" @click.native="addressWarning">
                      <label>{{$t('form.address.state')}}</label>
                      <MdInput v-model="addressSelected.state" type="text" :disabled="true" ></MdInput>
                    </MdField>
                  </div>
                  <MdField :class="[$style.addressItemLock]" :event="''" @click.native="addressWarning">
                    <label>{{$t('form.address.country')}}</label>
                    <MdInput v-model="addressSelected.country" type="text" :disabled="true" ></MdInput>
                  </MdField>
                  <div :class="$style.addressNumberComplement">
                    <MdField :class="[$style.addressItem, $style.addressNumber]">
                      <label>{{$t('form.address.number')}}</label>
                      <MdInput v-model="addressSelected.number" type="number" ></MdInput>
                    </MdField>
                    <MdField :class="[$style.addressItem, $style.addressComplement]">
                      <label>{{$t('form.address.complement')}}</label>
                      <MdInput v-model="addressSelected.complement" type="text" ></MdInput>
                    </MdField>
                  </div>
                  <MdField :class="[$style.addressItem]">
                    <label>{{$t('form.address.zipcode')}}</label>
                    <MdInput v-model="addressSelected.zipcode" type="text" ></MdInput>
                  </MdField>

                  <BaseButton
                    :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
                    :disabled="tryingToUpdate"
                    @click="createIuguVerify"
                  >
                    <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                    <span v-else>
                      {{iuguInfo.last_verification_request_status==='accepted'?$t('paymentOptions.createIuguVerifyAccept'):$t('paymentOptions.createIuguVerify')}}
                    </span>
                  </BaseButton>

                </div>
              </div>
              <div v-else >
                {{$t('paymentOptions.needCreateIugu')}}
              </div>
            </MdTab>
            <MdTab id="bank" :md-label="$t('paymentOptions.bankTitle')" >
              <div v-if="!iuguNotFound" :class="$style.body">
                <MdField>
                  <label >{{$t('iugu.accountVerification.bank')}}</label>
                  <MdSelect v-model="form2.bank">
                    <MdOption v-for="(sl, slIndex) of stateList.bankList" :key="slIndex" :value="slIndex">{{sl}}</MdOption>
                  </MdSelect>
                </MdField>
                <MdField :class="$style.formItemText">
                  <label >{{$t('iugu.accountVerification.account_type')}}</label>
                  <MdSelect v-model="form2.account_type" name="account_type">
                    <MdOption key="Corrente" value="Corrente">{{$t('iugu.accountVerification.account_typeCorrente')}}</MdOption>
                    <MdOption key="Poupança" value="Poupança">{{$t('iugu.accountVerification.account_typePoupanca')}}</MdOption>
                  </MdSelect>
                </MdField>
                <BaseInputText
                  v-model="form2.bank_ag"
                  :label="$t('iugu.accountVerification.bank_ag')"
                >
                </BaseInputText>
                <BaseInputText
                  v-model="form2.bank_cc"
                  :label="$t('iugu.accountVerification.bank_cc')"
                >
                </BaseInputText>

                <label>{{$t('iugu.accountVerification.document')}}</label>
                <input ref='inputDocRef' type="file" name="inputDocRef" accept="*/*" @change="inputDocChange" />
                <br><br>


                <BaseButton
                  :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
                  :disabled="tryingToUpdate "
                  @click="createIuguVerifyBank"
                >
                  <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                  <span v-else>
                    {{$t('paymentOptions.createIuguVerifyBank')}}
                  </span>
                </BaseButton>
              </div>
              <div v-else >
                {{$t('paymentOptions.needCreateIugu')}}
              </div>
            </MdTab>
          </MdTabs>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 1000px;
      .content {
        max-width: 1000px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            .schedulesItens {
              width: 160px;
              display: inline-block;
              margin-right: 30px;
            }

            .schedulesItensTitle {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }



            .addressItem {
              margin-left: 12px;
            }

            .addressItemLock {
              margin-left: 12px;

            }
            .addressNumberComplement {
              display: flex;
              .addressNumber {
                flex: 2;
                width: 60px;
              }
              .addressComplement {
                flex: 8;
              }
            }
            .addressCityState {
              display: flex;
              .addressCity {
                flex: 8;
              }
              .addressState {
                flex: 2;
                width: 60px;
              }
            }
          }
        }
      }
    }
  }
</style>





















