<script>
  /* eslint-disable vue/no-v-html */
  import {appConfig} from '@src/config';
  import Lottie from 'vue-lottie';
  import * as animationData from '@assets/animation/animation1.json';

  export default {
    components: {Lottie},
    data() {
      return {
        appConfig: appConfig,
        tabSelected: 'tabFlexibility',
        lstItems: [
          {id: '1', key: 'item1', img: require('@assets/images/home/feature-image-placeholder_3@3x.png')},
          {id: '2', key: 'item2', img: require('@assets/images/home/feature-image-placeholder_2@3x.png')},
          {id: '3', key: 'item3', img: require('@assets/images/home/feature-image-placeholder@3x.png')},
          {id: '4', key: 'item4', img: require('@assets/images/home/feature-image-placeholder_6@3x.png')},
          {id: '5', key: 'item5', img: require('@assets/images/home/feature-image-placeholder_5@3x.png')},
          {id: '6', key: 'item6', img: require('@assets/images/home/feature-image-placeholder_4@3x.png')},
          ],
        defaultOptions: {animationData: animationData},
        animationSpeed: 1,
        anim: null,
        orderCode: 428,
      }
    },
    mounted: function() {
      this.orderCode = parseInt(Math.random()*900)+100;
    },
    methods: {
      handleAnimation: function (anim) {
        this.anim = anim;
      },

      stop: function () {
        this.anim.stop();
      },

      play: function () {
        this.anim.play();
      },

      pause: function () {
        this.anim.pause();
      },

      onSpeedChange: function () {
        this.anim.setSpeed(this.animationSpeed);
      }
    }
  }
</script>

<template>
  <div :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.header">
        <span :class="$style.headerTxt1">{{$t('home.haveMore.title1')}} </span>
        <span :class="$style.headerTxt2">{{$t('home.haveMore.title2')}}</span>
      </div>
      <div :class="$style.lstItems">
        <div v-for="item in lstItems" :key="item.id"  :class="$style.item">
          <div v-if="item.key==='item4'" :class="$style.itemAnimation">
            <div :class="$style.back">
              <div :class="$style.backCenter">
                {{orderCode}}
              </div>
            </div>
            <Lottie :class="$style.animation" :options="defaultOptions" @on:animCreated="handleAnimation"/>
            <div :class="$style.mask">
              <div :class="$style.maskCenter">

              </div>
            </div>
          </div>
          <img v-else :class="$style.itemImg" :src="item.img" />
          <div :class="$style.itemTitle">{{$t('home.haveMore.'+item.key+'.title')}}</div>
          <div :class="$style.itemDesc">{{$t('home.haveMore.'+item.key+'.desc')}}</div>
        </div>
      </div>
    </div>
  </div>

</template>


<style lang="scss" module>
  @import '@design';
  .box {
    width: 100%;
    background-color: #fafafa;
    background-image: linear-gradient(to bottom, #fafafa 50%, #ffffff);
    color: #000000;
    padding: 64px 0px 88px 0px;
    border-bottom: #eeeeee solid 1px;

    @media screen and (max-width: 980px) {
      padding: 29px 8px 20px 8px;
    }

    .container {
      max-width: 1052px;
   //   height: 269px;
      margin: 0 auto;
      //  min-height: 672px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .header {

        .headerTxt1, .headerTxt2 {
          font-family: $font-montserrat;
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.2px;
          text-align: center;
          color: #293343;
        }

        .headerTxt2 {
          color: #fe0518;
        }
      }

      .lstItems {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        justify-content: space-around;

        .item {
          margin-top: 30px;

          flex: 1 1 312px;
          max-width: 412px;
          margin-left: 16px;
          margin-right: 16px;
          text-align: left;

          @media screen and (max-width: 500px) {
            margin-left: 0;
            margin-right: 0;
          }

          .itemAnimation {
            height: 170px!important;
            position: relative;
            overflow: hidden;
         //   clip-path: circle(66px at center);

            .back {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;


              display: flex;
              justify-content: center;
              align-items: center;

              .backCenter {
                font-family: $font-openSans;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #383838;
              }
            }
            .animation {
              object-fit: contain;
              height: 170px!important;
              clip-path: circle(66px at center);
            }
            .mask {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              .maskCenter {
                height: 136px;
                width: 136px;
                border: #d1d1d6 solid 3px;
                border-radius: 100px;
              }
            }
          }

          .itemImg {
            object-fit: contain;
          }
          .itemTitle {
            margin-top: 8px;
            font-family: $font-openSans;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #383838;
          }
          .itemDesc {
            font-family: $font-openSans;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #4a4a4a;
          }
        }
      }
    }
  }
</style>
