"use strict";
// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};

toExport.BagStatus = {
  waitPayment: 'waitPayment',
  toOrder: 'toOrder',
  transformedInOrder: 'transformedInOrder',
  isBag: 'isBag',
  hasError: 'hasError',
  bagCanceled: 'bagCanceled',
  paymentError: 'paymentError'
};

toExport.GroupStatus = {
  waitReady: 'waitReady',
  waitStartPayment: 'waitStartPayment',
  waitFinishPayment: 'waitFinishPayment',
  finishPayment: 'finishPayment'
};

toExport.AllPaidStatus = {
  complete: 'complete',
  partial: 'partial',
  error: 'error',
  errorSum: 'errorSum',
};


// baseado nos status do iugu
toExport.PaymentStatus = {
  pending: 'pending',
  paid: 'paid',
  canceled: 'canceled',
  draft: 'draft',   // rascunho
  partially_paid: 'partially_paid',
  refunded: 'refunded',
  expired: 'expired',
  in_protest: 'in_protest',
  chargeback: 'chargeback',
  in_analysis: 'in_analysis',

  invalidPaymentMethod: 'invalidPaymentMethod',
  invalidPaymentCard: 'invalidPaymentCard',
  invalidPaymentUser: 'invalidPaymentUser',
  invalidPaymentUnknown: 'invalidPaymentUnknown',
  invalidPaymentAlreadyEarly: 'invalidPaymentAlreadyEarly',
  invalidPaymentLimitValue: 'invalidPaymentLimitValue',
  iuguNotApproved: 'iuguNotApproved',
  iuguResponseUnknown: 'iuguResponseUnknown',
  iuguPaymentErrorCatch: 'iuguPaymentErrorCatch',
  iuguPaymentError: 'iuguPaymentError',
  iuguRefundErrorCatch: 'iuguRefundErrorCatch',
  iuguRefundErrorResult: 'iuguRefundErrorResult',

  bagStatusError: 'bagStatusError',


  partial: 'partial'
};

toExport.OrderStatus = {
  waitPayment: 'waitPayment',
  waitConfirm: 'waitConfirm',
  confirmed: 'confirmed',
  inPreparation: 'inPreparation',
  waitTakeout: 'waitTakeout',
  onDelivery: 'onDelivery',
  deliveryReady: 'deliveryReady',
  takeoutReady: 'takeoutReady',
  canceled: 'canceled'
};

toExport.lstOrderStatusActive = [
  'waitConfirm',
  'confirmed',
  'inPreparation',
  'waitTakeout',
  'onDelivery',
  'deliveryReady',
  'takeoutReady',
];

toExport.companyOrderStatus = {
  waitPayment: {
    id: 'waitPayment',
    color: '#d3c806',
  },
  waitConfirm: {
    id: 'waitConfirm',
    color: '#d3c806',
    companyEdit: true,
    hidden: true,
  },
  confirmed: {
    id: 'confirmed',
    color: '#2473C2',
    companyEdit: true,
  },
  inPreparation: {
    id: 'inPreparation',
    color: '#5a2997',
    companyEdit: true,
  },
  waitDelivery: {
    id: 'waitDelivery',
    color: '#F18400',
    companyEdit: true,
  },
  waitTakeout: {
    id: 'waitTakeout',
    color: '#F18400',
    companyEdit: true,
  },
  onDelivery: {
    id: 'onDelivery',
    color: '#F18400',
    companyEdit: true,
  },
  deliveryReady: {
    id: 'deliveryReady',
    color: '#0FA435',
    companyEdit: true,
  },
  takeoutReady: {
    id: 'takeoutReady',
    color: '#0FA435',
    companyEdit: true,
  },
  canceled: {
    id: 'canceled',
    color: '#FF0000',
    companyEdit: true,
  }
};


toExport.companyOrderDeliveryType = {
  'pickup': {
    id: 'pickup',
    icon: 'pan_tool'
  },
  'delivery': {
    id: 'delivery',
    icon: 'motorcycle'
  },
  'onSite': {
    id: 'onSite',
    icon: 'pan_tool'
  },
};

toExport.companyOrderStatusSequence = {
  pickup: [
    'waitConfirm',
    'confirmed',
    'inPreparation',
    'waitTakeout',
    'takeoutReady'
  ],
  delivery: [
    'waitConfirm',
    'confirmed',
    'inPreparation',
    'onDelivery',
    'deliveryReady'
  ],
  onSite: [
    'waitConfirm',
    'confirmed',
    'inPreparation',
    'waitTakeout',
    'takeoutReady'
  ],
};
toExport.companyOrderStatusSequenceGraph = {
  pickup: [
    'confirmed',
    'inPreparation',
    'waitTakeout',
    'takeoutReady'
  ],
  delivery: [
    'confirmed',
    'inPreparation',
    'onDelivery',
    'deliveryReady'
  ],
  onSite: [
    'confirmed',
    'inPreparation',
    'waitTakeout',
    'takeoutReady'
  ],
};

toExport.companyOrderPaymentStatus = {
  wait: {
    id: 'wait',
    color: '#d3c806'
  },
  waitConfirm: {
    id: 'waitConfirm',
    color: '#d3c806'
  },
  confirm: {
    id: 'confirm',
    color: '#0FA435'
  },
  canceledUser: {
    id: 'canceledUser',
    color: '#FF0000'
  },
  canceledCompany: {
    id: 'canceledCompany',
    color: '#FF0000'
  },
  operatorCardCanceled: {
    id: 'operatorCardCanceled',
    color: '#FF0000'
  },
  extorted: {
    id: 'extorted',
    color: '#FF0000'
  },
  orderCanceled: {
    id: 'orderCanceled',
    color: '#FF0000'
  }
};


toExport.rules = {
  adm: [
    'adm',
    'sell',
    'analy',
    'sup',
  ],
  cli: [
  ],
  comp: [
    'adm',
    'edit',
    'deli',
    'att',
  ],
};

toExport.apiErrorCode = {
  permissionDenied: 'permissionDenied',
  statusError: 'statusError',
  inputError: 'inputError',
  unknown: 'unknown',
  exhaustedAttempts: 'exhaustedAttempts',

  paymentError: 'paymentError',
  bagError: 'bagError',
  paymentInvalidValue: 'paymentInvalidValue',
  notFound: 'notFound',

  bagEmptyProduct: 'bagEmptyProduct',
  bagEmptyAddress: 'bagEmptyAddress',



};

// response example: {isOk:false, errorCode: orderErrorCode.unknown, msg:''}


toExport.OrderPaymentMethodType = {
  OnApp: 'OnApp',
  whenReceive: 'whenReceive'
};


toExport.OrderControlStatus = {
  onEdit: 'onEdit',
  waitFinishPayment: 'waitFinishPayment',  // Order não pode ser editada
  paymentComplete: 'paymentComplete',
};

toExport.VerifyStatus = {
  approved: "approved",
  pending: "pending",
  waitApproved: "waitApproved",
};

toExport.bankList = {
  "001": "Banco do Brasil",
  "033": "Santander",
  "104": "Caixa Econômica",
  "237": "Bradesco",
  "341": "Itaú",
  "041": "Banrisul",
  "748": "Sicredi",
  "756": "Sicoob",
  "077": "Inter",
  "070": "BRB",
  "085": "Via Credi",
  "655": "Neon",
  "260": "Nubank",
  "290": "Pagseguro",
  "212": "Banco Original",
  "422": "Safra",
  "746": "Modal",
  "021": "Banestes",
};


toExport.Workspace = {
  COMPANY: 'COMPANY',
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  ONG: 'ONG',
};


toExport.JobState = {
    NOT_STARTED: "NOT_STARTED",
    RUNNING: "RUNNING",
    FINISH: "FINISH",
};




module.exports = toExport;

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }
