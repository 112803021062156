<script>
  import { authComputed } from '@state/helpers';

  import firebase from 'firebase/app';
//  const timeUtils = require ('@common/time_utils');
  import BarChart from '@components/charts/bar';
  import OrderEdit from '@components/products/order_edit';
  import {companyService} from '@common/services';
  import SearchPick from '@components/diversos/searchPick';

  const stateList = require ('@common/status');
  const generetors = require ('@common/generators');

  export default {
    name: 'HistoryEdit',
    components: {BarChart, OrderEdit, SearchPick},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      },
      isSuperAdmin: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        uploadProgress: 0,
        isLoading: false,
        dateBegin: new Date(),
        dateEnd: new Date(),
        balance: {},
        iuguInfo: {},
        thatMonthKey: '',
        lastMonthKey: '',

        form: [],

        test: {
          labels: ['ald', 'new'],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [2, 20]
            }, {
              label: 'Data One',
              backgroundColor: '#4b50f8',
              data: [1, 14]
            }
          ]
        },
        chartMonthData: null,

        chartMonthOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        },
        orderResumes: [],


        ini: null,
        end: null,

        total: {
          payByAppGoTap: 0,
          payByAppSubAccount: 0,
          payByOnReceiveCash: 0,
          payByOnReceiveCard: 0,
          all: 0,
          android: 0,
          ios: 0,
          envUndefined: 0,
          male: 0,
          female: 0,
          sexUndefined: 0,
          ages: {
            _0000: 0,
            _0012: 0,
            _1218: 0,
            _1824: 0,
            _2436: 0,
            _3642: 0,
            _4250: 0,
            _5000: 0
          },
          deliveryType: {},
          regions: {},
          hubs: {},
        },
        totalDefault: {
          payByAppGoTap: 0,
          payByAppSubAccount: 0,
          payByOnReceiveCash: 0,
          payByOnReceiveCard: 0,
          all: 0,
          android: 0,
          ios: 0,
          envUndefined: 0,
          male: 0,
          female: 0,
          sexUndefined: 0,
          ages: {
            _0000: 0,
            _0012: 0,
            _1218: 0,
            _1824: 0,
            _2436: 0,
            _3642: 0,
            _4250: 0,
            _5000: 0
          },
          deliveryType: {},
          regions: {},
          hubs: {},
        },


        totalQtd: {
          payByAppGoTap: 0,
          payByAppSubAccount: 0,
          payByOnReceiveCash: 0,
          payByOnReceiveCard: 0,
          all: 0,
          android: 0,
          ios: 0,
          envUndefined: 0,
          male: 0,
          female: 0,
          sexUndefined: 0,
          ages: {
            _0000: 0,
            _0012: 0,
            _1218: 0,
            _1824: 0,
            _2436: 0,
            _3642: 0,
            _4250: 0,
            _5000: 0
          },
          status: {},
          orderCount: 0,
          orderCountValid: 0,
          deliveryType: {},
          regions: {},
          hubs: {},
        },
        totalQtdDefault: {
          payByAppGoTap: 0,
          payByAppSubAccount: 0,
          payByOnReceiveCash: 0,
          payByOnReceiveCard: 0,
          all: 0,
          android: 0,
          ios: 0,
          envUndefined: 0,
          male: 0,
          female: 0,
          sexUndefined: 0,
          ages: {
            _0000: 0,
            _0012: 0,
            _1218: 0,
            _1824: 0,
            _2436: 0,
            _3642: 0,
            _4250: 0,
            _5000: 0
          },
          status: {},
          orderCount: 0,
          orderCountValid: 0,
          deliveryType: {},
          regions: {},
          hubs: {},
        },


        secondsYear: 52596000,
        orderId: '',
        companyList: [],
        regionsList: [],
        hubsList: [],
        regionsSelected : [],
        hubsSelected : [],

      }
    },
    validations: {

    },
    computed: {
      ...authComputed,
      showModal: {
        get() {
          return !!this.orderId;
        },
        set(value) {
          console.error('showModal set', value);
        }
      }
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      this.thatMonthKey = moment().format('YYYYMM');
      this.lastMonthKey = moment().subtract(1, 'month').format('YYYYMM');

      let regionsObjP = firebase.firestore().collection('regions').get();
      let hubsObjP = firebase.firestore().collection('hubs').get();
      let regionsList = [];
      (await regionsObjP).forEach((categoryObj) => {
        regionsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
      });
      this.regionsList = regionsList;

      let hubsList = [];
      (await hubsObjP).forEach((categoryObj) => {
        hubsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
      });
      this.hubsList = hubsList;

      await this.loadForm();
      this.isLoading = false;
    },
    beforeMount: async function() {

    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        if(!this.itemId && !this.isSuperAdmin) {
          console.log('no found this.itemId');
          return;
        }
        this.isLoading = true;

        this.total = _.cloneDeep(this.totalDefault);
        this.totalQtd = _.cloneDeep(this.totalQtdDefault);
        // let companySchedule = {};


        this.loadExtraData(this.itemId);


        // console.log('loaded companySchedule', companySchedule);
        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async loadExtraData(itemId) {
        if(!itemId) {
          this.balance = {};
          this.iuguInfo = {};
          this.chartMonthData = null;
          return;
        }
        let iuguInfoP = firebase.functions().httpsCallable('companiesPayments-getIuguInfo')({
          id: itemId,
          lang: this.$i18n.locale
        });

        this.balance = (await firebase.firestore().collection('companiesBalance').doc(itemId).get()).data();
        let iuguInfo = await iuguInfoP;

        if(iuguInfo.data && iuguInfo.data.isOk && iuguInfo.data.data) {
          this.iuguInfo = iuguInfo.data.data;
        }

        let chartMonthData = {
          labels: [],
          datasets: [
            {
              label: this.$t('history.volume'),
              backgroundColor: '#f87979',
              data: []
            },
            {
              label: this.$t('history.receivable'),
              backgroundColor: '#493cf8',
              data: []
            }
          ],
        };

        if(this.balance && this.balance.months) {
          for(const monthId in this.balance.months) {
            let name = monthId.slice(4,6)+'/'+monthId.slice(0,4);
            chartMonthData.labels.push(name);
            chartMonthData.datasets[0].data.push(this.balance.months[monthId].volume);
            chartMonthData.datasets[1].data.push(this.balance.months[monthId].receivable);

          }
        }
        this.chartMonthData = chartMonthData;
      },

      async conclude() {
       // let form = _.cloneDeep(this.form);
        return this.getOrders();
      },

      async getOrders() {
        this.isLoading = true;
        let ini = moment(this.dateBegin).toDate();
        let end = moment(this.dateEnd).endOf('day').toDate();
        this.total = _.cloneDeep(this.totalDefault);
        this.totalQtd = _.cloneDeep(this.totalQtdDefault);

       // const globalConfigOng = (await firebase.firestore().collection('config').doc('ong').get()).data() || {};
       // const platePrice = globalConfigOng.plate || 2.5;


        let companyIds = [];
        if(this.isSuperAdmin) {
          if(this.companyList.length>0) {
            for(let company of this.companyList) {
              companyIds.push(company.id);
              if(companyIds.length>9) {
                break;
              }
            }
          }
          if(this.companyList.length===1) {
            this.loadExtraData(this.companyList[0].id);
          }
          else {
            this.loadExtraData();
          }
        }
        else {
          companyIds.push(this.itemId);
        }

        let orderResumesTmp = [];

        if(this.regionsSelected.length>10) {
          this.regionsSelected.splice(10);
        }
        if(this.hubsSelected.length>10) {
          this.hubsSelected.splice(10);
        }

        if((companyIds.length>1?1:0)+(this.regionsSelected.length>1?1:0)+(this.hubsSelected.length>1?1:0)>2) {
          this.$showError({text: this.$t('history.errorSelect')});
          console.error('Dados inválidos para busca');
          return;
        }

        for(let itemsGen = generetors.getOrderResume(ini, end, companyIds, this.regionsSelected, this.hubsSelected), itemObj = await itemsGen.next(),
            item = itemObj.value?itemObj.value.data():{} || {}, itemId = itemObj.value?itemObj.value.id:'';
          itemObj.value && !itemObj.done;
          itemObj = await itemsGen.next(),
            item = itemObj.value?itemObj.value.data():{} || {}, itemId = itemObj.value?itemObj.value.id:'') {
          item.id = itemId;
          item.lstUsersTxt = '';
          for(const userId in item.lstUsers || []) {
            item.lstUsersTxt = (item.lstUsersTxt?item.lstUsersTxt+', ':'')+ item.lstUsers[userId].name+' '+(item.lstUsers[userId].lastName||'');
          }

          orderResumesTmp.push(item);
          this.totalQtd.status[item.status] = this.totalQtd.status[item.status] || 0;
          this.totalQtd.status[item.status]++;


          this.totalQtd.orderCount++;
          if(item.status!==stateList.OrderStatus.canceled) {
            this.totalQtd.orderCountValid++;
            let value = 0;
            if(!item.groupCode) {
              for(let userId in item.paymentMethod) {
                this.contabilize( item.paymentMethod[userId], item.totalValue, item.lstUsers[userId]);
                value = item.totalValue;
              }
            }
            else {
              for(let userId in item.paymentMethod) {
                this.contabilize( item.paymentMethod[userId], item.paymentMethod[userId].groupUserTotalValue, item.lstUsers[userId]);
                value += item.paymentMethod[userId].groupUserTotalValue;
              }
            }

            this.totalQtd.deliveryType[item.deliveryType] = this.totalQtd.deliveryType[item.deliveryType] || 0;
            this.totalQtd.deliveryType[item.deliveryType]++;
            this.total.deliveryType[item.deliveryType] = this.total.deliveryType[item.deliveryType] || 0;
            this.total.deliveryType[item.deliveryType] +=value;

            if(this.isSuperAdmin) {
              if(item.company && item.company.address) {
                if(item.company.address.regionId) {
                  this.totalQtd.regions[item.company.address.regionId] = this.totalQtd.regions[item.company.address.regionId] || 0;
                  this.totalQtd.regions[item.company.address.regionId]++;

                  this.total.regions[item.company.address.regionId] = this.total.regions[item.company.address.regionId] || 0;
                  this.total.regions[item.company.address.regionId] +=value;
                }
                if(item.company.address.hubId && item.company.address.hasHub) {
                  this.totalQtd.hubs[item.company.address.hubId] = this.totalQtd.hubs[item.company.address.hubId] || 0;
                  this.totalQtd.hubs[item.company.address.hubId]++;

                  this.total.hubs[item.company.address.hubId] = this.total.hubs[item.company.address.hubId] || 0;
                  this.total.hubs[item.company.address.hubId] +=value;
                }
              }
            }


          }
        }

        if(!orderResumesTmp.length) {
          this.$showMsg({text:this.$t('history.emptyData')});
        }



        if((this.total.payByAppGoTap+this.total.payByAppSubAccount+this.total.payByOnReceiveCash+this.total.payByOnReceiveCard).toFixed(2)!==this.total.all.toFixed(2)) {
          console.error('Total inválido', this.total.payByAppGoTap+this.total.payByAppSubAccount+this.total.payByOnReceiveCash+this.total.payByOnReceiveCard, this.total.all);
        }

        this.orderResumes = orderResumesTmp;
        this.isLoading = false;
      },
      loadMore: function() {
        this.busy = true;

        setTimeout(() => {
          for (var i = 0, j = 10; i < j; i++) {
            this.data.push({ name: this.count++ });
          }
          this.busy = false;
        }, 1000);
      },
      _moment(date) {
        return moment(date);
      },
      contabilize(pm, value, user) {
        this.total.all += value;
        this.totalQtd.all ++;
        switch(pm.type) {
          case stateList.OrderPaymentMethodType.OnApp:
            if(pm.isSubAccount) {
              this.total.payByAppSubAccount += value;
              this.totalQtd.payByAppSubAccount ++;
            }
            else {
              this.total.payByAppGoTap += value;
              this.totalQtd.payByAppGoTap ++;
            }
            break;
          case stateList.OrderPaymentMethodType.whenReceive:
            if(pm.deliveryPaymentMethod) {
              if(pm.deliveryPaymentMethod.isCash) {
                this.total.payByOnReceiveCash += value;
                this.totalQtd.payByOnReceiveCash ++;
              }
              else {
                this.total.payByOnReceiveCard += value;
                this.totalQtd.payByOnReceiveCard ++;
              }
            }
            else {
              console.error('pm.deliveryPaymentMethod not found');
            }
            break;
          default:
            console.error('paymentType inválido', pm.type);
        }
        if(user.gender==='F') {this.total.female+=value;this.totalQtd.female++;}
        else if(user.gender==='M') {this.total.male+=value;this.totalQtd.male++;}
        else {this.total.sexUndefined+=value;this.totalQtd.sexUndefined++;}

        if(user.createdEnv==='android') {this.total.android+=value;this.totalQtd.android++;}
        else if(user.createdEnv==='iOS') {this.total.ios+=value;this.totalQtd.ios++;}
        else {this.total.envUndefined+=value;this.totalQtd.envUndefined++;}
        if(user.age) {
          if(user.age<this.secondsYear*18) {this.total.ages._0018+=value;this.totalQtd.ages._0018++;}
          else if(user.age<this.secondsYear*12) {this.total.ages._0012+=value;this.totalQtd.ages._0012++;}
          else if(user.age<this.secondsYear*18) {this.total.ages._1218+=value;this.totalQtd.ages._1218++;}
          else if(user.age<this.secondsYear*24) {this.total.ages._1824+=value;this.totalQtd.ages._1824++;}
          else if(user.age<this.secondsYear*42) {this.total.ages._3642+=value;this.totalQtd.ages._3642++;}
          else if(user.age<this.secondsYear*50) {this.total.ages._4250+=value;this.totalQtd.ages._4250++;}
          else {this.total.ages._5000+=value;this.totalQtd.ages._5000++;}
        }
        else {
          this.total.ages._0000+=value;
          this.totalQtd.ages._0000++;
        }


      },
      orderClick(orderId) {
        this.orderId = orderId;
      },
      async getCompanies(input) {
        return companyService.getCompanies({
          search: input.search,
          size: input.size,
          from: input.from,
          lang: this.$i18n.locale
        });
      },

    },
  }
</script>


<template>
  <div v-show="itemId || isSuperAdmin" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">

          <div :class="$style.body">

            <BarChart
              v-if="chartMonthData"
              :class="$style.chartMonth"
              :chart-data="chartMonthData"
              :options="chartMonthOptions"
            >
            </BarChart>


            <div :class="$style.filters">

              <div :class="$style.searchPickContent">
                <SearchPick v-if="isSuperAdmin"
                            v-model="companyList"
                            :get-items="getCompanies"
                            :label="$t('history.selectCompanies')"
                            :show-img="true"
                            :filter-region="true"
                            :limit="10"
                >
                  <template #item="slotProp" >
                    <div>{{slotProp.item.name}}</div>
                  </template>
                  <template #selected="slotProp" >
                    <div>{{slotProp.item.name}}</div>
                  </template>
                </SearchPick>
              </div>

              <MdDatepicker v-model="dateBegin" :md-open-on-focus="true" md-immediately :class="['formItemText', $style.date]" >
                <label>{{$t('history.dateBegin')}}</label>
              </MdDatepicker>
              <MdDatepicker v-model="dateEnd" :md-open-on-focus="true" md-immediately :class="['formItemText', $style.date]" >
                <label>{{$t('history.dateEnd')}}</label>
              </MdDatepicker>
              <MdField v-if="isSuperAdmin" :class="$style.selectRegions">
                <label >{{$t('history.selectRegions')}}</label>
                <MdSelect v-model="regionsSelected" name="region" multiple>
                  <MdOption v-for="(value) in regionsList" :key="value.id" :value="value.id">{{value.nameView}}</MdOption>
                </MdSelect>
              </MdField>
              <MdField v-if="isSuperAdmin" :class="$style.selectHubs">
                <label >{{$t('history.selectHubs')}}</label>
                <MdSelect v-model="hubsSelected" name="region" multiple>
                  <MdOption v-for="(value) in hubsList" :key="value.id" :value="value.id">{{value.nameView}}</MdOption>
                </MdSelect>
              </MdField>
              <BaseButton
                :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
                :disabled="tryingToUpdate"
                @click="conclude"
              >
                <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('form.search')}}
                </span>
              </BaseButton>
            </div>
            <p v-if="hasError" :class="$style.error">
              {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
            </p>


            <MdTable :class="$style.orderTable" >
              <MdTableRow>
                <md-table-head>{{$t('history.lstUsersTxt')}}</md-table-head>
                <md-table-head md-numeric>{{$t('history.totalValue')}}</md-table-head>
                <md-table-head md-date>{{$t('history.date')}}</md-table-head>
                <md-table-head>{{$t('history.status')}}</md-table-head>
                <md-table-head v-if="isSuperAdmin" md-numeric>{{$t('history.companyName')}}</md-table-head>
                <md-table-head md-numeric>{{$t('history.orderCode')}}</md-table-head>
                <md-table-head md-numeric>{{$t('history.orderNumber')}}</md-table-head>
              </MdTableRow>

              <MdTableRow v-for="(item) in orderResumes" :key="item.id" :class="$style.orderRow" @click="orderClick(item.id)" >
                <md-table-cell>{{item.lstUsersTxt}}</md-table-cell>
                <md-table-cell md-numeric>{{$n(item.totalValue, 'currency')}}</md-table-cell>
                <md-table-cell md-date>{{_moment(item.transformedInOrderDate.toDate()).format($t('format.data'))}}</md-table-cell>
                <md-table-cell>{{$t('orderStatus.'+item.status)}}</md-table-cell>
                <md-table-cell v-if="isSuperAdmin" md-numeric>{{(item.company || {}).name}}</md-table-cell>
                <md-table-cell md-numeric>{{item.orderCode}}</md-table-cell>
                <md-table-cell md-numeric>{{item.orderNumber}}</md-table-cell>
              </MdTableRow>
              <MdTableRow v-if="!orderResumes || !orderResumes.length" :class="$style.orderRow" >
                <md-table-cell colspan="100">{{$t('history.emptyLabel')}}</md-table-cell>
              </MdTableRow>

            </MdTable>

            <h3>{{$t('history.valueOrders')}} <span>{{$t('history.notCanceled')}}</span></h3>
            <div :class="$style.myTable">
              <div :class="[$style.bloco]">
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.payByApp')}}</div>
                  <div :class="$style.c2">{{$n(total.payByAppGoTap+total.payByAppSubAccount, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]">{{$t('history.total.payByAppGoTap')}}</div>
                  <div :class="$style.c2">{{$n(total.payByAppGoTap, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]" >{{$t('history.total.payByAppSubAccount')}}</div>
                  <div :class="$style.c2">{{$n(total.payByAppSubAccount, 'currency')}}</div>
                </div>
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.payByOnReceive')}}</div>
                  <div :class="$style.c2">{{$n(total.payByOnReceiveCash+total.payByOnReceiveCard, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]">{{$t('history.total.payByOnReceiveCash')}}</div>
                  <div :class="$style.c2">{{$n(total.payByOnReceiveCash, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]">{{$t('history.total.payByOnReceiveCard')}}</div>
                  <div :class="$style.c2">{{$n(total.payByOnReceiveCard, 'currency')}}</div>
                </div>
              </div>


              <div :class="[$style.bloco]">
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.android')}}</div>
                  <div :class="$style.c2">{{$n(total.android, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.ios')}}</div>
                  <div :class="$style.c2">{{$n(total.ios, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.envUndefined')}}</div>
                  <div :class="$style.c2">{{$n(total.envUndefined, 'currency')}}</div>
                </div>
              </div>

              <div :class="[$style.bloco]">
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.male')}}</div>
                  <div :class="$style.c2">{{$n(total.male, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.female')}}</div>
                  <div :class="$style.c2">{{$n(total.female, 'currency')}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.total.sexUndefined')}}</div>
                  <div :class="$style.c2">{{$n(total.sexUndefined, 'currency')}}</div>
                </div>
              </div>

              <div v-if="total.deliveryType && total.deliveryType.length>0" :class="[$style.bloco]">
                <div v-for="(status, index) in total.deliveryType" :key="index" :class="[$style.linha]" :style="'margin-top:0px;'+(status>0?'':'display:none;')">
                  <div :class="[$style.c1, $style.t1]">{{$t('deliveryType.'+index)}}</div>
                  <div :class="$style.c2">{{status}}</div>
                </div>
              </div>
              <div :class="[$style.bloco]">
                <div :class="[$style.linha]" style="font-weight: 600;">
                  <div :class="[$style.c1, $style.t0]">{{$t('history.total.all')}}</div>
                  <div :class="$style.c2">{{$n(total.all, 'currency')}}</div>
                </div>
              </div>
              <div :class="[$style.linhaM]"></div>
            </div>
            <!--  <div v-for="(age, index) in total.ages" :key="index" :class="[$style.linha]">
                <div :class="[$style.c1, $style.t1]" style="margin-left: 15px">{{$t('history.total.ages.'+index)}}</div>
                <div :class="$style.c2">{{$n(age, 'currency')}}</div>
              </div> -->
            <h3>{{$t('history.qtdPayments')}} <span>{{$t('history.notCanceled')}}</span></h3>
            <div :class="$style.myTable">
              <div :class="[$style.bloco]">
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.payByApp')}}</div>
                  <div :class="$style.c2">{{totalQtd.payByAppGoTap+totalQtd.payByAppSubAccount}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]">{{$t('history.totalQtd.payByAppGoTap')}}</div>
                  <div :class="$style.c2">{{totalQtd.payByAppGoTap}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]">{{$t('history.totalQtd.payByAppSubAccount')}}</div>
                  <div :class="$style.c2">{{totalQtd.payByAppSubAccount}}</div>
                </div>
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.payByOnReceive')}}</div>
                  <div :class="$style.c2">{{totalQtd.payByOnReceiveCash+totalQtd.payByOnReceiveCard}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]">{{$t('history.totalQtd.payByOnReceiveCash')}}</div>
                  <div :class="$style.c2">{{totalQtd.payByOnReceiveCash}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t2]">{{$t('history.totalQtd.payByOnReceiveCard')}}</div>
                  <div :class="$style.c2">{{totalQtd.payByOnReceiveCard}}</div>
                </div>
              </div>

              <div :class="[$style.bloco]">
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.android')}}</div>
                  <div :class="$style.c2">{{totalQtd.android}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.ios')}}</div>
                  <div :class="$style.c2">{{totalQtd.ios}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.envUndefined')}}</div>
                  <div :class="$style.c2">{{totalQtd.envUndefined}}</div>
                </div>
              </div>

              <div :class="[$style.bloco]">
                <div :class="[$style.linha, $style.linhaM]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.male')}}</div>
                  <div :class="$style.c2">{{totalQtd.male}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.female')}}</div>
                  <div :class="$style.c2">{{totalQtd.female}}</div>
                </div>
                <div :class="[$style.linha]">
                  <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.sexUndefined')}}</div>
                  <div :class="$style.c2">{{totalQtd.sexUndefined}}</div>
                </div>
              </div>

              <div v-if="total.deliveryType && total.deliveryType.length>0" :class="[$style.bloco]">
                <div v-for="(status, index) in totalQtd.deliveryType" :key="index" :class="[$style.linha]" :style="'margin-top:0px;'+(status>0?'':'display:none;')">
                  <div :class="[$style.c1, $style.t1]">{{$t('deliveryType.'+index)}}</div>
                  <div :class="$style.c2">{{status}}</div>
                </div>
              </div>
              <div :class="[$style.bloco]">
                <div :class="[$style.linha]" style="font-weight: 600;">
                  <div :class="[$style.c1, $style.t0]">{{$t('history.totalQtd.all')}}</div>
                  <div :class="$style.c2">{{totalQtd.all}}</div>
                </div>
              </div>
              <div :class="[$style.linha, $style.linhaM]"></div>
            </div>
            <!--  <div v-for="(age, index) in totalQtd.ages" :key="index" :class="[$style.linha]">
                <div :class="[$style.c1, $style.t1]" style="margin-left: 15px">{{$t('history.totalQtd.ages.'+index)}}</div>
                <div :class="$style.c2">{{age}}</div>
              </div> -->

            <h3>{{$t('history.qtdOrders')}}</h3>
            <div :class="$style.myTable">
              <div :class="[$style.linha]" style="font-weight: 600;">
                <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.orderCount')}}</div>
                <div :class="$style.c2">{{totalQtd.orderCount}}</div>
              </div>
              <div :class="[$style.linha]" style="font-weight: 600;">
                <div :class="[$style.c1, $style.t1]">{{$t('history.totalQtd.orderCountValid')}}</div>
                <div :class="$style.c2">{{totalQtd.orderCountValid}}</div>
              </div>
              <div v-for="(status, index) in totalQtd.status" :key="index" :class="[$style.linha]" :style="'margin-top:0px;'+(status>0?'':'display:none;')">
                <div :class="[$style.c1, $style.t1]">{{$t('orderStatus.'+index)}}</div>
                <div :class="$style.c2">{{status}}</div>
              </div>


            </div>

          </div>
          <MdDialog v-if="orderId" :md-active.sync="showModal" :class="$style.modal">
            <OrderEdit v-if="orderId"
              v-model="orderId"
              :is-modal="true"
            >
            </OrderEdit>
          </MdDialog>

        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 600px;
      .content {
        max-width: 900px;
        .contentLoaded {
          .body {
            .filters {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              div {
                margin-right: 10px;
              }
              .selectRegions {
                max-width: 300px;
              }
              .selectHubs {
                max-width: 300px;
              }
              .searchPickContent {
                width: 100%;
              }
              margin-bottom: 25px;
            }


            .date {
              max-width: 230px;
            }

            .chartMonth {
              height: 300px;
              width: 300px;
              position: relative;
            }


            .orderTable {
              max-height: 500px;
              .orderRow {
                cursor: pointer;
              }
            }

            .myTable {
              max-width: 400px;
              h3 {
                margin-top:50px;
              }
              .bloco {
                border: solid 1px #bfb5bb;
               // margin-top: 10px;
                padding: 6px;
              }
              .linha {
                display: flex;
                justify-content: space-between;
                .c1 {

                }
                .c2{
                  width: 120px;
                  text-align: end;
                }
                .t0 {
                  margin-left: 0px;
                }
                .t1 {
                  margin-left: 0px;
                }
                .t2 {
                  margin-left: 25px;
                }

              }
              .linhaM {
              //  margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
</style>





















