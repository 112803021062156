// export * as userService from './user.service';
// export * as chatService from './chat.service';
"use strict";

// Example of use:   import {chatService} from '@services';

let toExport = {};

toExport.chatService = require('./chat.service');
toExport.userService = require('./user.service');
toExport.companyService = require('./company.service');
toExport.productService = require('./product.service');









module.exports = toExport;
