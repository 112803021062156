<script>
// import NavBar from '@components/nav-bar'
import NavBar from '@components/layout/company/nav-bar'
import Footer from '@components/layout/footer'
import { authComputed } from '@state/helpers'
import Version from '@components/layout/version'

export default {
  components: { NavBar, Footer, Version },
  props: {
  },
  computed: {
    ...authComputed
  }
}
</script>

<template>
  <div :class="$style.box">
    <Version/>
    <NavBar :class="$style.navbar" />
    <div :class="$style.container" >
      <slot  />
    </div>

    <Footer :class="$style.footer" />

  </div>
</template>

<style lang="scss" module>
@import '@design';

.box {
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
}

.navbar {
  flex: 0 0 auto;
}

.container {
  /*margin: 0 auto;*/
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}


</style>
