<script>
  import { configComputed, timeComputed, authComputed} from '@state/helpers'
  const timeUtils = require ('@common/time_utils');
 // let {translateBd} = require('@i18n');
  export default {
    data () {
      return {
        isClose: false,
        showAlert: false,
        isCloseTime: 0,
        lastCheck: 0,
        versionNumber: process.env.VUE_APP_VERSION_NUMBER,
        version: process.env.VUE_APP_VERSION
      }
    },
    computed: {
      ...authComputed,
      ...configComputed,
      ...timeComputed,
    },
    watch: {
      nowMinuted() {
        if(this.isClose && this.nowMinuted>this.isCloseTime) {
          this.isClose = false;
        }
        if(this.currentCompany.active && this.nowMinuted>this.lastCheck) {
          this.lastCheck = new Date(this.nowMinuted.getTime()+1000*60*10);
          if(!this.currentCompany.isOpen && timeUtils.companyShouldBeOpen(this.currentCompany)) {
            this.showToast();
            this.showAlert = true;
          }
        }

      }
    },
    mounted: async function () {
      if(this.currentCompany.active && !this.currentCompany.isOpen && timeUtils.companyShouldBeOpen(this.currentCompany)) {
        this.showToast();
        this.showAlert = true;
      }
    },
    methods: {
      close() {
        this.isClose = true;
        this.isCloseTime = new Date(this.nowMinuted.getTime()+1000*60*10);
      },
      showToast() {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'warning',
          title: this.$t('company.alertClosed'),
          showConfirmButton: false,
          timer: 9000*60
        });
      }
    },

  }

</script>


<template>
<div v-if="showAlert && !isClose" :class="[$style.alert]" >
  <div :class="[$style.msg]">
    {{$t('company.alertClosed')}}
  </div>
  <div :class="[$style.closeButton]" @click="close"><MdIcon>close</MdIcon></div>
</div>
</template>


<style lang="scss" module>
@import '@design';

  .alert {
    @extend %defaultAlert;

    width: 100%;
    padding: 10px;
    position: absolute !important;
    display: flex;

    .msg {
      flex: 1;
    }

    .closeButton {
      flex: 0;
      color: #bb2f2c;
      cursor: pointer;
      top: 9px;
      right: 30px;

      i {
        color: #bb2f2c!important;
      }
    }
  }




</style>
