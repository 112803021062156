"use strict";
const firebase = require('firebase/app');

let toExport = {};


toExport.getDataSimple = async function* (ref, limit=1000) {
  let itemsObj = await ref.limit(limit).get();
  while(itemsObj.docs && itemsObj.docs.length>0) {
    const last = itemsObj.docs[itemsObj.docs.length-1];
    for(const index in itemsObj.docs) {
      const itemObj = itemsObj.docs[index].data();
      Object.defineProperty(itemObj, 'id', {
        enumerable: false,
        value: itemsObj.docs[index].id
      });
      yield itemObj;
    }
    itemsObj = await ref.limit(limit).startAfter(last).get();
  }
}

/*

  (async()=>{
    let ref = firebaseDebug.firestore().collection('companies');
    for await(const item of getDataSimple2(ref)) {
        console.log('item', item);
    }
  })();





  // Examplo de uso da getDataSimple
export const subCategoryOnWrite = functions.firestore.document('/companiesSubCategories/{documentId}')
.onWrite(async (change, context) => {
  const after = change.after.data() || null;
  const before = change.before.data() || null;
  const docId = context.params.documentId;
  let total = 0;
  let companyId = null;
  if(after) {
    companyId = after.companyId;
  }
  if(before && !companyId) {
    companyId = before.companyId;
  }
  if(await dataConvert.needUpdateSubCategory(docId, before, after)) {
    let ref = admin.firestore().collection('companiesProducts')
        .where('companyId', '==', companyId)
        .where('subCategoryId', '==', docId);
    let batch = admin.firestore().batch();
    let cont = 0;
    const resume = await dataConvert.subCategoryResume(after);

    for await(const item of utils.getDataSimple(ref)) {
      if (after) {
        if (!_.isEqual(item.subCategory, resume)) {
          batch.update(admin.firestore().collection('companiesProducts').doc(item.id), {subCategory: resume});
        }
      } else {
        batch.update(admin.firestore().collection('companiesProducts').doc(item.id), {
          subCategory: admin.firestore.FieldValue.delete(),
          subCategoryId: admin.firestore.FieldValue.delete()
        });
      }
      cont++;
      total++;
      if (cont > 200) {
        await batch.commit();
        batch = admin.firestore().batch();
        cont = 0;
      }
    }
    await batch.commit();
  }
  return 'OK, total:'+total;
});

 */


toExport.getCompanies = async function*(ini = false, end = false) {
  let ref = firebase.firestore().collection('companies');
  if(ini) {
    ref = ref.where('createdAt', '>=', ini);
  }
  if(end) {
    ref = ref.where('createdAt', '<', end);
  }
  let itemsObj = await ref.get();
  while(itemsObj.docs && itemsObj.docs.length>0) {
    const last = itemsObj.docs[itemsObj.docs.length-1];
    for(const index in itemsObj.docs) {
      const itemObj = itemsObj.docs[index];
      yield itemObj;
    }
    itemsObj = await ref.startAfter(last).get();
  }
};
toExport.getOngs = async function*(ini = false, end = false) {
  let ref = firebase.firestore().collection('ongs');
  if(ini) {
    ref = ref.where('createdAt', '>=', ini);
  }
  if(end) {
    ref = ref.where('createdAt', '<', end);
  }
  let itemsObj = await ref.get();
  while(itemsObj.docs && itemsObj.docs.length>0) {
    const last = itemsObj.docs[itemsObj.docs.length-1];
    for(const index in itemsObj.docs) {
      const itemObj = itemsObj.docs[index];
      yield itemObj;
    }
    itemsObj = await ref.startAfter(last).get();
  }
};

toExport.getOrders = async function*(ini, end, companies= null) {
  let ref = firebase.firestore().collection('userOrders')
  .where('transformedInOrderDate', '>=', ini)
  .where('transformedInOrderDate', '<', end);
  if(companies && companies.length>0) {
    ref = ref.where('companyId', 'in', companies)
  }
  let itemsObj = await ref.get();
  while(itemsObj.docs && itemsObj.docs.length>0) {
    const last = itemsObj.docs[itemsObj.docs.length-1];
    for(const index in itemsObj.docs) {
      const itemObj = itemsObj.docs[index];
      yield itemObj;
    }
    itemsObj = await ref.startAfter(last).get();
  }
};

toExport.getOrderResume = async function*(ini, end, companies=[], regions, hubs) {
  let ref = firebase.firestore().collection('orderResume')
  .where('transformedInOrderDate', '>=', ini)
  .where('transformedInOrderDate', '<', end);
  if(companies && companies.length===1) {
    ref = ref.where('companyId', '==', companies[0]);
  }
  else if(companies && companies.length>0) {
    ref = ref.where('companyId', 'in', companies);
  }

  if(regions && regions.length===1) {
    ref = ref.where('company.address.regionId', '==', regions[0]);
  }
  else if(regions && regions.length>0) {
    ref = ref.where('company.address.regionId', 'in', regions);
  }

  if(hubs && hubs.length===1) {
    ref = ref.where('company.address.hubId', '==', hubs[0]);
  }
  else if(hubs && hubs.length>0) {
    ref = ref.where('company.address.hubId', 'in', hubs);
  }


  let itemsObj = await ref.get();
  while(itemsObj.docs && itemsObj.docs.length>0) {
    const last = itemsObj.docs[itemsObj.docs.length-1];
    for(const index in itemsObj.docs) {
      const itemObj = itemsObj.docs[index];
      yield itemObj;
    }
    itemsObj = await ref.startAfter(last).get();
  }
};


module.exports = toExport;
