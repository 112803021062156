<script>
  import Locale from '@components/layout/locale'
  import {authComputed} from '@state/helpers'
  export default {
    components: { Locale },
    props: {
    },
    data() {
      return {
      }
    },
    computed: {
      ...authComputed,
    },
    methods: {
      notImplemented() {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'info',
          title: this.$t('all.notImplemented'),
          showConfirmButton: false,
          timer: 1500
        })
      },
      logout: function (event) {
        this.$router.push({ name: 'logout' })
      },
      onClick(event) {
        this.$eventHub.$emit('menuClicked', event);
      }
    }
  }
</script>

<template>
  <ul class="box">
    <li class="bloco menuActions">
      <ul class="items">
        <li>
          <div v-if="loggedIn" class="itemlink">
            <BaseButton
              class="md-accent md-raised md-lg"
              type="text"
              @click="logout"
            >
              {{$t('navBar.logOut')}}
            </BaseButton>
          </div>
        </li>
        <li>
          <div class="itemlink">
            <Locale />
          </div>
        </li>
      </ul>
    </li>
    <li class="bloco">
      <span class="title">{{$t('all.settings').toUpperCase()}}</span>
      <ul class="items">
        <RouterLink tag="li" class="item" to="/admin/users" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.users')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/users_clients" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.clients')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/categories" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.categories')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/cards" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.cards')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/products_ingredients" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.productsIngredients')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/tags" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.tags')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/products_nutritional" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.productsNutritional')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/jobs" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.jobs')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/regions" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.regions')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/blog" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.blog')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/faq" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.faq')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/pages" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.pages')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/blog_categories" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.blogCategories')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/delivery_options" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.adminDeliveryOptions')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && currentUser.rule.adm" tag="li" class="item" to="/admin/iugu_accounts" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.iuguAccounts')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/orders" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.orders')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/exports" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.exports')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/companies" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.companies')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/ongs" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.ongs')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/notifications" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.notifications')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/hubs" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.hubs')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/ad_cards" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.adCards')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/admin/history" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-history@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-history_2@3x.png')" >
          {{$t('all.history')}}
        </RouterLink>
      </ul>
    </li>
  </ul>
</template>




<style lang="scss" scoped>
  @import '@design';

  .menuActions {
    display: none;
    @media screen and (max-width: $size-menu-actions+px) {
      display: block;
    }
  }
  .box {
    /*width: 250px;*/
    /*height: 100%;*/
    text-align: left;
    list-style-type: none;
    padding-inline-start: 0px;

    .bloco {
      padding-bottom: 35px;
      padding-top: 15px;
      padding-left: 20px;
      .title {

      }
      .items {
        list-style-type: none;
        padding-inline-start: 4px;

        .onActive {
          display: none;
          color: $color-menu-active;
        }
        .onInative {
          display: unset;
          color: $color-menu-inactive;
        }

        .router-link-active .onActive {
          display: unset;
        }
        .router-link-active .onInative {
          display: none;
        }
        .item {
          cursor: pointer;
          margin-top: 20px;
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 24px;
          }
        }
      }
    }
  }
</style>
