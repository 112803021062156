<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/admin/main_tabs'
  import firebase from 'firebase/app'
  import ChildComponent from '@components/admin/card_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ChildComponent},
    props: {
    },
    data() {
      return {
        itemId: null,
        items: [],
        totalItems: 0,
      }
    },
    computed: {
      ...authComputed
    },
    async mounted(){

      await this.loadForm();
    },
    methods: {
      async loadForm() {
        this.isLoading = true;
        let result = await firebase.functions().httpsCallable('companiesPayments-listIuguAccounts')();

        if(!result.data || !result.data.isOk || !result.data.data) {
          return this.$showError();
        }
        this.items = result.data.data.items || [];
        this.totalItems = result.data.data.totalItems || 0;


        console.log('result.data', result.data);
      },

      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
       // firebase.firestore().collection('cards').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
       // this.itemId = firebase.firestore().collection('cards').doc().id;
      },

    },
  }
</script>

<template>
  <MainTabs>
    <template #left >
      <div>{{totalItems}}</div>
      <div :class="$style.list">
        <div v-for="(item) in items" :key="item.id" :class="$style.item">
          {{item}}
        </div>
      </div>
    </template>
    <template #right>
      <ChildComponent
        v-model="itemId"
      >
      </ChildComponent>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';


  .list {


    .item {

    }
  }

</style>





















