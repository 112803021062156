<script>
  import {appConfig} from '@src/config';
  import Layout from '@components/layout/client/main-layout'
  import { authMethods, authComputed } from '@state/helpers'
  import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
  import databaseUtils from '@utils/database_utils'
  import FcmEditor from "@/src/components/image/fcm-editor";
  import firebase from 'firebase/app'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {FcmEditor, Layout},
    props: {
    },
    data() {
      return {
        updateError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        tryingToUpload: false,
        imageBlob: null,
        isLoading: true,
        form: {},
        changePassword: false,
        passwordOld: '',
        password: '',
        emailOriginal: '',
        userProviders: []

      }
    },
    validations(){
      return {
        form: {
          governmentDocumentId: {
            required,
            minLength: minLength(4)
          },
          name: {
            required,
            minLength: minLength(4)
          },
          email: {
            required,
            email
          },
        },
        password: {
          required: this.changePassword? required: false,
          minLength: minLength(4),
          maxLength: maxLength(50)
        },
        passwordOld: {
          required: this.changePassword? required: false,
        }
      }
    },
    computed: {
      ...authComputed,
      haveFaceook: {
        get() {
          if(this.userProviders.find(function(el){
            return el.providerId ==='facebook.com'
          }))  {
            return true
          }

          return false
        }
      },
      haveGoogle: {
        get() {
          if(this.userProviders.find(function(el){
            return el.providerId ==='google.com'
          }))  {
            return true
          }

          return false
        }
      },
      haveApple: {
        get() {
          if(this.userProviders.find(function(el){
            return el.providerId ==='apple.com'
          }))  {
            return true
          }

          return false
        }
      },
      havePassword: {
        get() {
          if(this.userProviders.find(function(el){
            return el.providerId ==='password'
          }))  {
            return true
          }

          return false
        }
      },
      havePhone: {
        get() {
          if(this.userProviders.find(function(el){
            return el.providerId ==='phone'
          }))  {
            return true
          }

          return false
        }
      }
    },
    watch: {
      currentUser(value, oldValue)  {
        this.form = _.clone(value) || {}
        this.emailOriginal = this.form.email
        this.isLoading = false
        if(firebase.auth().currentUser) {
          this.userProviders = firebase.auth().currentUser.providerData
        }

      }
    },
    beforeMount: function() {
      if(this.currentUser) {
        this.form = _.clone(this.currentUser) || {}
        this.emailOriginal = this.form.email
        this.isLoading = false
        if(firebase.auth().currentUser) {
          this.userProviders = firebase.auth().currentUser.providerData
        }
      }
    },

    methods: {
      ...authMethods,

      async doUpdate() {
        this.$v.$reset();
        this.$v.form.email.$touch();
        this.$v.form.name.$touch();

        if(this.changePassword) {
          this.$v.password.$touch();
          if(this.havePassword) {
            this.$v.passwordOld.$touch();
          }
        }
        if (this.$v.$error) {
          console.log('invalid input form', this.$v);
          this.updateError = {message:'invalid input form'};
          return;
        }
        this.tryingToUpdate = true;
        this.updateError = null;

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('client/profile_'+this.form.id, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              this.form.profileImageUrl = url;
            }
          } catch(error) {
            console.error('Erro ao tentar fazer upload de imagem', error);
            this.updateError = error;
            this.$showError({title:this.$t('form.error.image.upload')});
          }
        }


        try {

          if(this.emailOriginal!==this.form.email) {
            await firebase.auth().signInWithEmailAndPassword( this.emailOriginal, this.passwordOld);
            await firebase.auth().currentUser.updateEmail(this.form.email);
          }

          if(this.changePassword) {
            if(this.havePassword) {
              await firebase.auth().signInWithEmailAndPassword( this.form.email, this.passwordOld);
            }
            else if(this.haveFaceook){
              await this.logInSocial({locale: this.$i18n.locale, provider: 'facebook.com'});
            }
            else if(this.haveGoogle){
              await this.logInSocial({locale: this.$i18n.locale, provider: 'google.com'});
            }
            else if(this.haveApple){
              await this.logInSocial({locale: this.$i18n.locale, provider: 'apple.com'});
            }
            else if(this.havePhone){
             // await this.logInSocial({locale: this.$i18n.locale, provider: 'phone'});
              this.$showError({title:'Relogin por telefone ainda não implementado'});
            }

            if(!this.havePassword) {
              await this.linkEmail(this.form.email, this.password);
            }
            await firebase.auth().currentUser.updatePassword(this.password);
            this.changePassword = false
          }

          await this.modelClientUpdate(this.form);
          this.$showSuccess();
        } catch(error) {
          console.error('Erro ao tentar registrar', error);
          this.updateError = error
          this.$showError({title:this.$t('form.error.update')});
        }
        this.tryingToUpdate = false
        this.tryingToUpload = false

      },
      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName]
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            }
          }
        }
        return {'md-invalid':false}
      },
      getValidationClassNotForm(fieldName) {
        if(this.$v) {
          const field = this.$v[fieldName]
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            }
          }
        }
        return {'md-invalid':false}
      },
      changeBlob (blob) {
        this.imageBlob = blob
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.profileImageUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      async removeProvider (provider) {
        if(provider.providerId ==='password') {
          this.$showError({title:this.$t('form.error.deleteProviderEmail')});
          return;
        }

        if( !this.havePassword) {
          this.$showError({title:this.$t('form.error.deleteProviderEmailNeed')});
          return;
        }

        try {
          await firebase.auth().currentUser.unlink(provider.providerId);
          this.$showSuccess();
        } catch (error) {
          this.$showError({title:this.$t('form.error.deleteProviderEmail')});
        }
        if(firebase.auth().currentUser) {
          this.userProviders = firebase.auth().currentUser.providerData
        }
      },
      async addProviderSocial(provider) {
        let providerObj = null;
        switch(provider) {
          case 'facebook.com':
            providerObj = new firebase.auth.FacebookAuthProvider();
            break;
          case 'google.com':
            providerObj = new firebase.auth.GoogleAuthProvider();
            break;
          case 'apple.com':
            providerObj = new firebase.auth.OAuthProvider('apple.com');
            break;
          default:
            return Promise.reject(new Error('Unknown provider'));
        }

        try {
          await firebase.auth().currentUser.linkWithPopup(providerObj);
          this.$showSuccess();
        } catch (error) {
          console.error('Erro link', error);
          this.$showError({title:this.$t('login.error.loginTitle'), text: this.$t('login.providerCode.'+error.code)});
        }
        if(firebase.auth().currentUser) {
          this.userProviders = firebase.auth().currentUser.providerData
        }
      },
      async linkEmail(email, password) {
        console.log('linkEmail', email, password);
        try {
          let credential = firebase.auth.EmailAuthProvider.credential(email, password);
          let usercred = await firebase.auth().currentUser.linkWithCredential(credential);
          var user = usercred.user;
          console.log("Account linking success", user);
          this.$showSuccess({title:this.$t('form.alert.emailAddSuccess')});
        } catch (error) {
          console.error('error', error);
          this.$showError();
        }


      }

    },
  }
</script>


<template>
  <Layout>
    <div :class="$style.box">
      <div :class="$style.container">
        <div :class="$style.content">
          <div v-show="isLoading" >
            <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
          </div>


          <div v-show="!isLoading" :class="$style.image" @click="tryChangeImage()" >
            <img :src="imageSrc || form.profileImageUrl || require('@assets/images/placeholder/user.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
          </div>

          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="195"
                     :max-height="195"
                     @changeBlob="changeBlob"
          ></FcmEditor>

          <div v-if="form.name" :class="$style.restaurantName">{{form.name}}</div>
          <div :class="$style.info">{{$t('profile.title.client')}}</div>

          <div :class="$style.body">
            <form v-show="!isLoading" :class="$style.form" @submit.prevent="doUpdate">
              <BaseInputText
                v-model="form.governmentDocumentId"
                :label="$t('form.cpf')"
                :class="getValidationClass('governmentDocumentId')"
              >
                <span v-if="!$v.form.governmentDocumentId.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.governmentDocumentId.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              </BaseInputText>

              <BaseInputText
                v-model="form.name"
                :label="$t('form.name')"
                :class="getValidationClass('name')"
              >
                <span v-if="!$v.form.name.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.name.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              </BaseInputText>

              <BaseInputText
                v-model="form.email"
                :label="$t('form.email')"
                :class="getValidationClass('email')"
              >
                <span v-if="!$v.form.email.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.email.email" class="md-error" >{{$t('form.error.email')}}</span>
              </BaseInputText>

              <MdSwitch v-model="changePassword" class="md-primary" :value="true">{{$t(havePassword?'login.changePassword':'login.addPassword')}}</MdSwitch>

              <BaseInputText v-if="changePassword && havePassword"
                             v-model="passwordOld"
                             type="password"
                             :label="$t('form.passwordOld')"
                             :class="getValidationClassNotForm('passwordOld')"
              >
                <span v-if="!$v.passwordOld.required" class="md-error" >{{$t('form.error.required')}}</span>
              </BaseInputText>
              <BaseInputText v-if="changePassword"
                             v-model="password"
                             type="password"
                             :label="$t('form.passwordNew')"
                             :class="getValidationClassNotForm('password')"
              >
                <span v-if="!$v.password.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.password.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
                <span v-else-if="!$v.password.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
              </BaseInputText>


              <div>
                <div>{{$t('login.providers')}}</div>
                <MdTable v-model="userProviders" :class="$style.tableList" >
                  <MdTableToolbar slot="md-table-alternate-header" slot-scope="{ count }">
                    <div class="md-toolbar-section-start">{{ count }}</div>
                  </MdTableToolbar>
                  <MdTableRow slot="md-table-row" slot-scope="{ item }">
                    <MdTableCell :md-label="$t('login.remove')" class="pointer" @click.native="removeProvider(item)" ><MdIcon>delete</MdIcon></MdTableCell>
                    <MdTableCell :md-label="$t('login.providerId')" >{{ item.providerId || '' }}</MdTableCell>
                    <MdTableCell :md-label="$t('login.email')" >{{ item.email || '' }}</MdTableCell>
                    <MdTableCell :md-label="$t('login.uid')" >{{ item.uid || '' }}</MdTableCell>
                  </MdTableRow>
                </MdTable>
              </div>
              <BaseButton
                v-if="!haveFaceook"
                :class="['md-primary','md-raised','md-lg', $style.loginFacebook, $style.loginButton]"
                :disabled="tryingToUpdate"
                @click="addProviderSocial('facebook.com')"
              >
                <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('login.addFacebook')}}
                </span>
              </BaseButton>
              <br v-if="!haveFaceook" />
              <BaseButton
                v-if="!haveGoogle"
                :class="['md-primary','md-raised','md-lg', $style.loginGoogle, $style.loginButton]"
                :disabled="tryingToUpdate"
                @click="addProviderSocial('google.com')"
              >
                <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('login.addGoogle')}}
                </span>
              </BaseButton>
              <br v-if="!haveGoogle" />
              <BaseButton
                v-if="!haveApple"
                :class="['md-primary','md-raised','md-lg', $style.loginApple, $style.loginButton]"
                :disabled="tryingToUpdate"
                @click="addProviderSocial('apple.com')"
              >
                <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('login.addApple')}}
                </span>
              </BaseButton>

              <br><br>

              <p v-if="updateError" :class="$style.error">
                {{$t('form.error.update')}}
              </p>
              <MdProgressBar v-if="tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
              <BaseButton
                :class="['md-primary','md-raised','md-lg', $style.fullwidth ]"
                :disabled="tryingToUpdate"
                type="submit"
                :fullwidth="true"
              >
                <BaseIcon v-if="tryingToUpdate" name="sync" spin />
                <span v-else>
                  {{$t('form.update')}}
                </span>
              </BaseButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }

  .box {
    background-color: #ffffff;
    /*min-height: 1000px;*/
    .container {
      /* min-width: $size-content-width-min;
       max-width: $size-content-width-max; */

      min-height: 600px;
      margin: 10px 8px 30px 8px;

      .content {
        /*flex: 1;*/
        /* width: 45%; */
        max-width: 700px;
        padding: 49px;
        text-align: left;
        background-color: $color-form-bg;
        box-shadow: 2px 2px 9px 0px #8888884f;

        margin: 0 auto;

        .image {
          @extend %defaultPreview;
          position: relative;
          height: 100px;
          width: 100px;
          cursor: pointer;
          border-radius: 300px;
          img {
            height: 100px;
            width: 100px;
            margin-bottom: 20px;
            border-radius: 300px;
          }
          .changeImage {
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;
            margin-top: 60px;
            color: white;
            background-color: rgba(0, 0, 0, 0.71);
            text-align: center;
            display: none;
          }
        }
        .image:hover .changeImage {
          display: block;
        }


        .restaurantName {
          font-size: 32px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #333333;
          margin-bottom: 20px;
        }
        .info {
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 3.2;
          letter-spacing: 0.3px;
          color: #707070;
        }
        .body {
          font-family: $font-input;
          BaseButton {
            font-family: $font-primary-text;
            float: right;
          }
          .loginButton {
            margin-bottom: 5px;
            margin-top: 10px;
          }
          .loginFacebook {
            background-color: #4267B2!important;
            text-transform: none;
          }
          .loginGoogle {
            background-color: #edeeed !important;
            text-transform: none;
            color: #1f2633 !important;
          }
          .loginApple {
            background-color: #000000 !important;
            text-transform: none;
            color: #ffffff !important;
          }

          .progressBar {
            margin-bottom: 20px;
          }
        }
      }
    }
  }



</style>





















