<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/admin/main_tabs'
  import ListItens from '@components/list_itens'
  import firebase from 'firebase/app'
  import ChildComponent from '@components/company/company_profile_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListItens, ChildComponent},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    mounted(){
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
        firebase.firestore().collection('companies').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
        this.itemId = firebase.firestore().collection('companies').doc().id;
      },
      makeSearch(el) {
        if(!el.name) {return '';}
        return ''+el.name;
      }
    },
  }
</script>

<template>
  <MainTabs>
    <template #left >
      <ListItens
        v-model="itemId"
        resource="companies"
        :actions="['edit', 'enable']"
        :make-search="makeSearch"
        :hide-active="true"
        :disable-order="true"
        order-by="createdAt"
        order-direction="asc"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
        <template #item="slotProp" >
          <div class="formItemHead">{{slotProp.item.name +
          ' ('+ (slotProp.item.active?$t('form.active'):$t('form.inactive'))+' '+(slotProp.item.isOpen?$t('navBar.isOpen'):$t('navBar.isClose')) +')'
          +(slotProp.item.createdAt?' '+moment(slotProp.item.createdAt.toDate()).format($t('format.data')):'')}}</div>
        </template>
      </ListItens>
    </template>
    <template #right>
      <ChildComponent
        v-model="itemId"
        :is-super-admin="true"
      >
      </ChildComponent>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

</style>





















