<script>
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'
  import { timeComputed, authComputed } from '@state/helpers'
  const stringUtils = require('@common/string_utils');
  const headUtils = require('@common/head_utils');
  const stateList = require ('@common/status');

  export default {
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      resource: {
        type: [String],
        default: ''
      },
      itemId: {
        type: String,
        default: null,
      },
      actions: {
        type: Array,
        default: () => {
          return [] // ['edit', 'enable', 'add']
        }
      },
      companyId: {
        type: [String],
        default: null
      },
      limit: {
        type: [Number],
        default: 1000
      },
      offset: {
        type: [Number],
        default: 0
      },
      isSuperAdmin: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        listOriginal: [],
        list: [],
        search: '',
        orderBy: 'order',
        customFilter: false,
        listeners: [],
        times: {},
        stateList: stateList,
        itemPending: {},
        sound: null,
        notificationIntervalId: null,
        timeElapsed: 12,
        timeElapsedList: [
          6, 12, 24, 48, 120, 240, 720, 8784
        ],
        disableStatusChange: {}
      }
    },
    computed: {
      ...timeComputed,
      ...authComputed,
      listView: {
        get() {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.customFilter = false
          if(!this.search) {
            return this.list;
          }
          let search = _.deburr(this.search.toLowerCase().trim() || '');
          if(!search) {
            return this.list;
          }
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.customFilter = true
          let tmp = this.list.filter((value)=>{
            // eslint-disable-next-line eqeqeq
            if(search==value.orderNumber || search==value.orderCode || search==value.id) {
              value.scoreSearch = 1;
            }
            else {
              value.scoreSearch = stringUtils.fcmlevenshteinNormalizeScore(search, value.search);
            }
            if(value.scoreSearch>0.6) {
              return true
            }
            return false
          });

          return tmp.sort((a, b)=>{return a.scoreSearch-b.scoreSearch})
        },
        set(value) {
          if(this.customFilter) {
            this.$swal({
              toast: true,
              position: 'top-end',
              type: 'error',
              title: this.$t('list.error.reorderOnFiltered'),
              showConfirmButton: false,
              timer: 3500
            });
            return
          }
          let updateList = [];
          for(let index in value) {
            if(value[index].order !== parseInt(index)) {
              value[index].order = parseInt(index);
              updateList.push(value[index])
            }
          }
          this.list.sort((a, b) => {return b.transformedInOrderDate.toMillis()-a.transformedInOrderDate.toMillis()});
          databaseUtils.databaseSliceUpdate(firebase.firestore().collection(this.resource), updateList);

        }
      },
      elapsedTime: {
        get() {
          if(this.form && this.form.waitConfirmAt) {
            return moment(this.form.waitConfirmAt.toDate()).fromNow();
          }
          return '';
        }

      }
    },
    watch: {
      itemPending: {
        handler: function(value, oldValue)  {
          if(_.size(value)>0) {
            if(!this.notificationIntervalId) {
              console.log('ligar som');
            //  this.sound.play();
              this.notificationIntervalId = setInterval(() => {
                if(this.sound) {
                  this.sound.play();
                }
              }, 2000);
              this.notifyMe();
            }

            headUtils.setBadge({iconSrc:'/favicon-192x192.png', count:_.size(value), faviconSize: 192, fontSize:114});
        //    this.sound.play();
          }
          else {
            if(this.notificationIntervalId) {
              console.log('desligar som');
              clearInterval(this.notificationIntervalId);
              this.notificationIntervalId = null;
            }
            headUtils.setBadge({iconSrc:'/favicon-192x192.png', count:0});
          }
        },
        immediate: false,
        deep: true
      },
      itemId(value, oldValue) {
        if(!value) {
          if(this.$route.params.id) {
            this.$router.push({params: {id: null}});
          }
        }
      }
    },
    beforeMount() {
      this.sound = new Audio('/sound/desk_bell_amp.wav');
      this.sound.volume = 1;
      this.$eventHub.$on('menuClicked', ()=>{
        this.$emit('edit', null);
      });

      if(this.$route.params.id) {
        this.$emit('edit', this.$route.params.id);
      }
    },
    mounted() {
      this.startObserver();

    },
    beforeDestroy(){
      this.$eventHub.$off('menuClicked');
      for(let i in this.listeners) {
        this.listeners[i]()
      }
      if(this.notificationIntervalId) {
        clearInterval(this.notificationIntervalId);
        this.notificationIntervalId = null;
      }
      headUtils.setBadge({iconSrc:'/favicon-192x192.png', count:0});
    },

    methods: {
      startObserver() {
        for(let i in this.listeners) {
          this.listeners[i]()
        }
        if(this.notificationIntervalId) {
          clearInterval(this.notificationIntervalId);
          this.notificationIntervalId = null;
        }
        let ref = firebase.firestore().collection('userOrders');
        if(this.companyId) {
          ref = ref.where("companyId", "==", this.companyId);
        }
        else if(this.isSuperAdmin && this.currentWorkspace === this.stateList.Workspace.ADMIN){

        }
        else {
          console.warn('companyId not found');
          return;
        }
        ref = ref.where("bagStatus", "==", "transformedInOrder");
        ref = ref.where("transformedInOrderDate", ">", moment().subtract(this.timeElapsed, 'hours').toDate())
                .orderBy("transformedInOrderDate", "desc");

        this.list = [];
        this.listeners.push(ref.limit(this.limit).onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added" || change.type === "modified") {
              let value = {...change.doc.data(),id: change.doc.id };
              if(!value.status) {
                value.status = 'waitConfirm';
              }
              if(!value.deliveryType) {
                value.deliveryType = 'pickup';
              }
              if(value.status === 'waitConfirm') {
                this.itemPending[value.id] = true;
                this.itemPending = Object.assign({}, this.itemPending);
              }

              if(stateList.companyOrderStatusSequence[value.deliveryType]) {
                let statusSequenceIndex = stateList.companyOrderStatusSequence[value.deliveryType].findIndex(a=>a===value.status);
                if(statusSequenceIndex>=0) {
                  value.nextStatus = stateList.companyOrderStatusSequence[value.deliveryType][statusSequenceIndex+1];
                }
              }
              if(!value.nextStatus) {
                value.nextStatus =null;
              }

              value.search = '';
              if(this.isSuperAdmin) {
                value.search = (value.company || {}).name || '';
              }
              for(let i in value.lstUsers || {}) {
                value.search += ' '+(value.lstUsers[i].name || '').trim();

                let user = value.lstUsers[i];
                let obj = {
                  id: user.id,
                  name: user.name,
                  description: '',
                  status: '',
                  value: '',
                  imgUrl: ''
                };
                if(value.paymentMethod && value.paymentMethod[user.id]) {
                  let pm = value.paymentMethod[user.id];
                  if(value.groupCode) {
                    obj.value = pm.groupUserTotalValue || 0;
                  }
                  else {
                    obj.value = value.totalValue || 0;
                  }
                  if(pm.type===stateList.OrderPaymentMethodType.OnApp && pm.status===stateList.PaymentStatus.paid) {
                    obj.status = this.$t('paymentInfo.OnApp');
                  }
                  if(pm.type===stateList.OrderPaymentMethodType.whenReceive && pm.deliveryPaymentMethod) {
                    obj.imgUrl = pm.deliveryPaymentMethod.imgUrl;
                    obj.status = this.$t('paymentInfo.whenReceive');
                    obj.description = this.$trdb(pm.deliveryPaymentMethod.name);
                    if(pm.deliveryPaymentMethod.isCash && pm.changeValue) {
                      obj.description+= '('+this.$t('paymentInfo.changeFor')+': '+this.$n(pm.changeValue, 'currency')+')';
                    }

                  }

                }
                user.paymentInfo = obj;


              }
              value.search = value.search.trim().toLowerCase();




              let index = _.findIndex(this.list, (o)=>{return o.id===value.id;});
              if(index<0) {
                this.list.splice(_.sortedIndexBy(this.list, value, function(o) { return o.transformedInOrderDate.toMillis()*-1 }), 0, value);
              }
              else {
                if(value.status === 'waitConfirm' && this.list[index].status!=='waitConfirm') {
                  this.itemPending[value.id] = true;
                  this.itemPending = Object.assign({}, this.itemPending);
                }
                else if(this.itemPending[value.id]) {
                  delete this.itemPending[value.id];
                  this.itemPending = Object.assign({}, this.itemPending);
                }

                let needReorder = false;
                if(this.list[index].transformedInOrderDate.toMillis()!==value.transformedInOrderDate.toMillis()) {
                  needReorder = true;
                }
                this.list[index] = value;
                if(needReorder) {
                  this.list.sort((a, b)=>{return b.transformedInOrderDate.toMillis()-a.transformedInOrderDate.toMillis();})
                }
                this.list = Array.from(this.list);
              }

            }
            else if (change.type === "removed") {
              console.log("Removed : ", change.doc.data());
            }
            else {
              console.warn('Recebido dads estranhos do firebase', change);
            }
          });
          this.isLoading = false
        }));


      },
      enableChange(id, value) {
        this.$emit('enable', {
          id,
          value
        });
      },

      editClick(id) {
        this.$emit('edit', id);
        if(!this.$route.params.id || this.$route.params.id!==id) {
          this.$router.push({params: {id: id}});
        }
        delete this.itemPending[id];
        this.itemPending = Object.assign({}, this.itemPending);
        this.$scrollTo({x:0, y:0});
      },
      addClick() {
        this.$emit('add');
      },
      getValue(item, attr) {
        return attr.split('.').reduce(function(o, k) {
          return o && o[k]
        }, item)
      },
      _moment(date) {
        return moment(date);
      },
      async notifyMe() {
        if (!("Notification" in window)) {
          return console.error("Este browser não suporta notificações de Desktop");
        }
        let permission = Notification.permissio;
        if (permission !== "granted" && permission !== 'denied') {
          permission = await Notification.requestPermission();
        }
        if (Notification.permission === 'granted') {

          let notification = new Notification( this.$t('order.newOrderNotificationTitle'), {
            body: this.$t('order.newOrderNotificationBody'),
            icon: "/images/logo/logo@3x.png",
            dir: "ltr",
            vibrate: [200, 100, 200]
          });

          notification.onclick = () => {
            this.$router.push("/company/orders");
          };
        }
      },
      async goToNextStatus(item) {
        this.disableStatusChange[item.id] = true;
        try {
          await firebase.firestore().collection('userOrders').doc(item.id).set({status: item.nextStatus},{merge: true});
          this.$showSuccess({text:this.$t('order.successStatus')});
        } catch(error) {
          this.$showError();
        }
        this.disableStatusChange[item.id] = false;
      },
      changeTimeElapsed(item) {
        this.startObserver();
      }
    }

  }
</script>

<template>
  <div :class="[$style.box, itemId?$style.boxItem:'']">
    <div :class="$style.head">
      <div :class="$style.firstLine">
        <MdField md-theme="white" :class="$style.search">
          <label>{{$t('all.search')}}</label>
          <MdInput v-model="search" type="text" ></MdInput>
        </MdField>
      </div>
      <div :class="$style.secondLine">
        <MdField md-theme="white">
          <label>{{$t('timeElapsed.label')}}</label>
          <MdSelect v-model="timeElapsed" @md-selected="changeTimeElapsed">
            <MdOption v-for="item of timeElapsedList" :key="item" :value="item">{{$t('timeElapsed.'+item)}}</MdOption>
          </MdSelect>
        </MdField>
      </div>
      <div :class="$style.sizesLine">{{list?list.length:0}} Pedido(s)</div>
    </div>
    <div :class="$style.body">
      <div :class="$style.listView">
        <div :class="$style.transitionGroup">
          <div v-for="(item) in listView" :key="item.id" :class="[$style.item, itemId===item.id?$style.itemSelected:'']" @click="editClick(item.id)">
            <div :class="$style.info">
              <div v-if="item.test" style="color: red; font-size: 25px">
                PEDIDO TESTE
              </div>
              <div v-if="isSuperAdmin">
                {{item.company.name}}
              </div>
              <div :class="$style.status">
                <span :class="$style.left"><span :class="[$style.bullet]" :style="'background-color: '+(stateList.companyOrderStatus[item.status]?stateList.companyOrderStatus[item.status].color || 'inherit':'inherit')"></span>
                  {{$t('orderStatus.'+item.status)}}
                </span>
                <span :class="$style.right">{{(item.transformedInOrderDate)?_moment(item.transformedInOrderDate.toDate()).from(nowMinuted):''}}</span>
                <MdIcon v-if="itemPending[item.id]" :class="[$style.icon, $style.itemPending]">new_releases</MdIcon>
              </div>
              <p>
                <MdIcon :class="$style.icon">{{(stateList.companyOrderDeliveryType[item.deliveryType]||{}).icon}}</MdIcon>
                {{$t('deliveryType.'+item.deliveryType)}}
              </p>
              <p v-for="client in item.lstUsers" :key="client.id">
                <MdIcon :class="$style.icon">perm_identity</MdIcon>{{client.name + ' ('+client.paymentInfo.status+')'}}   <br>
                <MdIcon v-if="client.phone" :class="$style.icon">phone</MdIcon>{{client.phone}}
              </p>
              <p>
                <MdIcon v-if="item.env==='android'" :class="$style.icon">android</MdIcon>
                <MdIcon v-else-if="item.env==='ios'" :class="$style.icon">phone_iphone</MdIcon>
                <MdIcon v-else :class="$style.icon">phone_android</MdIcon>
                {{item.env}}
                <span>{{$t('order.numberSlim')}} #{{item.orderNumber}}</span>
                <span> {{$t('order.codeSlim')}}: {{item.orderCode}}</span>
              </p>
              <p v-if="item.nextStatus" :class="$style.nextStatus" >
                <span>{{$t('order.changeForStatus')}}</span>
                <MdButton
                  md-theme="buttonprimary"
                  :disabled="disableStatusChange[item.id] || isSuperAdmin"
                  :class="['md-primary','md-raised']"
                  @click.prevent.stop="goToNextStatus(item)"
                >
                  {{$t('orderStatus.'+item.nextStatus)}}
                </MdButton>
              </p>
            </div>
          </div>


         <!-- <div v-for="(item) in listView" :key="item.id" :class="['formItem', $style.item]" @click="editClick(item.id)">
            <div ng-if="item.imgUrl" class="formItemImg">
              <img :class="$style.imgUrl" :src="item.imgUrl" />
            </div>
            <div class="formItemDesc">
              <div class="formItemHead">{{item.name}}</div>
              <div class="formItemSub">{{item.description}}</div>
            </div>
            <div class="formItemAction">
              <MdSwitch v-model="item.active" class="md-primary" :value="true" @change="enableChange(item.id, $event)"></MdSwitch>
              <div class="formItemControl"></div>
            </div>
          </div>-->
        </div>
      </div>
      <div v-if="listView.length<=0" :class="$style.noOrder">
        {{$t('order.noOrder')}}
      </div>
    </div>

  </div>
</template>




<style lang="scss" module>
  @import '@design';

  .boxItem {
    @media screen and (max-width: 950px) {
      display: none;
    }
  }

  .box {
  /*  background-color: #7691c2;*/

    flex: 1 2 0;

    max-width: 450px;
 /*   min-width: 450px; */
    margin-bottom: 20px;

    @media screen and (max-width: 1100px) {
      min-width: unset;
    }

    .head {
      padding: 15px;
      background-color: $color-submenu-bg;
    }
    .firstLine {

      display: flex;
      align-items: center;

      .add {
        padding: 15px;
        cursor: pointer;
      }

    }
    .sizesLine {
      color: white;

    }

    .listView {
      .transitionGroup {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        // scroll-snap-type: y mandatory;

        height: 100%;
        //  max-height: 100%;
        max-height: 1000px;
      }
    }


    .itemSelected {
      background-color: #bedbe4;
    }

    .item {
      cursor: pointer;
      user-select: none;
      align-items: flex-start;
      text-align: left;
      padding-left: 20px;
      position: relative;
      padding-top: 11px;
      padding-bottom: 11px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex: 1 0 auto;
      /* box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12); */
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);


      .info {
        margin-right: 7px;
        width: 100%;
        .icon {
          margin-right: 10px;
          font-size: 18px !important;
        }

        .status {
          display: flex;
          justify-content: space-between;
          .left {
            margin-right: 15px;
            .bullet {
              height: 15px;
              width: 15px;
              display: inline-block;
              border-radius: 15px;
              margin-right: 10px;
              margin-left: 5px;
            }


          }
          .right {

          }
          .itemPending {
            color: red;
          }
        }

        .statusPayment {
          vertical-align: baseline;
          border-radius: .25em;
          display: inline;
          /*color: #fff;*/
          padding: 2px;
        }
        p {
          margin-top: 1px;
          margin-bottom: 1px;
        }

        .nextStatus {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }

    .noOrder {
      padding: 40px;
      font-size: large;
    }

    .imgUrl {
      max-width: 110px;
      max-height: 110px;
    }
  }


</style>
