<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'
  import FcmEditor from "@/src/components/image/fcm-editor";
  import fitty from 'fitty'
  import design from '@design'
  import Multiselect from 'vue-multiselect'
  import colorUtils from '@common/color_utils'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {FcmEditor, Multiselect},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        defaultForm: {
          // Step 1
          line1: '50% OFF',
          line2: 'EM TODOS OS PRODUTOS',
          description: 'Oferta especial',
          applyIn: 'all',
          discountType: 'percent',
          // Step 3

          discountPercent: 50,
          discountValue: 0,
          dtBegin: null,
          dtEnd: null,
          limitQuantity: 100,
          minValue: 30,
        },

        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        imageSrc: null,
        uploadProgress: 0,
        imageBlob: null,

        form: {
          line1: '50% OFF',
          line2: 'EM TODOS OS PRODUTOS',
          description: 'Oferta especial',

          applyIn: 'all',
          discountType: 'percent',
          // Step 3

          discountPercent: 50,
          discountValue: 0,
          dtBegin: null,
          dtEnd: null,
          limitQuantity: 100,
          minValue: 30,
        },

        isLoading: true,
        isNew: true,
        fitties: [],
        dateBegin: moment().toDate(),
        hourBegin:  moment().format('HH:mm'),
        dateEnd: moment().add(10, 'days').toDate(),
        hourEnd: moment().add(10, 'days').format('HH:mm'),

        subCategories: [],
        products: [],

        productsSelected: [],
        subCategoriesSelected: [],
      }
    },
    validations: {
      form: {
        line1: {
          maxLength: maxLength(20),
        },
        line2: {
          maxLength: maxLength(35),
        },
        description: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(50),
        },
        limitQuantity: {
          decimal,
          minValue: minValue(0)
        },
        minValue: {
          decimal,
          minValue: minValue(0)
        },
        discountPercent: {

        },
        discountValue: {

        }
      }
    },
    computed: {
      ...authComputed,
      cssProps() {
        if(this.currentCompany && this.neverTrue) {
          return {
            '--primary-color': colorUtils.rgba2css( this.currentCompany.primaryColor),
            '--secondary-color': colorUtils.rgba2css( this.currentCompany.secondaryColor),
          }
        }
        else {
          return {
            '--primary-color':  'rgba(255, 59, 48, 1)',
            '--secondary-color':  'rgba(255, 255, 255, 1)',
          }
        }

      }
    },
    watch: {
      itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          this.loadForm()
        }
        else {
          this.form = _.cloneDeep(this.defaultForm)
        }

      }
    },
    beforeMount: async function() {
      if(!this.currentCompany || !this.currentCompany.id) {
        console.error('CompanyId not found');
        return;
      }
      let productsP = firebase.firestore().collection('companiesProducts').where("active", "==", true).where("companyId", "==", this.currentCompany.id).get();
      let subCategoriesP = firebase.firestore().collection('companiesSubCategories').where("active", "==", true).where("companyId", "==", this.currentCompany.id).get();

      let productsTmp = [];
      (await productsP).forEach((categoryObj) => {
        productsTmp.push({...categoryObj.data(), id:categoryObj.id});
      });
      this.products = productsTmp;

      let subCategoriesTmp = [];
      (await subCategoriesP).forEach((categoryObj) => {
        subCategoriesTmp.push({...categoryObj.data(), id:categoryObj.id});
      });
      this.subCategories = subCategoriesTmp;

    },
    mounted: async function() {
      this.isLoading = false;
      let scale = parseFloat(design['globalPreviewPromotionScale'] || 1);
      this.fitties = [];
      this.fitties.push(fitty(this.$refs.previewLine1Fit, {
        minSize: 5*scale,
        maxSize: 15*scale,
        multiLine: false,
      }));

      this.fitties.push(fitty(this.$refs.previewLine2Fit, {
        minSize: 7*scale,
        maxSize: 10*scale,
        multiLine: true,
      }));
    },
    beforeDestroy: function() {
      for(let fitti in this.fitties) {
        this.fitties[fitti].unsubscribe()
      }
      this.fitties = [];
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        let form = _.cloneDeep(this.defaultForm);

        let obj = await firebase.firestore().collection('companiesPromotions').doc(this.itemId).get();
        if(obj && obj.data()) {
          form = { ...form, ...obj.data()};


          if(form.dtBegin) {
            this.dateBegin= moment(form.dtBegin).toDate();
            this.hourBegin=  moment(form.dtBegin).format('HH:mm');
          }
          if(form.dtEnd) {
            this.dateEnd= moment(form.dtEnd).toDate();
            this.hourEnd= moment(form.dtEnd).format('HH:mm');
          }

          form.products = [];
          for(let pId of form.productsIds) {
            let p = this.products.find((a)=>a.id===pId);
            if(p) {
              form.products.push(p);
            }
          }
          form.subCategories = [];
          for(let pId of form.subCategoriesIds) {
            let p = this.subCategories.find((a)=>a.id===pId);
            if(p) {
              form.subCategories.push(p);
            }
          }
          this.isNew = false;
        }
        else {
          this.isNew = true;
        }
        this.form = form;
       // this.$refs.previewLine1Fit.trigger('resize');
        console.log('loaded form', this.form);
        this.isLoading = false;
        fitty.fitAll();
      },

      async conclude() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return
        }
        this.hasError = null;
        this.tryingToUpdate = true;
        let form = _.cloneDeep(this.form);

        if(!form.companyId) {
          form.companyId = this.currentCompany.id;
        }
        form.order = parseInt(form.order || 0) || 0;


        form.dtBegin = moment(moment(this.dateBegin).format('YYYY-MM-DD') + ' ' + this.hourBegin).valueOf();
        form.dtEnd = moment(moment(this.dateEnd).format('YYYY-MM-DD') + ' ' + this.hourEnd).valueOf();

        form.discountPercent = parseFloat(form.discountPercent || 0);
        form.discountValue = parseFloat(form.discountValue || 0);
        form.limitQuantity = parseInt(form.limitQuantity || 0);
        form.minValue = parseFloat(form.minValue || 0);
        form.company = this.currentCompany;
        form.active = !!form.active;

        form.productsIds = [];
        form.subCategoriesIds = [];
        for(const item of form.products || []) {
          form.productsIds.push(item.id);
        }
        for(const item of form.subCategories || []) {
          form.subCategoriesIds.push(item.id);
        }
        delete form.products;
        delete form.subCategories;

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('companiesPromotions/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          form.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          form.updatedBy = this.currentUser.id || '';
          if(!form.createdAt) {
            form.createdAt = firebase.firestore.FieldValue.serverTimestamp();
            form.createdBy = this.currentUser.id || '';
          }
          if(!form.imgUrl) {
            form.imgUrl = 'https://firebasestorage.googleapis.com/v0/b/supperappcg.appspot.com/o/placeholders%2Foffer.png?alt=media&token=8783ca6c-a801-435c-940f-f631930d9c17';
          }


          await firebase.firestore().collection('companiesPromotions').doc(this.itemId).set(form,{merge: true});
          this.$showSuccess();
          this.tryingToUpdate = false;
          this.$emit('changeModel', null);
          return;
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.form.logoImgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box" :style="cssProps">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>

          <div :class="$style.image" @click="tryChangeImage()" >
            <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/offer.png')" alt="Imagem principal"/>
            <div v-show="form.line1" :class="$style.previewLine1">
              <div><span ref="previewLine1Fit">{{form.line1}}</span></div>
            </div>
            <div v-show="form.line2" :class="$style.previewLine2">
              <div><span ref="previewLine2Fit">{{form.line2}}</span></div>
            </div>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
          </div>

          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     :max-width="525"
                     :max-height="375"
                     :aspect-ratio="525/375"
                     @changeBlob="changeBlob"
          ></FcmEditor>
          <div :class="$style.body">
            <div class="formItemSubSection">{{$t('promotion.infoSub').toUpperCase()}}</div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('promotion.active')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>

            <BaseInputText v-model="form.line1" :label="$t('promotion.line1')" :class="[getValidationClass('line1'), 'formItemText']" maxlength="20">
              <span v-if="!$v.form.line1.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.line1.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
            </BaseInputText>

            <BaseInputText v-model="form.line2" :label="$t('promotion.line2')" :class="[getValidationClass('line2.base'), 'formItemText']" maxlength="35">
              <span v-if="!$v.form.line2.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.line2.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
            </BaseInputText>

            <BaseInputText
              v-model="form.description"
              :label="$t('promotion.description')"
              :class="[getValidationClass('description.base'), 'formItemText']"
              maxlength="50"
            >
              <span v-if="!$v.form.description.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.description.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              <span v-else-if="!$v.form.description.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
            </BaseInputText>
            <!--
                        <div class="formItemSubSection">{{$t('promotion.behaviorSub').toUpperCase()}}</div>
                        <div class="formItem formItemFirst">
                          <div class="formItemDesc">
                            <div class="formItemHead">{{$t('promotion.behaviorAll')}}</div>
                            <div class="formItemSub">{{$t('promotion.behaviorAllDesc')}}</div>
                          </div>
                          <div class="formItemAction">
                            <MdRadio v-model="form.behavior" class="md-primary" value="all"></MdRadio>
                       <div class="formItemControl"></div>
                          </div>
                        </div>
                              <div class="formItem">
                                <div class="formItemDesc">
                                  <div class="formItemHead">{{$t('promotion.behaviorFirst')}}</div>
                                  <div class="formItemSub">{{$t('promotion.behaviorFirstDesc')}}</div>
                                </div>
                                <div class="formItemAction">
                                  <MdRadio v-model="form.behavior" class="md-primary" value="first"></MdRadio>
                                  <div class="formItemControl"></div>
                                </div>
                              </div>
                              <div class="formItem">
                                <div class="formItemDesc">
                                  <div class="formItemHead">{{$t('promotion.behaviorCoupon')}}</div>
                                  <div class="formItemSub">{{$t('promotion.behaviorCouponDesc')}}</div>
                                </div>
                                <div class="formItemAction">
                                  <MdRadio v-model="form.behavior" class="md-primary" value="coupon"></MdRadio>
                                  <div class="formItemControl"></div>
                                </div>
                              </div> -->

            <div>
              <div class="formItemSubSection">{{$t('promotion.typeSub').toUpperCase()}}</div>
              <!--              <div class="formItem formItemFirst">
                              <div class="formItemDesc">
                                <div class="formItemHead">{{$t('promotion.typeDelivery')}}</div>
                                <div class="formItemSub">{{$t('promotion.typeDeliveryDesc')}}</div>
                              </div>
                              <div class="formItemAction">
                                <MdSwitch v-model="form.typeDelivery" class="md-primary" :value="true"></MdSwitch>
                                <div class="formItemControl"></div>
                              </div>
                            </div> -->
              <div class="formItem formItemFirst">
                <div class="formItemDesc">
                  <div class="formItemHead">{{$t('promotion.typePercent')}}</div>
                  <div class="formItemSub">{{$t('promotion.typePercentDesc')}}</div>
                </div>
                <div class="formItemAction">
                  <MdRadio v-model="form.discountType" class="md-primary" value="percent"></MdRadio>
                  <div class="formItemControl"></div>
                </div>
              </div>
              <div class="formItem">
                <div class="formItemDesc">
                  <div class="formItemHead">{{$t('promotion.typeValue')}}</div>
                  <div class="formItemSub">{{$t('promotion.typeValueDesc')}}</div>
                </div>
                <div class="formItemAction">
                  <MdRadio v-model="form.discountType" class="md-primary" value="value"></MdRadio>
                  <div class="formItemControl"></div>
                </div>
              </div>
              <div v-if="form.discountType">
                <div class="formItemSubSection">{{$t('promotion.discountValueSub').toUpperCase()}}</div>
                <div :class="$style.restrictionBox">
                  <MdField v-if="form.discountType==='percent'" :class="[getValidationClass('discountPercent'), 'formItemText']">
                    <label>{{$t('promotion.discountPercent')}}</label>
                    <MdInput v-model="form.discountPercent" type="number"></MdInput>
                    <span class="md-suffix">%</span>
                    <span v-if="!$v.form.discountPercent.required" class="md-error" >{{$t('form.error.required')}}</span>
                    <span v-else-if="!$v.form.discountPercent.decimal" class="md-error" >{{$t('form.error.decimal')}}</span>
                    <span v-else-if="!$v.form.discountPercent.minValue" class="md-error" >{{$t('form.error.minValueZero')}}</span>
                  </MdField>
                  <MdField v-if="form.discountType==='value'" :class="[getValidationClass('discountValue'), 'formItemText']">
                    <label>{{$t('promotion.discountValue')}}</label>
                    <span class="md-prefix">R$</span>
                    <MdInput v-model="form.discountValue" type="number"></MdInput>
                    <span v-if="!$v.form.discountValue.required" class="md-error" >{{$t('form.error.required')}}</span>
                    <span v-else-if="!$v.form.discountValue.decimal" class="md-error" >{{$t('form.error.decimal')}}</span>
                    <span v-else-if="!$v.form.discountValue.minValue" class="md-error" >{{$t('form.error.minValueZero')}}</span>
                  </MdField>
                </div>
              </div>


              <!--
                  <div class="formItem">
                    <div class="formItemDesc">
                      <div class="formItemHead">{{$t('promotion.typeGift')}}</div>
                      <div class="formItemSub">{{$t('promotion.typeGiftDesc')}}</div>
                    </div>
                    <div class="formItemAction">
                      <MdSwitch v-model="form.typeGift" class="md-primary" :value="true"></MdSwitch>
                      <div class="formItemControl"></div>
                    </div>
                  </div> -->
            </div>

            <div>
              <div class="formItemSubSection">{{$t('promotion.applyInSub').toUpperCase()}}</div>
              <div class="formItem formItemFirst">
                <div class="formItemDesc">
                  <div class="formItemHead">{{$t('promotion.applyInAll')}}</div>
                  <div class="formItemSub">{{$t('promotion.applyInAllDesc')}}</div>
                </div>
                <div class="formItemAction">
                  <MdRadio v-model="form.applyIn" class="md-primary" value="all"></MdRadio>
                  <div class="formItemControl"></div>
                </div>
              </div>
              <div class="formItem">
                <div class="formItemDesc">
                  <div class="formItemHead">{{$t('promotion.applyInCategories')}}</div>
                  <div class="formItemSub">{{$t('promotion.applyInCategoriesDesc')}}</div>
                </div>
                <div class="formItemAction">
                  <MdRadio v-model="form.applyIn" class="md-primary" value="categories"></MdRadio>
                  <div class="formItemControl"></div>
                </div>
              </div>
              <div class="formItem">
                <div class="formItemDesc">
                  <div class="formItemHead">{{$t('promotion.applyInProducts')}}</div>
                  <div class="formItemSub">{{$t('promotion.applyInProductsDesc')}}</div>
                </div>
                <div class="formItemAction">
                  <MdRadio v-model="form.applyIn" class="md-primary" value="products"></MdRadio>
                  <div class="formItemControl"></div>
                </div>
              </div>
            </div>
            <div>
              <div v-show="form.applyIn==='categories'" class="formItem">
                <Multiselect v-model="form.subCategories" :options="subCategories" track-by="id" label="description" :multiple="true"
                             :placeholder="$t('all.categories')"
                             :select-label="$t('multiSelect.selectLabel')"
                             :select-group-label="$t('multiSelect.selectGroupLabel')"
                             :selected-label="$t('multiSelect.selectedLabel')"
                             :deselect-label="$t('multiSelect.deselectLabel')"
                             :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
                >
                </Multiselect>
              </div>
              <div v-show="form.applyIn==='products'" class="formItem">
                <Multiselect v-model="form.products" :options="products" track-by="id" label="name" :multiple="true"
                             :placeholder="$t('all.products')"
                             :select-label="$t('multiSelect.selectLabel')"
                             :select-group-label="$t('multiSelect.selectGroupLabel')"
                             :selected-label="$t('multiSelect.selectedLabel')"
                             :deselect-label="$t('multiSelect.deselectLabel')"
                             :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
                >
                </Multiselect>
              </div>
            </div>
          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 500px;
      .content {
        max-width: 400px;
        .contentLoaded {

          .image {
            @extend %defaultPreview;
            position: relative;
            width: #{$previewPromotionW*$previewPromotionScale}px;
            height: #{$previewPromotionH*$previewPromotionScale}px;
            cursor: pointer;
            margin-bottom: 10px;

            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            img {
              width: 100%;
              height: 100%;
              margin-bottom: 20px;

              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }
            .changeImage {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              margin-top: #{$previewPromotionH*$previewPromotionScale*0.6}px;
              color: white;
              background-color: rgba(0, 0, 0, 0.71);
              text-align: center;
              display: none;

              span {
                align-self: center;
                margin: 0 auto;
              }
            }

            .previewLine1 {
              background-color: var(--primary-color);
              color: var(--secondary-color);

              font-family: $font-primary-text;
              font-size: #{16*$previewPromotionScale}px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;


              top: #{66*$previewPromotionScale}px;
              text-align: left;
              position: absolute;
              width: #{120*$previewPromotionScale}px;

              height: #{21*$previewPromotionScale}px;

              padding-left: #{4*$previewPromotionScale}px;
              padding-right: #{4*$previewPromotionScale}px;

              div {
                display: flex;
                width: 100%;
                height: 100%;
                span {
                  align-self: center;
                  white-space: nowrap;
                }
              }

            }

            .previewLine2 {
              background-color: var(--secondary-color);
              color: var(--primary-color);

              font-family: Montserrat-ExtraBold $font-primary-text;
              font-size: #{9*$previewPromotionScale}px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: normal;
              display: flex;
              align-items: center;
              text-align: left;

              top: #{86*$previewPromotionScale}px;
              position: absolute;
              width: #{147*$previewPromotionScale}px;
              min-height: #{25*$previewPromotionScale}px;

              padding-left: #{4*$previewPromotionScale}px;
              padding-right: #{4*$previewPromotionScale}px;

              div {
                display: flex;
                width: 100%;
                height: 100%;
                span {
                  align-self: center;
                }
              }
            }
          }
          .image:hover .changeImage {
            display: flex;
          }


          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {

          }
        }
      }
    }
  }
</style>





















