<script>
  import Locale from '@components/layout/locale'
  import { authComputed } from '@state/helpers'

export default {
  components: { Locale },
  data() {
    return {

    }
  },
  computed: {
    ...authComputed,
    routeName: function () {
      return this.$router.currentRoute.name
    }
  },
  methods: {
    logout: function (event) {
      this.$router.push({ name: 'logout' })
    },
    goHome: function (event) {
      this.$router.push({ name: 'home' });
    }
  }
}
</script>

<template>
  <nav :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.logo" @click="goHome">
        <RouterLink :class="$style.logoRouter" to="/" v-bind="$attrs">
          <img :src="require('@assets/images/logo/img-navbar-logo@3x.png')" alt="logo" />
        </RouterLink>
      </div>
      <div :class="$style.containerlinks">
        <div>
          <div v-if="loggedIn" :class="[$style.itemlink, $style.menuActionsNot]" >
            <BaseButton
              class="md-accent md-raised md-lg"
              type="text"
              @click="logout"
            >
              {{$t('navBar.logOut')}}
            </BaseButton>
          </div>
          <div :class="[$style.itemlink, $style.menuActionsNot]">
            <Locale />
          </div>

        </div>
      </div>
    </div>
  </nav>

</template>

<style lang="scss" module>
@import '@design';

.menuActionsNot {
  display: block;
  @media screen and (max-width: $size-menu-actions+px) {
    display: none!important;
  }
}

.box {
  background-color: $color-body-bg;
  width: 100%;
  .container {
    padding: 10px;
    height: 118px;
    max-width: $size-content-width-max;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .logo {
      flex: 0;
      display: flex;
      min-width: 220px;
      cursor: pointer;
      img {
        align-self: center;
        max-height: 88px;
        @media screen and (max-width: $size-company-menu-max+px) {
          align-self: flex-start;
          max-height: 52px;
        }
      }
      @media screen and (max-width: $size-company-menu-max+px) {
        min-width: 65px;
      }
    }
    .containerlinks {
      display: flex;
      /*justify-content: flex-end;*/
      div {
        /*justify-content: flex-end;*/
        flex-wrap: wrap;
        align-self: center;
        display: flex;
        div {
          align-self: center;
          /*margin-left: 30px;*/
          BaseButton {

          }
        }
        .itemlink {
          margin-left: 10px;
        }

      }
    }
  }
}


</style>
