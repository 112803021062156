import Vue from 'vue'
import App from './app'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/performance';
import 'firebase/analytics';
import VueMaterial from 'vue-material'
import Vuelidate from 'vuelidate'
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';
import vuetify from '@src/plugins/vuetify'
import {config} from '@src/config';

import JSON5 from 'json5';
// eslint-disable-next-line no-unused-vars
import VueLodash from 'vue-lodash'
import VueSweetalert2 from 'vue-sweetalert2'
import VueI18n from "vue-i18n";

import 'vue-material/dist/vue-material.min.css'

import router from '@router'
import store from '@state/store'
import '@components/_globals'

import localeEnUS from '@i18n/en-US'
import localePtBR from '@i18n/pt-BR'
import VueTheMask from 'vue-the-mask'
import VueMoment from 'vue-moment'
import moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueScrollTo from 'vue-scrollto';


import pluginGlobal from '@src/plugins/global.js';

const stringUtils = require('@common/string_utils');
const fcmI18n = require('@i18n');

Vue.use(pluginGlobal);


require('moment/locale/pt-br');

let {i18nConfig, getLocale, languages} = fcmI18n;

window.levenshtein = stringUtils.fcmLevenshtein;


// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production';

firebase.initializeApp(config.firebase);

// console.log('firebase main', firebase);

// Debug functions local http and onCall
if(process.env.NODE_ENV !== 'production') {
  // firebase.functions().useFunctionsEmulator("http://localhost:5000")
}


const firestore = firebase.firestore();
firebase.analytics();

/*
const settings = {timestampsInSnapshots: true};
firestore.settings(settings); */

firestore.settings({});

window.firebaseDebug = firebase;
window.JSON5 = JSON5;

let onAuthStateChanged = null;

Vue.use(VueMaterial);
Vue.use(Vuelidate);

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
});

let locale = getLocale();
document.documentElement.setAttribute('lang', locale);
Vue.use(VueI18n);

let fcmMissinKey = null;
if(process.env.NODE_ENV !== 'production') {
  window.fcmMissinKeys = {};
  fcmMissinKey = function(language, key) {
    window.fcmMissinKeys[key] = ''
  }
}

const i18n = new VueI18n({
  ...i18nConfig,
  locale: locale,
  messages: {
    'en-US': localeEnUS,
    'pt-BR': localePtBR,
  },
  missing: fcmMissinKey,
});

if(languages[locale]) {
  i18n.localeDatabase = languages[locale].database;
}

window.VueI18n = i18n;



// Vue.use(VueLodash, { name: '_' })

Vue.use(VueTheMask);
Vue.use(VueMoment, {
  moment
});

window.moment = moment;

// If running inside Cypress...
if (window.Cypress) {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(VueGoogleMaps, {
  load: {
    key: config.maps.key,
    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
    language: i18n.locale
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // If you want to set the version, you can do so:
    // v: '3.26',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
});


Vue.use( CKEditor );


let performance = null;
// TODO FCM register bugs only on production
if(process.env.NODE_ENV === 'production' && process.env.NODE_ENV !== 'production') {
  Vue.config.errorHandler = function (err, vm, info) {
    // Toast.error(error.message)
    console.warn('errorHandler', err.message);
    // send error as event to google analytcs...
    if (err) {
      console.warn('errorHandler stack', err.stack);
      console.warn('errorHandler info', info)
    }
  };


  window.onerror = function (msg, url, lineNo, columnNo, error) {
    // maybe we need to also catch errors here and send to GA
    console.warn('onerror', msg, url, lineNo, columnNo, error);
  };
}


performance = firebase.performance();
Vue.mixin({
  methods: {
    $performance: function () {
      return performance
    },
  }
});

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});



const app = new Vue({
  vuetify,
  i18n,
  router,
  store,

  created(){
    if(!onAuthStateChanged) {
      onAuthStateChanged = firebase.auth().onAuthStateChanged((user) => {
        store.dispatch('auth/onAuthStateChanged', {firebaseUser: user})
      })
    }
    store.dispatch('time/start');
    store.dispatch('config/start');
    window.swal = this.$swal;
  },
  render: (h) => h(App),

}).$mount('#app');

window.vueApp = app;

if (module.hot) {
  module.hot.accept(['@i18n/en-US', '@i18n/pt-BR'], function () {
    i18n.setLocaleMessage('en-US', require('@i18n/en-US').default)
    i18n.setLocaleMessage('pt-BR', require('@i18n/pt-BR').default)
    // Or the following hot updates via $i18n property
    // app.$i18n.setLocaleMessage('en', require('./en').default)
    // app.$i18n.setLocaleMessage('ja', require('./ja').default)
  })
}

// If running inside Cypress...
if (window.Cypress) {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}



