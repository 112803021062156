<script>
  import Locale from '@components/layout/locale'
  import {authComputed, authMethods} from '@state/helpers'
  export default {
    components: { Locale },
    data() {
      return {
        isAuto: true,
        isUpdating: false,
        showConfig: false,
      }
    },
    computed: {
      ...authComputed,
      routeName: function () {
        return this.$router.currentRoute.name
      },
      strippedName: function(){
        if(!this.currentOng || !this.currentOng.name) {
          return '';
        }
        if(this.currentOng.name.length > 20) {
          return this.currentOng.name.slice(0,19) + '...';
        }
        return this.currentOng.name;
      },
      strippedUserName: function(){
        if(!this.currentUser || !this.currentUser.name) {
          return '';
        }
        if(this.currentUser.name.length > 18) {
          return this.currentUser.name.slice(0,17) + '...';
        }
        return this.currentUser.name;
      }
    },
    methods: {
      ...authMethods,
      logout: function (event) {
        this.$router.push({ name: 'logout' });
      },
      goLogin: function (event) {
        this.$router.push({ name: 'company/login' });
      },
      goRegister: function (event) {
        this.$router.push({ name: 'company/register' });
      },
      changeShowConfig() {
        this.showConfig = !this.showConfig;
      },
      awayShowConfig() {
        this.showConfig = false;
      },
      goHome: function (event) {
        this.$router.push({ name: 'home' });
      }
    }
  }
</script>

<template>
  <nav :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.logo" @click="goHome">
        <img :src="require('@assets/images/logo/img-navbar-logo@3x.png')" alt="logo" />
      </div>
      <div v-if="loggedIn && currentOng" :class="$style.ong">
        <img v-if="currentOng.logoImgUrl" :src="currentOng.logoImgUrl" />
        <div :class="$style.content">
          <span :class="$style.name">{{strippedName}}</span>
          <span :class="$style.userName">({{strippedUserName}})</span>
        </div>
      </div>
      <div :class="$style.containerlinks">
        <div>
          <div v-if="loggedIn" :class="[$style.itemlink, $style.menuActionsNot]">
            <BaseButton
                    class="md-accent md-raised md-lg"
                    type="text"
                    @click="logout"
            >
              {{$t('navBar.logOut')}}
            </BaseButton>
          </div>
          <!-- <div v-if="!loggedIn" :class="$style.itemlink">
            <BaseButton
              v-if="routeName!=='ong/register'"
              class="md-accent md-raised md-lg"
              type="text"
              @click="goRegister"
            >
              {{$t('navBar.singIn')}}
            </BaseButton>
          </div>
          <div v-if="!loggedIn" :class="$style.itemlink">
            <BaseButton
              v-if="routeName!=='ong/login'"
              class="md-accent md-raised md-lg"
              type="text"
              @click="goLogin"
            >
              {{$t('navBar.logIn')}}
            </BaseButton>
          </div> -->
          <div :class="[$style.itemlink, $style.menuActionsNot]">
            <Locale />
          </div>
        </div>
      </div>
    </div>
  </nav>

</template>


<style lang="scss">
  .configContent .md-switch .md-switch-thumb .md-ripple {
    height: 26px!important;
  }
</style>

<style lang="scss" module>
  @import '@design';

  .menuActionsNot {
    display: block;
    @media screen and (max-width: $size-menu-actions+px) {
      display: none!important;
    }
  }
  .box {
    background-color: $color-body-bg;
    width: 100%;
    .container {
      padding: 10px;
      height: 118px;
      min-width: $size-content-width-min;
      max-width: $size-content-width-max;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .logo {
        flex: 0;
        display: flex;
        min-width: 220px;
        cursor: pointer;
        img {
          align-self: center;
          max-height: 88px;
          @media screen and (max-width: $size-ong-menu-max+px) {
            align-self: flex-start;
            max-height: 52px;
          }
        }
        @media screen and (max-width: $size-ong-menu-max+px) {
          min-width: 65px;
        }
      }
      .ong {
        @extend %font-navbar;
        flex: 1;
        align-self: center;
        justify-content: flex-start;
        display: flex;
        text-align: start;
        padding-left: 10px;

        .name {
          margin-bottom: 4px;
        }
        .userName {
          @extend %font-content;
          margin-bottom: 10px;
          font-weight: 400;
        }

        img {
          border-radius: 50%;
          align-self: center;
          max-height: 75px;
          flex: 0;
          @media screen and (max-width: $size-ong-menu-max+px) {
            align-self: flex-start;
            max-height: 52px;
          }
        }
        .content {
          align-self: center;
          max-width: 270px;
          padding-left: 10px;
          text-align: start;
          display: flex;
          flex-direction: column;

          .iconsOngStatus {
            .icon {
              width: 35px;
              height: 35px;
              margin-right: 4px;
            }
            .active {
              filter: saturate(20000%) hue-rotate(320deg);
            }
            .config {
              cursor: pointer;
              z-index:100;
            }
            z-index:100;
          }

          .outside {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index:99;
          }

          .configContainer {
            position: relative;
            .configContent {
              position: absolute;
              z-index:100;
              background-color: white;
              padding: 14px;
              border-radius: 3px;
              min-width: 210px;

              >div {
                margin-bottom: 10px;
              }
            }
          }



        }

        .switch {
          @extend %font-content;
          margin: 1px 5px 5px 0;
        }
      }
      .containerlinks {
        flex: 0 1 auto;
        display: flex;
        /*justify-content: flex-end;*/
        div {
          justify-content: flex-end;
          align-self: center;
          display: flex;
          flex-wrap: wrap;
          div {
            align-self: center;
            /*margin-left: 30px;*/
            BaseButton {

            }
          }
          .itemlink {
            margin-left: 10px;
          }

        }
      }
    }
  }


</style>
