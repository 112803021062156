<script>
  import { configComputed} from '@state/helpers'
 // let {translateBd} = require('@i18n');
  export default {
    model: {
      prop: 'lock',
      event: 'change'
    },
    data () {
      return {
        isClose: false,
        versionNumber: process.env.VUE_APP_VERSION_NUMBER,
        version: process.env.VUE_APP_VERSION
      }
    },
    computed: {
      ...configComputed,
    },
    watch: {
      config(value, oldValue) {
        if(value.maintenance && value.maintenance.status && value.maintenance.msg && value.maintenance.lock) {
          this.$emit('change', true)
        }
        else if(value.maintenance && value.minVersion.version && value.minVersion.msg && value.minVersion.lock && parseInt(value.minVersion.version)>=parseInt(this.versionNumber)) {
          return this.$emit('change', true)
        }
        else {
          return this.$emit('change', false)
        }
      }
    },
    methods: {
      reload() {
        location.reload(true);
      },
      close() {
        this.isClose = true;
      }
    },
  }

</script>


<template>
<div v-if="config && ((config.maintenance && config.maintenance.status && config.maintenance.msg) || (config.minVersion && config.minVersion.version && parseInt(config.minVersion.version)>=parseInt(versionNumber) && config.minVersion.msg)) && !isClose" :class="[$style.config]" >
  <div v-if="config.maintenance && config.maintenance.status && config.maintenance.msg" :class="[$style.maintenance]">
    {{$trdb(config.maintenance.msg)}}
  </div>
  <div v-if="config.minVersion && config.minVersion.version && parseInt(config.minVersion.version)>=parseInt(versionNumber) && config.minVersion.msg" :class="[$style.minVersion]" @click="reload()">
    {{$trdb(config.minVersion.msg)}}
  </div>
  <div :class="[$style.closeButton]" @click="close"><MdIcon>close</MdIcon></div>
</div>
</template>


<style lang="scss" module>
@import '@design';

  .config {
    @extend %defaultAlert;

    width: 100%;
    padding: 10px;
    position: fixed;
    border: 1px solid red;
    display: flex;

    .minVersion {
      flex: 1;
      cursor: pointer;
    }

    .maintenance {
      flex: 1;
    }

    .closeButton {
      flex: 0;
      color: #bb2f2c;
      cursor: pointer;
      top: 9px;
      right: 30px;

      i {
        color: #bb2f2c!important;
      }
    }
  }




</style>
