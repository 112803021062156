"use strict";
import firebase from 'firebase/app'

// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};


toExport.getCompanies = async (input) => {
  let result = await firebase.functions().httpsCallable('search-listCompaniesCall')({
    search: input.search,
    size: input.size,
    from: input.from,
    lang: input.lang
  });
  let items = [];
  if(result.data && result.data.hits) {
    for(let item of result.data.hits) {
      items.push({
        id: item.id,
        name: item.name + (item.lastName?' '+item.lastName:''),
        imgUrl: item.profileImageUrl || null,
      });
    }
  }
  else {
    return {
      hits: [],
      total: 0,
      offset: 0,
    };
  }
  return {
    hits: items,
    total: result.data.total,
    offset: result.data.offset,
  };
};



module.exports = toExport;
