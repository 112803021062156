<script>
  import Layout from '@components/layout/company/main-visitant'
  import { authMethods, authComputed } from '@state/helpers'
  import {appConfig} from '@src/config';
  import defaults from '@/src/router/defaults'
  import firebase from 'firebase/app'

  export default {
    page: {
      title: 'Log in',
      meta: [
        {name: 'description', content: `Log in to ${appConfig.title}`}
      ],
    },
    components: { Layout },
    data() {
      return {
        email: '',
        password: '',
        updateError: null,
        tryingToLogIn: false,
        recaptchaResponse: false,
        isReset: false,
        isShowRecaptchar: false,
        isRegister: false,
        status: 'login',

      }
    },
    computed: {
      ...authComputed,
    },
    watch: {
      loggedIn() {
        if (this.$store.getters['auth/loggedIn'] && !this.tryingToLogIn) {
          console.log('Redirecionar usuário logado')
          this.$router.push({ name: defaults.routeLogged[this.$store.getters['auth/loggedIn']] })
        }
      }
    },
    mounted() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.$refs.recaptchaContainer, {
        'size': 'normal',
        'callback': function(response) {
          this.recaptchaResponse = response
          // console.log('recaptchaVerifier response', response);
        },
        'expired-callback': function() {
          console.warn('recaptchaVerifier expired');
        }
      });
    },
    methods: {
      ...authMethods,
      async showRecaptchar() {
        // if(window.recaptchaVerifier) {
        //   this.isShowRecaptchar = true;
        //   await window.recaptchaVerifier.render();
        //   window.recaptchaVerifier.verify();
        // }
      },
      async tryToLogIn() {
        if(!this.password) {
          return;
        }
        this.updateError = null
        if(!this.email || !this.password) {
          this.updateError = true
          return
        }
        this.tryingToLogIn = true
        try {
          await this.logInClient({
            email: this.email,
            password: this.password,
          });
          this.tryingToLogIn = false
          this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged['CLIENT'] })
        } catch(error) {
          console.error('Erro ao efetuar login', error)
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'error',
            title: this.$t('login.error.loginTitle'),
            text: this.$t('login.providerCode.'+error.code),
            showConfirmButton: false,
            timer: 20000
          });
          if(error.code==='auth/too-many-requests') {
            await this.showRecaptchar();
          }

          this.tryingToLogIn = false;
          this.updateError = {message: this.$t('login.providerCode.'+error.code)};
        }
      },
      submitForm() {
        if(this.status==='reset') {
          this.tryReset()
        }
        else if(this.status==='register') {
          this.tryToRegister()
        }
        else {
          this.tryToLogIn()
        }
      },
      async tryReset() {
        if(!this.email) {
          this.updateError = true
          return
        }
        try {
          this.tryingToLogIn = true
          let result = await firebase.functions().httpsCallable('auth-resetPassword')({email:this.email, workspace:'CLIENT'})
          console.log('result', result);
          if(result && result.data && result.data.status) {
            this.$swal({
              toast: true,
              position: 'top-end',
              type: 'success',
              title: this.$t('form.emailResetSuccess'),
              showConfirmButton: false,
              timer: 15000
            })
          }
          else {
            this.$swal({
              toast: true,
              position: 'top-end',
              type: 'error',
              title: this.$t('form.emailResetError'),
              showConfirmButton: false,
              timer: 20000
            })
          }
        } catch(error) {
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'error',
            title: this.$t('form.emailResetError'),
            showConfirmButton: false,
            timer: 20000
          })
        }
        this.tryingToLogIn = false
      },
      async tryToRegister() {
        if(!this.password) {
          return;
        }
        this.updateError = null;
        if(!this.email || !this.password) {
          this.updateError = true;
          return
        }
        this.tryingToLogIn = true;
        try {
          await this.registerClient({
            email: this.email,
            password: this.password,
          });
          this.tryingToLogIn = false;
          this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged['CLIENT'] })
        } catch(error) {
          this.$showError({title:this.$t('login.error.loginTitle'), text: this.$t('login.providerCode.'+error.code)});

          if(error.code==='auth/too-many-requests') {
            // await this.showRecaptchar();
          }

          this.tryingToLogIn = false;
          this.updateError = {message: this.$t('login.providerCode.'+error.code)};
        }
      },
      async tryLoginSocial(provider) {
        this.updateError = null;
        this.tryingToLogIn = true;
        try {
          await this.logInSocial({locale: this.$i18n.locale, provider: provider});
          this.tryingToLogIn = false;
          this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged['CLIENT'] })
        } catch(error) {
          this.$showError({title:this.$t('login.error.loginTitle'), text: this.$t('login.providerCode.'+error.code)});
          this.tryingToLogIn = false;
          this.updateError = {message:this.$t('login.providerCode.'+error.code)}
        }
      },

      async tryRegisterSocial(provider) {
        this.updateError = null;
        this.tryingToLogIn = true;
        try {
          await this.logInSocial({locale: this.$i18n.locale, provider: provider});
          this.tryingToLogIn = false;
          this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged['CLIENT'] })
        } catch(error) {
          this.$showError({title:this.$t('login.error.loginTitle'), text: this.$t('login.providerCode.'+error.code)});
          this.tryingToLogIn = false;
          this.updateError = {message:this.$t('login.providerCode.'+error.code)}
        }
      },

      async tryPhone() {
        this.tryingToLogIn = true;
        this.updateError = null;
        try {
          const {value: phone} = await this.$swal({
            title: this.$t('login.getPhoneNumber'),
            input: 'text',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              return !value && 'You need to write something!'
            }
          });

          if(!phone) {
            throw this.$t('login.error.phoneEmpty')
          }

          this.startLogin()
          let appVerifier = window.recaptchaVerifier;
          let confirmationResult = await firebase.auth().signInWithPhoneNumber(phone, appVerifier);

          const {value: code} = await this.$swal({
            title: this.$t('login.getPhoneCode'),
            input: 'text',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              return !value && 'You need to write something!'
            }
          })

          if(!code) {
            throw this.$t('login.error.phoneCodeEmpty')
          }

          let authUser = await confirmationResult.confirm(code)
          await this.logInPhone({authUser})

          this.tryingToLogIn = false
          this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged['CLIENT'] })
        } catch(error) {
          this.endLogin()
          this.$showError({title:this.$t('login.error.loginTitle'), text: this.$t('login.providerCode.'+error.code)});
          this.tryingToLogIn = false
          this.updateError = error
        }
      },
      async switchRegister () {
        this.status = (this.status==='login')?'register': 'login';
        this.updateError = null;
      },
      async switchReset () {
        this.status = (this.status==='login')?'reset': 'login';
        this.updateError = null;
      },
      async focusPassword () {
        this.$refs.password.$el.querySelector('input').focus();
      }

    },
  }
</script>

<template>
  <Layout>



    <div :class="$style.container">
      <div :class="$style.content" >
        <div v-if="status==='reset'" :class="$style.title">{{$t('login.resetClient')}}</div>
        <div v-else-if="status==='register'" :class="$style.title">{{$t('login.registerClient')}}</div>
        <div v-else :class="$style.title">{{$t('login.accessClient')}}</div>

        <div :class="$style.body">
          <form :class="$style.form" @submit.prevent="submitForm">
            <BaseInputText
              v-model="email"
              label="Email"
              @keyup.enter="focusPassword"
            />
            <BaseInputText
              v-if="status!=='reset'"
              ref="password"
              v-model="password"
              type="password"
              label="Senha"
            />
            <p v-if="updateError" :class="$style.error">
              {{updateError.message?updateError.message:$t('login.error.generic')}}
            </p>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.loginButton]"
              :disabled="tryingToLogIn"
              type="submit"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                <span v-if="status==='reset'">{{$t('login.resetPassword')}}</span>
                <span v-else-if="status==='register'">{{$t('login.emailRegister')}}</span>
                <span v-else>{{$t('login.login')}}</span>
              </span>
            </BaseButton>

            <BaseButton
              v-if="status==='login'"
              :class="['md-primary','md-raised','md-lg', $style.loginFacebook, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="tryLoginSocial('facebook.com')"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.facebook')}}
              </span>
            </BaseButton>
            <BaseButton
              v-if="status==='login'"
              :class="['md-primary','md-raised','md-lg', $style.loginGoogle, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="tryLoginSocial('google.com')"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.google')}}
              </span>
            </BaseButton>
            <BaseButton
              v-if="status==='login'"
              :class="['md-primary','md-raised','md-lg', $style.loginApple, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="tryLoginSocial('apple.com')"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.apple')}}
              </span>
            </BaseButton>

<!--

            <BaseButton
              v-if="status==='register'"
              :class="['md-primary','md-raised','md-lg', $style.loginFacebook, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="tryRegisterSocial('facebook.com')"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.facebookRegister')}}
              </span>
            </BaseButton> -->
            <BaseButton
              v-if="status==='register'"
              :class="['md-primary','md-raised','md-lg', $style.loginGoogle, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="tryRegisterSocial('google.com')"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.googleRegister')}}
              </span>
            </BaseButton>
            <BaseButton
              v-if="status==='register'"
              :class="['md-primary','md-raised','md-lg', $style.loginApple, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="tryRegisterSocial('apple.com')"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.appleRegister')}}
              </span>
            </BaseButton>


            <BaseButton
              v-if="status==='login'"
              :class="['md-primary','md-raised','md-lg', $style.loginPhone, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="tryPhone"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
              {{$t('login.phone')}}
              </span>
            </BaseButton>
            <div
              :class="['md-primary','md-raised','md-lg', $style.loginText]"
              :disabled="tryingToLogIn"
              @click="switchReset"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t(status==='login'?'login.goReset':'login.goLogin')}}
              </span>
            </div>
            <div
              v-show="status==='login'"
              :class="['md-primary','md-raised','md-lg', $style.loginText]"
              :disabled="tryingToLogIn"
              @click="switchRegister"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t(status==='login'?'login.register':'login.goLogin')}}
              </span>
            </div>

            <br>
            <div id="recaptcha-container" ref="recaptchaContainer"></div>

          </form>
        </div>

      </div>

    </div>

  </Layout>
</template>


<style lang="scss" module>
  @import '@design';
  .error {
    color: $color-input-error;
  }
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    padding-top: 80px;
    padding-bottom: 30px;
    .content {
      width: 90%;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      margin: 0 auto;
      max-width: 400px;
      // max-height: 450px;

      .title {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .body {
        font-family: $font-input;
        .fullwidth {
          width: 100%;
        }
        .loginButton {
          margin-bottom: 5px;
          margin-top: 10px;
          width: 100%;
        }
        .loginFacebook {
          background-color: #4267B2!important;
          text-transform: none;
        }
        .loginApple {
          background-color: #000000!important;
          text-transform: none;
        }
        .loginGoogle {
          background-color: #edeeed !important;
          text-transform: none;
          color: #1f2633!important;
        }
        .loginPhone {
          text-transform: none;
        }

        .loginText {
          margin-bottom: 5px;
          margin-top: 10px;
          width: 100%;
          color: #4872c2;
          cursor: pointer;
          padding: 7px;
          align-content: center;
          text-align: center;
        }
      }
    }
  }


</style>
