<script>
  // import NavBar from '@components/nav-bar'
  import MainLayout from '@components/layout/ong/main-layout'

  export default {
    components: {MainLayout},
    props: {
      styleOrder: {
        type: [Boolean],
        default: false,
      }
    },
    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>

<template>
  <MainLayout :style-order="styleOrder">
    <div :class="$style.box">
      <slot name="left">
      </slot>
      <slot name="right"></slot>
    </div>
  </MainLayout>
</template>


<!-- <div v-if="showLeft" :class="$style.left">
  <slot name="left" :teste2="teste2" @teste="teste"></slot>
</div>
<div v-if="showRight" :class="$style.right">
  <slot name="right"></slot>
</div> -->



<style lang="scss" module>
  @import '@design';

  .box {
    display: flex;
    overflow-y: scroll;

    > div {
      flex: 1 1 0px;
      position: relative;
    }
  }



</style>
