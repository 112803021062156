<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/admin/main_tabs'
  import firebase from 'firebase/app'
  import fileUtils from '@common/file_utils'
  import {companyService} from '@common/services';
  import SearchPick from '@components/diversos/searchPick'

  const generators = require('@common/generators');
  const dataConvert = require('@common/dataConvert');

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, SearchPick},
    props: {
    },
    data() {
      return {
        itemId: null,
        tryingToUpdate: false,
        dateBegin: new Date(),
        dateEnd: new Date(),
        companies: [],

      }
    },
    computed: {
      ...authComputed
    },
    async mounted(){
    },
    methods: {
      async exportUsers() {
        this.tryingToUpdate = true;
        let ini = moment.utc(this.dateBegin).format('YYYY-MM-DD');
        let end = moment.utc(this.dateEnd).format('YYYY-MM-DD');

        let result = await firebase.functions().httpsCallable('exports-getUsers')({ini: ini, end: end});
        if(result.data && result.data.isOk && result.data.data) {
          fileUtils.download(JSON.stringify(result.data.data), 'users_'+moment().format('YYYY-MM-DD-HH-mm-ss')+'_'+moment.utc(this.dateBegin).format('YYYY-MM-DD')+'_'+moment.utc(this.dateEnd).format('YYYY-MM-DD'), 'application/json');
          console.log('Sucesso, número de registros: '+result.data.data.length);
          this.$showSuccess({title:'Sucesso, número de registros: '+result.data.data.length});
        }
        else {
          this.$showError({title:'nenhum dado para o período'});
        }
        this.tryingToUpdate = false;
      },

      async exportOrders() {
        this.tryingToUpdate = true;
        let ini = moment(this.dateBegin);
        let end = moment(this.dateEnd).endOf('day');

        const ret = [];
        const itemsGen = generators.getOrders(ini.toDate(), end.toDate(), this.companies.reduce((ret, value)=>{ret.push(value.id);return ret}, []));
        while(true) {
          const itemObj = await itemsGen.next();
          if (!itemObj || itemObj.done) {
            break;
          }
          if (!itemObj.value) {
            continue;
          }
          const item = itemObj.value.data() || {};
          item.id = itemObj.value.id;
          const itemId = itemObj.value.id;

          if(item.companyId==='yPqVEBHAW3HftoXmljaC') {
            continue;
          }
          ret.push(await dataConvert.orderResumeExport(itemId, item));
        }
        if(ret.length>0) {
          fileUtils.download(JSON.stringify(ret), 'orders_'+moment().format('YYYY-MM-DD-HH-mm-ss')+'_'+moment.utc(this.dateBegin).format('YYYY-MM-DD')+'_'+moment.utc(this.dateEnd).format('YYYY-MM-DD'), 'application/json');
          console.log('Sucesso, número de registros: '+ret.length);
          this.$showSuccess({title:'Sucesso, número de registros: '+ret.length});
        }
        else {
          this.$showError({title:'nenhum dado para o período'});
        }
        this.tryingToUpdate = false;
      },
      async exportOrdersAll() {
        this.tryingToUpdate = true;
        let ini = moment(this.dateBegin);
        let end = moment(this.dateEnd).endOf('day');


        const ret = [];
        const itemsGen = generators.getOrders(ini.toDate(), end.toDate(), this.companies.reduce((ret, value)=>{ret.push(value.id);return ret}, []));
        while(true) {
          const itemObj = await itemsGen.next();
          if (!itemObj || itemObj.done) {
            break;
          }
          if (!itemObj.value) {
            continue;
          }
          const item = itemObj.value.data() || {};
          item.id = itemObj.value.id;

          if(item.companyId==='yPqVEBHAW3HftoXmljaC') {
            continue;
          }
          ret.push(item);
        }
        if(ret.length>0) {
          fileUtils.download(JSON.stringify(ret), 'orders_all_'+moment().format('YYYY-MM-DD-HH-mm-ss')+'_'+moment.utc(this.dateBegin).format('YYYY-MM-DD')+'_'+moment.utc(this.dateEnd).format('YYYY-MM-DD'), 'application/json');
          console.log('Sucesso, número de registros: '+ret.length);
          this.$showSuccess({title:'Sucesso, número de registros: '+ret.length});
        }
        else {
          this.$showError({title:'nenhum dado para o período'});
        }
        this.tryingToUpdate = false;
      },

      async exportCompanies() {
        this.tryingToUpdate = true;
        let ini = moment(this.dateBegin);
        let end = moment(this.dateEnd);

        const ret = [];
        const itemsGen = generators.getCompanies(ini.toDate(), end.toDate());
        while(true) {
          const itemObj = await itemsGen.next();
          if (!itemObj || itemObj.done) {
            break;
          }
          if (!itemObj.value) {
            continue;
          }
          const item = itemObj.value.data() || {};
          item.id = itemObj.value.id;
          ret.push({
            ...item,
            createdAt: (item.createdAt || {}).seconds || '',
            createdAtTxt:  item.createdAt?moment(item.createdAt.toDate()).format('DD/MM/YYYY HH:mm:ss'):'',
          });
        }
        if(ret.length>0) {
          fileUtils.download(JSON.stringify(ret), 'restaurantes_'+moment().format('YYYY-MM-DD-HH-mm-ss')+'_'+moment.utc(this.dateBegin).format('YYYY-MM-DD')+'_'+moment.utc(this.dateEnd).format('YYYY-MM-DD'), 'application/json');
          console.log('Sucesso, número de registros: '+ret.length);
          this.$showSuccess({title:'Sucesso, número de registros: '+ret.length});
        }
        else {
          this.$showError({title:'nenhum dado para o período'});
        }
        this.tryingToUpdate = false;
      },
      async exportPayments() {
        this.tryingToUpdate = true;
        let ini = moment(this.dateBegin);
        let end = moment(this.dateEnd).endOf('day');


        const ret = [];
        const itemsGen = generators.getOrders(ini.toDate(), end.toDate(), this.companies.reduce((ret, value)=>{ret.push(value.id);return ret}, []));
        while(true) {
          const itemObj = await itemsGen.next();
          if (!itemObj || itemObj.done) {
            break;
          }
          if (!itemObj.value) {
            continue;
          }
          const item = itemObj.value.data() || {};
          item.id = itemObj.value.id;
          const itemId = itemObj.value.id;

          if(item.companyId==='yPqVEBHAW3HftoXmljaC') {
            continue;
          }

          const order = await dataConvert.orderResumeExport(itemId, item);
          for(let userId in order.paymentMethod) {
            if(userId !==order.paymentMethod[userId].userId) {
              console.error('Dados de pgamento inconsistentes userId !==order.paymentMethod[userId].userId');
            }
            ret.push({...order.paymentMethod[userId], ...order, statusPayment: order.paymentMethod[userId].status});
          }
        }
        if(ret.length>0) {
          fileUtils.download(JSON.stringify(ret), 'pagamentos_'+moment().format('YYYY-MM-DD-HH-mm-ss')+'_'+moment.utc(this.dateBegin).format('YYYY-MM-DD')+'_'+moment.utc(this.dateEnd).format('YYYY-MM-DD'), 'application/json');
          console.log('Sucesso, número de registros: '+ret.length);
          this.$showSuccess({title:'Sucesso, número de registros: '+ret.length});
        }
        else {
          this.$showError({title:'nenhum dado para o período'});
        }
        this.tryingToUpdate = false;
      },
      async exportOngs() {
        this.tryingToUpdate = true;
        let ini = moment.utc(this.dateBegin);
        let end = moment.utc(this.dateEnd);


        const ret = [];
        const itemsGen = generators.getOngs(ini.toDate(), end.toDate());
        while(true) {
          const itemObj = await itemsGen.next();
          if (!itemObj || itemObj.done) {
            break;
          }
          if (!itemObj.value) {
            continue;
          }
          const item = itemObj.value.data() || {};
          item.id = itemObj.value.id;
          ret.push({
            ...item,
            createdAt: (item.createdAt || {}).seconds || '',
            createdAtTxt:  item.createdAt?moment(item.createdAt.toDate()).format('DD/MM/YYYY HH:mm:ss'):'',
          });
        }
        if(ret.length>0) {
          fileUtils.download(JSON.stringify(ret), 'ongs_'+moment().format('YYYY-MM-DD-HH-mm-ss')+'_'+moment.utc(this.dateBegin).format('YYYY-MM-DD')+'_'+moment.utc(this.dateEnd).format('YYYY-MM-DD'), 'application/json');
          console.log('Sucesso, número de registros: '+ret.length);
          this.$showSuccess({title:'Sucesso, número de registros: '+ret.length});
        }
        else {
          this.$showError({title:'nenhum dado para o período'});
        }
        this.tryingToUpdate = false;
      },
      async getCompanies(input) {
        return companyService.getCompanies({
          search: input.search,
          size: input.size,
          from: input.from,
          lang: this.$i18n.locale
        });
      },
    },
  }
</script>

<template>
  <MainTabs>
    <template #left >
      <div>
        <div :class="$style.box">
          <br><br>

          <MdDatepicker v-model="dateBegin" :md-open-on-focus="true" md-immediately :class="['formItemText', $style.date]" >
            <label>{{$t('exports.dateBegin')}}</label>
          </MdDatepicker>
          <MdDatepicker v-model="dateEnd" :md-open-on-focus="true" md-immediately :class="['formItemText', $style.date]" >
            <label>{{$t('exports.dateEnd')}}</label>
          </MdDatepicker>
          <br>
          <SearchPick
            v-model="companies"
            :get-items="getCompanies"
            :label="$t('exports.selectCompanies')"
            :show-img="false"
            :filter-region="false"
            :limit="10"
            :class="$style.searchPick"
          >
            <template #item="slotProp" >
              <div>{{slotProp.item.name+(slotProp.item.lastName?'_'+slotProp.item.lastName:'')+(slotProp.item.isPreUser?'('+$t('all.isPreUser')+')':'')}}</div>
            </template>
            <template #selected="slotProp" >
              <div>{{slotProp.item.name+(slotProp.item.lastName?'+'+slotProp.item.lastName:'')}}</div>
            </template>
          </SearchPick>
          <br>
          <BaseButton
            :class="['md-primary','md-raised', $style.button ]"
            :disabled="tryingToUpdate"
            @click="exportUsers"
          >
            <BaseIcon v-if="tryingToUpdate" name="sync" spin />
            <span v-else>
              {{$t('exports.exportUsers')}}
            </span>
          </BaseButton>


          <br><br>

          <BaseButton
            :class="['md-primary','md-raised', $style.button ]"
            :disabled="tryingToUpdate"
            @click="exportOrders"
          >
            <BaseIcon v-if="tryingToUpdate" name="sync" spin />
            <span v-else>
              {{$t('exports.exportOrders')}}
            </span>
          </BaseButton>
          <br><br>
          <BaseButton
            :class="['md-primary','md-raised', $style.button ]"
            :disabled="tryingToUpdate"
            @click="exportOrdersAll"
          >
            <BaseIcon v-if="tryingToUpdate" name="sync" spin />
            <span v-else>
              {{$t('exports.exportOrdersAll')}}
            </span>
          </BaseButton>
          <br><br>
          <BaseButton
            :class="['md-primary','md-raised', $style.button ]"
            :disabled="tryingToUpdate"
            @click="exportPayments"
          >
            <BaseIcon v-if="tryingToUpdate" name="sync" spin />
            <span v-else>
              {{$t('exports.exportPayments')}}
            </span>
          </BaseButton>
          <br><br>
          <BaseButton
            :class="['md-primary','md-raised', $style.button ]"
            :disabled="tryingToUpdate"
            @click="exportCompanies"
          >
            <BaseIcon v-if="tryingToUpdate" name="sync" spin />
            <span v-else>
              {{$t('exports.exportCompanies')}}
            </span>
          </BaseButton>

          <br><br>
          <BaseButton
            :class="['md-primary','md-raised', $style.button ]"
            :disabled="tryingToUpdate"
            @click="exportOngs"
          >
            <BaseIcon v-if="tryingToUpdate" name="sync" spin />
            <span v-else>
              {{$t('exports.exportOngs')}}
            </span>
          </BaseButton>

          <br><br>
        </div>
      </div>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

  .searchPick {
    max-width: 400px;
  }

  .box {
    text-align: initial;
    margin-left: 10px;
  }

  .date {
    max-width: 230px;
  }

  .button {
    max-width: 450px;
  }

</style>





















