<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import {VDialog} from 'vuetify/lib'

export default {
  components: {
    VueCropper,
    VDialog
  },
  model: {
    prop: 'imageSrc',
    event: 'change'
  },
  props: {
    imageSrc: {
      type: [String],
      default: ''
    },
    // [ modal | block ]
    typeContent: {
      type: String,
      default: 'modal'
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    maxWidth: {
      type: Number,
      default: 1024
    },
    maxHeight: {
      type: Number,
      default: 1024
    },
    cropperWidth: {
      type: Number,
      default: 450
    },
    cropperHeight: {
      type: Number,
      default: 450
    },
    fillColor: {
      type: [String, null],
      default: null
    },
    mimeType: {
      type: [String],
      default: 'image/png'
    },
    qualityArgument: {
      type: [Number],
      default: 1
    },
    format: {
      type: [String],
      default: 'squad' // circle
    },

  },

  data() {
    return {
      cropImg: '',
      showEditor: false,
      localImageSrc: '',
      inputFile: {
        type: File,
        default: null
      },
      imgEditor: {
        scaleX: 1,
        scaleY: 1
      }
    };
  },
  computed: {
    showModal: {
      get() {
        return (this.showEditor && this.typeContent==='modal');
      },
      set(value) {
        this.showEditor = false;
      }
    }
  },
  watch: {
    inputFile: function () {
      this.showEditor = false;
      if(!this.inputFile || !this.inputFile.type) {
        console.error('Invalid inputFile', this.inputFile )
        return;
      }
      if (!this.inputFile.type.includes('image/')) {
        console.error('Invalid image type inputFile', this.inputFile )
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'error',
          title: this.$t('form.error.image.type'),
          showConfirmButton: false,
          timer: 2000
        })
        return;
      }
      if (typeof FileReader !== 'function') {
        alert('Sorry, FileReader API not supported');
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        this.localImageSrc = event.target.result;

        // rebuild cropperjs with the updated source
        this.$refs.cropper.replace(event.target.result);

        this.updatePreview(this.localImageSrc)
      };
      reader.readAsDataURL(this.inputFile);

      this.showEditor = true;
    }
  },
  methods: {
    setImage(e) {
      const file = e.target.files[0];

      if (!file ||!file.type.includes('image/')) {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'error',
          title: this.$t('form.error.imageType'),
          showConfirmButton: false,
          timer: 2000
        })
        return
      }
      this.inputFile = file;
    },
    done() {
      let canvas = this.updatePreview();

      canvas.toBlob((blob) => {
            this.$emit('changeBlob', blob)
            this.showEditor = false

          },
          this.mimeType, this.qualityArgument);
    },
    cancel() {
      this.showEditor = false
      this.$emit('change', null)
      return this.$emit('changeBlob', null)
    },
    insertImage() {
      this.showEditor = true
      this.$refs.inputRef.click();
    },
    scaleX() {
      this.imgEditor.scaleX *= -1;
      this.$refs.cropper.scaleX(this.imgEditor.scaleX)
      this.updatePreview()
    },
    scaleY() {
      this.imgEditor.scaleY *= -1;
      this.$refs.cropper.scaleY(this.imgEditor.scaleY)
      this.updatePreview()
    },
    rotate(value) {
      this.$refs.cropper.rotate(value)
      this.updatePreview()
    },
    relativeZoom(value) {
      this.$refs.cropper.relativeZoom(value)
      this.updatePreview()
    },

    dataURLtoBlob(dataurl) {
      let arr = dataurl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type:mime});
    },
    cropEnd(e) {
      this.updatePreview()
    },
    updatePreview(input) {
      if(input) {
        return this.$emit('change', input)
      }
      let canvasOld = this.$refs.cropper.getCroppedCanvas({
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        fillColor: this.fillColor || 'transparent',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'
      });
      let canvas = canvasOld;
      if(this.format==='circle') {
        canvas = document.createElement('canvas');
        canvas.width = canvasOld.width;
        canvas.height = canvasOld.height;
        let ctx = canvas.getContext('2d');

        let cw=canvas.width;
        let ch=canvas.height;
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, cw, ch);

        ctx.arc(cw/2,ch/2,ch/2,0,Math.PI*2);
        ctx.clip();
        ctx.drawImage(canvasOld, 0, 0);
      }


      this.$emit('change', canvas.toDataURL(this.mimeType, this.qualityArgument));
      return canvas;
      //  this.$emit('change', this.$refs.cropper.getCroppedCanvas().toDataURL())
    }


  }
};

</script>


<template>
  <div v-show="showEditor && localImageSrc">
    <input ref='inputRef' :class="$style.inputImage" type="file" name="image" accept="image/*"
           style="font-size: 1.2em; padding: 10px 0;"
           @change="setImage" />

    <component :is="typeContent==='modal'?'VDialog':'div'" v-if="showEditor" v-model="showModal" :class="$style.modal"
               :fullscreen="$vuetify.breakpoint.xs"
    >
      <div :class="$style.box">
        <div :class="$style.content">
          <div :class="$style.cropper">
            <VueCropper
                ref="cropper"
                :guides="true"
                :view-mode="0"
                drag-mode="crop"
                :auto-crop-area="1"
                :aspect-ratio="aspectRatio"
                :initial-aspect-ratio="aspectRatio"
                :background="true"
                :rotatable="true"
                :zoomable="true"
                :scalable="true"
                :src="localImageSrc"
                alt="Source Image"
                :cropend="cropEnd"
                :viewport="{type: 'circle'}"
                :img-style="{ 'height': '380px' }"
            >
            </VueCropper>
            <div :class="$style.actionsIcons">
              <div @click="done()"><VIcon :class="$style.actionsIconDone">mdi-check</VIcon></div>
              <div @click="cancel()"><VIcon :class="$style.actionsIconCancel" >mdi-cancel</VIcon></div>
            </div>
          </div>
          <div v-if="localImageSrc !== ''" :class="$style.edit">
            <VBtn fab small class="ma-1" @click="rotate(-45)">
              <VIcon>mdi-rotate-left</VIcon>
              <v-tooltip md-direction="top">{{$t('image.rotateLeft')}}</v-tooltip>
            </VBtn>
            <VBtn fab small class="ma-1" @click="rotate(45)">
              <VIcon>mdi-rotate-right</VIcon>
              <v-tooltip md-direction="top">{{$t('image.rotateRight')}}</v-tooltip>
            </VBtn>

            <VBtn fab small class="ma-1" @click="relativeZoom(0.1)">
              <VIcon>mdi-magnify-plus-outline</VIcon>
              <v-tooltip md-direction="top">{{$t('image.zoomIn')}}</v-tooltip>
            </VBtn>
            <VBtn fab small class="ma-1" @click="relativeZoom(-0.1)">
              <VIcon>mdi-magnify-minus-outline</VIcon>
              <v-tooltip md-direction="top">{{$t('image.zoomOut')}}</v-tooltip>
            </VBtn>

            <VBtn fab small class="ma-1" @click="scaleX()">
              <VIcon>mdi-swap-horizontal</VIcon>
              <v-tooltip md-direction="top">{{$t('image.mirrorX')}}</v-tooltip>
            </VBtn>
            <VBtn fab small class="ma-1" @click="scaleY()">
              <VIcon>mdi-swap-vertical</VIcon>
              <v-tooltip md-direction="top">{{$t('image.mirrorY')}}</v-tooltip>
            </VBtn>
          </div>
          <div :class="$style.actions">
            <VBtn :class="['ma-1', $style.button]" @click="cancel()">
              {{$t('form.cancel')}}
            </VBtn>
            <VBtn :class="['ma-1', $style.button]" @click="done()">
              {{$t('form.conclude')}}
            </VBtn>
          </div>
        </div>
      </div>
    </Component>
  </div>
</template>


<style lang="scss" module>
// @import '@design';

.inputImage {
  display: none;
}

.modal {
  // padding-left: 20px;
  // padding-right: 20px;

  > div {
    @media screen and (max-height: 700px) {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      transform: none;
    }
  }
}
.box {
  background-color: white;
  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .cropper {
      position: relative;
      min-width: 200px;
      max-width: 700px;
      .actionsIcons {
        display: block;
        position: absolute;
        top:10px;
        right: 10px;
        div {
          display: inline;
          .actionsIconDone {
            color: green;
            font-size: 32px!important;
            cursor: pointer;
          }
          .actionsIconCancel {
            margin-left: 10px;
            font-size: 27px!important;
            cursor: pointer;
          }
        }
      }
    }

    .edit {
      margin-top: 10px;
    }
  }
}


.separator {
  margin-left: 18px;
}




.button {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
}


</style>
