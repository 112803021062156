<script>
  import {appConfig} from '@src/config';
  export default {
    props: {
      isfake: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        appConfig: appConfig,
        tabSelected: 'tabFlexibility'
      }
    },
    methods: {
      onClick(to, hash) {
        // this.$router.push({ to: to, hash: hash });
      }
    }
  }
</script>


<template>
  <div :class="[$style.box, isfake?$style.isfake:'']">
    <div :class="$style.container">
      <div :class="$style.head">
        <img :class="$style.logo" src="@assets/images/logo/footer-logo@3x.png" />
        <a v-if="appConfig.links.twitter" :href="appConfig.links.twitter.link"><img src="@assets/images/social/twitter-icon-white@3x.png" /></a>
        <a v-if="appConfig.links.instagram" :href="appConfig.links.instagram.link"><img src="@assets/images/social/instagram-icon-white@3x.png" /></a>
        <a v-if="appConfig.links.facebook" :href="appConfig.links.facebook.link"><img src="@assets/images/social/face-icon-white@3x.png" /></a>
        <div class="clear-float"></div>
      </div>
      <div :class="$style.body">
        <div><RouterLink to="/#home" @click.native="onClick('/#home', '#home')">{{$t('footer.home')}}</RouterLink></div>
        <div><RouterLink to="/#download" @click.native="onClick('/#download', '#download')">{{$t('footer.download')}}</RouterLink></div>
        <div><RouterLink to="/company/login" >{{$t('footer.accessCompany')}}</RouterLink></div>
        <div><RouterLink to="/ong/login" >{{$t('footer.accessOng')}}</RouterLink></div>
        <div><RouterLink to="/admin/login" >{{$t('footer.accessAdmin')}}</RouterLink></div>
         <!--<div><a href="/">{{$t('footer.faq')}}</a></div>-->
        <div class="clear-float"></div>
      </div>
      <div :class="$style.footer">
        <div :class="$style.copyright">
          {{$t('footer.copyright')}}
        </div>

        <a :href="appConfig.android.linkStore"><img src="@assets/images/store/black-play-store-badge@3x.png" /></a>
        <a :href="appConfig.ios.linkStore"><img src="@assets/images/store/black-app-store-badge@3x.png" /></a>
        <div class="clear-float"></div>
      </div>
    </div>
  </div>


</template>

<style lang="scss" module>
@import '@design';

.isfake {
  position: relative!important;
  bottom: unset!important;
  //background-color: rgba(13, 51, 73, 0.32)!important;
  visibility: hidden;
}

.box {
  width: 100%;
  background-color: $color-footer-bg;
  padding: 10px;
  margin: 0;
/*  position: absolute;
  bottom: 0;*/
  .container {
    padding: 0;
    text-align: center;
    list-style-type: none;
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    .head {
      padding-top: 28px;
      padding-bottom: 22px;
      border-bottom: solid 1px $color-footer-hr;
      img {
        float: left;
        height: 36px;
      }
      a {
        float: right;
        margin-left: 16px;
        height: 36px;
      }
      .logo {
        height: 86px;
      }

    }

    .body {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      div {
        padding-bottom: 5px;
        padding-top: 5px;
      }
      a, a:link, a:visited, a:hover, a:active {
        color: $color-footer-link;
        text-decoration: none;
      }

    }

    .footer {
      padding-bottom: 9px;
      padding-top: 19px;
      border-top: solid 1px $color-footer-hr;
      .copyright {
        padding-top: 10px;
        float: left;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-footer-copyright;
      }
      a {
        float: right;
        margin-left: 16px;
        img {
          height: 40px;
        }
      }
    }
  }
}




</style>
