<script>
  import { authComputed } from '@state/helpers'
  // eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'

  import firebase from 'firebase/app'
  const ExampleBag = require ('@common/examples/bag');

  export default {
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      isSuperAdmin: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        uploadProgress: 0,
        isLoading: false,
        dateBegin: new Date(),
        dateEnd: new Date(),
        bagId: null,
        result: null,

        form: [],
      }
    },
    validations: {

    },
    computed: {
      ...authComputed,
    },
    watch: {
    },
    mounted: async function() {
      if(this.itemId) {
        await this.loadForm();
      }
      this.isLoading = false;
    },
    beforeMount: async function() {

    },
    beforeDestroy: function() {

    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        // let companySchedule = {};
        // let obj = await firebase.firestore().collection('companies').doc(this.itemId).get();
        // if(obj && obj.data()) {
        //   let company = obj.data() ||{};
        //   companySchedule = company.schedule || {};
        // }

        // console.log('loaded companySchedule', companySchedule);
        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
       // let form = _.cloneDeep(this.form);

      },
      async createOrder () {
        let bag = ExampleBag.bag1;
        this.bagId = firebase.firestore().collection('companies').doc().id;

        bag['id'] =  this.bagId;
        bag['transformedInOrderDate'] =  new Date();
        bag['createdDate'] =  new Date();

        await firebase.firestore().collection('userBags').doc(this.bagId).set(bag);
      },
      async makeOrder () {
        this.result = await firebase.functions().httpsCallable('order-makeOrder')({ orderId: this.bagId, lang: 'pt-BR'});
      }

    },
  }
</script>


<template>
  <div :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">

          <div :class="$style.body">

            <BaseButton
              :class="['md-primary','md-raised', $style.button ]"
              :disabled="tryingToUpdate"
              @click="createOrder"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                {{$t('test.createOrder')}}
              </span>
            </BaseButton>
            <br><br>
            <BaseButton
              v-if="bagId"
              :class="['md-primary','md-raised', $style.button ]"
              :disabled="tryingToUpdate"
              @click="makeOrder"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                {{$t('test.makeOrder')}}
              </span>
            </BaseButton>
            <br><br>{{bagId}}<br><br>
            {{result}}


          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 600px;
      .content {
        max-width: 900px;
        .contentLoaded {
          .body {
            .date {
              max-width: 230px;
            }
          }
        }
      }
    }
  }
</style>





















