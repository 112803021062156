<script>
  let {languages, setLocale} = require('@i18n');
  export default {
    props: {
      showName: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        langs: languages
      }
    },
    computed: {
      localeSelected : {
        get() {
          return this.$i18n.locale
        },
        set(value) {
          this.$i18n.locale = value;
          if(languages[value]) {
            window.VueI18n.localeDatabase = languages[value].database;
          }
          document.documentElement.setAttribute('lang', value);
          setLocale(value)
        }
      }
    },
    methods: {
      changeLanguage() {
        this.$refs.languageSelect.$el.querySelector('.md-input').click();
      }
    }
  }

  /*
  <img :src="'@assets/flags/'+lang.flag+'.png'" />

  <img src="@assets/flags/us.png" />

<MdOption v-for="(lang, index) in langs" :key="index" :value="index">
        {{ lang.name }} {{'@assets/flags/'+lang.flag+'.png'}}
      </MdOption>

 <md-icon>attach_money</md-icon>

   */
</script>


<template>
  <div :class="[$style.localeChanger, 'select-auto-width', 'locale-localeChanger']">
    <MdField v-if="langs" :class="$style.localeField">
      <MdSelect ref="languageSelect" v-model="localeSelected" md-class="select-auto-width locale-localeChangerItem" >
        <MdOption v-for="(lang, index) in langs" :key="index" :value="index" :class="$style.flagSpan">
          {{ showName?lang.name:'' }}<img :class="$style.flag" :src="require('@assets/flags/'+lang.flag+'.png')"/>
        </MdOption>
      </MdSelect>
      <img :class="$style.flag" :src="require('@assets/flags/'+langs[$i18n.locale].flag+'.png')" @click.stop="changeLanguage()"/>

    </MdField>
  </div>
</template>

<style lang="scss">
  .locale-localeChanger .md-field.md-theme-default:after {
    background-color: transparent;
  }
  .locale-localeChanger .md-field.md-theme-default:before {
    background-color: transparent;
  }
  .locale-localeChanger {
    min-width: 55px !important;
  }
  .locale-localeChangerItem {
    margin-left: 30px;
  }

  .locale-localeChanger .md-icon {
    height: 28px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .locale-localeChanger .md-menu {
    height: 28px;
    overflow: hidden;
  }
</style>


<style lang="scss" module>
  @import '@design';

  .localeChanger {
    width: 36px;
    .localeField {
      padding-top: 24px;
    }
  }
  .flag {
    width: 30px;
    display: inline;
    max-height: 28px;
    cursor: pointer;
  }

  .flagSpan span{
    justify-content: space-between;
    flex-direction: row;
  }

</style>
