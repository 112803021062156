<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainLayout from '@components/layout/client/main-layout'
  import ChildComponent from '@components/client/test_bag_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainLayout, ChildComponent},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    watch: {
    },
    beforeMount: function() {

    },
    mounted(){
    },
    methods: {
    },
  }
</script>

<template>
  <MainLayout>
    <ChildComponent v-if="currentUser && currentUser.isTest"
      v-model="itemId"
    >
    </ChildComponent>
  </MainLayout>
</template>

<style lang="scss" module>
  @import '@design';

</style>
