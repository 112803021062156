<script>
  import firebase from 'firebase/app'
  // import databaseUtils from '@utils/database_utils'
  import Paginate from 'vuejs-paginate'

  // const stringUtils = require('@common/string_utils');
  const timeUtils = require('@common/time_utils');

  /*

  let input = {
    search: String,
    size: Number,
    from: Number,
    regionId: String,
    lastItem: Object
  };

  let output = {
    hits: Array({
      id: String,
      name: String,
      imgUrl: String,
    }),
    total: Number,
    offset: Number,
  };

  async getUsers(input) {
        let result = await firebase.functions().httpsCallable('search-listUsersCall')({...input, lang: this.$i18n.locale});
        let items = [];
        if(result.data && result.data.hits) {
          for(let item of result.data.hits) {
            items.push({
              id: item.id,
              name: item.name + (item.lastName?' '+item.lastName:''),
              imgUrl: item.profileImageUrl || null,
            });
          }
        }
        else {
          return {
            hits: [],
            total: 0,
            offset: 0,
          };
        }
        return {
          hits: items,
          total: result.data.total,
          offset: result.data.offset,
        };
      },



      <SearchPick v-if="form.sendToType==='selected'"
                  v-model="form.users"
                  :get-items="getUsers"
                  :label="$t('form.selectUsers')"
                  :show-img="false"
                  :filter-region="true"
                  :limit="0"
      >
        <template #item="slotProp" >
          <div>{{slotProp.item.name+(slotProp.item.lastName?'_'+slotProp.item.lastName:'')}}</div>
        </template>
        <template #selected="slotProp" >
          <div>{{slotProp.item.name+(slotProp.item.lastName?'+'+slotProp.item.lastName:'')}}</div>
        </template>
      </SearchPick>


  */


  export default {
    components: {Paginate},
    model: {
      prop: 'items',
      event: 'changeModel'
    },
    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
      limit: {
        type: Number,
        default: 0,
      },
      getItems: {
        type: [Function],
        default: null
      },
      label: {
        type: String,
        default: '',
      },
      showImg: {
        type: Boolean,
        default: false,
      },
      filterRegion: {
        type: Boolean,
        default: false,
      },
      mdThemeInput: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        list: [],
        pickList: [],
        search: '',
        lastSearchTime: null,
        regionsList: [],
        regionId: 'all',
        showEditor: false,
        typeContent: 'modal',
        searching: false,
        numberPerPage: 15,
        totalPages: 0,
        page: 0,
        lastItem: null,
        showAddUser: false,
        refInput: this.$newId(),
      }
    },
    computed: {
      listView: {
        get() {
          return this.list.filter((a)=>{
            return !this.items.find(element => element.id===a.id);
          });
        },
        set() {

        }
      },
      showModal: {
        get() {
          return (this.showEditor && this.typeContent==='modal');
        },
        set(value) {
          console.log('showModal set', value);
          this.showEditor = value;
        }
      }
    },
    watch: {
      async showModal(value, oldValue) {
        console.log('showModal', value);
        if(value) {
          this.focusInput();
        }
      }
    },

    mounted: async function () {
      let regionsObjP = firebase.firestore().collection('regions').get();
      let regionsList = [];
      (await regionsObjP).forEach((categoryObj) => {
        regionsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
      });
      this.regionsList = regionsList;
    },
    beforeDestroy(){
    },

    methods: {
      async makeSearch (isNow = false) {
        let search = this.search;
        if(!search) {
          this.lastSearchTime = Date.now();
          this.list = [];
          return;
        }

        let time = Date.now();
        this.lastSearchTime = time;
        if(!isNow) {
          await timeUtils.delay(650);
        }

        if(this.lastSearchTime !== time) { // ignore that search
          return null;
        }
        this.searching = true;
        let result = null;
        if(this.getItems) {
          result = await this.getItems({search: search, size: this.numberPerPage, from: 0, regionId: this.regionId!=='all'?this.regionId:null, lastItem: null});
        }
        else {
          this.searching = false;
          this.$showError();
          return;
        }
        if(this.lastSearchTime !== time) { // ignore that search
          return null;
        }
        this.searching = false;
        this.processSearch(result);
      },
      editClick(item) {
        let find = this.items.find(element => element.id===item.id);  // limit 5    tem 7
        if(!find && this.limit>0) {
          if(this.items.length>=this.limit) {
            this.items.splice(0, (this.items.length+1)-this.limit);
          }
        }
        if(!find) {
          this.items.push(item);
        }
        this.$emit('changeModel', this.items);
        if(this.limit>0 && this.items.length>=this.limit) {
          this.showEditor = false;
          this.$emit('change', this.items);
        }
      },
      removeClick(id) {
        let index = this.items.findIndex(element => element.id===id);
        if(index>=0) {
          this.items.splice(index, 1);
          this.$emit('changeModel', this.items);
        }
      },
      clickShowModal() {
        this.showEditor = true;
      },
      closeModal() {
        this.showEditor = false;
        this.$emit('change', this.items);
      },
      async clickPage(pageSelect) {
        this.searching = true;
        let result = null;
        if(this.getItems) {
          result = await this.getItems({search: this.search, size: this.numberPerPage, from: (pageSelect-1)*this.numberPerPage, regionId: this.regionId!=='all'?this.regionId:null, lastItem: this.lastItem});
        }
        else {
          this.searching = false;
          this.$showError();
          return;
        }
        this.searching = false;
        this.processSearch(result);
      },
      async processSearch(result) {
        this.lastItem = null;
        this.page = 0;
        this.lastItem = null;
        this.totalPages = 0;
        if(result && result.total && result.hits) {
          let listTmp = [];
          for(let row of result.hits) {
            listTmp.push({
              id: row.id,
              name: row.name + (row.lastName?' '+row.lastName:''),
              imgUrl: row.imgUrl?row.imgUrl:(row.profileImageUrl?row.profileImageUrl:''),
            });
            this.lastItem = row;
          }
          this.list = listTmp;
          this.totalPages = Math.ceil((result.total || 0)/this.numberPerPage);
          this.page = ((result.offset || 0)/this.numberPerPage)+1;
          console.log('this.listView ', this.list );
        }
        else {
          this.list = [];
          this.$showError({title: this.$t('form.search.emptyResult')});
        }
      },
      async focusInput() {
        await timeUtils.delay(50);
        if(this.$refs[this.refInput]) {
          this.$refs[this.refInput].$el.focus()
        }
        else {
          await timeUtils.delay(200);
          if(this.$refs[this.refInput]) {
            this.$refs[this.refInput].$el.focus()
          }
        }
      }
    }

  }
</script>

<template>

  <div>
    <div :class="$style.bodyExternal">
      <div :class="$style.list">
        <MdField :class="$style.search" :md-theme="mdThemeInput" @click.native="clickShowModal()">
          <label>{{label}}</label>
          <MdInput type="hidden" autocomplete="no" :value="items.length>0?'itens':''"></MdInput>
          <div :class="$style.left">
            <div v-for="(item) in items" :key="item.id" :class="$style.item">
              <slot name="selected" :item="item">
                {{item.name}}
              </slot>
            </div>
          </div>
          <div :class="$style.right">
            <MdIcon :class="$style.searchButton" >search</MdIcon>
          </div>
        </MdField>
      </div>
    </div>
    <div :is="typeContent==='modal'?'MdDialog':'div'" v-if="showEditor" :md-active.sync="showModal" :class="$style.modal">
      <div :class="$style.box">
        <div :class="$style.selected">
          <div :class="$style.left">
            <div v-for="(item) in items" :key="item.id" :class="$style.selectedItem" @click="removeClick(item.id)">
              <slot name="selected" :item="item">
                {{item.name}}
              </slot>
              <MdIcon >close</MdIcon>
            </div>
          </div>
          <div :class="$style.right" >
            <MdIcon :class="$style.checkButton" @click.native="closeModal()" >check</MdIcon>
          </div>
        </div>
        <div :class="$style.head">
          <div :class="$style.firstLine">
            <input style="display:none" type="text" name="fakeusernameremembered"/>
            <input style="display:none" type="password" name="fakepasswordremembered"/>
            <MdField :class="$style.search">
              <label>{{label}}</label>
              <MdInput :ref="refInput" v-model="search" type="text" autocomplete="no" @input="makeSearch()"></MdInput>
              <MdIcon v-if="!searching" :class="$style.searchButton" @click="makeSearch(true)">search</MdIcon>
              <BaseIcon v-if="searching" name="sync" spin />
            </MdField>
          </div>
          <div>
            <MdField v-if="filterRegion" :class="$style.region">
              <label >{{$t('form.region')}}</label>
              <MdSelect v-model="regionId" name="region">
                <MdOption value="all">{{$t('form.regionAll')}}</MdOption>
                <MdOption v-for="(value) in regionsList" :key="value.id" :value="value.id">{{value.nameView}}</MdOption>
              </MdSelect>
            </MdField>
          </div>
        </div>
        <div :class="$style.body">
          <PreUser v-if="showAddUser" :conclude="concludeAddUser" :cancel="cancelAddUser">

          </PreUser>
          <div :class="$style.listView">
            <div v-for="(item) in listView" :key="item.id" :class="[$style.item]" @click="editClick(item)">
              <slot v-if="showImg" name="img" :item="item">
                <div v-if="item.imgUrl" :class="$style.itemImg">
                  <img :class="$style.imgUrl" :src="item.imgUrl" />
                </div>
              </slot>
              <div :class="$style.itemDesc">
                <slot name="item" :item="item">
                  <div :class="$style.itemHead">{{item.name}}</div>
                </slot>
              </div>
            </div>
          </div>
          <div v-if="totalPages>0" :class="$style.paginateContent">
            <Paginate
              v-model="page"
              :page-count="totalPages"
              :click-handler="clickPage"
              :prev-text="$t('prev')"
              :next-text="$t('next')"
              :container-class="'pagination'">
            </Paginate>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>


<style lang="scss" module>
  @import '@design';
  .bodyExternal {

    .list {
      .search {
        cursor: pointer;
        justify-content: space-between;
        .left {
          display: flex;
          flex-wrap: wrap;
          .item {
            align-items: center;
            background-color: #fff;
            border: 1px solid #dadce0;
            border-radius: 10px;
            box-sizing: border-box;
            display: inline-flex;
            height: 20px;
            line-height: 20px;
            margin: 2px;
            padding-left: 8px;
            padding-right: 4px;
          }
        }
        .right {
          display: flex;
          .searchButton {
            display: flex;
            width: 24px!important;
            min-width: 24px!important;
            height: 24px!important;
            font-size: 24px!important;
          }
        }
      }
    }

  }

  .modal {

    .box {
      max-width: 580px;
      min-width: 480px;

      @media screen and (max-width: 480px) {
        min-width: 100%!important;
      }

      .selected {
        padding: 10px;
        justify-content: space-between;
        display: flex;
        .left {
          .selectedItem {
            flex-wrap: wrap;
            cursor: pointer;

            align-items: center;
            background-color: #fff;
            border: 1px solid #dadce0;
            border-radius: 10px;
            box-sizing: border-box;
            display: inline-flex;
            height: 20px;
            line-height: 20px;
            margin: 2px;
            padding-left: 8px;
            padding-right: 4px;

            i {
              width: 17px;
              min-width: 17px;
              height: 17px;
              font-size: 17px !important;
            }
          }
        }
        .right {
          display: flex;
          i {
            cursor: pointer;
            width: 32px;
            min-width: 32px;
            height: 32px;
            font-size: 32px !important;
            color: green;
          }
        }
      }

      .head {
        padding: 6px 15px 5px;
        background-color: #EFE5E8;

        .search {
          margin-bottom: 8px;
          .searchButton {
            cursor: pointer;
          }
        }
        .region {
          margin-bottom: 8px;
        }

      }
      .firstLine {
        display: flex;
        align-items: center;
        .add {
          padding: 15px;
          cursor: pointer;
        }
      }
      .body {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        overflow: scroll;

        .paginateContent {
          width: 100%;
          display: flex;
          justify-content: center;
        }


        .listView {
          background-color: #7691c214;
          display: flex;
          flex-direction: column;
          // overflow: scroll;
          // scroll-snap-type: y mandatory;

          height: 100%;
          //  max-height: 100%;
          max-height: 1000px;

          .imgUrl {
            max-width: 110px;
            max-height: 70px;
          }

          .itemSelected {
            background-color: #bedbe4;
          }

          .item {
            cursor: pointer;
            user-select: none;
            position: relative;
            padding-left: 10px;
            padding-top: 7px;
            padding-bottom: 8px;
            display: flex;
            flex: 1 0 auto;
            /*box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);*/
            border-bottom: solid 1px rgba(0, 0, 0, 0.12);
            .formItemImg {
              display: flex;
              align-items: center;
            }

            .itemDesc {
              flex: 10;
              display: flex;
              justify-content: center;
              flex-direction: column;

              .itemHead {
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.25;
                letter-spacing: normal;
                color: rgba(0, 0, 0, 0.87);
                padding-left: 8px;
                padding-right: 8px;
              }
              .itemSub {
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: rgba(0, 0, 0, 0.54);
                padding-left: 8px;
                padding-right: 8px;
              }
            }
            .itemAction {
              flex: 1;
              align-self: center;
              .md-radio, .md-switch {
                margin: 7px 16px 7px 0;
              }
              .itemControl {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
              }
            }

            .itemAction .md-checked + .itemControl  {
              background-color: rgba(0, 0, 0, 0.05);
            }

            .itemHelp {
              color: rgba(0, 0, 0, 0.12);
              align-self: center;
            }



          }

        }
      }

    }
  }



</style>


<style lang="scss">

  // vuejs-paginate
  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .pager {
    padding-left: 0;
    margin: 20px 0;
    text-align: center;
    list-style: none;
  }
  .pager li {
    display: inline;
  }
  .pager li > a,
  .pager li > span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
  }
  .pager li > a:hover,
  .pager li > a:focus {
    text-decoration: none;
    background-color: #eee;
  }
  .pager .next > a,
  .pager .next > span {
    float: right;
  }
  .pager .previous > a,
  .pager .previous > span {
    float: left;
  }
  .pager .disabled > a,
  .pager .disabled > a:hover,
  .pager .disabled > a:focus,
  .pager .disabled > span {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
  }
</style>
