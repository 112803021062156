"use strict";
const _ = require('lodash');
const moment = require('moment');

let toExport = {};

toExport.company2algolia = async (id, obj, is4product=false) => {
  const ret = {
    objectID: id,
    id: id,
    address: obj.address || null,
    uniqueName: obj.uniqueName || null,
    name: obj.name || null,
    description: obj.description || null,
    logoImgUrl: obj.logoImgUrl || null,
    phone: obj.phone || null,
    corporateName: obj.corporateName || null,
    governmentDocumentId: obj.governmentDocumentId || null,
    acceptCashPayment: obj.acceptCashPayment || false,
    acceptPaymentOnApp: obj.acceptPaymentOnApp || false,
    acceptDeliveryEnable: obj.acceptDeliveryEnable || false,
    acceptOnSiteEnable: obj.acceptOnSiteEnable || false,
    acceptTakeoutEnable: obj.acceptTakeoutEnable || false,
    isNew: obj.isNew || false,
    active: obj.active || false,
    rating: obj.rating || 0,
 //   schedule: obj.schedule || null,
    primaryColor: obj.primaryColor || null,
    secondaryColor: obj.secondaryColor || null,
    showOnlyCategoryOnMenu: obj.showOnlyCategoryOnMenu || false,
    category: obj.category || null,
    categoryId: obj.categoryId || null,
    tags: obj.tags || [],
    tagsId: obj.tagsId || [],
    timeForDeliveryMax: obj.timeForDeliveryMax || 0,
    timeForDeliveryMin: obj.timeForDeliveryMin || 0,
    timeForPickupAndOnSiteMax: obj.timeForPickupAndOnSiteMax || 0,
    timeForPickupAndOnSiteMin: obj.timeForPickupAndOnSiteMin || 0,
    deliveryAreas: obj.deliveryAreas || [],
    hubId: obj.hubId || null,
 //   validPaymentMethods: obj.validPaymentMethods || [],
 //   validPaymentMethodsIds: obj.validPaymentMethodsIds || [],
  };
  if(!is4product) {
    ret.isOpen = obj.isOpen || false;
    ret.acceptDelivery= obj.acceptDelivery || false;
    ret.acceptOnSite= obj.acceptOnSite || false;
    ret.acceptTakeout= obj.acceptTakeout || false;
  }
  if(obj.address && obj.address.latitude && obj.address.longitude) {
    ret._geoloc = {
      lat: ret.address.latitude,
      lng: ret.address.longitude
    };
  }
  return ret;
};

toExport.needUpdateCompany = async function (docId, before, after, is4product=false) {
  const p1 = toExport.company2algolia(docId, before, is4product);
  const p2 = toExport.company2algolia(docId, after, is4product);
  return !_.isEqual(await p1, await p2);
};

toExport.user2algolia = async (id, obj) => {
  const out = {
    objectID: id,
    id: id,
    name: obj.name,
    lastName: obj.lastName,
    email: obj.email,

  };
  if(obj.address && obj.address.latitude && obj.address.longitude) {
    out._geoloc = {
      lat: obj.address.latitude,
      lng: obj.address.longitude
    };
  }
  return out;
};

/**
 *
 * @param docId
 * @param before
 * @param after
 */
toExport.needUpdateUser = async function (docId, before, after) {
  const p1 = toExport.user2algolia(docId, before);
  const p2 = toExport.user2algolia(docId, after);
  return !_.isEqual(await p1, await p2);
};

/**
 *
 * @param id
 * @param obj
 */
toExport.product2algoliaNoCompany = async (id, obj) => {
  const out = {
    objectID: id,
    id: id,
    active: obj.active || false,
    companyId: obj.companyId || '',
    description: obj.description || '',
    hasVariation: obj.hasVariation || false,
    imgUrl: obj.imgUrl,
  //  search: obj.search,
    ingredients: obj.ingredients || [],
    ingredientsId: obj.ingredientsId || [],
    isLiquid: obj.isLiquid || false,
    name: obj.name || '',
    nutritionals: obj.nutritionals || [],
    nutritionalsId: obj.nutritionalsId || [],
    order: obj.order || 0,
    portion: obj.portion || 0,
    subCategory: obj.subCategory || null,
    subCategoryId: obj.subCategoryId || '',
    tags: obj.tags || [],
    tagsId: obj.tagsId || [],
    value: obj.value || 0,
    variationData: obj.variationData || [],
    valueOriginal: obj.valueOriginal || 0,
    variationDataOriginal: obj.variationDataOriginal || [],
    visible: obj.visible || false,
    hasPromotion: obj.hasPromotion || false,
  };

  return out;
};


toExport.needUpdateProduct = async function (docId, before, after) {
  const p1 = toExport.product2algoliaNoCompany(docId, before);
  const p2 = toExport.product2algoliaNoCompany(docId, after);
  return !_.isEqual(await p1, await p2);
};




toExport.orderResume = async (id, obj) => {
  const ret = {
    id: id,
    active: obj.active || false,
    bagStatus: obj.bagStatus || null,
    companyId: obj.companyId || null,
    company:{
      name: (obj.company || {}).name || '',
      logoImgUrl: (obj.company || {}).logoImgUrl || null,
      address: {
        regionId: ((obj.company || {}).address || {}).regionId || '',
        hubId: ((obj.company || {}).address || {}).hubId || '',
        hasHub: ((obj.company || {}).address || {}).hasHub || '',
      },
    },
    createdDate: obj.createdDate || null,
    createdEnv: obj.createdEnv || null,
    deliveryTxValue: obj.deliveryTxValue || null,
    deliveryType: obj.deliveryType || null,
    groupCode: obj.groupCode || null,
    groupStatus: obj.groupStatus || null,
    isPaymentBySystem: obj.isPaymentBySystem || false,
    lstUserIds: obj.lstUserIds || null,
    lstUsers: {},
    orderCode: obj.orderCode || null,
    orderControlStatus: obj.orderControlStatus || null,
    orderNumber: obj.orderNumber || null,
    status: obj.status || null,
    test: obj.test || false,
    totalValue: obj.totalValue || 0,
    transformedInOrderDate: obj.transformedInOrderDate || null,
    updatedAt: obj.updatedAt || null,
    cancelReason: obj.cancelReason || '',
    paymentMethod: {},
    lstUserIdsArray: [],
  };
  for (const userId in obj.lstUsers) {
    ret.lstUsers[userId] = {
      name: (obj.lstUsers[userId].name ||'') + ' ' + (obj.lstUsers[userId].lastName ||''),
      ongId: obj.lstUsers[userId].ongId || null,
      createdEnv: obj.lstUsers[userId].createdEnv || null,
      gender: obj.lstUsers[userId].gender || null,
      orderCount: obj.lstUsers[userId].orderCount || 0,
      age: (obj.transformedInOrderDate && obj.lstUsers[userId].birthDate)?(((obj.transformedInOrderDate||{})._seconds||0)-((obj.lstUsers[userId].birthDate||{})._seconds||0)): 0,
      since: (obj.transformedInOrderDate && obj.lstUsers[userId].createdAt)?(((obj.transformedInOrderDate||{})._seconds||0)-((obj.lstUsers[userId].createdAt||{})._seconds||0)): 0,
    };
    ret.lstUserIdsArray.push(userId);

  }
  for(const pIndex in obj.paymentMethod) {
    const p = obj.paymentMethod[pIndex];
    ret.paymentMethod[pIndex] = {
      userId: p.userId || null,
      status: p.status || null,
      isSubAccount: p.isSubAccount || null,
      invoiceId: p.invoiceId || null,
      orderId: p.orderId || null,
      groupUserTotalValue: p.groupUserTotalValue || null,
      type: p.type || null,
      deliveryPaymentMethod: {
        id: (p.deliveryPaymentMethod ||{}).id || '',
        isCash: (p.deliveryPaymentMethod ||{}).isCash || false,
      }
    };
  }
  return ret;
};
toExport.orderResumeExport = async (id, obj) => {
  const ret = {
    id: id,
    active: obj.active || false,
    bagStatus: obj.bagStatus || null,
    companyId: obj.companyId || null,
    companyName:(obj.company || {}).name,
    companyLogoImgUrl: (obj.company || {}).logoImgUrl,
    createdEnv: obj.createdEnv || null,
    deliveryTxValue: obj.deliveryTxValue || null,
    deliveryType: obj.deliveryType || null,
    groupCode: obj.groupCode || null,
    groupStatus: obj.groupStatus || null,
    isPaymentBySystem: obj.isPaymentBySystem || false,
    isRatingCompleted: obj.isRatingCompleted || false,
    isRatingIgnored: obj.isRatingIgnored || null,
    lstUserIds: obj.lstUserIds || null,
    lstUsers: [],
    lstUsersTxt: '',
    orderCode: obj.orderCode || null,
    orderControlStatus: obj.orderControlStatus || null,
    orderNumber: obj.orderNumber || null,
    status: obj.status || null,
    test: obj.test || false,
    totalValue: obj.totalValue || 0,
    transformedInOrderDate: (obj.transformedInOrderDate || {})._seconds || '',
    transformedInOrderDateTxt: obj.transformedInOrderDate?moment(obj.transformedInOrderDate.toDate()).format('DD/MM/YYYY HH:mm:ss'): null,
    paymentMethod: {},
  };
  for(const userId in obj.lstUsers) {
    ret.lstUsers.push(obj.lstUsers[userId].name+' '+obj.lstUsers[userId].lastName);
    ret.lstUsersTxt += obj.lstUsers[userId].name+' '+obj.lstUsers[userId].lastName + ', ';
  }

  for(const pIndex in obj.paymentMethod) {
    const p = obj.paymentMethod[pIndex];
    ret.paymentMethod[pIndex] = {
      userId: p.userId || null,
      status: p.status || null,
      groupUserTotalValue: p.groupUserTotalValue || null,
      type: p.type || null,
      isSubAccount: p.isSubAccount || false,
    };
  }
  return ret;
};


toExport.subCategoryResume = async (id, obj) => {
  const _obj = obj || {};
  const out = {
    active: _obj.active || false,
    categoryId: _obj.categoryId || null,
    companyId: _obj.companyId || null,
    description: _obj.description || '',
    imgUrl: _obj.imgUrl || null,
    order: _obj.order || null,
    visible: _obj.visible || null,
  };
  return out;
};

toExport.promotionResume = async (id, obj) => {
  const _obj = obj || {};
  const out = {
    active: _obj.active || false,
    applyIn: _obj.applyIn || '',
    companyId: _obj.companyId || '',
    description: _obj.description || '',
    discountPercent: _obj.discountPercent || 0,
    discountValue: _obj.discountValue || 0,
    discountType: _obj.discountType || '',
    dtBegin: _obj.dtBegin || null,
    dtEnd: _obj.dtEnd || null,
    imgUrl: _obj.imgUrl || null,
    limitQuantity: _obj.limitQuantity || 0,
    line1: _obj.line1 || '',
    line2: _obj.line2 || '',
    minValue: _obj.minValue || 0,
    order: _obj.order || 0,
    productsIds: _obj.productsIds || [],
    subCategoriesIds: _obj.subCategoriesIds || [],

  };
  return out;
};


toExport.needUpdateSubCategory = async function (docId, before, after) {
  const p1 = toExport.subCategoryResume(docId, before);
  const p2 = toExport.subCategoryResume(docId, after);
  return !_.isEqual(await p1, await p2);
};






















module.exports = toExport;
