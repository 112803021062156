<script>
  // import NavBar from '@components/nav-bar'
  import MainLayout from '@components/layout/admin/main-layout'
  import { authComputed } from '@state/helpers'

  export default {
    components: {MainLayout},
    props: {
    },
    data() {
      return {
        isShowMenuMobile: false
      }
    },
    computed: {
      ...authComputed
    },
    methods: {
      changeMenu() {
        this.isShowMenuMobile = !this.isShowMenuMobile
      }
    }
  }
</script>

<template>
  <MainLayout>
    <div :class="$style.box">
      <slot name="left">
      </slot>
      <slot name="right"></slot>
    </div>
  </MainLayout>
</template>




<style lang="scss" module>
  @import '@design';

  .box {
    display: flex;
    overflow-y: scroll;
    /*    .left {
          flex: 1 1 0px;
          position: relative;
          max-width: 100%;
        }

        .right {
          flex: 1 1 0px;
          position: relative;
          max-width: 100%;
        } */

 /* > div(1) {
    flex: 1 1 0px;
    position: relative;
    //   max-width: 100%;
  }

    > :nth-child(1) {
      flex: 1 1 0px;
      position: relative;
      //   max-width: 100%;
    }
    > :nth-child(0) {
      flex: 1 1 0px;
      position: relative;
      //   max-width: 100%;
    }
    */
    > div {
      flex: 1 1 0px;
      position: relative;
    }
  }



</style>
