<script>
  import Layout from '@components/layout/company/main-visitant'
  import { authMethods, authComputed } from '@state/helpers'
  import {appConfig} from '@src/config';

  export default {
    page: {
      title: 'Test',
      meta: [
        {name: 'description', content: `Log in to ${appConfig.title}`}
      ],
    },
    components: { Layout },
    props: {
      on : {
        type: Function,
        default: ()=>{},
      }
    },
    data() {
      return {
        email: '',
        password: '',
        updateError: null,
        tryingToLogIn: false,
        companies: false,
        fakeMail: false,
        companiesNotFound: false,
        testeValor: 3.141547,


        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [
          { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
          { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
          { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
          { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        value: '',
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Alexandre', 'Birthday', 'Conference', 'Felippi'],

      }
    },
    computed: {
      ...authComputed,
    },
    watch: {
      logged() {
        if (this.$store.getters['auth/loggedIn'] && !this.tryingToLogIn) {
          console.log('Redirecionar usuário logado')
          this.$router.push({ name: 'home' })
        }
      }
    },
    methods: {
      ...authMethods,
      // Try to log the user in with the email
      // and password they provided.

      tryToLogIn() {

        // Reset the updateError if it existed.
        this.updateError = null

        if(!this.email || !this.fakeMail || !this.password) {
          this.updateError = true
          return
        }

        this.tryingToLogIn = true
        return this.logIn({
          email: this.email,
          fakeMail: this.fakeMail,
          password: this.password,
        })
          .then((token) => {
            console.log('login completo');
            // Redirect to the originally requested page, or to the home page
            this.$router.push(this.$route.query.redirectFrom || { name: 'home' })
          })
          .catch((error) => {
            console.error('Erro ao efetuar login', error)
            this.tryingToLogIn = false
            this.updateError = error
          })
      },
      tryListCompanies() {
        this.tryingToLogIn = true
        // Reset the updateError if it existed.
        this.updateError = null
        return this.listCompanies({
          email: this.email
        })
          .then((listCompanies) => {
            this.tryingToLogIn = false
            if(listCompanies) {
              this.companies = listCompanies
              let first = _.find(listCompanies) || {}
              this.fakeMail = first.fakeMail || false
            }
            else {
              this.companiesNotFound = true
            }

          })
          .catch((error) => {
            console.error('Erro ao buscar lista de restaurantes', error)
            this.tryingToLogIn = false
            this.updateError = error
          })
      },
      resetCompanies() {
        this.companies = false
        this.companiesNotFound = false
      },
      submitForm() {
        if(this.companies) {
          this.tryToLogIn()
        }
        else {
          this.tryListCompanies()
        }
      },


      getEvents ({ start, end }) {
        const events = []

        const min = new Date(`${start.date}T00:00:00`)
        const max = new Date(`${end.date}T23:59:59`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

        for (let i = 0; i < eventCount; i++) {
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
          })
        }

        this.events = events
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      on_fcm (...args) {
        console.log('method on', args);
      }


    },
  }
</script>

<template>
  <Layout>



    <div :class="$style.container">
      <div :class="$style.content" >


        <div>
          <v-sheet
            tile
            height="54"
            color="grey lighten-3"
            class="d-flex"
          >
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-select
              v-model="type"
              :items="types"
              dense
              outlined
              hide-details
              class="ma-2"
              label="type"
            ></v-select>
            <v-select
              v-model="mode"
              :items="modes"
              dense
              outlined
              hide-details
              label="event-overlap-mode"
              class="ma-2"
            ></v-select>
            <v-select
              v-model="weekday"
              :items="weekdays"
              dense
              outlined
              hide-details
              label="weekdays"
              class="ma-2"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.next()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              @change="getEvents"
            ></v-calendar>
          </v-sheet>
        </div>



        <div class="text-center d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >Button</v-btn>
            </template>
            <span>Tooltip</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">mdi-home</v-icon>
            </template>
            <span>Tooltip</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">This text has a tooltip</span>
            </template>
            <span>Tooltip</span>
          </v-tooltip>
        </div>
        <v-icon color="primary" dark v-on="on">mdi-home</v-icon>


        <div :class="$style.cadastreSeuRestaurante">{{$t('login.access')}}</div>

        {{$n(testeValor, 'currency') }} {{ $t("message.hello") }} {{$n(testeValor) }}


        <br>
        <p>{{ $d(new Date(), 'short') }}</p>
        <p>{{ $d(new Date(), 'long') }}</p>
        <p>{{ $d(new Date(), 'numeric') }}</p>

        HH{{$n(40, 'currencyDisplay')}}FF


        <div :class="$style.body">
          <form :class="$style.form" @submit.prevent="submitForm">
            <BaseInputText
              v-model="email"
              label="Email"
              @change="resetCompanies"
              @input="resetCompanies"
            />

            <BaseButton v-if="!companies"
                        :class="['md-primary','md-raised','md-lg' ]"
                        :disabled="tryingToLogIn"
                        @click="tryListCompanies"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('all.next')}}
              </span>
            </BaseButton>

            <MdField v-if="companies">
              <label >{{$t('all.restaurants')}}</label>
              <MdSelect v-model="fakeMail" name="fakeMail" >
                <MdOption v-for="(item) in companies" :key="item.fakeMail" :value="item.fakeMail">{{item.company.name}}</MdOption>
              </MdSelect>
            </MdField>

            <BaseInputText
              v-show="companies"
              v-model="password"
              type="password"
              label="Senha"
            />


            <div :class="$style.fcmContent">
              <div :class="$style.fcmItem">Item 1 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 2 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 3 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 4 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 5 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 6 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 7 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 8 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 9 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 10 sdfsfsdf</div>
              <div :class="$style.fcmItem">Item 11 sdfsfsdf</div>

            </div>



            <p v-if="updateError" :class="$style.error">
              {{$t('login.error.generic')}}
            </p>
            <p v-if="companiesNotFound" :class="$style.error">
              {{$t('login.errorCompany')}}
            </p>
            <BaseButton
              v-if="companies"
              :class="['md-primary','md-raised','md-lg']"
              :disabled="tryingToLogIn"
              type="submit"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.login')}}
              </span>
            </BaseButton>
          </form>
        </div>

      </div>

    </div>

  </Layout>
</template>


<style lang="scss" module>
  @import '@design';

  .fcmContent {
    height: 200px;
    overflow: scroll;
  }

  .fcmItem {
    height: 80px;
    border: #7691c2 1px solid;
  }


  .error {
    color: $color-input-error;
  }

  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    padding-top: 80px;
    padding-bottom: 30px;
    .content {
      width: 90%;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      margin: 0 auto;
      max-width: 1550px;
      .cadastreSeuRestaurante {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .jaPossuiConta {
        font-family: $font-secondary-text;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #9b9b9b;

      }
      .criaUmaNovaConta {
        font-family: $font-secondary-text;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-primary;
      }
      .body {
        font-family: $font-input;
        .fullwidth {
          width: 100%;
        }
        BaseButton {
          font-family: $font-primary-text;
        }
      }
    }
  }


</style>
