import moment from 'moment'
import firebase from 'firebase/app'
let {translateDb, i18nConfig, languages} = require('@i18n');

export default {

  install (Vue, options) {
    /*
    Vue.mixin({
      beforeDestroy: function () {

        console.log('unsubscribe function', Vue.prototype.$fcmUnsubscriber);
        // for(let us of Vue.prototype.$fcmUnsubscriber) {
        //   if(typeof us === 'function') {
        //     us();
        //   }
        // }
      //  Vue.prototype.$fcmUnsubscriber = [];
      }
    });
    Vue.prototype.$fcmUnsubscriber = [];
    */


    Vue.prototype.$eventHub = new Vue(); // Global event bus

    Vue.prototype.$trdb = function (obj) {
      if(this.$i18n && this.$i18n.locale) {
        return translateDb(this.$i18n.locale, obj);
      }
      return translateDb('pt-BR', obj);
    };

    Vue.prototype.$trdbLocale = function () {
      if(this.$i18n && this.$i18n.locale) {
        return (languages[this.$i18n.locale] || {}).database;
      }
      return (languages[i18nConfig.fallbackLocale] || {}).database;
    };

    Vue.prototype.$showMsg = function ({title='', text=null, timer=1500}={}) {
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showWarning = function ({title='', text=null, timer=2500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showSuccess = function ({title='', text=null, timer=1500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showError = function ({title='', text=null, timer=3500}={}) {
      if(!title && !text) {
        title = this.$t('form.error.update');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showConfirm = async function ({title='', text=null, timer=3500}={}) {
      if(!title && !text) {
        title = this.$t('form.confirmQuestion');
      }
      let result = await this.$swal({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: this.$t('form.confirmQuestionYes'),
        cancelButtonText: this.$t('form.confirmQuestionCancel')
      });
      if(result.value) {
        return true;
      }
      return false
    };

    Vue.prototype.$newId = function () {
      return firebase.firestore().collection('id').doc().id;
    };



    Vue.prototype.moment = moment;

  }
}
