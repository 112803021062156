<script>
  import { authMethods, authComputed } from '@state/helpers'
  import databaseUtils from '@utils/database_utils'
 // import FcmEditor from "@/src/components/image/fcm-editor";
  import firebase from 'firebase/app'
  import Multiselect from 'vue-multiselect'
  import Draggable from 'vuedraggable'
  import {productService} from '@common/services';
  import SearchPick from '@components/diversos/searchPick';
  let {languages} = require('@i18n');

  export default {
    components: {Multiselect, Draggable, SearchPick},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        updateError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        tryingToUpload: false,
        imageBlob: null,
        isLoading: true,
        defaultForm: {
          numberOfChoices: 0,
          type: 'additionals',
          order: 0,
          active: true
        },
        form: {
          numberOfChoices: 0,
          type: 'additionals',
          order: 0,
          active: true
        },
        defaultItem: {
          title: '',
          active: true,
          order: 0
        },
        languages: languages,
        ref: 'productsConfigs',
        subCategories: [],
        isNew: false,
        itemsText: [],
        subCategoryArray: [],
      }
    },
    validations: {

    },
    computed: {
      ...authComputed
    },
    watch: {
      async itemId(value, oldValue) {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
          if(this.type) {
            this.form.type = this.type;
          }
          this.itemsText = [{...this.defaultItem, id:this.getNewIdItem()}];
        }
      },
      type(value, oldValue) {
        if(value!==oldValue) {
          this.$emit('changeModel', null);
        }
      }
    },
    mounted: async function() {
      this.unsubscribeObserver = firebase.firestore().collection('companiesSubCategories').where("companyId", "==", this.currentCompany.id).limit(10000).onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            let value = {...change.doc.data(),id: change.doc.id };
            this.subCategories.splice(_.sortedIndexBy(this.list, value, function(o) { return o.order }), 0, value);
          }
          else if (change.type === "modified") {
            console.log("Modified : ", change.doc.data());
            let value = {...change.doc.data(),id: change.doc.id };
            let index = _.findIndex(this.subCategories, (o)=>{return o.id===value.id;});
            if(index>=0) {
              let needReorder = false;
              if(this.subCategories[index].order!==value.order) {
                needReorder = true;
              }
              this.subCategories[index] = value;
              if(needReorder) {
                this.subCategories.sort((a, b)=>{return a.order-b.order;});
              }
            }
            else {
              this.subCategories.splice(_.sortedIndexBy(this.list, value, function(o) { return o.order }), 0, value);
            }
          }
          else if (change.type === "removed") {
            console.log("Removed : ", change.doc.data());
          }
          else {
            console.warn('Recebido dados estranhos do firebase', change);
          }
        });
        this.isLoading = false
      });
      this.isLoading = false
    },
    beforeDestroy: function() {
      if(typeof this.unsubscribeObserver === 'function') {
        this.unsubscribeObserver();
        this.unsubscribeObserver = null;
      }
    },

    methods: {
      ...authMethods,
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        let obj = await firebase.firestore().collection(this.ref).doc(this.itemId).get();

        if(obj && obj.data()) {
          this.form = { ...this.defaultForm, ...obj.data()};
          this.isNew = false
        }
        else {
          this.form = { ...this.defaultForm};
          this.isNew = true;
          if(this.type) {
            this.form.type = this.type;
          }
        }

/*
        let tmp = [];
        if(this.form.additionalText) {
          let objItemsText = await firebase.firestore().collection('additionalsText').doc(this.form.additionalText).collection('items').get();
          objItemsText.forEach((snapshot)=>{
            tmp.push({...snapshot.data(), id:snapshot.id})
          });
        }
        tmp.sort((a, b)=>{return a.order-b.order;});
        this.itemsText = tmp; */

        this.itemsText = this.form.itemsText || [];
        if(this.itemsText.length===0) {
          this.itemsText = [{...this.defaultItem, id:this.getNewIdItem()}];
        }

        this.subCategoryArray = [];
        if(this.form.subCategoryArray) {
          for(let item of this.form.subCategoryArray) {
            let test = this.subCategories.find((value) => value.id===item);
            if(test) {
              this.subCategoryArray.push(test);
            }
          }
        }

        console.log('loaded form', this.form);
        this.isLoading = false;
      },
      async conclude() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.updateError = {message:'invalid input form'};
          return
        }

        const form = _.cloneDeep(this.form);

        this.tryingToUpdate = true;
        this.updateError = null;
        this.hasError = null;
        if(!form.companyId) {
          form.companyId = this.currentCompany.id;
        }
        form.numberOfChoices = parseInt(form.numberOfChoices || 0) || 0;
        form.isMandatory = Boolean(form.isMandatory);
        form.additionalText = form.additionalText || this.itemId;
        form.order = parseInt(form.order || 0) || 0;
        form.active = !!form.active;

        form.subCategoryArray = [];
        for(let item of this.subCategoryArray) {
          if(item.id) {
            form.subCategoryArray.push(item.id);
          }
        }


        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile(this.ref+'/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress
            });
            if(url) {
              form.img = url;
            }
          } catch(error) {
            this.updateError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
        }

        form.productArray = [];
        for(let product of form.productsResume || []) {
          form.productArray.push(product.id);
        }


        try {
          let promises = [];
          form.itemsText = [];
          promises.push(firebase.firestore().collection('additionalsText').doc(form.additionalText).set({...form, attention:'Os dados desse nó é apenas para deixa o banco mais legível, utilize o nó productsConfigs'},{merge: false}));
          for(let i in this.itemsText) {
            let tag = this.itemsText[i];
            promises.push(firebase.firestore().collection('additionalsText').doc(form.additionalText).collection('items').doc(tag.id).set({
              active: Boolean(tag.active),
              title: tag.title || '',
              companyId: this.currentCompany.id,
              order: parseInt(i) || 0,
              value: parseFloat(tag.value) || 0
            },{merge: true}));

            form.itemsText.push({
              active: Boolean(tag.active),
              title: tag.title || '',
              order: parseInt(i) || 0,
              value: parseFloat(tag.value) || 0,
              id: tag.id,
            });
          }
          promises.push(firebase.firestore().collection(this.ref).doc(this.itemId).set(form,{merge: true}));

          await promises;
          this.$showSuccess();
          this.tryingToUpdate = false;
          this.tryingToUpload = false;
          this.$emit('changeModel', null);
        } catch(error) {
          console.error('Erro ao tentar registrar', error);
          console.log('form', form);
          this.updateError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;

      },
      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.img = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      getNewIdItem() {
        return firebase.firestore().collection('additionalsText').doc().id;
      },
      addItemClick() {
        this.itemsText.push({...this.defaultItem, id:this.getNewIdItem()});
      },
      async getProducts(input) {
        return productService.getProducts({
          search: input.search,
          size: input.size,
          from: input.from,
          lang: this.$i18n.locale,
          companyIds: [this.currentCompany.id],
          inactive: true,
        });
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <!--
                    <div :class="$style.imagePreview" @click="tryChangeImage()" >
                      <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
                      <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
                      <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
                    </div>
                    <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                               type-content="modal"
                               :max-width="180"
                               :max-height="180"
                               :aspect-ratio="0"
                               @changeBlob="changeBlob"
                    ></FcmEditor> -->
          <div :class="$style.body">
            <BaseInputText v-model="form.title" :label="$t('additional.inputTitle')" :class="[getValidationClass('title'), 'formItemText']" maxlength="200">
            </BaseInputText>

            <div class="formItem">
              <Multiselect v-model="subCategoryArray" :options="subCategories" track-by="id" label="description" :multiple="true"
                           :placeholder="$t('additional.placeholder')"
                           :select-label="$t('multiSelect.selectLabel')"
                           :select-group-label="$t('multiSelect.selectGroupLabel')"
                           :selected-label="$t('multiSelect.selectedLabel')"
                           :deselect-label="$t('multiSelect.deselectLabel')"
                           :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
              >
              </Multiselect>
            </div>
            <SearchPick
                        v-model="form.productsResume"
                        :get-items="getProducts"
                        :label="$t('form.selectProducts')"
                        :show-img="true"
                        :filter-region="false"
                        :limit="0"
            >
              <template #item="slotProp" >
                <div>{{slotProp.item.name}}</div>
              </template>
              <template #selected="slotProp" >
                <div>{{slotProp.item.name}}</div>
              </template>
            </SearchPick>

            <div class="formItemSubSection"></div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.active')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.isMandatory')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.isMandatory" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <MdField >
                <label >{{$t('form.numberOfChoices')}}</label>
                <MdInput v-model="form.numberOfChoices" :value="form.numberOfChoices" type="number"></MdInput>
              </MdField>
            </div>
            <div class="formItem">
              <MdField>
                <label >{{$t('additional.type')}}</label>
                <MdSelect v-model="form.type" name="access" disabled="disabled">
                  <MdOption key="additionals" value="additionals">{{$t('additional.additionals')}}</MdOption>
                  <MdOption key="variation" value="variation">{{$t('additional.variation')}}</MdOption>
                  <MdOption key="freeText" value="freeText">{{$t('additional.freeText')}}</MdOption>
                  <MdOption key="flavor" value="flavor">{{$t('additional.flavor')}}</MdOption>
                </MdSelect>
              </MdField>
            </div>
            <div class="formItem">
              <div :class="$style.add" @click="addItemClick()">
                <MdIcon class="md-small-size-90" >add</MdIcon>
              </div>
              <br>
              <Draggable v-model="itemsText">
                <TransitionGroup>
                  <div v-for="(item, index) in itemsText" :key="item.id" :class="['formItem', $style.item]">
                    <!--  <div ng-if="item.imgUrl" class="formItemImg">
                        <img :class="$style.imgUrl" :src="item.imgUrl" />
                      </div> -->
                    <div class="formItemDesc">
                      <div class="formItemHead">
                        <MdField>
                          <label>{{$t('additional.itemTitle')}}</label>
                          <MdInput v-model="itemsText[index].title" :class="$style.textTitle" :value="itemsText[index].title" type="text"></MdInput>
                        </MdField>
                        <MdField v-show="form.type==='additionals' || form.type==='freeText'">
                          <label>{{$t('additional.itemValue')}}</label>
                          <MdInput v-model="itemsText[index].value" :class="$style.textValue" :value="itemsText[index].value" type="number"></MdInput>
                        </MdField>
                      </div>
                    </div>
                    <div class="formItemAction">
                      <MdSwitch v-model="item.active" class="md-primary" :value="true" ></MdSwitch>
                      <div class="formItemControl"></div>
                    </div>
                  </div>
                </TransitionGroup>
              </Draggable>
            </div>
          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 530px;
      .content {
        max-width: 650px;
        padding: 20px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {

          }
        }
      }
    }
  }
</style>





















