<script>
  /* eslint-disable vue/no-v-html */
import Layout from '@components/layout/visitant/main-layout'
import {appConfig} from '@src/config';
import firebase from 'firebase/app'

export default {
  page: {
    title: '',
    meta: [
      {name: 'description', content: `${appConfig.title}`}
    ],
  },
  components: { Layout },
  data() {
    return {
      itemId: null,
      slug: null,
      form: {
        name: {},
        imgUrl: null,
        order: 0,
        active: true,
        txt: {},
        abstract: {},
      },
      defaultForm: {
        name: {},
        imgUrl: null,
        order: 0,
        active: true,
        txt: {},
        abstract: {},
      },
      isLoading: true,
      listPosts: [],
    }
  },
  computed: {
  },
  watch: {

  },
  mounted: async function () {
    this.loadForm();
  },
  methods: {
    async loadForm() {
      this.listPosts = [];
      let objs = await firebase.firestore().collection('blog').where('active', '==', true).get();

      objs.forEach((obj)=>{
        if(obj && obj.data()) {
          let form = { ..._.cloneDeep(this.defaultForm), ...obj.data()};
          if(typeof form.name !== 'object') {
            form.name = {};
          }
          this.listPosts.push(form);
        }
      });

      this.isLoading = false;
    },
  },


}
</script>

<template>
  <Layout>
    <div :class="$style.container">
      <div :class="$style.content">

  <!--      <div :class="$style.blogItem" v-for="(item, index) in listPosts"  :key="index">
          <div :class="$style.image">
            <img :src="item.imgUrl" />
          </div>
          <div :class="$style.title">{{$trdb(item.name)}}</div>
        </div> -->
        <RouterLink v-for="(item, index) in listPosts"  :key="index" :class="$style.blogItem" tag="div" :to="'/post/'+item.slug">
          <div :class="$style.image">
            <img :src="item.imgUrl" />
          </div>
          <div :class="$style.title">{{$trdb(item.name)}}</div>
        </RouterLink>
      </div>

    </div>

  </Layout>
</template>


<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 300px;
    padding-bottom: 30px;
    .content {
      width: 100%;
      padding: 25px 10px 15px;
      text-align: left;

      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      justify-content: space-around;
      .blogItem {
        margin-top: 30px;
        cursor: pointer;

        flex: 1 1 400px;
        max-width: 412px;
        margin-left: 16px;
        margin-right: 16px;
        text-align: left;

        @media screen and (max-width: 500px) {
          margin-left: 0;
          margin-right: 0;
        }

        .image {
          max-width: 100%;
        }
        .image img {
          border-radius: 8px;
        }

        .title {
          margin-top: 15px;
          font-size: 24px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.67;
          letter-spacing: -0.4px;
          margin-bottom: 10px;
        }
      }
    }
  }


</style>
