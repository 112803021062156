<script>
  import {appConfig} from '@src/config';
  import NavBar from '@components/visitant/nav-bar'
  import EatTogether from '@components/visitant/home/eatTogether'
  import InfoTab from '@components/visitant/home/infoTab'
  import Download from '@components/visitant/home/download'
  import SaibaMais from '@components/visitant/home/saibamais'
  import HaveMore from '@components/visitant/home/haveMore'
  import Footer from '@components/layout/footer'

  export default {
    page: {
      title: 'Home',
      meta: [
        { name: 'description', content: appConfig.description },
        { name: 'og:description', content: appConfig.description },
      ],
    },
    components: {NavBar, EatTogether, Footer, InfoTab, Download, SaibaMais, HaveMore},
  }
</script>

<template>
  <div id="home" :class="$style.box">
    <NavBar></NavBar>
    <EatTogether></EatTogether>
    <InfoTab></InfoTab>
    <Download id="download"></Download>
    <HaveMore></HaveMore>
    <SaibaMais></SaibaMais>



    <Footer></Footer>
  </div>

</template>


<style lang="scss" module>
  @import '@design';

  html {
    background-color: $color-primary !important;
  }

  .box {

  }

</style>
