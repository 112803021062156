"use strict";

let toExport = {};

let links = {
  site: {
    name: 'Site',
    link: 'https://www.gotap.com.br'
  },
  facebook: {
    name: 'Facebook',
    link: 'https://www.facebook.com/GoTap-102984017841217'
  },
  instagram: {
    name: 'Instagram',
    link: 'https://www.instagram.com/gotapapp/'
  }
};

const android = {
  linkStore: 'https://play.google.com/store/apps/details?id=br.com.gotap',
  storeCode: '',
  id: 'br.com.gotap',
  uri: 'vnd.gotap://',
  name: 'Go Tap',
  packageName: 'br.com.gotap'
};

const ios = {
  linkStore: 'https://apps.apple.com/br/app/go-tap/id1494537068?mt=8',
  storeCode: 'id1494537068',
  id: 'id1494537068',
  uri: 'vnd.gotap://',
  name: 'Go Tap',
  packageName: '',
};

toExport.listConfig = {
  'default': {
    name: 'Default ALGO DE ERRADO NÃO ESTA CERTO',
    firebase: null,
    fbId: '',

    title: 'GO TAP'+(!(process.env.NODE_ENV === 'production' || process.env.VUE_APP_DEVELOPER_DATABASE_MODE === 'production')?' DEV ':''),
    description: 'Go Tap! o app mais revolucionário de pedir comida. Juntos vamos acabar com a fome do mundo, uma cidade de cada vez. Use o Go Tap! e surpreenda-se com inúmeras funcionalidades nunca antes vista',

    baseUrl: 'https://www.gotap.com.br',
    links: links,
    android: android,
    ios: ios,
  },
  'production': {
    name: 'supperappcg PRODUCTION',
    maps: {
      key: 'AIzaSyA95_PzZzvfKkJSRT8KRxJuaq9_AlMuP18'
    },
    title: 'GO TAP',
    description: 'Go Tap! o app mais revolucionário de pedir comida. Juntos vamos acabar com a fome do mundo, uma cidade de cada vez. Use o Go Tap! e surpreenda-se com inúmeras funcionalidades nunca antes vista',

    baseUrl: 'https://www.gotap.com.br',
    firebase: {
      apiKey: "AIzaSyAAnMBdXIZJzqhkg7ZZNpgfjE28cwAyJKc",
      authDomain: "supperappcg.firebaseapp.com",
      databaseURL: "https://supperappcg.firebaseio.com",
      projectId: "supperappcg",
      storageBucket: "supperappcg.appspot.com",
      messagingSenderId: "748012174951",
      appId: "1:748012174951:web:07faa94bcb4e2374",
      measurementId: "G-KX5C8TMFMH"
    },
    fbId: '270092647044962',
    links: links,
    android: android,
    ios: ios,
  },
  'developer': {
    name: 'supperapp-dev DEVELOPER',
    maps: {
      key: 'AIzaSyDtXUEjzhkOUjaBkm4PQ4rHxIgVOvIUEFA'
    },
    title: 'GO TAP DEV ',
    description: 'Go Tap! o app mais revolucionário de pedir comida. Juntos vamos acabar com a fome do mundo, uma cidade de cada vez. Use o Go Tap! e surpreenda-se com inúmeras funcionalidades nunca antes vista',

    baseUrl: 'https://www.gotap.com.br',
    firebase: {
      apiKey: "AIzaSyAAnMBdXIZJzqhkg7ZZNpgfjE28cwAyJKc",
      authDomain: "supperappcg.firebaseapp.com",
      databaseURL: "https://supperappcg.firebaseio.com",
      projectId: "supperappcg",
      storageBucket: "supperappcg.appspot.com",
      messagingSenderId: "748012174951",
      appId: "1:748012174951:web:07faa94bcb4e2374",
      measurementId: "G-KX5C8TMFMH"
    },
    fbId: '270092647044962',
    links: links,
    android: android,
    ios: ios,
  }
};
toExport.config = toExport.listConfig['default'];

if (process.env.NODE_ENV === 'production' || process.env.VUE_APP_DEVELOPER_DATABASE_MODE === 'production') {
  toExport.config = toExport.listConfig['production'];
}
else {
  toExport.config = toExport.listConfig['developer'];
  console.log('Server config start configName: ' + toExport.config.name);
}

// console.log('Server config start configName: ' + toExport.config.name);

toExport.appConfig = toExport.config;

module.exports = toExport;
