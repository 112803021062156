<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/admin/main_tabs'
  import ListItens from '@components/list_itens'
  import firebase from 'firebase/app'
  import ChildComponent from '@components/admin/blog_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListItens, ChildComponent},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    mounted(){
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
        firebase.firestore().collection('blog').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
        this.itemId = firebase.firestore().collection('blog').doc().id;
      },
      makeSearch(el) {
        if(!el.name) {return '';}
        let search= '';
        for(let lang in el.name) {
          search += ' '+el.name[lang];
        }
        return search;
      }
    },
  }
</script>

<template>
  <MainTabs>
    <template #left >
      <ListItens
        v-model="itemId"
        resource="blog"
        :actions="['edit', 'enable']"
        :make-search="makeSearch"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
        <template #item="slotProp" >
          <div class="formItemHead">{{($trdb(slotProp.item.name)?$trdb(slotProp.item.name):'')+' ('+slotProp.item.slug+')'}}</div>
        </template>
      </ListItens>
    </template>
    <template #right>
      <ChildComponent
        v-model="itemId"
      >
      </ChildComponent>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

</style>





















