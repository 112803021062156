<script>
  import Locale from '@components/layout/locale'
  import {authComputed} from '@state/helpers'
  export default {
    components: { Locale },
    props: {
    },
    data() {
      return {
      }
    },computed: {
      ...authComputed,
    },
    methods: {
      notImplemented() {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'info',
          title: this.$t('all.notImplemented'),
          showConfirmButton: false,
          timer: 1500
        })
      },
      logout: function (event) {
        this.$router.push({ name: 'logout' })
      },
      onClick(event) {
        this.$eventHub.$emit('menuClicked', event);
      }
    }
  }
</script>

<template>
  <ul class="box">
    <li class="bloco menuActions">
      <ul class="items">
        <li>
          <div v-if="loggedIn" class="itemlink">
            <BaseButton
              class="md-accent md-raised md-lg"
              type="text"
              @click="logout"
            >
              {{$t('navBar.logOut')}}
            </BaseButton>
          </div>
        </li>
        <li>
          <div class="itemlink">
            <Locale />
          </div>
        </li>
      </ul>
    </li>
    <li class="bloco">
      <span class="title">{{$t('all.settings').toUpperCase()}}</span>
      <ul class="items">
        <RouterLink tag="li" class="item" to="/ong/profile" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-restaurant@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-restaurant_2@3x.png')" >
          {{$t('all.ong')}}
        </RouterLink>

        <RouterLink tag="li" class="item" to="/ong/users" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.users')}}
        </RouterLink>
      </ul>
    </li>

  </ul>
</template>




<style lang="scss" scoped>
  @import '@design';

  .menuActions {
    display: none;
    @media screen and (max-width: $size-menu-actions+px) {
      display: block;
    }
  }

  .box {
    /*width: 250px;*/
    /*height: 100%;*/
    text-align: left;
    list-style-type: none;
    padding-inline-start: 0px;

    .bloco {
      padding-bottom: 35px;
      padding-top: 15px;
      padding-left: 20px;
      .title {

      }
      .items {
        list-style-type: none;
        padding-inline-start: 4px;

        .onActive {
          display: none;
        }
        .onInative {
          display: unset;
        }

        .router-link-active .onActive {
          display: unset;
        }
        .router-link-active .onInative {
          display: none;
        }
        .item {
          cursor: pointer;
          margin-top: 20px;
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 24px;
          }
          .onActive {
            color: $color-primary;
          }
          .onInative {
            color: $color-disabled;
          }

        }
      }
    }
  }
</style>
