<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/company/main_tabs'
  import ListItens from '@components/list_itens'
  import firebase from 'firebase/app'
  import AdditionalEdit from '@components/products/additional_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListItens, AdditionalEdit},
    props: {
    },
    data() {
      return {
        itemId: null,
        listRef: null,
        type: '',
      }
    },
    computed: {
      ...authComputed
    },
    watch: {
      '$route.params.type': function (type) {
        if(type!==this.type) {
          let listRef = firebase.firestore().collection('productsConfigs');
          if(type) {
            listRef = listRef.where("type", "==", type);
            this.type = type;
          }
          this.listRef = listRef;
        }
      }
    },
    beforeMount(){
      this.listRef = firebase.firestore().collection('productsConfigs');
      if(this.$route.params.type) {
        this.listRef = this.listRef.where("type", "==", this.$route.params.type);
        this.type = this.$route.params.type;
      }
    },
    mounted() {
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
        firebase.firestore().collection('productsConfigs').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
        this.itemId = firebase.firestore().collection('productsConfigs').doc().id;
      },
      makeSearch(el) {
        if(el.title) {return el.title;}
        return '';
      }
    },
  }
</script>

<template>
  <MainTabs>
    <template #left >
      <ListItens
        v-if="currentCompany && currentCompany.id"
        v-model="itemId"
        :actions="['edit', 'enable']"
        :company-id="currentCompany.id"
        :list-ref="listRef"
        :make-search="makeSearch"
        resource="productsConfigs"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
        <template #item="slotProp" >
          <div class="formItemHead">{{slotProp.item.title}}</div>
        </template>
      </ListItens>
    </template>
    <template #right>
      <AdditionalEdit
        v-model="itemId"
        :type="type"
      >
      </AdditionalEdit>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

</style>





















