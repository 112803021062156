"use strict";
let toExport = {};


toExport.bag1 = {
  "active": true,
  "bagStatus": "toOrder",
  "company": {
    "acceptCashPayment": false,
    "acceptDelivery": true,
    "acceptOnSite": false,
    "acceptPaymentOnApp": false,
    "acceptPaymentOnDelivery": true,
    "acceptTakeout": true,
    "active": false,
    "address": {
      "city": "Campo Grande",
      "complement": "",
      "country": "Brasil",
      "latitude": -20.4562751,
      "longitude": -54.593829984106435,
      "neighborhood": "Jardim Ima",
      "number": "1234",
      "placeMarkName": "Rua Ceará",
      "placeMarkNameFull": null,
      "regionId": "6s7GUTKArgVOJD7laE9Y",
      "state": "MS",
      "stateFull": null,
      "street": "Rua Ceará",
      "zipcode": ""
    },
    "category": {
      "img": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/categories%2FCpA2qWRPpBsWEFCSUbJ8?alt=media&token=a96d5c2d-ccdc-4cd4-915a-0a075988664e",
      "name": {
        "en": "Hamburguer",
        "ptbr": "Hambúrguer"
      },
      "tagId": "CHkfIjqYemK66a4KDrea"
    },
    "contact": "felippicm@gmail.com",
    "corporateName": "Teste FCM",
    "deliveryAreas": [
      {
        "id": "6Bz0tosq0Xz2zt5hXAd5",
        "name": "teste",
        "paths": [
          {
            "lat": -20.426839243940908,
            "lng": -54.60842120114745
          },
          {
            "lat": -20.466729101658775,
            "lng": -54.63691698972167
          },
          {
            "lat": -20.484740480503827,
            "lng": -54.56893908444823
          },
          {
            "lat": -20.432147765650534,
            "lng": -54.55554949704589
          }
        ]
      }
    ],
    "deliveryOptions": {
      "kmValue": 0.5,
      "lstPrecedence": [],
      "minValue": 0.5,
      "precedence": {
        "distance": 3,
        "value": 0
      },
      "roundAt": 0.5
    },
    "description": null,
    "id": "yPqVEBHAW3HftoXmljaC",
    "isNew": false,
    "isOpen": true,
    "logoImgUrl": "https://firebasestorage.googleapis.com/v0/b/supperappcg.appspot.com/o/companiesLogo%2FyPqVEBHAW3HftoXmljaC?alt=media&token=f6b00e77-5291-4d3b-ba20-808f9a79106d",
    "maxValueForDelivery": 0,
    "maxValueForOnSiteAndTakeout": 0,
    "minValueForDelivery": 0,
    "name": "TESTE FCM",
    "phone": "+5567992295041",
    "primaryColor": {
      "a": 1,
      "b": 101,
      "g": 158,
      "r": 22
    },
    "rating": 5,
    "secondaryColor": {
      "a": 1,
      "b": 255,
      "g": 255,
      "r": 255
    },
    "showOnlyCategoryOnMenu": true,
    "timeForDeliveryMax": 1,
    "timeForDeliveryMin": 1,
    "timeForPickupAndOnSiteMax": 1,
    "timeForPickupAndOnSiteMin": 1,
    "updatedAt": new Date(),
    "updatedBy": "admin",
    "validPaymentMethods": [
      {
        "active": true,
        "id": "1W2Kn78hU7V9y0Te3SYh",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2F1W2Kn78hU7V9y0Te3SYh?alt=media&token=b7073c22-5700-4d8e-8b3f-9e1058550047",
        "isCash": false,
        "name": {
          "en": "Rede Shop (Debit)",
          "ptbr": "Rede Shop  (Débito)"
        },
        "order": 12
      },
      {
        "active": true,
        "id": "1gu6qeEneSI6xsAcx0VN",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2F1gu6qeEneSI6xsAcx0VN?alt=media&token=5056ff26-94b6-413a-b1b7-24be459bd166",
        "isCash": false,
        "name": {
          "en": "Mastercard (Credit)",
          "ptbr": "Mastercard (Crédito)"
        },
        "order": 4
      },
      {
        "active": true,
        "id": "64r9Q1x83sAvtusCcSWF",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2F64r9Q1x83sAvtusCcSWF?alt=media&token=b7fa24f9-3035-46b7-b888-19b075bf2a58",
        "isCash": false,
        "name": {
          "en": "Elo (Debit)",
          "ptbr": "Elo (Débito)"
        },
        "order": 10
      },
      {
        "active": true,
        "id": "7wCTZ4thKlnQiEANXoOA",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2F7wCTZ4thKlnQiEANXoOA?alt=media&token=80c6c063-2169-4630-9574-16a4faf8ff47",
        "isCash": false,
        "name": {
          "en": "Rede Shop (Credit)",
          "ptbr": "Rede Shop (Crédito)"
        },
        "order": 11
      },
      {
        "active": true,
        "id": "Bq8yM0iSmQez9xGYDKMH",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FBq8yM0iSmQez9xGYDKMH?alt=media&token=dde635f8-efbd-45a6-8031-b1eaeb7f3db6",
        "isCash": false,
        "name": {
          "en": "Sicredi (Credit)",
          "ptbr": "Sicredi (Crédito)"
        },
        "order": 14
      },
      {
        "active": true,
        "id": "DGcieLLadcydVHRPLF8P",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FDGcieLLadcydVHRPLF8P?alt=media&token=5b6e24c2-6093-4cb2-86fa-206793204ba3",
        "isCash": false,
        "name": {
          "en": "Amex (Credit)",
          "ptbr": "Amex (Crédito)"
        },
        "order": 8
      },
      {
        "active": true,
        "id": "Vwi9aa532jyc2yUwTZVg",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FVwi9aa532jyc2yUwTZVg?alt=media&token=2131c787-b5c0-47b9-9fef-ff97b95837e0",
        "isCash": false,
        "name": {
          "en": "Visa (Credit)",
          "ptbr": "Visa (Crédito)"
        },
        "order": 2
      },
      {
        "active": true,
        "id": "WorMEYsOmLS7JLrj6kM9",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FWorMEYsOmLS7JLrj6kM9?alt=media&token=1175034b-6115-476a-a321-46ec37ebb5ad",
        "isCash": false,
        "name": {
          "en": "Elo (Credit)",
          "ptbr": "Elo (Crédito)"
        },
        "order": 9
      },
      {
        "active": true,
        "id": "e9exH9ICCpNVj6p9NlXA",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2Fe9exH9ICCpNVj6p9NlXA?alt=media&token=933301cb-e0b5-4149-b5f3-f16dbcde9ae8",
        "isCash": false,
        "name": {
          "en": "Hipercard (Debit)",
          "ptbr": "Hipercard (Débito)"
        },
        "order": 6
      },
      {
        "active": true,
        "id": "pV9yEmg7aCvi1EuwgTeV",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FpV9yEmg7aCvi1EuwgTeV?alt=media&token=a2e9640b-afd8-4e53-866b-c0c764bee50e",
        "isCash": true,
        "name": {
          "en": "Cash",
          "ptbr": "Dinheiro"
        },
        "order": 1000
      },
      {
        "active": true,
        "id": "q70OnUHhqSHWSZEyDIhE",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2Fq70OnUHhqSHWSZEyDIhE?alt=media&token=69106464-50d8-479a-92e9-20d7e682bf4e",
        "isCash": false,
        "name": {
          "en": "Mastercard (Debit)",
          "ptbr": "Mastercard (Débito)"
        },
        "order": 3
      },
      {
        "active": true,
        "id": "qjS3da4yxGxhWY3iA5r7",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FqjS3da4yxGxhWY3iA5r7?alt=media&token=8ecb93d0-e2a1-43b2-953a-8f00e521775e",
        "isCash": false,
        "name": {
          "en": "Sicredi (Debit)",
          "ptbr": "Sicredi (Débito)"
        },
        "order": 13
      },
      {
        "active": true,
        "id": "r2kjUt0KIq8FoWyx4JWz",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2Fr2kjUt0KIq8FoWyx4JWz?alt=media&token=1d098c29-853e-4354-9f4c-147a9a68ee45",
        "isCash": false,
        "name": {
          "en": "Amex (Debit)",
          "ptbr": "Amex (Débito)"
        },
        "order": 7
      },
      {
        "active": true,
        "id": "sGftZYwjz42FQLghG7l1",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FsGftZYwjz42FQLghG7l1?alt=media&token=eed23324-5efc-473d-b198-0e0adc3218c3",
        "isCash": false,
        "name": {
          "en": "Hipercard (Credit)",
          "ptbr": "Hipercard (Crédito)"
        },
        "order": 5
      },
      {
        "active": true,
        "id": "vuLr9Pbxlfwvij6j6lo4",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2FvuLr9Pbxlfwvij6j6lo4?alt=media&token=96c72d2a-2731-4fc0-a45d-53ada13445a7",
        "isCash": false,
        "name": {
          "en": "Visa (Debit)",
          "ptbr": "Visa (Débito)"
        },
        "order": 1
      },
      {
        "active": true,
        "id": "ZOpJsKlX3K7vVEC7oKzy",
        "imgUrl": null,
        "isCash": false,
        "name": {
          "en": "Mark on the tab",
          "ptbr": "Convênio"
        },
        "order": 1000
      }
    ],
    "validPaymentMethodsIds": [
      "1W2Kn78hU7V9y0Te3SYh",
      "1gu6qeEneSI6xsAcx0VN",
      "64r9Q1x83sAvtusCcSWF",
      "7wCTZ4thKlnQiEANXoOA",
      "Bq8yM0iSmQez9xGYDKMH",
      "DGcieLLadcydVHRPLF8P",
      "Vwi9aa532jyc2yUwTZVg",
      "WorMEYsOmLS7JLrj6kM9",
      "e9exH9ICCpNVj6p9NlXA",
      "pV9yEmg7aCvi1EuwgTeV",
      "q70OnUHhqSHWSZEyDIhE",
      "qjS3da4yxGxhWY3iA5r7",
      "r2kjUt0KIq8FoWyx4JWz",
      "sGftZYwjz42FQLghG7l1",
      "vuLr9Pbxlfwvij6j6lo4",
      "ZOpJsKlX3K7vVEC7oKzy"
    ]
  },
  "companyId": "yPqVEBHAW3HftoXmljaC",
  "createdDate": new Date(),
  "createdEnv": "android",
  "deliveryAddress": {
    "active": true,
    "addressName": "Trabalho",
    "addressType": "work",
    "city": "Campo Grande",
    "complement": "",
    "country": "Brasil",
    "default": false,
    "id": "unWeyuXjI4OR4cTD2jh0",
    "latitude": -20.457349,
    "longitude": -54.5988933,
    "neighborhood": "Jardim dos Estados",
    "number": "559",
    "placeMarkName": "R. Piratininga, 551 - Jardim dos Estados, Campo Grande - MS, 79020-240, Brasil",
    "placeMarkNameFull": "R. Piratininga, 551 - Jardim dos Estados, Campo Grande - MS, 79020-240, Brasil",
    "regionId": "6s7GUTKArgVOJD7laE9Y",
    "state": "Mato Grosso do Sul",
    "stateFull": "Mato Grosso do Sul",
    "street": "Rua Piratininga",
    "userID": "ZKhhzRZLRtXXSLLwZKCpmYzawWr2",
    "zipcode": "79020-240"
  },
  "deliveryTxValue": 0,
  "deliveryType": "delivery",
  "groupCode": "YJSDJ5",
  "groupStatus": null,
  "id": "Ib2FUnWYWdf9Pe5Gckt1",
  "isPaymentBySystem": null,
  "isRatingCompleted": null,
  "isRatingIgnored": null,
  "lstGroupUsersSplitBill": {
    "202F53DD-52A7-11EA-A1BB-751246DB401E": {
      "ZKhhzRZLRtXXSLLwZKCpmYzawWr2": true
    }
  },
  "lstGroupUsersSplitBillAddress": {
    "ZKhhzRZLRtXXSLLwZKCpmYzawWr2": true
  },
  "lstProducts": {
    "202F53DD-52A7-11EA-A1BB-751246DB401E": {
      "lstConfigProduct": [
        {
          "active": true,
          "additionalText": "TpMgX3ef79oNapCiGyLy",
          "companyId": "yPqVEBHAW3HftoXmljaC",
          "id": "TpMgX3ef79oNapCiGyLy",
          "isMandatory": false,
          "lstAdditionalTextsObjects": [
            {
              "active": true,
              "chosedProduct": null,
              "companyId": "yPqVEBHAW3HftoXmljaC",
              "id": "jf2ioZ1TDAlsozLc4aH6",
              "order": 0,
              "qtd": null,
              "title": "COCA",
              "value": 4
            },
            {
              "active": true,
              "chosedProduct": null,
              "companyId": "yPqVEBHAW3HftoXmljaC",
              "id": "VSbdBhFyotZLq0pGkUvu",
              "order": 1,
              "qtd": null,
              "title": "FANTA",
              "value": 4
            }
          ],
          "lstChooseAdditionalObjects": [
            {
              "active": true,
              "chosedProduct": null,
              "companyId": "yPqVEBHAW3HftoXmljaC",
              "id": "jf2ioZ1TDAlsozLc4aH6",
              "order": 0,
              "qtd": 1,
              "title": "COCA",
              "value": 4
            }
          ],
          "numberOfChoices": 0,
          "order": 1,
          "subCategoryArray": [
            "szTLqPsVxp8zYDnoiUTD"
          ],
          "title": "ESCA",
          "type": "additionals"
        }
      ],
      "lstIngredientsRestrictions": [],
      "observation": "",
      "product": {
        "active": true,
        "company": null,
        "companyId": "yPqVEBHAW3HftoXmljaC",
        "description": "escolha aesede",
        "hasVariation": false,
        "id": "tKNhKdxaabGmUBrOBi6E",
        "imgUrl": null,
        "ingredients": [],
        "ingredientsId": [],
        "name": "lata 350",
        "nutritionals": [],
        "nutritionalsId": [],
        "order": 0,
        "subCategory": {
          "active": true,
          "categoryId": "3I0gTgmL25mEwHihLrPW",
          "deleted": null,
          "description": "Crystal",
          "id": "szTLqPsVxp8zYDnoiUTD",
          "imgUrl": null,
          "name": "",
          "order": 1,
          "subCategoryId": null,
          "visible": true
        },
        "subCategoryId": "szTLqPsVxp8zYDnoiUTD",
        "tags": [],
        "tagsId": [],
        "value": 4,
        "variationData": [],
        "visible": true
      },
      "qtd": 1,
      "total": 8,
      "uuid": "202F53DD-52A7-11EA-A1BB-751246DB401E"
    }
  },
  "lstUserIds": {
    "ZKhhzRZLRtXXSLLwZKCpmYzawWr2": true
  },
  "lstUsers": {
    "ZKhhzRZLRtXXSLLwZKCpmYzawWr2": {
      "acceptedPromotions": true,
      "birthDate": new Date(),
      "createdEnv": "android",
      "createdType": "e",
      "email": "felippicm@gmail.com",
      "gender": "M",
      "governmentDocumentId": "03053783177",
      "id": "ZKhhzRZLRtXXSLLwZKCpmYzawWr2",
      "isUserCanceled": false,
      "lastName": "Cm2",
      "lstUserIngredientsRestrictions": [
        "Hr405qC782lwlxClBY9C",
        "OMxu04Ut9EUh8dXhGt07",
        "HB9wQkuA0zBGUWjzEuSB"
      ],
      "name": "Felippi",
      "ongId": "8ZsAYGqq8vR7Ozy9WrzD",
      "phone": "+5567992295041",
      "profileImageUrl": "https://firebasestorage.googleapis.com/v0/b/supperappcg.appspot.com/o/images%2FZKhhzRZLRtXXSLLwZKCpmYzawWr2%2Fphotoperfil.jpeg?alt=media&token=c1f7bceb-18f9-4969-b42e-428cb9d3c87a"
    }
  },
  "obsOrder": null,
  "orderCode": null,
  "orderNumber": null,
  "paymentMethod": {
    "ZKhhzRZLRtXXSLLwZKCpmYzawWr2": {
      "changeValue": null,
      "deliveryPaymentMethod": {
        "active": true,
        "id": "1gu6qeEneSI6xsAcx0VN",
        "imgUrl": "https://firebasestorage.googleapis.com/v0/b/supperapp-godspromise.appspot.com/o/cards%2F1gu6qeEneSI6xsAcx0VN?alt=media&token=5056ff26-94b6-413a-b1b7-24be459bd166",
        "isCash": false,
        "name": {
          "en": "Mastercard (Credit)",
          "ptbr": "Mastercard (Crédito)"
        },
        "order": 4
      },
      "groupUserDeliveryAddressTotalValue": null,
      "groupUserProductsTotalValue": null,
      "groupUserTotalValue": 8,
      "type": "whenReceive",
      "userId": "ZKhhzRZLRtXXSLLwZKCpmYzawWr2",
      "userPaymentCreditCard": null
    }
  },
  "status": null,
  "totalValue": 8,
  "transformedInOrderDate": new Date(),
  "updatedAt": new Date(),
  "userGroupStatus": {
    "ZKhhzRZLRtXXSLLwZKCpmYzawWr2": {
      "status": "waitReady",
      "userId": "ZKhhzRZLRtXXSLLwZKCpmYzawWr2"
    }
  }
};

module.exports = toExport;
