"use strict";

// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};

toExport.googleAddress2db = function(datails, old = {}) {
  let ret = {
    city: '',
    street: '',
    neighborhood: '',
    state: '',
    country: '',
    zipcode: '',
    number: old.number || '',
    complement: old.complement || '',
    latitude: 0,
    longitude: 0,
    placeMarkName: '',
  };
  ret.placeId = datails.place_id;
  for (var i = 0; i < datails.address_components.length; i++) {
    var component = datails.address_components[i];

    for (var j = 0; j < component.types.length; j++) {
      var type = component.types[j];
      if (type === 'administrative_area_level_2' || type === 'locality') { // cidade

        ret.city = component.long_name;
      }
      else if (type === 'route') { // rua
        ret.street = component.long_name;
      }
      else if (type === 'sublocality_level_1' || type === 'sublocality') { // bairro
        ret.neighborhood = component.long_name;
      }
      else if (type === 'administrative_area_level_1') { // estado
        ret.state = component.short_name;
      }
      else if (type === 'country') { // pais
        ret.country = component.long_name;
      }
      else if (type === 'postal_code' || type === 'postal_code_prefix') { // cep
        ret.zipcode = component.long_name;
      }
      else if (type === 'street_number') {
        ret.number = component.long_name;
      }
    }
  }
  ret.placeMarkName = datails.name || '';

  if(datails.geometry) {
    ret.latitude= datails.geometry.location.lat;
    ret.longitude= datails.geometry.location.lng;
  }
  return ret
};

/**
 *
 * @param point
 * @param vs
 * @returns {boolean}
 *
 * Bibliotecas correlatas:
 * https://www.npmjs.com/package/@turf/turf
 * https://www.npmjs.com/package/@turf/boolean-point-in-polygon
 * https://www.npmjs.com/package/point-in-polygon
 * https://www.npmjs.com/package/d3-geo

 toExport.inside(
 [lat, lng],
 [
    [lat, lng],
    [lat, lng],
    [lat, lng],
    [lat, lng],
    [lat, lng]
 ]
 )

 */
toExport.inside = function (point, vs) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  // based on https://www.npmjs.com/package/point-in-polygon

  let x = point[0], y = point[1];

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    let xi = vs[i][0], yi = vs[i][1];
    let xj = vs[j][0], yj = vs[j][1];
    let intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
};

toExport.insideLatLng = function (p, vs) {
  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    let xi = vs[i].lat, yi = vs[i].lng;
    let xj = vs[j].lat, yj = vs[j].lng;
    let intersect = ((yi > p.lng) !== (yj > p.lng))
      && (p.lat < (xj - xi) * (p.lng - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
};


toExport.addressDb2Resume = function(address) {
  if(!address) { return '' }
  let resume = '';

  if(address.street) { resume += address.street +',' }
  if(address.number) { resume += address.number +' - ' }
  if(address.neighborhood) { resume += address.neighborhood +', ' }
  if(address.city) { resume += address.city +' - ' }
  if(address.state) { resume += address.state +', ' }
  if(address.country) { resume += address.country }
  return resume
};


toExport.degreesToRadians = function (degrees) {
  return (degrees * Math.PI) / 180;
};

/**
 * Get locations within a bounding box defined by a center point and distance from from the center point to the side of the box;
 *
 * @param {Object} area an object that represents the bounding box
 *    around a point in which locations should be retrieved
 * @param {Object} area.center an object containing the latitude and
 *    longitude of the center point of the bounding box
 * @param {number} area.center.latitude the latitude of the center point
 * @param {number} area.center.longitude the longitude of the center point
 * @param {number} area.radius (in kilometers) the radius of a circle
 *    that is inscribed in the bounding box;
 *    This could also be described as half of the bounding box's side length.
 * @return {Promise} a Promise that fulfills with an array of all the
 *    retrieved locations
 */

/*

Example of use

function getLocations(area) {
  // calculate the SW and NE corners of the bounding box to query for
  const box = toExport.boundingBoxCoordinates(area.center, area.radius);

  // construct the GeoPoints

  // const lesserGeopoint =  new GeoPoint(box.swCorner.latitude, box.swCorner.longitude);
  // const greaterGeopoint = new GeoPoint(box.neCorner.latitude, box.neCorner.longitude);

  const lesserGeopoint = {latitude: box.swCorner.latitude, longitude: box.swCorner.longitude };
  const greaterGeopoint = {latitude: box.neCorner.latitude, longitude: box.neCorner.longitude };

  // construct the Firestore query
  let query = firebase.firestore().collection('myCollection').where('location', '>', lesserGeopoint).where('location', '<', greaterGeopoint);

  // return a Promise that fulfills with the locations
  return query.get()
  .then((snapshot) => {
    const allLocs = []; // used to hold all the loc data
    snapshot.forEach((loc) => {
      // get the data
      const data = loc.data();
      // calculate a distance from the center
      data.distanceFromCenter = toExport.distance(area.center, data.location);
      // add to the array
      allLocs.push(data);
    });
    return allLocs;
  })
  .catch((error) => {
    console.log('Error:', error)
    return new Error('Error while retrieving events');
  });
}
toExport.getLocations = getLocations;
*/



/**
 * Calculates the distance, in kilometers, between two locations, via the
 * Haversine formula. Note that this is approximate due to the fact that
 * the Earth's radius varies between 6356.752 km and 6378.137 km.
 *
 * @param {Object} location1 The first location given as .latitude and .longitude
 * @param {Object} location2 The second location given as .latitude and .longitude
 * @return {number} The distance, in kilometers, between the inputted locations.
 */
toExport.distance = function (location1, location2)
{
  const radius = 6371; // Earth's radius in kilometers
  const latDelta = toExport.degreesToRadians(location2.latitude - location1.latitude);
  const lonDelta = toExport.degreesToRadians(location2.longitude - location1.longitude);

  const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
    (Math.cos(toExport.degreesToRadians(location1.latitude)) * Math.cos(toExport.degreesToRadians(location2.latitude)) *
      Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2));

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return radius * c;
}


toExport.calcRadiusDistance = function (lat1, lon1, lat2, lon2) {
  function deg2rad (deg) {
    return deg * Math.PI / 180;
  }
  const
    RADIUSMETERS = 6373000,
    latR1 = deg2rad(lat1),
    lonR1 = deg2rad(lon1),
    latR2 = deg2rad(lat2),
    lonR2 = deg2rad(lon2),
    latDifference = latR2 - latR1,
    lonDifference = lonR2 - lonR1,
    a  = Math.pow(Math.sin(latDifference / 2), 2) + Math.cos(latR1) * Math.cos(latR2) * Math.pow(Math.sin(lonDifference / 2), 2),
    c  = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)),
    dk = c * RADIUSMETERS;
  return dk;
};



/**
 * Calculates the SW and NE corners of a bounding box around a center point for a given radius;
 *
 * @param {Object} center The center given as .latitude and .longitude
 * @param {number} radius The radius of the box (in kilometers)
 * @return {Object} The SW and NE corners given as .swCorner and .neCorner
 */
toExport.boundingBoxCoordinates = function(center, radius)
{
  const KM_PER_DEGREE_LATITUDE = 110.574;
  const latDegrees = radius / KM_PER_DEGREE_LATITUDE;
  const latitudeNorth = Math.min(90, center.latitude + latDegrees);
  const latitudeSouth = Math.max(-90, center.latitude - latDegrees);
  // calculate longitude based on current latitude
  const longDegsNorth = toExport.metersToLongitudeDegrees(radius, latitudeNorth);
  const longDegsSouth = toExport.metersToLongitudeDegrees(radius, latitudeSouth);
  const longDegs = Math.max(longDegsNorth, longDegsSouth);
  return {
    swCorner: { // bottom-left (SW corner)
      latitude: latitudeSouth,
      longitude: toExport.wrapLongitude(center.longitude - longDegs),
    },
    neCorner: { // top-right (NE corner)
      latitude: latitudeNorth,
      longitude: toExport.wrapLongitude(center.longitude + longDegs),
    },
  };
};

/**
 * Calculates the number of degrees a given distance is at a given latitude.
 *
 * @param {number} distance The distance to convert.
 * @param {number} latitude The latitude at which to calculate.
 * @return {number} The number of degrees the distance corresponds to.
 */
toExport.metersToLongitudeDegrees = function(distance, latitude) {
  const EARTH_EQ_RADIUS = 6378137.0;
  // this is a super, fancy magic number that the GeoFire lib can explain (maybe)
  const E2 = 0.00669447819799;
  const EPSILON = 1e-12;
  const radians = toExport.degreesToRadians(latitude);
  const num = Math.cos(radians) * EARTH_EQ_RADIUS * Math.PI / 180;
  const denom = 1 / Math.sqrt(1 - E2 * Math.sin(radians) * Math.sin(radians));
  const deltaDeg = num * denom;
  if (deltaDeg < EPSILON) {
    return distance > 0 ? 360 : 0;
  }
  // else
  return Math.min(360, distance / deltaDeg);
};


/**
 * Wraps the longitude to [-180,180].
 *
 * @param {number} longitude The longitude to wrap.
 * @return {number} longitude The resulting longitude.
 */
toExport.wrapLongitude = function(longitude) {
  if (longitude <= 180 && longitude >= -180) {
    return longitude;
  }
  const adjusted = longitude + 180;
  if (adjusted > 0) {
    return (adjusted % 360) - 180;
  }
  // else
  return 180 - (-adjusted % 360);
};

module.exports = toExport;

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }

