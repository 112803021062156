<script>
  import { authComputed } from '@state/helpers'
  export default {
    props: {
      id: {
        type: String,
        default: '',
      }
    },
    computed: {
      ...authComputed
    },
  }
</script>

<template>
  <div v-if="currentUser && currentUser.showId" :class="$style.devInfo">
    Dev: <slot />
  </div>
</template>

<style lang="scss" module>
  .devInfo {
  }
</style>
