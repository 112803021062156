"use strict";

let toExport = {};


toExport.setBadge = function({iconSrc, faviconSize=16, count=0, color='#FF0000', fontSize=10}) {
  if(!document) {
    return console.error('Only supported in browser, document not found');
  }

  let favicon = document.getElementById('favicon');
  let canvas = document.createElement('canvas');
  canvas.width = faviconSize;
  canvas.height = faviconSize;

  let context = canvas.getContext('2d');
  let img = document.createElement('img');
  img.src = iconSrc;

  img.onload = () => {
    context.drawImage(img, 0, 0, faviconSize, faviconSize);
    if(count>0) {
      context.beginPath();
      context.arc( canvas.width - faviconSize / 3 , faviconSize / 3, faviconSize / 3, 0, 2*Math.PI);
      context.fillStyle = '#FF0000';
      context.fill();

      context.font = fontSize+'px "helvetica", sans-serif';
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = '#FFFFFF';
      context.fillText(count, canvas.width - faviconSize / 3, faviconSize / 3);
    }
    favicon.href = canvas.toDataURL('image/png');
  }

};

module.exports = toExport;
