<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'
  import FcmEditor from "@/src/components/image/fcm-editor";
  import {companyService} from '@common/services';
  import SearchPick from '@components/diversos/searchPick'
  import {gmapApi} from 'vue2-google-maps'
  let {languages} = require('@i18n');


  export default {
    components: {FcmEditor, SearchPick},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        imageSrc: null,
        imageSrc2: null,
        uploadProgress: 0,
        imageBlob: null,
        imageBlob2: null,
        languages: languages,
        tryingToUpload: false,
        isLoading: true,
        isNew: true,

        form: {
          name: {},
          imgUrls: {},
          order: 0,
          active: true,
        },
        defaultForm: {
          name: {},
          imgUrls: {},
          order: 0,
          active: true,
        },
        oldCompanies: [],
        regionsList: [],


        markers: [],
        center: {lat:0, lng:0},
        areas: [],
        infoWindowPos: null,
        infoContent: '',
        infoWinOpen: false,
        areaToDelete: false,
        hasAddress: true,
        mapStarted: false

      }
    },
    validations: {
      form: {
      }
    },
    computed: {
      ...authComputed,
      google: gmapApi,
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        this.imageBlob2 = null;
        this.imageSrc2 = null;
        this.oldCompanies = [];
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      this.isLoading = false;
      let regionsObjP = firebase.firestore().collection('regions').get();
      let regionsList = [];
      (await regionsObjP).forEach((categoryObj) => {
        regionsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
      });
      this.regionsList = regionsList;
    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        this.center = {lat: -20.4695185, lng: -54.6203906};
        this.areas = [];

        let obj = await firebase.firestore().collection('hubs').doc(this.itemId).get();
        if(obj && obj.data()) {
          this.form = { ..._.cloneDeep(this.defaultForm), ...obj.data()};
          this.isNew = false;
          if(typeof this.form.name !== 'object') {
            this.form.name = {};
          }
          if(typeof this.form.imgUrls !== 'object') {
            this.form.imgUrls = {};
          }
          if(this.form.areas) {
            this.areas = this.form.areas;
          }
        }
        else {
          this.isNew = true;
          this.form = _.cloneDeep(this.defaultForm)
        }

        if(this.form.box && this.form.box.lat && this.form.box.lng) {
          this.center = {
            lat: this.form.box.lat,
            lng: this.form.box.lng
          };
        }

        this.oldCompanies = _.cloneDeep(this.form.companiesResume || []);
        console.log('loaded form', this.form);
        this.isLoading = false;
        this.loadMapDrawingManager();
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;
        form.order = parseInt(form.order || 1000) || 1000;
        form.active = !!form.active;
        form.deliveryHubDenied = !!form.deliveryHubDenied;

        form.contCompanies = 0;

        for(let company of this.oldCompanies || []) {
          let find = false;
          for(let company2 of form.companiesResume || []) {
            if(company.id===company2.id) {
              find = true;
              break;
            }
          }
          if(!find) {
            await firebase.firestore().collection('companies').doc(company.id).set({hubId: null},{merge: true});
          }
        }

        form.companies = [];
        for(let company of form.companiesResume || []) {
          form.contCompanies++;
          await firebase.firestore().collection('companies').doc(company.id).set({hubId: this.itemId},{merge: true});
          form.companies.push(company.id);
        }



        const box = {
          minLat: 200,
          minLng: 200,
          maxLat: -200,
          maxLng: -200
        };
        for(const area of this.areas) {
          for(const p of area.paths) {
            if(box.maxLat<p.lat) {
              box.maxLat=p.lat;
            }
            if(box.minLat>p.lat) {
              box.minLat=p.lat;
            }
            if(box.maxLng<p.lng) {
              box.maxLng=p.lng;
            }
            if(box.minLng>p.lng) {
              box.minLng=p.lng;
            }
          }
        }
        box.lat = box.minLat + ((box.maxLat - box.minLat)/2);
        box.lng = box.minLng + ((box.maxLng - box.minLng)/2);
        form.box = box;

        form.areas = this.areas;



        if(this.imageBlob || this.imageBlob2) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            if(this.imageBlob) {
              let url = await databaseUtils.uploadFile('hubs/'+this.itemId+'_dark', this.imageBlob, (progress) => {
                this.uploadProgress = progress/(this.imageBlob2?1:2);
              });
              if(url) {
                if(!form.imgUrls) {
                  form.imgUrls = {};
                }
                form.imgUrls.dark = url;
              }
            }
            if(this.imageBlob2) {
              let url = await databaseUtils.uploadFile('hubs/'+this.itemId+'_light', this.imageBlob2, (progress) => {
                this.uploadProgress = progress/(this.imageBlob?1:2);
              });
              if(url) {
                if(!form.imgUrls) {
                  form.imgUrls = {};
                }
                form.imgUrls.light = url;
              }
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          form.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          await firebase.firestore().collection('hubs').doc(this.itemId).set(form,{merge: false});
          this.$showSuccess();
          this.$emit('changeModel', null);
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      changeBlob2 (blob) {
        this.imageBlob2 = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      tryChangeImage2 () {
        this.$refs.fcmEditor2.insertImage();
      },
      removeImage() {
        this.form.imgUrls.dark = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      removeImage2() {
        this.form.imgUrls.light = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      async getCompanies(input) {
        return companyService.getCompanies({
          search: input.search,
          size: input.size,
          from: input.from,
          lang: this.$i18n.locale
        });
      },




      async addPolygon(event) {
        let pathsTmp = event.overlay.getPath().getArray();

        const {value: areaName} = await this.$swal({
          title: this.$t('form.getDeliveryAreaName'),
          input: 'text',
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            return !value && 'You need to write something!'
          }
        });

        if(!areaName) {
          event.overlay.setMap(null);
          throw this.$t('form.error.deliveryAreaName')
        }
        let id = firebase.firestore().collection('companies').doc().id;

        let paths = [];

        for (var j = 0; j < pathsTmp.length; j++) {
          paths.push({
            lat: pathsTmp[j].lat(),
            lng: pathsTmp[j].lng()
          })
        }
        event.overlay.setMap(null);
        this.areas.push({name:areaName, paths: paths, id: id });

      },

      loadMapDrawingManager() {
        if(this.mapStarted) {
          return;
        }
        this.mapStarted = true;
        let self = this;
        this.$refs.map.$mapPromise.then((mapObject) => {
          const drawingManager = new this.google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
              position: this.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [this.google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: {
              fillColor: '#e9342c',
              fillOpacity: 0.7,
              strokeColor: '#AA2143',
              strokeWeight: 4,
              editable: true,
              draggable: true
            }
          });
          drawingManager.setMap(mapObject);

          this.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
            // Get overlay paths
            // let paths = event.overlay.getPaths().getArray();
            // Remove overlay from map
            // event.overlay.setMap(null);

            // Disable drawingManager
            // drawingManager.setDrawingMode(null);

            // Create Polygon
            self.addPolygon(event);
          });
        });
      },
      pathChange (mvcArray, area) {
        for (let i=0; i<mvcArray.getLength(); i++) {
          let path = [];
          for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
            let point = mvcArray.getAt(i).getAt(j);
            path.push({lat: point.lat(), lng: point.lng()});
          }
          area.paths = path;
        }
      },
      showDescPolygon(event, area) {
        this.infoWindowPos = event.latLng;
        this.infoContent = this.$t('maps.areaDeliveryInfo')+ ': '+area.name+' ';
        this.infoWinOpen = true;
        this.areaToDelete = area
      },
      deleteArea() {
        if(this.areaToDelete) {
          let index = _.findIndex(this.areas, (o) => { return o.id === this.areaToDelete.id })
          if(index>=0) {
            this.areas.splice(index, 1);
          }
          this.infoWinOpen = false;
          this.areaToDelete = false;
        }
      },
      deleteAreaById(id) {
        console.log(id);
        if(id) {
          let index = _.findIndex(this.areas, (o) => { return o.id === id })
          if(index>=0) {
            this.areas.splice(index, 1);
          }
          this.infoWinOpen = false;
        }
      },



    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded">
          <div v-if="isNew" :class="$style.contentHeaderTitle">{{$t('all.create').toUpperCase()}}</div>
          <div v-if="!isNew" :class="$style.contentHeaderTitle">{{$t('all.edit').toUpperCase()}}</div>
          <BaseDev>{{itemId}}</BaseDev>

          <p>Dark</p>
          <div :class="$style.imagePreview" @click="tryChangeImage()" >
            <img :src="imageSrc || form.imgUrls.dark || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
          </div>
          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="1083"
                     :max-height="438"
                     :aspect-ratio="0"
                     @changeBlob="changeBlob"
          ></FcmEditor>

          <p>Light</p>
          <div :class="$style.imagePreview" @click="tryChangeImage2()" >
            <img :src="imageSrc2 || form.imgUrls.light || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage2()" ><i class="material-icons">close</i></div>
          </div>
          <FcmEditor ref="fcmEditor2" v-model="imageSrc2" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="1083"
                     :max-height="438"
                     :aspect-ratio="0"
                     @changeBlob="changeBlob2"
          ></FcmEditor>
          <div :class="$style.body">
            <div class="formItemSubSection"></div>
            <MdTabs >
              <MdTab v-for="(item, index) in languages"  :key="index+item.database"  :md-label="item.name" >
                <BaseInputText
                  v-model="form.name[item.database]"
                  :label="$t('form.name')"
                >
                </BaseInputText>
              </MdTab>
            </MdTabs>

            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.active')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>

            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.deliveryHubDenied')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.deliveryHubDenied" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
          </div>
          <SearchPick
                      v-model="form.companiesResume"
                      :get-items="getCompanies"
                      :label="$t('form.selectCompanies')"
                      :show-img="true"
                      :filter-region="true"
                      :limit="0"
          >
            <template #item="slotProp" >
              <div>{{slotProp.item.name}}</div>
            </template>
            <template #selected="slotProp" >
              <div>{{slotProp.item.name}}</div>
            </template>
          </SearchPick>

          <MdField :class="$style.formItemText">
            <label >{{$t('form.region')}}</label>
            <MdSelect v-model="form.regionId" name="region">
              <MdOption v-for="(value) in regionsList" :key="value.id" :value="value.id">{{value.nameView}}</MdOption>
            </MdSelect>
          </MdField>

          <div>
            {{$t('form.regionAreaInfo')}}
          </div>
          <GmapMap
            ref="map"
            :center="center"
            :zoom="13"
            map-type-id="terrain"
            :class="$style.map"
            :options="{
                 fullscreenControl: false,
               }"
          >
            <GmapMarker
              v-for="(m, index) in markers"
              :key="index"
              :ref="'mapMakerRef'+index"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :icon="{
                    url: require('@assets/images/map/img_map_pin_store.png'),
                    scaledSize: {height: 57, width: 36},
                    origin: {x:0, y:0},
                    anchor: {x:18, y:52}}"
              @click="center=m.position"
            />
            <GmapInfoWindow
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false"
            >
              <div :class="$style.infoBox">
                <div :class="$style.infoText">{{infoContent}}</div>
                <div :class="$style.infoButton">
                  <MdButton :class="['md-primary', 'md-raised']" @click="deleteArea()">
                    {{$t('form.delete')}}
                  </MdButton>
                </div>
              </div>
            </GmapInfoWindow>
            <GmapPolygon
              v-for="(area, index ) in areas" :key="index+'map'"
              :options="{fillColor: '#e9342c',
                    fillOpacity: 0.7,
                    strokeColor: '#AA2143',
                    strokeWeight: 4}"
              :draggable="true"
              :editable="true"
              :paths="area.paths"
              :deep-watch="true"
              @paths_changed="pathChange($event, area)"
              @click="showDescPolygon($event, area)"
            >

            </GmapPolygon>
          </GmapMap>
          <div :class="$style.areasContent">
            <table>
              <tr v-for="(value) in areas" :key="value.id">
                <td>{{value.name}}</td>
                <td></td>
                <td @click="deleteAreaById(value.id)"><MdIcon :class="$style.deleteArea">delete</MdIcon></td>
              </tr>
            </table>
          </div>

          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      .content {
        .contentLoaded {
          .imagePreview {
            width: 270px;
            height: 109px;
            img {
            }
          }
          .body {

          }

          .map {
            width: 100%; height: 700px
          }

          .areasContent {
            margin-top: 20px;
            .deleteArea {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>





















