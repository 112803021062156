<script>
  import { authComputed } from '@state/helpers'
  /* eslint-disable vue/no-v-html */
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'

  let {languages} = require('@i18n');

  export default {
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        imageBlob: null,
        languages: languages,
        tryingToUpload: false,
        isLoading: true,
        isNew: true,

        form: {
          name: '',
        },
        defaultForm: {
          name: '',
        },
        listType: ['fixCompanyBags', 'fixProducts', 'fixCompanies', 'algoliaCompanies', 'algoliaProducts',
                   'fixInfosProducts', 'fixCompaniesClaims', 'fixAdminClaims', 'algoliaUsers', 'fixRegionsUsers',
                   'fixRegionsCompanies', 'rebuildBalance', 'rebuildResume', 'fixUsersProviders' ],

        unsubscribeObserver: null,

      }
    },
    validations: {
      form: {
      }
    },
    computed: {
      ...authComputed,
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      this.isLoading = false;
    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;


        if(typeof this.unsubscribeObserver === 'function') {
          this.unsubscribeObserver();
          this.unsubscribeObserver = null;
        }

        this.isNew = true;
        this.form = _.cloneDeep(this.defaultForm);
        this.unsubscribeObserver = firebase.firestore().collection('jobs').doc(this.itemId).onSnapshot(async (snapshot) => {
          // snapshot.docChanges().forEach((change) => {
          if(snapshot.exists) {
            this.lastOrderCode = (snapshot.data() || {}).orderCode;
            let form = { ...this.defaultForm, ...snapshot.data(), id: snapshot.id};
            form.startedAt = (typeof form.startedAt==='object')?moment(form.startedAt.toDate()).format(this.$t('format.data')+' HH:mm'):'';
            form.finishedAt = (typeof form.finishedAt==='object')?moment(form.finishedAt.toDate()).format(this.$t('format.data')+' HH:mm'):'';

            this.isNew = false;
            console.log('loaded form', form);
            this.form = form;

          }
          else {
            console.log('debug, entrou no else', snapshot);
          }
        });

        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;
        form.order = parseInt(form.order || 1000) || 1000;
        form.active = !!form.active;

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('jobs/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          form.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          await firebase.firestore().collection('jobs').doc(this.itemId).set(form,{merge: false});
          this.$showSuccess();
         // this.$emit('changeModel', null);
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">
          <div v-if="isNew" :class="$style.contentHeaderTitle">{{$t('all.create').toUpperCase()}}</div>
          <div v-if="!isNew" :class="$style.contentHeaderTitle">{{$t('all.edit').toUpperCase()}}</div>
          <BaseDev>{{itemId}}</BaseDev>

          <!-- <div :class="$style.imagePreview" @click="tryChangeImage()" >
            <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
          </div>
          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="180"
                     :max-height="180"
                     :aspect-ratio="0"
                     @changeBlob="changeBlob"
          ></FcmEditor> -->
          <div :class="$style.body">
            <div class="formItemSubSection"></div>

            <div class="formItem">
              <MdField >
                <label >{{$t('jobs.type')}}</label>
                <MdSelect v-model="form.type">
                  <MdOption v-for="item of listType" :key="item" :value="item">{{item}}</MdOption>
                </MdSelect>
              </MdField>
            </div>
            <div class="formItem">
              <MdField :class="[getValidationClass('name'), $style.formItemText]">
                <label>{{$t('jobs.name')}}</label>
                <MdInput v-model="form.name"></MdInput>
              </MdField>
            </div>
            <div class="formItem">
              <MdField :class="[getValidationClass('arg'), $style.formItemText]">
                <label>{{$t('jobs.arg')}}</label>
                <MdInput v-model="form.arg"></MdInput>
              </MdField>
            </div>
            <pre><code class="language-json" v-html="form"></code></pre>
            <br>

          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      .content {
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            pre {
              white-space: pre-wrap;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
</style>





















