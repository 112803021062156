<script>
  import { authComputed, authMethods} from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'
  import FcmEditor from "@/src/components/image/fcm-editor";
  import {gmapApi} from 'vue2-google-maps';
  import Multiselect from 'vue-multiselect';
  let {languages} = require('@i18n');
  const geoUtils = require ('@common/geo_utils');
  const timeUtils = require ('@common/time_utils');
  const stringUtils = require ('@common/string_utils');

  export default {
    components: {FcmEditor, Multiselect},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      },
      isSuperAdmin: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        imageBlob: null,
        languages: languages,
        tryingToUpload: false,
        isLoading: true,
        form: {
          primaryColor: { r: 22, g: 158, b: 101, a: 1 },
          secondaryColor: { r: 255, g: 255, b: 255, a: 1 },
          address: {},
          logoImgUrl: null,
          active: false,
          name: '',
          ranking: 0,
          disableAutoIsOpen: false
        },
        defaultForm: {
          primaryColor: { r: 22, g: 158, b: 101, a: 1 },
          secondaryColor: { r: 255, g: 255, b: 255, a: 1 },
          address: {},
          logoImgUrl: null,
          active: false,
          name: '',
          ranking: 0,
          disableAutoIsOpen: false
        },

        categories: [],
        tagList: [],
        tags: [],

        address: '',
        addressList: [],
        lastSearchTime: null,
        term: '',
        addressValue: '',
        changeAddress: false,
        markers: []

      }
    },
    validations: {
      form: {
        governmentDocumentId: {
          required,
          minLength: minLength(4)
        },
        name: {
          required,
          minLength: minLength(4)
        },
        corporateName: {
          required,
          minLength: minLength(4)
        }
      }
    },
    computed: {
      ...authComputed,
      google: gmapApi
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      this.isLoading = true;
      let tagsObjP = firebase.firestore().collection('tags').where('isCompany', '==', true).get();
      let categoriesObjP = firebase.firestore().collection('categories').get();

      let categoriesTmp = [];
      (await categoriesObjP).forEach((categoryObj) => {
        let cat = categoryObj.data();
        cat.id = categoryObj.id;
        cat.nameView= this.$trdb(cat.name);
        categoriesTmp.push(cat);
      });

      this.categories = _.sortBy(categoriesTmp, 'nameView');

      let tmp = [];
      (await tagsObjP).forEach((categoryObj) => {
        tmp.push({...categoryObj.data(),id:categoryObj.id, nameView:this.$trdb((categoryObj.data()||{}).name||{})})
      });
      this.tagList = _.sortBy(tmp, 'nameView');

      if(this.itemId) {
        await this.loadForm();
      }
      this.isLoading = false;
    },

    beforeDestroy: function() {
    },
    methods: {
      ...authMethods,
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.address= '';
        this.addressList= [];
        this.lastSearchTime= null;
        this.term= '';
        this.addressValue= '';
        this.changeAddress= false;
        this.markers= [];
        this.hasError = null;


        this.form = _.cloneDeep(this.defaultForm);
        let obj = await firebase.firestore().collection('companies').doc(this.itemId).get();

        if(obj && obj.data()) {
          this.form = { ..._.cloneDeep(this.defaultForm), ...obj.data()};
        }
        else {
          console.error('Empresa não encontrada');
          return;
        }
        if(this.form.address) {
          // this.address = geoUtils.addressDb2Resume(this.form.address)
        }
        if(this.form.address && this.form.address.latitude && this.form.address.longitude) {
          this.markers = [{
            position: {lat: this.form.address.latitude, lng: this.form.address.longitude}
          }]
        }

        let tmp = [];
        this.form.tagsId = this.form.tagsId || [];
        if(this.form.categoryId && this.categories[this.form.categoryId] && this.categories[this.form.categoryId].tagId) {
          if(!this.form.tagsId.find((a)=>a===this.categories[this.form.categoryId].tagId)) {
            this.form.tagsId.push(this.categories[this.form.categoryId].tagId);
          }
        }
        for(let tagId of this.form.tagsId || [] ) {
          let tag = this.tagList.find(a=>a.id===tagId);
          if(tag) {
            tmp.push({...tag, nameView: this.$trdb(tag.name)});
          }
        }
        this.tags = tmp;

        if(this.form.phone) {
          this.form.phone = this.form.phone.replace('+55', '');
        }

        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;

        // TODO
        form.active = !!form.active;
        form.isNew = !!form.isNew ;
        form.isTest = !!form.isTest ;
        form.disableAutoIsOpen = !!form.disableAutoIsOpen;
        form.rating = parseFloat(form.rating || 0);

        form.uniqueName = stringUtils.username(form.uniqueName);

        if(form.phone) {
          form.phone = form.phone.replace('+55', '');
          form.phone = '+55'+form.phone.replace(/[^\d]/g, '');
        }

        form.tags = [];
        form.tagsId = [];

        delete form.category;
        if(form.categoryId) {
          form.category = _.cloneDeep(this.categories.find((a)=>{return a.id===form.categoryId;}));
          if(form.category && form.category.nameView) {
            delete form.category.nameView;
          }
          if(!form.category) {
            delete form.category;
            delete form.categoryId;
          }
        }

        if(form.category && form.category.tagId) {
          if(!this.tags.find((a)=>a.id===form.category.tagId)) {
            let tagTmp = this.tagList.find((a)=>{return a.id===form.category.tagId});
            if(tagTmp) {
              form.tags.push({id: tagTmp.id,name:tagTmp.name || '',logoImgUrl:tagTmp.logoImgUrl || '', showOnMenu: tagTmp.showOnMenu || false});
              form.tagsId.push(tagTmp.id);
            }
          }
        }

        for(let tag of this.tags) {
          form.tagsId.push(tag.id);
          form.tags.push({id: tag.id,name:tag.name || '',logoImgUrl:tag.logoImgUrl || '', showOnMenu: tag.showOnMenu || false});
        }

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('companiesLogo/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.logoImgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          form.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          console.log('Update', form);

          await this.modelCompanyUpdate({form: form, itemId: this.itemId, isSuperAdmin: this.isSuperAdmin});
          this.$showSuccess();
        } catch(error) {
          console.error('Erro ao atualizar company', error);
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.logoImgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },

      async getListAddress(input){
        if(!input) {
          this.lastSearchTime = Date.now();
          this.addressList = Promise.resolve([]);
          return;
        }

        let resolveLocal = null;
        let rejectLocal = null;
        this.addressList = new Promise((resolve, reject)=>{
          resolveLocal = resolve;
          rejectLocal = reject;
        });

        let time = Date.now();
        this.lastSearchTime = time;
        await timeUtils.delay(650);

        if(this.lastSearchTime !== time) { // ignore that search
          return null;
        }

        console.log('search: ', input);
        let result = await firebase.functions().httpsCallable('maps-search')({term:input, language: this.$i18n.locale});
        if(this.lastSearchTime !== time) { // ignore that search
          return null;
        }
        if(result.data && !result.data.length) {
          this.$showError({title: this.$t('form.address.emptyResult')});
          rejectLocal();
        }
        else {
          resolveLocal(result.data || []);
          this.addressList = Promise.resolve(result.data || []);
        }
      },
      async addressSelect(input) {
        console.log('Select ', input);
        this.changeAddress = true;
        if(!input || !input.place_id) {
          this.address = '';
          return
        }
        this.address = input.description;
        let result = await firebase.functions().httpsCallable('maps-details')({placeId:input.place_id, language: this.$i18n.locale});

        if(!result.data) {
          this.address = '';
          return;
        }

        this.form.address = geoUtils.googleAddress2db(result.data, this.form.address);
        console.log('this.form', this.form);
        this.address = input.description;

        if(this.form.address && this.form.address.latitude && this.form.address.longitude) {
          this.markers = [{
            position: {lat: this.form.address.latitude, lng: this.form.address.longitude}
          }]
        }
      },
      addressWarning() {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'warning',
          title: this.$t('form.address.fieldWarning'),
          showConfirmButton: false,
          timer: 5000
        });

        this.$refs.addressInput.$el.querySelector('input').focus();
      },
      mapDragEnd(input) {
        if(input && input.latLng && this.form.address && input.latLng.lat() && input.latLng.lng()) {
          this.form.address.latitude = input.latLng.lat();
          this.form.address.longitude = input.latLng.lng();
        }
      },
      alertNeedApproved(value) {
        if(!this.form.approved && value) {
          this.$showError({title:this.$t('form.error.companyNeedApproved')});
          this.form.active = false;
        }
      },
      alertNeedApprovedIugu(value) {
        if(!this.form.approved && value) {
          this.$showError({title:this.$t('form.error.companyNeedApproved')});
          this.form.availableForIugu = false;
        }
      },
      alertOnlyAdmin() {
        if(!this.isSuperAdmin) {
          this.$showError({title:this.$t('form.error.changeOnlyAdmin')});
        }
      }
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>

          <div :class="$style.imagePreview" @click="tryChangeImage()" >
            <img :src="imageSrc || form.logoImgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
          </div>
          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="310"
                     :max-height="310"
                     :aspect-ratio="1"
                     fill-color="#ffffff"
                     mime-type="image/jpeg"
                     format="circle"
                     :quality-argument="0.75"
                     @changeBlob="changeBlob"

          ></FcmEditor>
          <div :class="$style.body">
            <div v-if="form.name" :class="$style.restaurantName">{{form.name}}</div>

            <BaseInputText
              v-model="form.governmentDocumentId"
              v-mask="['##.###.###/####-##']"
              :label="$t('form.cnpj')"
              :class="getValidationClass('governmentDocumentId')"
            >
              <span v-if="!$v.form.governmentDocumentId.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.governmentDocumentId.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
            </BaseInputText>

            <BaseInputText
              v-model="form.name"
              :label="$t('form.companyName')"
              :class="getValidationClass('name')"
            >
              <span v-if="!$v.form.name.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.name.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
            </BaseInputText>

            <BaseInputText
              v-model="form.corporateName"
              :label="$t('form.corporateName')"
              :class="getValidationClass('corporateName')"
            >
              <span v-if="!$v.form.corporateName.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.corporateName.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
            </BaseInputText>

            <BaseInputText
              v-model="form.uniqueName"
              :label="$t('form.uniqueName')"
            >
            </BaseInputText>
<!--
            <FcmColorPick v-model="form.primaryColor">{{$t('form.changePrimaryColor')}}</FcmColorPick>
            <br>
            <FcmColorPick v-model="form.secondaryColor">{{$t('form.changeSecondaryColor')}}</FcmColorPick> -->

            <br>

            <BaseInputText
              v-model="form.phone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              :label="$t('form.phone')"
            >
            </BaseInputText>
            <BaseInputText
              v-model="form.contact"
              :label="$t('form.contact')"
            >
            </BaseInputText>
            <BaseInputText
              v-model="form.description"
              :label="$t('form.description')"
            >
            </BaseInputText>


            <br>

            <MdField>
              <label >{{$t('all.category')}}</label>
              <MdSelect v-model="form.categoryId" name="category">
                <MdOption v-for="(item) in categories" :key="item.id" :value="item.id">{{item.nameView}}</MdOption>
              </MdSelect>
            </MdField>

            <Multiselect v-model="tags" :options="tagList" track-by="id" label="nameView" :multiple="true"
                         :placeholder="$t('product.placeholderTags')"
                         :select-label="$t('multiSelect.selectLabel')"
                         :select-group-label="$t('multiSelect.selectGroupLabel')"
                         :selected-label="$t('multiSelect.selectedLabel')"
                         :deselect-label="$t('multiSelect.deselectLabel')"
                         :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
            >
            </Multiselect>

            <MdAutocomplete ref="addressInput" v-model="address" :md-options="addressList" :value="addressValue" autocomplete="no" @md-changed="getListAddress" @md-opened="getListAddress" @md-selected="addressSelect" >
              <label>{{$t('form.address.search')}}</label>
              <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item.description }}</template>
            </MdAutocomplete>


            <MdField :class="[$style.addressItemLock]" :event="''" @click.native="addressWarning">
              <label>{{$t('form.address.street')}}</label>
              <MdInput v-model="form.address.street" type="text" :disabled="true" ></MdInput>
            </MdField>
            <MdField :class="[$style.addressItemLock]" :event="''" @click.native="addressWarning">
              <label>{{$t('form.address.district')}}</label>
              <MdInput v-model="form.address.neighborhood" type="text" :disabled="true" ></MdInput>
            </MdField>
            <div :class="$style.addressCityState">
              <MdField :class="[$style.addressItemLock, $style.addressCity]" :event="''" @click.native="addressWarning">
                <label>{{$t('form.address.city')}}</label>
                <MdInput v-model="form.address.city" type="text" :disabled="true" ></MdInput>
              </MdField>
              <MdField :class="[$style.addressItemLock, $style.addressState]" :event="''" @click.native="addressWarning">
                <label>{{$t('form.address.state')}}</label>
                <MdInput v-model="form.address.state" type="text" :disabled="true" ></MdInput>
              </MdField>
            </div>
            <MdField :class="[$style.addressItemLock]" :event="''" @click.native="addressWarning">
              <label>{{$t('form.address.country')}}</label>
              <MdInput v-model="form.address.country" type="text" :disabled="true" ></MdInput>
            </MdField>
            <div :class="$style.addressNumberComplement">
              <MdField :class="[$style.addressItem, $style.addressNumber]">
                <label>{{$t('form.address.number')}}</label>
                <MdInput v-model="form.address.number" type="number" ></MdInput>
              </MdField>
              <MdField :class="[$style.addressItem, $style.addressComplement]">
                <label>{{$t('form.address.complement')}}</label>
                <MdInput v-model="form.address.complement" type="text" ></MdInput>
              </MdField>
            </div>

            <GmapMap
              ref="mapRef"
              :center="{lat:form.address.latitude || -20.4637867, lng:form.address.longitude || -54.616342}"
              :zoom="15"
              map-type-id="terrain"
              style="max-width: 800px; height: 305px"
            >
              <GmapMarker
                v-for="(m, index) in markers"
                :key="index"
                :ref="'mapMakerRef'+index"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                :icon="{
                    url: require('@assets/images/map/img_map_pin_store.png'),
                    scaledSize: {height: 57, width: 36},
                    origin: {x:0, y:0},
                    anchor: {x:18, y:52}}"
                @click="center=m.position"
                @dragend="mapDragEnd"
              />
            </GmapMap>

            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.active')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction" >
                <MdSwitch v-model="form.active" class="md-primary" :value="true" @change="alertNeedApproved"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem " @click.capture="alertOnlyAdmin">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.availableForIugu')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction" >
                <MdSwitch v-model="form.availableForIugu" class="md-primary" :value="true" :disabled="!isSuperAdmin" @change="alertNeedApprovedIugu"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem " @click.capture="alertOnlyAdmin">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.approved')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.approved" class="md-primary" :value="true" :disabled="!isSuperAdmin"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div v-show="isSuperAdmin" class="formItem ">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.isTest')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.isTest" class="md-primary" :value="true" :disabled="!isSuperAdmin" ></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem" @click.capture="alertOnlyAdmin">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.disableAutoIsOpen')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.disableAutoIsOpen" class="md-primary" :value="true" :disabled="!isSuperAdmin"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem" @click.capture="alertOnlyAdmin">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.isNew')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.isNew" class="md-primary" :value="true" :disabled="!isSuperAdmin"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>

          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              v-show="isSuperAdmin"
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      .content {
        max-width: 800px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            .restaurantName {
              font-size: 32px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.25;
              letter-spacing: normal;
              color: #333333;
              margin-bottom: 20px;
            }

            .progressBar {
              margin-bottom: 20px;
            }

            .addressItem {
              margin-left: 12px;
            }

            .addressItemLock {
              margin-left: 12px;

            }
            .addressNumberComplement {
              display: flex;
              .addressNumber {
                flex: 2;
                width: 60px;
              }
              .addressComplement {
                flex: 8;
              }
            }
            .addressCityState {
              display: flex;
              .addressCity {
                flex: 8;
              }
              .addressState {
                flex: 2;
                width: 60px;
              }
            }
          }
        }
      }
    }
  }
</style>





















