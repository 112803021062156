import store from '@state/store'
import defaults from '@/src/router/defaults'


export default [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@views/visitant/home')),
  },
  {
    path: '/login',
    redirect: { name: 'company/login' }
  },
  {
    path: '/register',
    redirect: { name: 'company/register' }
  },




  // COMPANY
  {
    path: '/company/register',
    name: 'company/register',
    component: () => lazyLoadView(import('@views/company/register')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          // Continue to the login page
          next()
        }
      },
    }
  },
  {
    path: '/company/profile',
    name: 'company/profile',
    component: () => lazyLoadView(import('@views/company/company_profile')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY'
    },
  },


  {
    path: '/company/promotions/:id?',
    name: 'company/promotions',
    component: () => lazyLoadView(import('@views/company/promotions')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY'
    },
  },

  /* {
     path: '/company/promotion/:id?',
     name: 'company/promotion',
     component: () => lazyLoadView(import('@views/company/promotion')),
     meta: {
       authRequired: true,
       workspace: 'COMPANY'
     },
   },

   {
     path: '/company/promotions/:id?',
     name: 'company/promotions',
     component: () => lazyLoadView(import('@views/generic/generic_list')),
     meta: {
       authRequired: true,
       workspace: 'COMPANY',
       database: {
         name: 'companiesPromotions',
         editPath: 'company/promotion',
         filterCompany: true,
         attributes: [{
           database: 'description.base',
           name: 'form.description'
         }, {
           database: 'discountPercent',
           name: 'promotion.discountPercent'
         }, {
           database: 'discountValue',
           name: 'promotion.discountValue'
         },
         ]
       }
     },
   },
   */


  // {
  //   path: '/company/users',
  //   name: 'company/users',
  //   component: () => lazyLoadView(import('@views/company/users')),
  //   meta: {
  //     authRequired: true,
  //     workspace: 'COMPANY'
  //   },
  // },
  {
    path: '/company/users/:id?',
    name: 'company/users',
    component: () => lazyLoadView(import('@views/company/users')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY'
    },
  },
  {
    path: '/company',
    redirect: { name: 'company/login' }
  },
  {
    path: '/company/login',
    name: 'company/login',
    component: () => lazyLoadView(import('@views/company/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/company/schedule/:id?',
    name: 'company/schedule',
    component: () => lazyLoadView(import('@views/company/schedule')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY'
    },
  },
  {
    path: '/company/delivery_options',
    name: 'company/delivery_options',
    component: () => lazyLoadView(import('@views/company/delivery_options')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY'
    },
  },
  {
    path: '/company/other_options',
    name: 'company/other_options',
    component: () => lazyLoadView(import('@views/company/other_options')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY'
    },
  },
  {
    path: '/company/printer_options',
    name: 'company/printer_options',
    component: () => lazyLoadView(import('@views/company/printer_options')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY'
    },
  },

  {
    path: '/company/products/:id?',
    name: 'company/products',
    component: () => lazyLoadView(import('@views/company/products')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },


  {
    path: '/company/products_categories/:id?',
    name: 'company/products_categories',
    component: () => lazyLoadView(import('@views/company/categories')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },
  {
    path: '/company/products_sub_categories/:id?',
    name: 'company/products_sub_categories',
    component: () => lazyLoadView(import('@views/company/sub_categories')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },
  {
    path: '/company/orders/:id?',
    name: 'company/orders',
    component: () => lazyLoadView(import('@views/company/orders')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },
  {
    path: '/company/additionals/:type?/:id?',
    name: 'company/additionals',
    component: () => lazyLoadView(import('@views/company/additionals')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },
  {
    path: '/company/payment_options',
    name: 'company/payment_options',
    component: () => lazyLoadView(import('@views/company/payment_options')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
      rule: ['adm']
    },
  },
  {
    path: '/company/history',
    name: 'company/history',
    component: () => lazyLoadView(import('@views/company/history')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
      rule: ['adm']
    },
  },





  // ONG
  {
    path: '/ong/register',
    name: 'ong/register',
    component: () => lazyLoadView(import('@views/ong/register')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          // Continue to the login page
          next()
        }
      },
    }
  },
  {
    path: '/ong/profile',
    name: 'ong/profile',
    component: () => lazyLoadView(import('@views/ong/ong_profile')),
    meta: {
      authRequired: true,
      workspace: 'ONG'
    },
  },
  {
    path: '/ong/users/:id?',
    name: 'ong/users',
    component: () => lazyLoadView(import('@views/ong/users')),
    meta: {
      authRequired: true,
      workspace: 'ONG'
    },
  },
  {
    path: '/ong',
    redirect: { name: 'ong/login' }
  },
  {
    path: '/ong/login',
    name: 'ong/login',
    component: () => lazyLoadView(import('@views/ong/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },




  // CLIENT

  {
    path: '/client',
    redirect: { name: 'client/login' }
  },
  {
    path: '/client/login',
    name: 'client/login',
    component: () => lazyLoadView(import('@views/client/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          // Continue to the login page
          next()
        }
      },
      supperTestFCM: true
    },
  },
  {
    path: '/client/profile',
    name: 'client/profile',
    component: () => lazyLoadView(import('@views/client/profile_edit')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT'
    },
  },
  {
    path: '/client/test_bag',
    name: 'client/test_bag',
    component: () => lazyLoadView(import('@views/client/test_bag')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT'
    },
  },



// ADMIN
  {
    path: '/admin',
    redirect: { name: 'admin/login' }
  },
  {
    path: '/admin/login',
    name: 'admin/login',
    component: () => lazyLoadView(import('@views/admin/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: defaults.routeLogged[store.getters['auth/loggedIn']] })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/admin/categories/:id?',
    name: 'admin/categories',
    component: () => lazyLoadView(import('@views/admin/categories')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/products_ingredients/:id?',
    name: 'admin/products_ingredients',
    component: () => lazyLoadView(import('@views/admin/products_ingredients')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/cards/:id?',
    name: 'admin/cards',
    component: () => lazyLoadView(import('@views/admin/cards')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/users/:id?',
    name: 'admin/users',
    component: () => lazyLoadView(import('@views/admin/users')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/users_clients/:id?',
    name: 'admin/users_clients',
    component: () => lazyLoadView(import('@views/admin/users_clients')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm']
    },
  },
  {
    path: '/admin/language_tool',
    name: 'admin/language_tool',
    component: () => lazyLoadView(import('@views/admin/language_tool')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/tags/:id?',
    name: 'admin/tags',
    component: () => lazyLoadView(import('@views/admin/tags')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/products_nutritional/:id?',
    name: 'admin/products_nutritional',
    component: () => lazyLoadView(import('@views/admin/products_nutritional')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/jobs/:id?',
    name: 'admin/jobs',
    component: () => lazyLoadView(import('@views/admin/jobs')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm']
    },
  },
  {
    path: '/admin/regions/:id?',
    name: 'admin/regions',
    component: () => lazyLoadView(import('@views/admin/regions')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/blog/:id?',
    name: 'admin/blog',
    component: () => lazyLoadView(import('@views/admin/blog')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/faq/:id?',
    name: 'admin/faq',
    component: () => lazyLoadView(import('@views/admin/faq')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/pages/:id?',
    name: 'admin/pages',
    component: () => lazyLoadView(import('@views/admin/pages')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/blog_categories/:id?',
    name: 'admin/blog_categories',
    component: () => lazyLoadView(import('@views/admin/blog_categories')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/delivery_options/:id?',
    name: 'admin/delivery_options',
    component: () => lazyLoadView(import('@views/admin/delivery_options')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/iugu_accounts/:id?',
    name: 'admin/iugu_accounts',
    component: () => lazyLoadView(import('@views/admin/iugu_accounts')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },
  {
    path: '/admin/orders/:id?',
    name: 'admin/orders',
    component: () => lazyLoadView(import('@views/admin/orders')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/exports',
    name: 'admin/exports',
    component: () => lazyLoadView(import('@views/admin/exports')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/companies/:id?',
    name: 'admin/companies',
    component: () => lazyLoadView(import('@views/admin/companies')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/notifications/:id?',
    name: 'admin/notifications',
    component: () => lazyLoadView(import('@views/admin/notifications')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/ongs/:id?',
    name: 'admin/ongs',
    component: () => lazyLoadView(import('@views/admin/ongs')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/hubs/:id?',
    name: 'admin/hubs',
    component: () => lazyLoadView(import('@views/admin/hubs')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/ad_cards/:id?',
    name: 'admin/ad_cards',
    component: () => lazyLoadView(import('@views/admin/ad_cards')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/history',
    name: 'admin/history',
    component: () => lazyLoadView(import('@views/admin/history')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN'
    },
  },






  // GENERIC
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => lazyLoadView(import('@views/resetPassword')),
    meta: {

    },
  },





  // VISITANT

  {
    path: '/post/:id?',
    name: 'post',
    component: () => lazyLoadView(import('@views/visitant/post')),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/page/:id?',
    name: 'page',
    component: () => lazyLoadView(import('@views/visitant/page')),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/blog/:id?',
    name: 'blog',
    component: () => lazyLoadView(import('@views/visitant/blog')),
    meta: {
      authRequired: false,
    },
  },





  /* {
    path: '/profile',
    name: 'profile',
    component: () => lazyLoadView(import('@views/old/profile')),
    meta: {
      authRequired: true,
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  }, */
  {
    path: '/profile/:username',
    name: 'username-profile',
    component: () => lazyLoadView(import('@views/old/profile')),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store
          // Try to fetch the user's information by their username
          .dispatch('users/fetchUser', { username: routeTo.params.username })
          .then((user) => {
            // Add the user to the route params, so that it can
            // be provided as a prop for the view component below.
            routeTo.params.user = user
            // Continue to the route.
            next()
          })
          .catch(() => {
            // If a user with the provided username could not be
            // found, redirect to the 404 page.
            next({ name: '404', params: { resource: 'User' } })
          })
      },
    },
    // Set the user from the route params, once it's set in the
    // beforeResolve route guard.
    props: (route) => ({ user: route.params.user }),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        let routeTmp = defaults.routeLogOut[store.getters['auth/loggedIn']]
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: routeTmp || 'login' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => lazyLoadView(import('@views/_404')),
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    name: '*',
   // redirect: '404',
    component: () => lazyLoadView(import('@views/_404')),
  },
  {
    path: '/test',
    name: 'test',
    component: () => lazyLoadView(import('@views/test'))
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView, params = {}) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading'),
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout'),
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
