"use strict";
const _ = require('lodash');
const moment = require('moment');

// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};


toExport.delay = ms => new Promise((resolve) => setTimeout(resolve, ms));


toExport.minutes2field = function(sc) {
  if(!sc && sc!==0) {
    return '';
  }

  return (sc<0?'-':'')+_.padStart(Math.abs(Math.floor((sc||0)/60)) || 0, 2, '00')+':'+_.padStart((sc||0)%60 || 0, 2, '00')
};

toExport.field2minutes = function(field) {
  let sp = (field ||'').split(':');
  let ret = parseInt(sp[0] || 0) * 60;
  ret +=parseInt(sp[1] || 0);
  return ret;
};


toExport.minutes2schedule = function(sc) {
  if(!sc || sc ==='') {
    return {ini: '', end: ''};
  }
  sc.ini = toExport.minutes2field(sc.ini);
  sc.end = toExport.minutes2field((sc.end<=1440)?sc.end:sc.end-1440);
  return sc;
};

toExport.schedule2minutes = function(field) {
  if(!field || !field.ini || !field.end) {
    return {
      ini: null,
      end: null
    };
  }
  let ini = toExport.field2minutes(field.ini);
  let end = toExport.field2minutes(field.end);

  if(ini>1440) {
    ini=1440;
  }
  if(end>1440) {
    end=1440;
  }

  return {
    ini: ini,
    end: (end>=ini)? end: end+1440
  };
};

function companyShouldBeOpenAux(obj, week, minutes) {
  if(obj) {
    let lastWeek = week-1;
    if(lastWeek<0) {
      lastWeek = 6;
    }

    if(obj[week]) {
      for(let scale in obj[week]) {
        if(minutes>obj[week][scale].ini && minutes<obj[week][scale].end) {
          return true;
        }
      }
    }
    minutes += 1440;
    if(obj[lastWeek]) {
      for(let scale in obj[lastWeek]) {
        if(minutes>obj[lastWeek][scale].ini && minutes<obj[lastWeek][scale].end) {
          return true;
        }

      }
    }
  }
  return false;
}

/**
 *
 * @param company
 * @param now
 * @returns {boolean}
 * @deprecated
 */
toExport.companyShouldBeOpen = function(company, now = moment()) {
  if(!company.schedule) {return false;}

  let week = now.format('e');
  let minutes = now.get('hour')*60 + now.get('minute');

  let needOpen = false;
  if(company.schedule.delivery) {
    needOpen = companyShouldBeOpenAux(company.schedule.delivery, week, minutes);
  }
  if(!needOpen && company.schedule.onSite) {
    needOpen = companyShouldBeOpenAux(company.schedule.onSite, week, minutes);
  }
  if(!needOpen && company.schedule.takeout) {
    needOpen = companyShouldBeOpenAux(company.schedule.takeout, week, minutes);
  }
  return needOpen;
};


toExport.companyChangeOpen = function(company, now = moment()) {
  if(!company.schedule) {return false;}

  let week = parseInt(now.format('e'));
  let minutes = now.get('hour')*60 + now.get('minute');

  let needOpen = false;
  let ret = {
    mustUpdate: false
  };

  if(company.schedule.delivery && (!company.acceptDeliveryLockAuto || now>=moment(company.acceptDeliveryLockAuto.toDate()))) {
    needOpen = companyShouldBeOpenAux(company.schedule.delivery, week, minutes);
    if(company.acceptDelivery && !needOpen) {
      ret.acceptDelivery = false;
      company.acceptDelivery = false;
      ret.mustUpdate = true;
    }
    if(!company.acceptDelivery && needOpen && company.acceptDeliveryEnable) {
      ret.acceptDelivery = true;
      company.acceptDelivery = true;
      ret.mustUpdate = true;
    }
  }

  if(company.schedule.onSite && (!company.acceptOnSiteLockAuto || now>=moment(company.acceptOnSiteLockAuto.toDate()))) {
    needOpen = companyShouldBeOpenAux(company.schedule.onSite, week, minutes);
    if(company.acceptOnSite && !needOpen) {
      ret.acceptOnSite = false;
      company.acceptOnSite = false;
      ret.mustUpdate = true;
    }
    if(!company.acceptOnSite && needOpen && company.acceptOnSiteEnable) {
      ret.acceptOnSite = true;
      company.acceptOnSite = true;
      ret.mustUpdate = true;
    }
  }

  if(company.schedule.takeout && (!company.acceptTakeoutLockAuto || now>=moment(company.acceptTakeoutLockAuto.toDate()))) {
    needOpen = companyShouldBeOpenAux(company.schedule.takeout, week, minutes);
    if(company.acceptTakeout && !needOpen) {
      ret.acceptTakeout = false;
      company.acceptTakeout = false;
      ret.mustUpdate = true;
    }
    if(!company.acceptTakeout && needOpen && company.acceptTakeoutEnable) {
      ret.acceptTakeout = true;
      company.acceptTakeout = true;
      ret.mustUpdate = true;
    }
  }
  if((company.acceptTakeout || company.acceptOnSite || company.acceptDelivery) && !company.isOpen) {
    ret.isOpen = true;
    ret.mustUpdate = true;
  }
  else if(!(company.acceptTakeout || company.acceptOnSite || company.acceptDelivery) && company.isOpen) {
    ret.isOpen = false;
    ret.mustUpdate = true;
  }

  return ret;
};


toExport.companyNextClose = function(company, type, field, now = moment()) {
  if(!company.schedule) {return false;}

  let weekBase = parseInt(now.format('e'));
  let lastWeekBase = (weekBase-1)%7;
  let minutes = now.get('hour')*60 + now.get('minute');
  let minutesTmp = minutes+1440;
  let weekCont = 0;

  let minWeek = -1;
  let minValue = 99999;
  if(company.schedule[type]) {
   // needOpen = companyShouldBeOpenAux(company.schedule.delivery, week, minutes);

    let obj = company.schedule[type];

    if(obj[weekBase]) {
      for(let scale in obj[weekBase]) {
        if(obj[weekBase][scale][field]===null) {
          continue;
        }
        let timeTmp = obj[weekBase][scale][field]-minutes;
        if(timeTmp>=0) {
          if (timeTmp < minValue) {
            minWeek = weekBase;
            minValue = timeTmp;
          }
        }
      }
    }
    if(obj[lastWeekBase]) {
      for(let scale in obj[lastWeekBase]) {
        if(obj[lastWeekBase][scale][field]<1440 || obj[lastWeekBase][scale][field]===null){
          continue;
        }
        let timeTmp2 = obj[lastWeekBase][scale][field]-minutesTmp;
        if(timeTmp2>=0) {
          if (timeTmp2 < minValue) {
            minWeek = lastWeekBase;
            minValue = timeTmp2;
          }
        }
      }
    }

    for(let i=1;i<6;i++) {
      if(minWeek>=0) {
        break;
      }
      weekCont++;
      let week = (weekBase+i)%7;
      let lastWeek = (week-1)%7;
      if(obj[week]) {
        for(let scale in obj[week]) {
          if(obj[week][scale][field]===null) {
            continue;
          }
          let timeTmp3 = ((weekCont*1440)-minutes)+obj[week][scale][field];
        //  let timeTmp3 = obj[week][scale][field];
          if(timeTmp3>=0) {
            if (timeTmp3 < minValue) {
              minWeek = week;
              minValue = timeTmp3;
            }
          }
        }
      }
      if(obj[lastWeek]) {
        for(let scale in obj[lastWeek]) {
          if(obj[lastWeek][scale][field]<1440 || obj[lastWeek][scale][field]===null){
            continue;
          }
          let timeTmp4 = (((weekCont-1)*1440)-minutesTmp)+obj[lastWeek][scale][field];
          if(obj[lastWeek][scale][field]>1440) {
            timeTmp4 = obj[lastWeek][scale][field]-1440;
          }
          if(timeTmp4>=0) {
            if (timeTmp4 < minValue) {
              minWeek = lastWeek;
              minValue = timeTmp4;
            }
          }
        }
      }
    }
  }

  let ret = moment(now);
  if(minWeek>=0) {
   // ret.add(weekCont, 'w');
    ret.add(minValue, 'm');
  }
  console.log('weekCont: '+weekCont +' minValue:'+minValue);

  return ret;
};




module.exports = toExport;
// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }
