<script>
  import firebase from 'firebase/app'
  import Draggable from 'vuedraggable'
  import databaseUtils from '@utils/database_utils'
  import Multiselect from 'vue-multiselect'

  const stringUtils = require('@common/string_utils');

  export default {
    components: {Draggable, Multiselect},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      resource: {
        type: [String],
        default: ''
      },
      itemId: {
        type: String,
        default: null,
      },
      actions: {
        type: Array,
        default: () => {
          return [] // ['edit', 'enable', 'add']
        }
      },
      makeSearch: {
        type: [Function],
        default: null
      },
      companyId: {
        type: [String],
        default: null
      },
      ongId: {
        type: [String],
        default: null
      },
      userId: {
        type: [String],
        default: null
      },
      limit: {
        type: [Number],
        default: 1000
      },
      offset: {
        type: [Number],
        default: 0
      },
      groupByField: {
        type: [String],
        default: null
      },
      groupByTable: {
        type: [String],
        default: null
      },
      groupByCompanyId: {
        type: [String],
        default: null
      },
      groupByLimit: {
        type: [Number],
        default: 1000
      },
      listRef: {
        type: Object,
        default: function () {
          return null;
        }
      },
      disableOrder: {
        type: [Boolean],
        default: false
      },
      disableActive: {
        type: [Boolean],
        default: false
      },
      hideActive: {
        type: [Boolean],
        default: false
      },
      isSuperAdmin: {
        type: Boolean,
        default: false,
      },
      orderBy: {
        type: [String],
        default: null
      },
      orderDirection: {
        type: [String], // 'desc' | 'asc'
        default: 'desc'
      },
    },
    data() {
      return {
        listOriginal: [],
        list: [],
        search: '',
        customFilter: false,
        listeners: [],
        groups: [],
        groupsSelected: [],
        groupsSelectedObj: {},
        groupsSelectedExists: false,

      }
    },
    computed: {
      listView: {
        get() {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.customFilter = false
          let tmpList = Array.from(this.list);
          if(this.groupsSelectedExists) {
            tmpList = tmpList.filter((value)=> this.groupsSelectedObj[value[this.groupByField]]);
          }

          if(!this.search) {
            return tmpList;
          }
          let search = _.deburr(this.search.toLowerCase().trim() || '');
          if(!search) {
            return tmpList;
          }
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.customFilter = true;
          let tmp = tmpList.filter((value)=>{
            // eslint-disable-next-line eqeqeq
            if(search==value.id) {
              value.scoreSearch = 1;
            }
            else {
              value.scoreSearch = stringUtils.fcmlevenshteinNormalizeScore(search, value.search);
            }
            if(value.scoreSearch>0.6) {
              return true;
            }
            return false;
          });

         // return tmp.sort((a, b)=>{return b.scoreSearch-a.scoreSearch})
          return tmp.sort(this.sortList)
        },
        set(value) {
        //  console.log('value', value);
          if(this.customFilter || this.groupsSelected.length>0) {
            this.$swal({
              toast: true,
              position: 'top-end',
              type: 'error',
              title: this.$t('list.error.reorderOnFiltered'),
              showConfirmButton: false,
              timer: 3500
            });
            return;
          }
          let updateList = [];
          for(let index in value) {
            if(value[index].order !== parseInt(index)) {
              // value[index].order = parseInt(index);
              updateList.push({order: parseInt(index), id:value[index].id});
            }
          }

        //  this.list.sort((a, b) => {return a.order-b.order});
          this.list.sort(this.sortList);

          databaseUtils.databaseSliceUpdate(firebase.firestore().collection(this.resource), updateList);
        }
      }
    },
    watch: {
      listRef: function(listRef, old) {
        if(listRef!==old) {
          this.startWatchList();
        }
      },
      itemId(value, oldValue) {
        if(!value) {
          if(this.$route.params.id) {
            this.$router.push({params: {id: null}});
          }
        }
      }

    },

    mounted: async function () {
      this.startWatchList();
      this.$eventHub.$on('menuClicked', ()=>{
        this.$emit('edit', null);
      });
      if(this.$route.params.id) {
        this.$emit('edit', this.$route.params.id);
      }
    },
    beforeDestroy(){
      this.$eventHub.$off('menuClicked');
      for(let i in this.listeners) {
        this.listeners[i]()
      }
    },

    methods: {
      async startWatchList() {
        for(let i in this.listeners) {
          this.listeners[i]();
        }
        this.listeners = [];
        try {
          let ref = null;
          if (this.listRef) {
            ref = this.listRef;
          }
          else if (this.resource) {
            ref = firebase.firestore().collection(this.resource)
          }
          else {
            console.error('Não encontrado this.resource ou listRef', this.resource);
            return this.$swal({
              toast: true,
              position: 'top-end',
              type: 'error',
              title: this.$t('listItems.unknownResource'),
              showConfirmButton: false,
              timer: 2000
            });
          }

          if (this.companyId) {
            ref = ref.where("companyId", "==", this.companyId)
          }
          if (this.ongId) {
            ref = ref.where("ongId", "==", this.ongId)
          }
          if (this.userId) {
            ref = ref.where("userId", "==", this.userId)
          }
          if (this.orderBy) {
            ref = ref.orderBy(this.orderBy, this.orderDirection);
          }

          this.groups = [];
          if (this.groupByField && this.groupByTable) {
            let refGroup = firebase.firestore().collection(this.groupByTable)
            if (this.groupByCompanyId) {
              refGroup = refGroup.where("companyId", "==", this.groupByCompanyId)
            }
            let groupObjs = await refGroup.get();
            let tmpGroups = [];
            groupObjs.forEach((groupObj) => {
              tmpGroups.push({...groupObj.data(), id: groupObj.id});
            });

            this.groups = tmpGroups;
          }
          this.list = [];
          this.listeners.push(ref.limit(this.limit).onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
//              console.log('change', change);
              if (change.type === "added" || change.type === "modified") {
                let value = {...change.doc.data(), id: change.doc.id};

                if (this.makeSearch) {
                  value.search = this.makeSearch(value);
                  if (value.search && value.search.toLowerCase) {
                    value.search = _.deburr(value.search.toLowerCase().trim() || '');
                  }
                  else {
                    value.search = '';
                  }
                } else if(!value.search) {
                  value.search = '';
                }
                if (this.groupByField && this.groupByTable) {
                  if (value[this.groupByField]) {
                    value.group = this.groups.find((item) => item.id === value[this.groupByField]) || null;
                  }
                }
                let index = _.findIndex(this.list, (o) => {
                  return o.id === value.id;
                });
                if (index >= 0) {
                  let needReorder = false;
                  if (this.list[index].order !== value.order) {
                    needReorder = true;
                  }
                  this.list[index] = value;
                  if (needReorder) {
                    this.list.sort(this.sortList)
                  }
                  this.list = Array.from(this.list);
                }
                else {
                  this.list.splice(_.sortedIndexBy(this.list, value, function (o) {
                    let aOrder = o.order;
                    if (o.group) {
                      aOrder = (((o.group.order || 0) + 2) * 100000) + aOrder;
                    }
                    return aOrder
                  }), 0, value);
                }
              }
              else if (change.type === "removed") {
                console.log("Removed : ", change.doc.data());
              }
              else {
                console.warn('Recebido dads estranhos do firebase', change)
              }
            });
          }));
        } catch(error) {
          console.error('Erro na listagem de itens', error);
        }

        this.isLoading = false
      },
      enableChange(id, value) {
        this.$emit('enable', {
          id,
          value
        });
      },

      editClick(id) {
        this.$emit('edit', id);
        this.$router.push({params: {id: id}});
        this.$scrollTo({x:0, y:0});
      },
      addClick() {
        this.$emit('add');
      },
      getValue(item, attr) {
        return attr.split('.').reduce(function(o, k) {
          return o && o[k]
        }, item)
      },
      selectGroup(group) {
        this.groupsSelectedObj[group.id] = true;
        this.groupsSelectedObj = Object.assign({}, this.groupsSelectedObj);
        this.groupsSelectedExists = true;
      },
      removeGroup(group) {
        delete this.groupsSelectedObj[group.id];
        if(!_.size(this.groupsSelectedObj)) {
          this.groupsSelectedExists = false;
        }
        this.groupsSelectedObj = Object.assign({}, this.groupsSelectedObj);
      },

      sortList(a, b) {
        let aOrder = a.order;
        let bOrder = b.order;
        if(a.group) {
          aOrder = (((a.group.order || 0)+2)*100000)+aOrder;
        }
        if(b.group) {
          bOrder = (((b.group.order || 0)+2)*100000)+bOrder;
        }

        return aOrder-bOrder
      }
    }

  }
</script>

<template>
  <div v-if="!itemId" :class="$style.box">
    <div :class="$style.head">
      <div :class="$style.firstLine">
        <input style="display:none" type="text" name="fakeusernameremembered"/>
        <input style="display:none" type="password" name="fakepasswordremembered"/>
        <MdField  md-theme="white" :class="$style.search">
          <label>{{$t('all.search')}}</label>
          <MdInput v-model="search" type="text" autocomplete="no"  ></MdInput>
        </MdField>
        <div :class="$style.add" @click="addClick()">
          <MdIcon class="md-small-size-90" md-theme="white">add</MdIcon>
        </div>
      </div>
      <div v-if="groupByField && groupByTable">
        <Multiselect v-model="groupsSelected" :options="groups" track-by="id" label="description" :multiple="true"
                     :placeholder="$t('additional.placeholder')"
                     :select-label="$t('multiSelect.selectLabel')"
                     :select-group-label="$t('multiSelect.selectGroupLabel')"
                     :selected-label="$t('multiSelect.selectedLabel')"
                     :deselect-label="$t('multiSelect.deselectLabel')"
                     :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
                     @select="selectGroup"
                     @remove="removeGroup"
        >
        </Multiselect>
      </div>
    </div>
    <div :class="$style.body">
      <Draggable
        v-model="listView"
        :class="$style.listView"
        :scroll-sensitivity="190"
        :force-fallback="true"
        handle=".my-handle"
        :scroll="true"
        :disabled="disableOrder"
      >
          <div v-for="(item) in listView" :key="item.id" :class="[$style.item, itemId===item.id?$style.itemSelected:'', item.hasError?$style.hasError:'']" @click="editClick(item.id)">
            <slot name="img" :item="item">
              <div v-if="item.imgUrl" :class="$style.itemImg">
                <img :class="$style.imgUrl" :src="item.imgUrl" />
              </div>
            </slot>
            <div :class="$style.itemDesc">
              <slot name="item" :item="item">
                <div :class="$style.itemHead">{{item.name}}</div>
                <div v-if="item.group && item.group.description" :class="$style.itemSub1" class="formItemSub">({{item.group.name}})</div>
                <div v-else :class="$style.itemSub">({{$t('listItems.noGroup')}})</div>
              </slot>
            </div>
            <div :class="$style.itemAction">
              <span v-if="!disableOrder" class="my-handle"><MdIcon>format_line_spacing</MdIcon></span>
              <MdSwitch v-if="!hideActive" v-model="item.active" class="md-primary" :disabled="disableActive" :value="true" @change="enableChange(item.id, $event)"></MdSwitch>
              <div :class="$style.itemControl"></div>
            </div>
          </div>
      </Draggable>

    </div>

  </div>
</template>


<style lang="scss" module>
  @import '@design';
  .box {
    max-width: 480px;
    min-width: 480px;

    @media screen and (max-width: 480px) {
      min-width: 100%!important;
    }

    .head {
      padding: 15px;
      background-color: $color-submenu-bg;
    }
    .firstLine {
      display: flex;
      align-items: center;
      .add {
        padding: 15px;
        cursor: pointer;
      }
    }
    .body {
      .listView {
        background-color: #7691c214;
        display: flex;
        flex-direction: column;
        overflow: scroll;
       // scroll-snap-type: y mandatory;

        height: 100%;
      //  max-height: 100%;
        max-height: 1000px;

        .imgUrl {
          max-width: 110px;
          max-height: 70px;
        }

        .itemSelected {
          background-color: #bedbe4;
        }
        .hasError {
          background-color: #ef0f1838;
        }

        .item {
          cursor: pointer;
          user-select: none;
          position: relative;
          padding-left: 10px;
          padding-top: 8px;
          padding-bottom: 8px;
          display: flex;
          flex: 1 0 auto;
          /*box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);*/
          border-bottom: solid 1px rgba(0, 0, 0, 0.12);
          .formItemImg {
            display: flex;
            align-items: center;
          }

          .itemDesc {
            flex: 10;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .itemHead {
              font-size: 16px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.25;
              letter-spacing: normal;
              color: rgba(0, 0, 0, 0.87);
              padding-left: 8px;
              padding-right: 8px;
            }
            .itemSub {
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.43;
              letter-spacing: normal;
              color: rgba(0, 0, 0, 0.54);
              padding-left: 8px;
              padding-right: 8px;
            }
          }
          .itemAction {
            flex: 1;
            align-self: center;
            .md-radio, .md-switch {
              margin: 7px 16px 7px 0;
            }
            .itemControl {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              pointer-events: none;
            }
          }

          .itemAction .md-checked + .itemControl  {
            background-color: rgba(0, 0, 0, 0.05);
          }

          .itemHelp {
            color: rgba(0, 0, 0, 0.12);
            align-self: center;
          }



        }

      }
    }

  }


</style>
