<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/company/main_tabs'
  import ListItens from '@components/list_itens'
  import firebase from 'firebase/app'
  import ProductEdit from '@components/products/product_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListItens, ProductEdit},
    props: {
    },
    data() {
      return {
        itemId: null,
        isLoading: true,
      }
    },
    computed: {
      ...authComputed,
    },
    async mounted(){
      let additionalsObjP = firebase.firestore().collection('productsConfigs').where("companyId", "==", this.currentCompany.id).get();

      let additionalsTmp = [];
      let promisesText = [];
      (await additionalsObjP).forEach((additionalObj) => {
        let item = {...additionalObj.data(), id:additionalObj.id};
        additionalsTmp.push(item);
      });
      await Promise.all(promisesText);
      this.additionals = additionalsTmp;
      this.isLoading = false;
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
        firebase.firestore().collection('companiesProducts').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true});
      },
      setError(id) {
        firebase.firestore().collection('companiesProducts').doc(id).set({active:false, hasError:true, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true});
      },
      addItem() {
        this.itemId = firebase.firestore().collection('companiesProducts').doc().id;
      },
      productPrice(product) {
       // console.log('price');
        let ret = '';
        let additionalsProduct = (this.additionals || []).filter((a)=> a.productArray && a.productArray.includes(product.id));
        if(!additionalsProduct || additionalsProduct.length === 0) {
          additionalsProduct = (this.additionals || []).filter((a)=> a.subCategoryArray && a.subCategoryArray.includes(product.subCategoryId));
        }

      //  let variantProduct = (this.additionals || []).find((a)=> a.type==='variation' && a.subCategoryArray && a.subCategoryArray.indexOf(product.subCategoryId)>=0);

        let variantProduct = additionalsProduct.find((a)=> a.type==='variation');
        if(variantProduct) {
          ret += '['+this.$n(product.value, 'currency')+'] ';
          product.variationData = product.variationData || [];
          let hasError = false;
          for(let text of variantProduct.itemsText) {
            if(!text.active) {
              continue;
            }
            let value = (product.variationData.find(a=>a.id===text.id) || {}).value || 0;
            ret+= text.title + ' '+this.$n(value, 'currency') + ', ';
            if(!value) {
              hasError = true;
            }
          }
          ret = ret.slice(0, -2);
          if((!product.hasVariation || hasError) && !product.hasError && product.active) {
            ret = this.$t('product.productHasError');
            this.setError(product.id);
          }
        }
        else {
          ret += this.$n(product.value, 'currency');
        }
        return ret;
      }
    },
  }
</script>

<template>
  <MainTabs>
    <template v-if="!isLoading" #left >
      <ListItens
        v-model="itemId"
        resource="companiesProducts"
        :actions="['edit', 'enable']"
        :company-id="currentCompany?currentCompany.id:null"
        group-by-field="subCategoryId"
        group-by-table="companiesSubCategories"
        :group-by-company-id="currentCompany?currentCompany.id:null"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
        <template #item="slotProp" >
          <div class="formItemHead">{{slotProp.item.name +
            (!slotProp.item.visible?' ('+$t('product.notVisible')+')':'') +
            (slotProp.item.hasError?' ('+$t('product.productHasError')+')':'')
            }}</div>
          <div class="formItemSub">{{slotProp.item.description}}</div>
          <div v-if="slotProp.item.group && slotProp.item.group.description" class="formItemSub">({{slotProp.item.group.description}})</div>
          <div v-else class="formItemSub">({{$t('listItems.noGroup')}})</div>
          <div class="formItemSub">({{slotProp.item.priceCache?slotProp.item.priceCache:(slotProp.item.priceCache=productPrice(slotProp.item))}})</div>
        </template>
      </ListItens>
    </template>
    <template #right>
      <ProductEdit
        v-model="itemId"
      >
      </ProductEdit>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }

  .box {
    background-color: #ffffff;
    .container {
      min-height: 600px;
      margin: 5px 0px 30px 0px;

      .head {
        text-align: right;
      }
      .tableList {
        text-align: left;
      }
    }
  }
</style>





















