<script>
  import Locale from '@components/layout/locale'
  import {authComputed, authMethods} from '@state/helpers'
  export default {
    components: { Locale },
    data() {
      return {
        isAuto: true,
        isUpdating: false,
        showConfig: false,
      }
    },
    computed: {
      ...authComputed,
      routeName: function () {
        return this.$router.currentRoute.name
      },
      isOpen: {
        get() {
          return this.currentCompany.isOpen;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen(value);
            this.$showSuccess();
          } catch(error) {
            this.$showError();
          }
          this.isUpdating = false;
        }
      },
      acceptDelivery: {
        get() {
          return this.currentCompany.acceptDelivery;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen({type:'acceptDelivery', active: value});
            this.$showSuccess();
          } catch(error) {
            console.error(error);
            this.$showError();
          }
          this.isUpdating = false;
        }
      },
      acceptTakeout: {
        get() {
          return this.currentCompany.acceptTakeout;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen({type:'acceptTakeout', active: value});
            this.$showSuccess();
          } catch(error) {
            console.error(error);
            this.$showError();
          }
          this.isUpdating = false;
        }
      },
      acceptOnSite: {
        get() {
          return this.currentCompany.acceptOnSite;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen({type:'acceptOnSite', active: value});
            this.$showSuccess();
          } catch(error) {
            console.error(error);
            this.$showError();
          }
          this.isUpdating = false;
        }
      },
      strippedName: function(){
        if(!this.currentCompany || !this.currentCompany.name) {
          return '';
        }
        if(this.currentCompany.name.length > 20) {
          return this.currentCompany.name.slice(0,19) + '...';
        }
        return this.currentCompany.name;
      },
      strippedUserName: function(){
        if(!this.currentUser || !this.currentUser.name) {
          return '';
        }
        if(this.currentUser.name.length > 18) {
          return this.currentUser.name.slice(0,17) + '...';
        }
        return this.currentUser.name;
      },
      autoDisabledDelivery() {
        let lock = this.currentCompany.acceptDeliveryLockAuto;
        if(!lock || this.currentCompany.disableAutoIsOpen) {
          return '';
        }
        let date = moment(lock.toDate());
        if(date>moment()) {
          return '('+this.$t('navBar.nextAutoChange')+' '+date.format(this.$t('format.dataHour'))+')';
        }
        return '';
      },
      autoDisabledTakeout() {
        let lock = this.currentCompany.acceptTakeoutLockAuto;
        if(!lock || this.currentCompany.disableAutoIsOpen) {
          return '';
        }
        let date = moment(lock.toDate());
        if(date>moment()) {
          return '('+this.$t('navBar.nextAutoChange')+' '+date.format(this.$t('format.dataHour'))+')';
        }
        return '';
      },
      autoDisabledOnSite() {
        let lock = this.currentCompany.acceptOnSiteLockAuto;
        if(!lock || this.currentCompany.disableAutoIsOpen) {
          return '';
        }
        let date = moment(lock.toDate());
        if(date>moment()) {
          return '('+this.$t('navBar.nextAutoChange')+' '+date.format(this.$t('format.dataHour'))+')';
        }
        return '';
      },

    },
    methods: {
      ...authMethods,
      logout: function (event) {
        this.$router.push({ name: 'logout' });
      },
      goLogin: function (event) {
        this.$router.push({ name: 'company/login' });
      },
      goRegister: function (event) {
        this.$router.push({ name: 'company/register' });
      },
      changeShowConfig() {
        this.showConfig = !this.showConfig;
      },
      awayShowConfig() {
        this.showConfig = false;
      },
      goHome: function (event) {
        this.$router.push({ name: 'home' });
      },

    }
  }
</script>

<template>
  <nav :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.logo" @click="goHome">
        <img :src="require('@assets/images/logo/img-navbar-logo@3x.png')" alt="logo" />
      </div>
      <div v-if="loggedIn && currentCompany" :class="$style.company">
        <img v-if="currentCompany.logoImgUrl" :src="currentCompany.logoImgUrl" />
        <div :class="$style.content">
          <span :class="$style.name">{{strippedName}}</span>
          <span :class="$style.userName">({{strippedUserName}})</span>
          <div :class="$style.iconsCompanyStatus">
            <img v-if="currentCompany.acceptDeliveryEnable || currentCompany.acceptDelivery" :class="[$style.icon, acceptDelivery?$style.active:'']" :src="require('@assets/images/company/delivery_icon_disabled.png')" >
            <img v-if="currentCompany.acceptTakeoutEnable || currentCompany.acceptTakeout" :class="[$style.icon, acceptTakeout?$style.active:'']" :src="require('@assets/images/company/takeout_icon_disabled.png')" >
            <img v-if="currentCompany.acceptOnSiteEnable || currentCompany.acceptOnSite" :class="[$style.icon, acceptOnSite?$style.active:'']" :src="require('@assets/images/company/on_site_icon_disabled.png')" >
            <span v-show="showConfig" @click="changeShowConfig()" ><MdIcon :class="[$style.icon, $style.config]" >expand_less</MdIcon></span>
            <span v-show="!showConfig" @click="changeShowConfig()" ><MdIcon :class="[$style.icon, $style.config]" >expand_more</MdIcon></span>
          </div>
          <div v-if="showConfig" :class="$style.outside" @click="awayShowConfig()"></div>
          <div v-if="showConfig" :class="$style.configContainer" >
            <div :class="[$style.configContent, 'configContent', 'md-elevation-2']" >
              <MdSwitch v-if="currentCompany.acceptDeliveryEnable || currentCompany.acceptDelivery" v-model="acceptDelivery" :disabled="isUpdating" :class="['md-primary', $style.switch]" :value="true">{{$t('navBar.acceptDelivery')}} <span>{{autoDisabledDelivery}}</span></MdSwitch>
              <MdSwitch v-if="currentCompany.acceptTakeoutEnable || currentCompany.acceptTakeout" v-model="acceptTakeout" :disabled="isUpdating" :class="['md-primary', $style.switch]" :value="true">{{$t('navBar.acceptTakeout')}} <span>{{autoDisabledTakeout}}</span></MdSwitch>
              <MdSwitch v-if="currentCompany.acceptOnSiteEnable || currentCompany.acceptOnSite" v-model="acceptOnSite" :disabled="isUpdating" :class="['md-primary', $style.switch]" :value="true">{{$t('navBar.acceptOnSite') }}  <span>{{autoDisabledOnSite}}</span></MdSwitch>
              <span :class="$style.disableAutoIsOpen">({{currentCompany.disableAutoIsOpen?$t('navBar.manual'):$t('navBar.auto')}})</span>
              <div v-if="!currentCompany.acceptDeliveryEnable && !currentCompany.acceptTakeoutEnable && !currentCompany.acceptOnSiteEnable">
                {{$t('navBar.noAcceptConfig')}}
              </div>
            </div>
          </div>



          <!--<MdSwitch v-model="isAuto" :disabled="isUpdating" :class="['md-primary', $style.switch]" :value="true">{{isAuto?$t('navBar.isAuto'):$t('navBar.isManual')}}</MdSwitch>-->
        </div>
      </div>
      <div :class="$style.containerlinks">
        <div>
          <div v-if="loggedIn" :class="[$style.itemlink, $style.menuActionsNot]">
            <BaseButton
                    class="md-accent md-raised md-lg"
                    type="text"
                    @click="logout"
            >
              {{$t('navBar.logOut')}}
            </BaseButton>
          </div>
          <div v-if="!loggedIn" :class="$style.itemlink">
            <BaseButton
              v-if="routeName!=='company/register'"
              class="md-accent md-raised md-lg"
              type="text"
              @click="goRegister"
            >
              {{$t('navBar.singIn')}}
            </BaseButton>
          </div>
          <div v-if="!loggedIn" :class="$style.itemlink">
            <BaseButton
              v-if="routeName!=='company/login'"
              class="md-accent md-raised md-lg"
              type="text"
              @click="goLogin"
            >
              {{$t('navBar.logIn')}}
            </BaseButton>
          </div>
          <div :class="[$style.itemlink, $style.menuActionsNot]">
            <Locale />
          </div>
        </div>
      </div>
    </div>
  </nav>

</template>


<style lang="scss">
  .configContent .md-switch .md-switch-thumb .md-ripple {
    height: 26px!important;
  }
</style>

<style lang="scss" module>
  @import '@design';

  .menuActionsNot {
    display: block;
    @media screen and (max-width: $size-menu-actions+px) {
      display: none!important;
    }
  }
  .box {
    background-color: $color-body-bg;
    width: 100%;
    .container {
      padding: 10px;
      height: 118px;
      min-width: $size-content-width-min;
      max-width: $size-content-width-max;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .logo {
        flex: 0;
        display: flex;
        min-width: 220px;
        cursor: pointer;
        img {
          align-self: center;
          max-height: 88px;
          @media screen and (max-width: $size-company-menu-max+px) {
            align-self: flex-start;
            max-height: 52px;
          }
        }
        @media screen and (max-width: $size-company-menu-max+px) {
          min-width: 65px;
        }
      }
      .company {
        @extend %font-navbar;
        flex: 1;
        align-self: center;
        justify-content: flex-start;
        display: flex;
        text-align: start;
        padding-left: 10px;

        .name {
          margin-bottom: 4px;
        }
        .userName {
          @extend %font-content;
          margin-bottom: 10px;
          font-weight: 400;
        }

        img {
          border-radius: 50%;
          align-self: center;
          max-height: 75px;
          flex: 0;
          @media screen and (max-width: $size-company-menu-max+px) {
            align-self: flex-start;
            max-height: 52px;
          }
        }
        .content {
          align-self: center;
          max-width: 270px;
          padding-left: 10px;
          text-align: start;
          display: flex;
          flex-direction: column;

          .iconsCompanyStatus {
            .icon {
              width: 35px;
              height: 35px;
              margin-right: 4px;
            }
            .active {
              filter: saturate(20000%) hue-rotate(320deg);
            }
            .config {
              cursor: pointer;
              z-index:100;
            }
            z-index:100;
          }

          .outside {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index:99;
          }

          .configContainer {
            position: relative;
            .configContent {
              position: absolute;
              z-index:100;
              background-color: white;
              padding: 14px;
              border-radius: 3px;
              min-width: 210px;

              >div {
                margin-bottom: 10px;
                span {
                  font-size: 15px;
                }
              }
              .disableAutoIsOpen {
                font-size: 15px;
                font-weight: 300;
              }
            }
          }



        }

        .switch {
          @extend %font-content;
          margin: 1px 5px 5px 0;
          > label {
            white-space: nowrap;
          }
        }
      }
      .containerlinks {
        flex: 0 1 auto;
        display: flex;
        /*justify-content: flex-end;*/
        div {
          justify-content: flex-end;
          align-self: center;
          display: flex;
          flex-wrap: wrap;
          div {
            align-self: center;
            /*margin-left: 30px;*/
            BaseButton {

            }
          }
          .itemlink {
            margin-left: 10px;
          }

        }
      }
    }
  }


</style>
