<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'
  import FcmEditor from "@/src/components/image/fcm-editor";
  import {companyService, productService} from '@common/services';
  import SearchPick from '@components/diversos/searchPick';
  import Multiselect from 'vue-multiselect'
  let {languages} = require('@i18n');


  export default {
    components: {FcmEditor, SearchPick, Multiselect},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        imageSrc: null,
        imageSrc2: null,
        uploadProgress: 0,
        imageBlob: null,
        imageBlob2: null,
        languages: languages,
        tryingToUpload: false,
        isLoading: true,
        isNew: true,

        form: {
          name: {},
          imgUrls: {},
          order: 0,
          active: true,
          search: {
            showOn: 'mainScreen',
            type: 'search',
            tagsId: [],
          },
          products: [],
          companies: [],
        },
        defaultForm: {
          name: {},
          imgUrls: {},
          order: 0,
          active: true,
          search: {
            showOn: 'mainScreen',
            type: 'search',
            tagsId: [],
          },
          products: [],
          companies: [],
        },
        regionsList: [],
        tagList: [],
        tags: [],
        tagsRestrictions: [],

      }
    },
    validations: {
      form: {
      }
    },
    computed: {
      ...authComputed,
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        this.imageBlob2 = null;
        this.imageSrc2 = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      this.isLoading = false;
      let regionsObjP = firebase.firestore().collection('regions').get();
      let tagsObjP = firebase.firestore().collection('tags').where('isProduct', '==', true).get();

      let regionsList = [];
      (await regionsObjP).forEach((categoryObj) => {
        regionsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
      });
      this.regionsList = regionsList;

      let tmp = [];
      (await tagsObjP).forEach((categoryObj) => {
        tmp.push({id:categoryObj.id, name:categoryObj.data().name, nameView:this.$trdb((categoryObj.data()||{}).name||{})})
      });
      this.tagList = _.sortBy(tmp, 'nameView');

    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        let form = _.cloneDeep(this.defaultForm);

        let obj = await firebase.firestore().collection('adCards').doc(this.itemId).get();
        if(obj && obj.data()) {
          form = { ...form, ...obj.data()};
          this.isNew = false;
          if(typeof form.name !== 'object') {
            form.name = {};
          }
          if(typeof form.imgUrls !== 'object') {
            form.imgUrls = {};
          }
        }
        else {
          this.isNew = true;
        }
        if(!form.search) {
          form.search = {};
        }

        let tmp = [];
        for(let tagId of form.search.tags || [] ) {
          let tag = this.tagList.find(a=>a.id===tagId);
          if(tag) {
            tmp.push({...tag, nameView: this.$trdb(tag.name)});
          }
        }
        this.tags = tmp;
        let tmp2 = [];
        for(let tagId of form.search.tagsRestrictions || [] ) {
          let tag = this.tagList.find(a=>a.id===tagId);
          if(tag) {
            tmp2.push({...tag, nameView: this.$trdb(tag.name)});
          }
        }
        this.tagsRestrictions = tmp2;

        if(!form.search) {
          form.search = {};
        }

        this.form = form;

        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;
        form.order = parseInt(form.order || 1000) || 1000;
        form.active = !!form.active;


        form.search.tags = [];
        form.search.tagsRestrictions = [];
        for(let tag of this.tags) {
          form.search.tags.push(tag.id);
        }
        for(let tag of this.tagsRestrictions) {
          form.search.tagsRestrictions.push(tag.id);
        }
        for(let tag of this.form.products || []) {
          form.search.productIds.push(tag.id);
        }
        for(let tag of this.form.companies || []) {
          form.search.companyIds.push(tag.id);
        }

        if(this.imageBlob || this.imageBlob2) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            if(this.imageBlob) {
              let url = await databaseUtils.uploadFile('adCards/'+this.itemId+'_dark', this.imageBlob, (progress) => {
                this.uploadProgress = progress/(this.imageBlob2?1:2);
              });
              if(url) {
                if(!form.imgUrls) {
                  form.imgUrls = {};
                }
                form.imgUrls.dark = url;
              }
            }
            if(this.imageBlob2) {
              let url = await databaseUtils.uploadFile('adCards/'+this.itemId+'_light', this.imageBlob2, (progress) => {
                this.uploadProgress = progress/(this.imageBlob?1:2);
              });
              if(url) {
                if(!form.imgUrls) {
                  form.imgUrls = {};
                }
                form.imgUrls.light = url;
              }
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          form.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          await firebase.firestore().collection('adCards').doc(this.itemId).set(form,{merge: false});
          this.$showSuccess();
          this.$emit('changeModel', null);
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      changeBlob2 (blob) {
        this.imageBlob2 = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      tryChangeImage2 () {
        this.$refs.fcmEditor2.insertImage();
      },
      removeImage() {
        this.form.imgUrls.dark = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      removeImage2() {
        this.form.imgUrls.light = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      async getCompanies(input) {
        return companyService.getCompanies({
          search: input.search,
          size: input.size,
          from: input.from,
          lang: this.$i18n.locale
        });
      },
      async getProducts(input) {
        return productService.getProducts({
          search: input.search,
          size: input.size,
          from: input.from,
          lang: this.$i18n.locale,
          companyIds: [this.currentCompany.id],
          inactive: true,
        });
      },

    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded">
          <div v-if="isNew" :class="$style.contentHeaderTitle">{{$t('all.create').toUpperCase()}}</div>
          <div v-if="!isNew" :class="$style.contentHeaderTitle">{{$t('all.edit').toUpperCase()}}</div>
          <BaseDev>{{itemId}}</BaseDev>

          <p>Dark</p>
          <div :class="$style.imagePreview" @click="tryChangeImage()" >
            <img :src="imageSrc || form.imgUrls.dark || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
          </div>
          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="1125"
                     :max-height="645"
                     :aspect-ratio="1125/645"
                     @changeBlob="changeBlob"
          ></FcmEditor>

          <p>Light</p>
          <div :class="$style.imagePreview" @click="tryChangeImage2()" >
            <img :src="imageSrc2 || form.imgUrls.light || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage2()" ><i class="material-icons">close</i></div>
          </div>
          <FcmEditor ref="fcmEditor2" v-model="imageSrc2" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="1125"
                     :max-height="645"
                     :aspect-ratio="1125/645"
                     @changeBlob="changeBlob2"
          ></FcmEditor>
          <div :class="$style.body">
            <div class="formItemSubSection"></div>
            <MdTabs >
              <MdTab v-for="(item, index) in languages"  :key="index+item.database"  :md-label="item.name" >
                <BaseInputText
                  v-model="form.name[item.database]"
                  :label="$t('form.name')"
                >
                </BaseInputText>
              </MdTab>
            </MdTabs>

            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.active')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>

            <div class="formItem">
              <MdField >
                <label >{{$t('adCards.type.title')}}</label>
                <MdSelect v-model="form.type">
                  <MdOption value="link">{{$t('adCards.type.link')}}</MdOption>
                  <MdOption value="search">{{$t('adCards.type.search')}}</MdOption>
                </MdSelect>
              </MdField>
            </div>

            <div class="formItem">
              <MdField >
                <label >{{$t('adCards.showOn.title')}}</label>
                <MdSelect v-model="form.showOn">
                  <MdOption value="mainScreen">{{$t('adCards.showOn.mainScreen')}}</MdOption>
                </MdSelect>
              </MdField>
            </div>

            <div v-if="form.type==='link'">
              <div class="formItem">
                <BaseInputText
                  v-model="form.linkUrl"
                  :label="$t('adCards.link')"
                >
                </BaseInputText>
              </div>
            </div>
            <div v-if="form.type==='search'">
              <div class="formItem">
                <BaseInputText
                  v-model="form.search.search"
                  :label="$t('adCards.search')"
                >
                </BaseInputText>
              </div>
              <SearchPick
                v-model="form.companies"
                :get-items="getCompanies"
                :label="$t('form.selectCompany')"
                :show-img="true"
                :filter-region="true"
                :limit="1"
              >
                <template #item="slotProp" >
                  <div>{{slotProp.item.name}}</div>
                </template>
                <template #selected="slotProp" >
                  <div>{{slotProp.item.name}}</div>
                </template>
              </SearchPick>
              <SearchPick
                v-model="form.products"
                :get-items="getProducts"
                :label="$t('form.selectProduct')"
                :show-img="true"
                :limit="1"
                :filter-region="true"
              >
                <template #item="slotProp" >
                  <div>{{slotProp.item.name}}</div>
                </template>
                <template #selected="slotProp" >
                  <div>{{slotProp.item.name}}</div>
                </template>
              </SearchPick>
              <div class="formItem">
                <Multiselect v-model="tags" :options="tagList" track-by="id" label="nameView" :multiple="true"
                             :placeholder="$t('product.placeholderTags')"
                             :select-label="$t('multiSelect.selectLabel')"
                             :select-group-label="$t('multiSelect.selectGroupLabel')"
                             :selected-label="$t('multiSelect.selectedLabel')"
                             :deselect-label="$t('multiSelect.deselectLabel')"
                             :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
                >
                </Multiselect>
              </div>
              <div class="formItem">
                <Multiselect v-model="tagsRestrictions" :options="tagList" track-by="id" label="nameView" :multiple="true"
                             :placeholder="$t('product.placeholderTagsRestrictions')"
                             :select-label="$t('multiSelect.selectLabel')"
                             :select-group-label="$t('multiSelect.selectGroupLabel')"
                             :selected-label="$t('multiSelect.selectedLabel')"
                             :deselect-label="$t('multiSelect.deselectLabel')"
                             :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
                >
                </Multiselect>
              </div>
            </div>



          </div>

          <MdField :class="$style.formItemText">
            <label >{{$t('form.region')}}</label>
            <MdSelect v-model="form.regionId" name="region">
              <MdOption v-for="(value) in regionsList" :key="value.id" :value="value.id">{{value.nameView}}</MdOption>
            </MdSelect>
          </MdField>



          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      .content {
        .contentLoaded {
          .imagePreview {
            width: 281px;
            height: 161px;
            img {
            }
          }
          .body {

          }
        }
      }
    }
  }
</style>





















