<script>
  import { Sketch } from 'vue-color'
  const colorUtils = require ('@common/color_utils');

  export default {
    components: {
      'SketchPicker': Sketch
    },
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      type: {
        type: String,
        default: 'rgba',
      },
      value: {
        type: [String, Number, Object],
        default: '',
      },
      label: {
        type: [String, Number],
        default: '',
      },
      icon: {
        type: [String, Number,Boolean],
        default: false,
      }
    },
    data() {
      return {
        showModal: false
      }
    },
    computed: {
      colorBackground: {
        get() {
          return colorUtils.rgba2css(this.value)
        }
      }
    },
    methods: {
      onInput(event) {
        if(event[this.type]) {
          this.$emit('update', event[this.type])
        }
        else {
          this.$emit('update', event.rgba)
        }
      },
      conclude() {
        this.showModal = false
      },
    }
  }
</script>

<template>
  <div :class="$style.box">
    <span>{{label}}</span>
    <div :class="$style.container" @click="showModal = !showModal;">
      <div :class="[$style.colorBg, 'md-elevation-4']" :style="'background-color:'+colorBackground+';'"></div>
      <div  :class="$style.text"><slot/></div>
    </div>
    <MdDialog :md-active.sync="showModal" :class="$style.modal">
      <SketchPicker
                    :value="value"
                    @input="onInput($event)"
                    v-on="$listeners"
      ></SketchPicker>
      <MdButton :class="['md-primary','md-raised']" @click="conclude">
        {{$t('form.conclude')}}
      </MdButton>
    </MdDialog>
  </div>
</template>

<style lang="scss" module>
  @import '@design';

  .box {
    .container {
      cursor: pointer;
      display: flex;
      .colorBg {
        width: 30px;
        height: 30px;

      }
      .text {
        margin-left: 10px;
      }
    }
  }

  .modal {
    min-width: 220px!important;
  }
</style>
