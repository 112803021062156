<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'
  const timeUtils = require ('@common/time_utils');

  export default {
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        uploadProgress: 0,
        imageSrc: null,
        imageBlob: null,
        isLoading: true,

        form: [],
        numberOfScales: 1,

        schedulesTypes: {
          takeout: {
            name: this.$t('all.takeout'),
            database: 'takeout'
          },
          onSite: {
            name: this.$t('all.onSite'),
            database: 'onSite'
          },
          delivery: {
            name: this.$t('all.delivery'),
            database: 'delivery'
          }
        },
        weekList: {
          0: {
            name: this.$t('calendar.week.0'),
            database: '0'
          },
          1: {
            name: this.$t('calendar.week.1'),
            database: '1'
          },
          2: {
            name: this.$t('calendar.week.2'),
            database: '2'
          },
          3: {
            name: this.$t('calendar.week.3'),
            database: '3'
          },
          4: {
            name: this.$t('calendar.week.4'),
            database: '4'
          },
          5: {
            name: this.$t('calendar.week.5'),
            database: '5'
          },
          6: {
            name: this.$t('calendar.week.6'),
            database: '6'
          }
        },
        scheduleAllIni: {
          delivery: [],
          onSite: [],
          takeout: []
        },
        scheduleAllEnd: {
          delivery: [],
          onSite: [],
          takeout: []
        },

      }
    },
    validations: {

    },
    computed: {
      ...authComputed,
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      if(this.itemId) {
        await this.loadForm();
      }
      this.isLoading = false;
    },
    beforeMount: async function() {
      let cardsObjP = firebase.firestore().collection('cards').get();
      this.cardsList = [];
      (await cardsObjP).forEach((categoryObj) => {
        this.cardsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
      });

    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        let companySchedule = {};
        let obj = await firebase.firestore().collection('companies').doc(this.itemId).get();
        if(obj && obj.data()) {
          let company = obj.data() ||{};
          companySchedule = company.schedule || {};
        }

        let delivery = this.getNumberScales(companySchedule.delivery);
        let onSite = this.getNumberScales(companySchedule.onSite);
        let takeout = this.getNumberScales(companySchedule.takeout);

        this.numberOfScales = 0;
        if(delivery>this.numberOfScales) {
          this.numberOfScales = delivery;
        }
        if(onSite>this.numberOfScales) {
          this.numberOfScales = onSite;
        }
        if(takeout>this.numberOfScales) {
          this.numberOfScales = takeout;
        }

        if(this.numberOfScales<1) {
          this.numberOfScales = 1;
        }
        else if(this.numberOfScales>2) {
          this.numberOfScales = 2;
        }
        companySchedule.delivery = this.minutes2schedule(_.cloneDeep(companySchedule.delivery));
        companySchedule.onSite = this.minutes2schedule(_.cloneDeep(companySchedule.onSite));
        companySchedule.takeout = this.minutes2schedule(_.cloneDeep(companySchedule.takeout));

        companySchedule.delivery = this.complementScales(companySchedule.delivery, this.numberOfScales);
        companySchedule.onSite = this.complementScales(companySchedule.onSite, this.numberOfScales);
        companySchedule.takeout = this.complementScales(companySchedule.takeout, this.numberOfScales);

        this.form = companySchedule;


       // console.log('loaded companySchedule', companySchedule);
        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;

        let toUpdate = {
          schedule: {
            delivery: this.schedule2minutes(_.cloneDeep(form.delivery)),
            onSite: this.schedule2minutes(_.cloneDeep(form.onSite)),
            takeout: this.schedule2minutes(_.cloneDeep(form.takeout))
          }
        };

        console.log('toUpdate', toUpdate);

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('companiesSchedule/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          await firebase.firestore().collection('companies').doc(this.itemId).set(toUpdate,{merge: true});
          this.$showSuccess();
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      minutes2schedule(obj) {
        if(obj) {
          for(let week in obj) {
            if(obj[week]) {
              for(let scale in obj[week]) {
                obj[week][scale] = timeUtils.minutes2schedule(obj[week][scale]);
              }
            }
          }
        }
        return obj;
      },

      schedule2minutes(obj) {
        let ret = {};
        if(obj) {
          for(let week in obj) {
            if(obj[week]) {
              ret[week] = [];
              for(let scale in obj[week]) {
                ret[week][scale] = timeUtils.schedule2minutes(obj[week][scale]);
              }
            }
          }
        }
        return ret;
      },

      getNumberScales(obj) {
        let n = 1;
        if(obj) {
          for(let week in obj) {
            if(obj[week] && obj[week].length) {
              if(obj[week].length>n) {
                n = obj[week].length;
              }
            }
          }
        }
        return n;
      },
      addScale() {
        if(this.numberOfScales>=2) {
          return this.$showError({title:this.$t('form.error.scheduleMax')});
        }
        this.numberOfScales++;
        let delivery = this.complementScales(this.form.delivery);
        let onSite = this.complementScales(this.form.onSite);
        let takeout = this.complementScales(this.form.takeout);
        this.form = {
          delivery,
          onSite,
          takeout
        }
      },
      removeScale() {
        if(this.numberOfScales<2) {
          return this.$swal({
            toast: true,
            position: 'top-end',
            type: 'error',
            title: this.$t('form.error.schedule'),
            showConfirmButton: false,
            timer: 2000
          });
        }
        this.numberOfScales--;
        let delivery = this.complementScales(this.form.delivery, this.numberOfScales);
        let onSite = this.complementScales(this.form.onSite, this.numberOfScales);
        let takeout = this.complementScales(this.form.takeout, this.numberOfScales);
        this.form = {
          delivery,
          takeout,
          onSite
        }
      },
      complementScales(obj, maxScales=-1) {
        if(!obj) {
          obj = [];
        }
        for(let i in this.weekList) {
          if(!obj[i]) {
            obj[i] = [];
          }
          for(let j=0;j<this.numberOfScales;j++) {
            if(!obj[i][j]) {
              obj[i][j] = {};
            }
            if(!obj[i][j].ini) {
              obj[i][j].ini = '';
            }
            if(!obj[i][j].end) {
              obj[i][j].end = '';
            }
          }
          if(maxScales>0) {
            obj[i].splice(maxScales);
          }
        }
        return obj;
      },

      changeAll(event, database, field, scale) {
       // if(event) {
          for(let i in this.form[database]) {
            this.form[database][i][scale][field] = event
          }
       // }
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <!--
                    <div :class="$style.imagePreview" @click="tryChangeImage()" >
                      <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
                      <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
                      <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
                    </div>
                    <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                               type-content="modal"
                               :max-width="180"
                               :max-height="180"
                               :aspect-ratio="0"
                               @changeBlob="changeBlob"
                    ></FcmEditor> -->
          <div :class="$style.body">
            <MdTabs >
              <MdTab v-for="(item, index) in schedulesTypes" :key="index" :md-label="item.name" >
                <div>
                  <div v-for="n in numberOfScales" :key="'title'+index+n" :class="[$style.schedulesScaleTitle]">
                    <span :class="[$style.schedulesItensTitle]">Início</span>
                    <span :class="[$style.schedulesItensTitle]">Fecha</span>
                  </div>

                  <MdButton class="md-primary" @click="addScale()">
                    <MdIcon>add</MdIcon>
                  </MdButton>
                  <MdButton class="md-primary" @click="removeScale()">
                    <MdIcon>remove</MdIcon>
                  </MdButton>
                </div>

                <div>
                  <div v-for="n in numberOfScales" :key="'all'+index+n" :class="[$style.schedulesScaleTitle]">
                    <MdField :class="[$style.schedulesItens]">
                      <label>todos</label>
                      <MdInput
                        v-if="scheduleAllIni[item.database]"
                        v-model="scheduleAllIni[item.database][n-1]"
                        v-mask="'##:##'"
                        type="text"
                        @input="changeAll($event, item.database, 'ini', n-1)"
                      >
                      </MdInput>
                    </MdField>
                    <MdField :class="[$style.schedulesItens]">
                      <label>todos</label>
                      <MdInput
                        v-if="scheduleAllIni[item.database]"
                        v-model="scheduleAllEnd[item.database][n-1]"
                        v-mask="'##:##'"
                        type="text"
                        @input="changeAll($event, item.database, 'end', n-1)"
                      >
                      </MdInput>
                    </MdField>
                  </div>
                </div>


                <div v-for="(itemWeek, indexWeek) in weekList" :key="indexWeek" :class="[$style.schedulesWeek]">
                  <div v-for="(sc, indexSc) in ((form[item.database] || [])[itemWeek.database] || [])" :key="index+indexWeek+indexSc"  :class="[$style.schedulesScale]">
                    <MdField :class="[$style.schedulesItens, (form[index][indexWeek][indexSc].end && !form[index][indexWeek][indexSc].ini)?'md-invalid':false]">
                      <label>{{itemWeek.name}}</label>
                      <MdInput
                        v-model="form[index][indexWeek][indexSc].ini"
                        v-mask="'##:##'"
                        type="text"
                      >
                      </MdInput>
                    </MdField>
                    <MdField :class="[$style.schedulesItens, (form[index][indexWeek][indexSc].ini && !form[index][indexWeek][indexSc].end)?'md-invalid':false]">
                      <label>{{itemWeek.name}}</label>
                      <MdInput
                        v-model="form[index][indexWeek][indexSc].end"
                        v-mask="'##:##'"
                        type="text"
                      >
                      </MdInput>
                    </MdField>

                  </div>

                </div>
              </MdTab>
            </MdTabs>
          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
       <!--     <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton> -->
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 600px;
      .content {
        max-width: 900px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            .schedulesScaleTitle {
              display: inline-block;
            }
            .schedulesScale {
              display: inline-block;
            }
            .schedulesItens {
              width: 100px;
              display: inline-block;
              margin-right: 20px;
            }

            .schedulesItensTitle {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }
          }
        }
      }
    }
  }
</style>





















