<script>
  import { authComputed, timeComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import {gmapApi} from 'vue2-google-maps'
 // import OrderClient from '@components/products/order_client'
  const geoUtils = require ('@common/geo_utils');
  const stateList = require ('@common/status');

  export default {
    name: 'OrderEdit',
  //  components: {OrderClient},
    components: {OrderClient: () => import('@components/products/order_client')},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      },
      isSuperAdmin: {
        type: Boolean,
        default: false,
      },
      isModal: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        defaultForm: {
        },

        hasError: null,
        tryingToUpdate: false,

        form: {
        },

        isLoading: true,
        isNew: true,
        clients: [],
        unsubscribeObserver: null,
        stateList: stateList,
        center: {lat:0, lng:0},
        markerCompany: null,
        markerDst: null,
        directionPath: [],
        distanceGoogleMaps: 0,
        distanceLine: 0,
        directionsDisplay: null,
        statusSequenceIndex: 0,
        lastStatusKeyTime: {},
        timeoutUpdateCode: null,

        paymentList: [],
        lastOrderCode: null,
        lastPosition: null,

        disableStatusChange: false,
        printOptions: {
          silent: true,
          printBackground: false,
          deviceName: '',
          color: false,
          margins: {
            marginType: 'custom', // default, none, printableArea, custom
            top: 2, // in pixels
            bottom: 2,
            left: 2,
            right: 2,
          },
          landscape: false,
          scaleFactor: 1,
          copies: 1,

        /*
          pagesPerSheet: 1,
          collate: false,
          pageRanges: {from: 0, to:10},
          duplexMode: 'simplex',  // simplex, shortEdge, longEdge.
          dpi: {horizontal: 150, vertical: 150},
          header: 'Header',
          footer: 'Footer',
          pageSize: 'A3',   // String | Size (optional)   A3, A4, A5, Legal, Letter, Tabloid   or   height
        */
        },
        clientSelectedDialog: false,
        clientSelected: null,
      }
    },
    validations: {
      form: {
        value: {
          decimal,
          minValue: minValue(0)
        },
      }
    },
    computed: {
      ...authComputed,
      ...timeComputed,
      google: gmapApi,
    },
    watch: {
      itemId(value, oldValue) {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        this.clients = [];
        if(value) {
          this.loadForm()
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }
      }
    },
    mounted: async function() {
      if(this.currentCompany && this.currentCompany.address && this.currentCompany.address.latitude && this.currentCompany.address.longitude) {
        this.markerCompany = {position: {lat: this.currentCompany.address.latitude, lng: this.currentCompany.address.longitude}}
      }
      this.imageBlob = null;
      this.imageSrc = null;
      this.clients = [];
      if(this.itemId) {
        this.loadForm()
      }
      else {
        this.form = _.cloneDeep(this.defaultForm);
      }
    },
    beforeDestroy: function() {
      if(typeof this.unsubscribeObserver === 'function') {
        this.unsubscribeObserver();
        this.unsubscribeObserver = null;
      }
      if(this.directionsDisplay) {
        this.directionsDisplay.setMap(null);
        this.directionsDisplay = null;
      }

    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
        this.$emit('fcm-close', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = {...this.defaultForm};
        this.paymentList = [];
        this.directionPath = [];
        this.distanceGoogleMaps = 0;
        this.distanceLine = 0;

        if(typeof this.unsubscribeObserver === 'function') {
          this.unsubscribeObserver();
          this.unsubscribeObserver = null;
        }

        this.unsubscribeObserver = firebase.firestore().collection('userOrders').doc(this.itemId).onSnapshot(async (snapshot) => {
         // snapshot.docChanges().forEach((change) => {
          if(snapshot.exists) {
            this.lastOrderCode = (snapshot.data() || {}).orderCode;
            this.form = { ...this.defaultForm, ...snapshot.data(), id: snapshot.id};

            this.paymentList = [];
            if(!this.form.status) {
              this.form.status = 'waitConfirm';
            }
            if(!this.form.deliveryType) {
              this.form.deliveryType = 'pickup';
            }

            if(this.isSuperAdmin) {
              if(this.form.company && this.form.company.address && this.form.company.address.latitude && this.form.company.address.longitude) {
                this.markerCompany = {position: {lat: this.form.company.address.latitude, lng: this.form.company.address.longitude}}
              }
            }

            this.statusSequenceIndex = -1;
            this.statusSequenceIndexGraph = -1;
            if(stateList.companyOrderStatusSequence[this.form.deliveryType]) {
              this.statusSequenceIndex = stateList.companyOrderStatusSequence[this.form.deliveryType].findIndex(a=>a===this.form.status);
              this.statusSequenceIndexGraph = stateList.companyOrderStatusSequenceGraph[this.form.deliveryType].findIndex(a=>a===this.form.status);
            }

            let lstProductsArray = [];
            for(const i in this.form.lstProducts || {}) {
              let item = this.form.lstProducts[i];
              item.tmpIngredientsRestrictions = [];
              for(const restriction of item.lstIngredientsRestrictions || []) {
                const info = ((item.product || {}).ingredients || []).find(a=>a.id===restriction);
                if(info) {
                  item.tmpIngredientsRestrictions.push(info);
                }
              }
              item.flavorTmp = null;
              item.flavorTmpQtd = 0;
              for(const j in item.lstConfigProduct) {
                if(item.lstConfigProduct[j].type==='flavor' && item.lstConfigProduct[j].lstChooseAdditionalObjects && item.lstConfigProduct[j].lstChooseAdditionalObjects.length>1) {
                  item.flavorTmpQtd = item.lstConfigProduct[j].lstChooseAdditionalObjects.length;
                  item.flavorTmp = item.lstConfigProduct[j];
                }
              }
              lstProductsArray.push(item);
            }
            this.form.lstProductsArray = _.sortBy(lstProductsArray, [function(o) { return (o.product ||{}).name; }]);

            if(stateList.companyOrderStatusSequence[this.form.deliveryType]) {
              let statusSequenceIndex = stateList.companyOrderStatusSequence[this.form.deliveryType].findIndex(a=>a===this.form.status);
              if(statusSequenceIndex>=0) {
                this.form.nextStatus = stateList.companyOrderStatusSequence[this.form.deliveryType][statusSequenceIndex+1];
              }
            }
            if(!this.form.nextStatus) {
              this.form.nextStatus =null;
            }

            this.lastStatusKeyTime = {};
            for(let el of this.form.statusTime || []) {
              this.lastStatusKeyTime[el.status] = el.on;
            }
            for(let i in this.form.lstUsers || []) {
              let user = this.form.lstUsers[i];
              let obj = {
                id: user.id,
                name: user.name,
                description: '',
                status: '',
                value: '',
                imgUrl: ''
              };
              if(this.form.paymentMethod && this.form.paymentMethod[user.id]) {
                let pm = this.form.paymentMethod[user.id];
                if(this.form.groupCode) {
                  obj.value = pm.groupUserTotalValue || 0;
                }
                else {
                  obj.value = this.form.totalValue || 0;
                }
                if(pm.type===stateList.OrderPaymentMethodType.OnApp && pm.status===stateList.PaymentStatus.paid) {
                  obj.status = this.$t('paymentInfo.OnApp');
                }
                if(pm.type===stateList.OrderPaymentMethodType.whenReceive && pm.deliveryPaymentMethod) {
                  obj.imgUrl = pm.deliveryPaymentMethod.imgUrl;
                  obj.status = this.$t('paymentInfo.whenReceive');
                  obj.description = this.$trdb(pm.deliveryPaymentMethod.name);
                  if(pm.deliveryPaymentMethod.isCash && pm.changeValue) {
                    obj.description+= '('+this.$t('paymentInfo.changeFor')+': '+this.$n(pm.changeValue, 'currency')+')';
                  }

                }

              }
              this.paymentList.push(obj);
            }

            this.clients = this.form.lstUsers || [];
            if(this.form && this.form.deliveryAddress && this.form.deliveryAddress.latitude && this.form.deliveryAddress.longitude) {
              this.markerDst = {position: {lat: this.form.deliveryAddress.latitude, lng: this.form.deliveryAddress.longitude}};
              this.center = {lat: this.form.deliveryAddress.latitude, lng: this.form.deliveryAddress.longitude};
            }
            else {
              this.markerDst = null;
            }
            this.calculateRoute();
          }
        //  console.log('form', this.form);
          this.isLoading = false
        });

      //  this.isLoading = false;
      },


      async conclude() {
        this.tryingToUpdate = true;

        this.tryingToUpdate = false;
      },
      print() {
        let url = firebase.functions()._url('api-api')+'/api/order/print/'+this.itemId+'?lang='+(this.$i18n?this.$i18n.locale:'');

        let printElectron = false;
        if(process.env.IS_ELECTRON) {
          printElectron = true;
          const printer = JSON.parse(window.localStorage.getItem('printerSelected')) || null;
          if(printer && printer.deviceName) {
            this.printOptions.deviceName = printer.deviceName;
          }

          const { ipcRenderer } = window.require('electron');
          ipcRenderer.on('printOrderReply', (event, arg) => {
            if(arg.isOk) {
              this.$showSuccess({title: this.$t('all.successElectronPrinter')});
            }
            else {
              this.$showError({title: this.$t('all.errorElectronPrinter')});
            }
          });
          ipcRenderer.send('printOrder', {url: url, printOptions: this.printOptions});


          /* const {BrowserWindow} = window.require("electron").remote;
           if(BrowserWindow) {
            let win = new BrowserWindow({ width: 1, height: 1 });
            if(win) {
              printElectron = true;
              setTimeout(()=>{
                win.loadURL(url);
                win.webContents.on('did-finish-load', () => {
                  console.log('lets print');
                  win.webContents.print(this.printOptions, (success, errorType) => {
                    if (!success) {
                      console.error(errorType);
                      this.$showError({title: this.$t('all.errorElectronPrinter')});
                    }
                    else {
                      console.log('Sucesso ao imprimir');
                      this.$showSuccess({title: this.$t('all.successElectronPrinter')});
                    }
                  });
                });
              }, 100);
            }
          } */
        }
        if(!printElectron) {
          console.log('print default');
          window.open(url, '_blank');
        }
      },
      _moment(date) {
        return moment(date);
      },
      async changeStatus(status) {
        await firebase.firestore().collection('userOrders').doc(this.itemId).set({status: status},{merge: true});
      },

      async calculateRoute(isEnable=false) {
        if(this.markerDst && this.markerDst.position) {
          if(this.lastPosition && this.lastPosition.lat===this.markerDst.position.lat && this.lastPosition.lng===this.markerDst.position.lng) {
            return;
          }
          else {
            this.lastPosition = this.markerDst.position;
          }
        }
        else {
          return;
        }
        this.directionPath = [];
        this.distanceGoogleMaps = 0;
        this.distanceLine = 0;
        if(!this.markerCompany || !this.markerCompany.position || !this.markerDst || !this.markerDst.position) {
          if(this.directionsDisplay) {
            this.directionsDisplay.setMap(null);
            this.directionsDisplay = null;
          }
          return;
        }
        if(!isEnable && !window.forceShowRoute) {
          console.log('disable route');
          return;
        }


        this.distanceLine = geoUtils.calcRadiusDistance(this.markerCompany.position.lat, this.markerCompany.position.lng, this.markerDst.position.lat, this.markerDst.position.lng);

        let mapObject = await this.$refs.map.$mapPromise;
        let directionsService = new this.google.maps.DirectionsService();
        directionsService.route({
          origin: this.markerCompany.position,
          destination: this.markerDst.position,
          avoidTolls: true,
          avoidHighways: false,
          travelMode: this.google.maps.TravelMode.DRIVING
        },
        (response, status) => {
          if (status === this.google.maps.DirectionsStatus.OK) {
            if(this.directionsDisplay) {
              this.directionsDisplay.setMap(null);
              this.directionsDisplay = null;
            }
            this.directionsDisplay = new this.google.maps.DirectionsRenderer({
              map: mapObject,
              suppressMarkers: true
            });
           // this.routeResponse = response;
            this.directionsDisplay.setDirections(response);
            if(response.routes && response.routes[0] && response.routes[0].legs && response.routes[0].legs[0] && response.routes[0].legs[0].distance && response.routes[0].legs[0].distance.value) {
              this.distanceGoogleMaps = response.routes[0].legs[0].distance.value;
              console.log(this.distanceGoogleMaps, this.distanceLine)
            }
          }
        }
        );
      },
      addressName(addr) {
        return geoUtils.addressDb2Resume(addr);
      },
      clickChangeStatus(newStatus, index) {
        if(this.isSuperAdmin) {
          return this.$showError({title:this.$t('order.onlyRestaurantHaveAccess')});
        }
        if(this.statusSequenceIndexGraph<index) {
          this.changeStatus(newStatus);
        }
      },
      getStatusColor(item, index) {
        if(index<=this.statusSequenceIndexGraph) {
          return 'background-color: '+this.stateList.companyOrderStatus[item].color+';border-color: '+this.stateList.companyOrderStatus[item].color+';';
        }
        return '';
      },
      changeOrderCode() {
        if(this.isSuperAdmin) {
          return;
        }
       // console.log('teste', this.form.orderCode);
        if(this.timeoutUpdateCode) {
          clearTimeout(this.timeoutUpdateCode);
        }
        this.timeoutUpdateCode = setTimeout(async ()=>{
          if(this.itemId && this.lastOrderCode!==this.form.orderCode) {
            this.lastOrderCode= this.form.orderCode;
            await firebase.firestore().collection('userOrders').doc(this.itemId).set({orderCode: this.form.orderCode},{merge: true});
            this.$showSuccess({text:this.$t('order.successOrderCode')});
          }
        }, 700);
      },
      getFocus() {
        this.$refs.orderCode.$el.focus();
      },
      async goToNextStatus(item) {
        this.disableStatusChange = true;
        try {
          await firebase.firestore().collection('userOrders').doc(item.id).set({status: item.nextStatus},{merge: true});
          this.$showSuccess({text:this.$t('order.successStatus')});
        } catch(error) {
          this.$showError();
        }

        this.disableStatusChange = false;
      },
      async cancelOrder(item) {

        this.$swal({
          title: this.$t('order.youAreSure'),
          text: this.$t('order.youAreWantCancelOrder'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#ef0f18;',
          confirmButtonText: this.$t('order.youAreYes'),
          cancelButtonText: this.$t('order.youAreNo'),

          input: 'textarea',
          inputPlaceholder: this.$t('order.cancelReason'),
          inputAttributes: {
            'aria-label': 'Type your message here'
          },

        }).then(async (result) => {
          if(result.dismiss) {
            return;
          }
          await firebase.firestore().collection('userOrders').doc(item.id).set({status: stateList.OrderStatus.canceled, cancelReason: result.value ||''},{merge: true});
          this.$showSuccess({text:this.$t('order.successCancel')});
        });
      },
      showProduct(id) {
        console.log('TODO show product', id);
        this.$router.push({ name: 'company/products', params: { id: id } });
      },
      showVariantion(id, type) {
        console.log('TODO showVariantion', id, type);
        this.$router.push({ name: 'company/additionals', params: { type:type, id: id } });
      },
      showClient(id) {
        this.clientSelected = id;
        this.clientSelectedDialog = true;
      },
    },
  }
</script>

<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container" :style="isModal?'margin: 0;':''">
      <div :class="[$style.content, isModal?$style.disableShadow:'']">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded" @submit.prevent="companyUpdate">
          <div :class="$style.body">
            <div v-if="form.test" style="color: red; font-size: 25px">
              PEDIDO TESTE
            </div>
            <div :class="[$style.section, $style.sectionTitle]" >
              <div :class="[$style.orderInfo]">
                <div :class="[$style.closeButton]" @click="cancel"><MdIcon>close</MdIcon></div>
                <MdIcon>shopping_cart</MdIcon>
                <span>{{$t('order.number')}} #{{form.orderNumber}}</span>
                <span>{{$t('order.code')}}: </span>

                <MdField :class="[$style.orderEdit]">
                  <MdInput
                    ref="orderCode"
                    v-model="form.orderCode"
                    type="text"
                    :disabled="isSuperAdmin"
                    @input="changeOrderCode($event)"


                  >
                  </MdInput>
                  <span @click="getFocus()"><MdIcon >edit</MdIcon></span>


                </MdField>

                <span v-if="currentUser && currentUser.showId">devId: {{form.id}}</span>
               <!-- <MdField :class="$style.code">
                  <label>{{$t('order.orderCode')}}</label>
                  <MdInput v-model="form.orderCode" ></MdInput>
                </MdField> -->
              </div>
              <div>
                <MdButton
                  md-theme="buttonprimary"
                  :class="['md-primary','md-raised']"
                  @click="print"
                >
                  <MdIcon>print</MdIcon>
                  {{$t('form.print')}}
                </MdButton>
              </div>
            </div>
            <div :class="[$style.section]" >
              <table>
                <tr>
                  <td>{{$t('order.time')}}:</td>
                  <td>{{(typeof form.transformedInOrderDate==='object')?_moment(form.transformedInOrderDate.toDate()).format($t('format.data')+' HH:mm'):''}}</td>
                </tr>
                <tr>
                  <td>{{$t('order.clientName')}}:</td>
                  <td>
                    <span v-for="(client) in clients" :key="client.id" :class="$style.client" @click="showClient(client.id)">
                      {{client.name +' '+client.lastName+(client.phone?' - '+client.phone:'')}}<MdIcon v-if="client.createdEnv==='android'" :class="$style.icon">android</MdIcon><MdIcon v-else-if="client.createdEnv==='iOS'" :class="$style.icon">phone_iphone</MdIcon><MdIcon v-else :class="$style.icon">phone_android</MdIcon>,
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{{$t('order.total')}}:</td>
                  <td>{{$n(form.totalValue, 'currency')}}</td>
                </tr>
                <tr>
                  <td>{{$t('order.deliveryType')}}:</td>
                  <td>{{$t('deliveryType.'+form.deliveryType)}}</td>
                </tr>
                <tr v-for="pay in paymentList" :key="'pm'+pay.id" >
                  <td>{{pay.name}}</td>
                  <td>{{pay.status+ ', '+pay.description + ' '+$n(pay.value, 'currency')}}</td>
                  <td></td>
                </tr>
<!--
                <tr>
                  <td>{{$t('order.status')}}:</td>
                  <td>
                    <MdField>
                      <MdSelect v-if="form.status" v-model="form.status" @md-selected="changeStatus">
                        <MdOption v-for="sl of stateList.companyOrderStatus" :key="sl.id" :value="sl.id">{{$t('orderStatus.'+sl.id)}}</MdOption>
                      </MdSelect>
                    </MdField>
                  </td>
                </tr> -->
                <tr v-if="form.deliveryType==='delivery'">
                  <td>{{$t('order.address')}}:</td>
                  <td>{{addressName(form.deliveryAddress)}}</td>
                </tr>
                <tr v-if="distanceGoogleMaps">
                  <td>{{$t('order.distance')}}:</td>
                  <td>{{$n(parseInt(distanceLine))}}m</td>
                  <!--<td>{{distanceGoogleMaps}}m</td>-->
                </tr>

                <tr>
                  <td>{{$t('order.status')}}:</td>
                  <td :class="$style.nextStatusTd">
                    <span>{{$t('orderStatus.'+form.status)}}</span>
                    <span v-if="form.nextStatus">{{$t('order.changeForStatus')}}</span>
                    <MdButton
                      v-if="form.nextStatus"
                      md-theme="buttonprimary"
                      :class="['md-primary','md-raised']"
                      :disabled="disableStatusChange || isSuperAdmin"
                      @click.prevent.stop="goToNextStatus(form)"
                    >
                      {{$t('orderStatus.'+form.nextStatus)}}
                    </MdButton>

                    <MdButton
                      v-if="form.status!==stateList.OrderStatus.canceled && form.status!==stateList.OrderStatus.deliveryReady
                        && form.status!==stateList.OrderStatus.takeoutReady"
                      md-theme="buttonprimary"
                      :class="['md-primary','md-raised', $style.buttonCancell]"
                      :disabled="disableStatusChange || isSuperAdmin"
                      @click.prevent.stop="cancelOrder(form)"
                    >
                      {{$t('order.cancelOrder')}}
                    </MdButton>
                  </td>
                </tr>
                <tr v-if="form.status===stateList.OrderStatus.canceled && form.cancelReason">
                  <td>{{$t('order.cancelReason')}}:</td>
                  <td>{{form.cancelReason}}</td>
                </tr>
                <tr v-if="form.company && form.company.tableActive">
                  <td>{{form.company.onSiteTableName || $t('order.tableNameEmpty')}}:</td>
                  <td v-if="form.company.tables && form.additionalOnSiteDataId">{{(form.company.tables.find((t)=>t.id===form.additionalOnSiteDataId)||{}).name || $t('order.tableSelectError')}}</td>
                  <td v-else>{{$t('order.tableSelectEmpty')}}</td>
                </tr>
              </table>
            </div>
            <div :class="[$style.section, $style.orderStatus]" >
              <ul>
                <li v-for="(item, index) in stateList.companyOrderStatusSequenceGraph[form.deliveryType]" :key="item" :class="[(index<=statusSequenceIndexGraph)?$style.active:'', (index === 0? $style.liFirst:'')]"  @click="clickChangeStatus(item, index)">
                  <div :class="$style.lineContent">
                    <div :class="$style.line" :style="getStatusColor(item, index)+(index === 0? 'display:none;':'')"></div>
                  </div>
                  <div :class="$style.button">
                    <a href="javascript:;" :style="getStatusColor(item, index)">
                      <b>{{index+1}}</b>
                    </a>
                    <span :class="$style.statusName">{{$t('orderStatus.'+item)}}</span>
                    <span v-if="lastStatusKeyTime[item]" :class="$style.statusTime">{{_moment(lastStatusKeyTime[item].toDate()).format($t('format.data')+' HH:mm')}}</span>
                  </div>
                </li>
                <li v-if="form.status===stateList.PaymentStatus.canceled" :class="[$style.active]">
                  <div :class="$style.lineContent">
                    <div :class="$style.line" :style="getStatusColor(stateList.PaymentStatus.canceled, -1)"></div>
                  </div>
                  <div :class="$style.button">
                    <a href="javascript:;" :style="getStatusColor(stateList.PaymentStatus.canceled, -1)">
                      <b>{{stateList.companyOrderStatusSequenceGraph[form.deliveryType].length+1}}</b>
                    </a>
                    <span :class="$style.statusName">{{$t('orderStatus.'+stateList.PaymentStatus.canceled)}}</span>
                    <span v-if="lastStatusKeyTime[stateList.PaymentStatus.canceled]" :class="$style.statusTime">{{_moment(lastStatusKeyTime[stateList.PaymentStatus.canceled].toDate()).format($t('format.data')+' HH:mm')}}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div :class="[$style.section, $style.orderItems]" >
              <table>
                <thead>
                  <tr>
                    <td>{{$t('order.itemName')}}</td>
                    <td>{{$t('order.itemQtd')}}</td>
                    <td>{{$t('order.itemValueTotal')}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item) in form.lstProductsArray" :key="item.uuid" >
                    <td :class="$style.orderItem">
                      <span v-if="!item.flavorTmp" :class="$style.orderItemName" @click="showProduct(item.product.id)">{{item.product.name}}</span>
                      <span v-if="item.flavorTmp" :class="$style.orderItemAdditionalContentFlavor">
                        <span>{{/* item.flavorTmp.title */}}Pizza {{item.flavorTmpQtd}} Sabores:</span>
                        <span v-for="(choose, chooseI) in item.flavorTmp.lstChooseAdditionalObjects" :key="choose.id" :class="$style.orderItemNameFlavor" @click="showProduct(choose.chosedProduct.id)" >
                          {{'1/'+item.flavorTmpQtd+' ' +(chooseI?'':'')+(choose.qtd?choose.qtd+ 'x ':'')+choose.chosedProduct.name}}
                        </span>
                      </span>
                      <span :class="$style.orderItemDescription">{{item.product.description}}</span>
                      <span v-for="(additionals) in item.lstConfigProduct" :key="additionals.id" :class="$style.orderItemAdditional" @click="showVariantion(additionals.id, additionals.type)">
                        <span v-if="additionals.type!=='flavor' && additionals.lstChooseAdditionalObjects && additionals.lstChooseAdditionalObjects.length"
                              :class="$style.orderItemAdditionalContent">
                          <span>{{additionals.title}}:</span>
                          <span v-for="(choose, chooseI) in additionals.lstChooseAdditionalObjects" :key="choose.id">{{(chooseI?'':'')+(choose.qtd?choose.qtd+ 'x ':'')+choose.title}}</span>
                        </span>
                      </span>
                      <span v-if="item.tmpIngredientsRestrictions && item.tmpIngredientsRestrictions.length" :class="$style.orderItemAdditional" >
                        <span :class="$style.orderItemIngredientsRestrictions">
                          <span>{{$t('order.ingredientsRestrictions')}}:</span>
                          <span v-for="(choose, chooseI) in item.tmpIngredientsRestrictions" :key="chooseI" >{{$trdb(choose.name)}}</span>
                        </span>
                      </span>
                      <span v-if="item.observation" :class="$style.orderItemObservation"><b>{{$t('order.itemObservation')+ ': '}}</b>{{item.observation}}</span>

                    </td>
                    <td>{{item.qtd}}</td>
                    <td>{{$n(item.total, 'currency')}}</td>
                  </tr>
                  <tr v-if="form.deliveryType==='delivery'">
                    <td colspan="2">
                      {{$t('order.deliveryTxValue')}}
                    </td>
                    <td>{{$n(form.deliveryTxValue || 0, 'currency')}}</td>
                  </tr>
                  <tr>
                    <td :class="$style.orderItem" colspan="2">
                      <b>{{$t('order.total')}}</b>
                    </td>
                    <td>{{$n(form.totalValue, 'currency')}}</td>
                  </tr>
                  <tr v-for="pay in paymentList" :key="pay.id" >
                    <td>{{pay.name + ': ' + pay.description}}</td>
                    <td>{{pay.status}}</td>
                    <td>{{$n(pay.value, 'currency')}}</td>
                  </tr>

                  <tr v-if="form.changeValue">
                    <td :class="$style.orderItem" colspan="2">
                      <b>{{$t('order.changeFor')}}</b>
                    </td>
                    <td>{{$n(form.changeValue, 'currency')}}</td>
                  </tr>
                  <tr v-if="form.obsOrder">
                    <td :class="$style.orderItem" colspan="3">
                      <b>{{$t('order.obsOrder')}}: </b>
                      {{form.obsOrder}}
                    </td>
                  </tr>

                </tbody>

              </table>
            </div>
            <div v-if="markerDst" v-show="markerDst" :class="[$style.section]" >
              <GmapMap
                ref="map"
                :center="center"
                :zoom="13"
                map-type-id="terrain"
                style="max-width: 2000px; height: 700px"
              >
                <GmapPolyline
                  ref="polygon"
                  :path="directionPath"
                  :editable="false"
                  >
                </GmapPolyline>
                <GmapMarker
                  v-if="markerCompany"
                  :position="markerCompany.position"
                  :clickable="true"
                  :draggable="false"
                  :icon="{
                    url: require('@assets/images/map/img_map_pin_store.png'),
                    scaledSize: {height: 57, width: 36},
                    origin: {x:0, y:0},
                    anchor: {x:18, y:52}}"
                  @click="center=markerCompany.position"

                />
                <GmapMarker
                  v-if="markerDst"
                  :position="markerDst.position"
                  :clickable="true"
                  :draggable="false"

                  :icon="{
                    url: require('@assets/images/map/img_map_pin_home.png'),
                    scaledSize: {height: 57, width: 36},
                    origin: {x:0, y:0},
                    anchor: {x:18, y:52}}"
                  @click="center=markerDst.position"

                />
              </GmapMap>
            </div>

          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="clientSelectedDialog"
        :fullscreen="$vuetify.breakpoint.xs"
    >
      <OrderClient
        v-if="clientSelectedDialog"
        v-model="clientSelected"
        :company-id="form.companyId"
        :is-super-admin="isSuperAdmin"
        :order-id="itemId"
        @fcm-close="clientSelectedDialog = false"
      ></OrderClient>
    </v-dialog>
  </div>
</template>



<style lang="scss" module>
  @import '@design';


  .orderEdit {
    width: 45px;
    display: inline-flex;
    margin-right: 15px;
  }

  .orderEdit span {
    margin-left: 0!important;
  }

  .orderEdit input{
    width: 45px!important;
  }

  .nextStatusTd {
    display: flex;
    align-items: center;
    vertical-align: middle;
  }

  .nextStatusTd span{
    display: block;
  }

  .nextStatusTd > :nth-child(2){
    margin-left: 20px;
  }

  .buttonCancell {
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }


  .box {
    @extend %defaultBoxF1;

    flex: 2 1 0;
    .container {
      min-height: 530px;

      .disableShadow {
        box-shadow: none!important;
        max-width: 1500px!important;
      }
      .content {
        min-width: unset;
        max-width: 1000px;

        @media screen and (max-width: 820px) {
          max-width: unset;
        }
        padding: 20px;
        position: relative;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            font-family: $font-input;
            .client {
              cursor: pointer;
            }
            .closeButton {
              color: #bb2f2c;
              cursor: pointer;
              top: 9px;
              left: 9px;
              position: absolute;

              i {
                color: #bb2f2c;
              }
            }

            .orderInfo span {
              margin-left: 18px;
            }

            .sectionTitle {
              //  display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .section {
              margin-bottom: 30px;
            }
            .orderItem {
              max-width: 450px;
              .orderItemName {
                cursor: pointer;
                display: block;
                font-weight: bold;
              }
              .orderItemDescription {
                font-size: small;
                margin-left: 0px;
                display: block;
              }

              .orderItemNameFlavor {
                cursor: pointer;
              }

              .orderItemAdditionalContentFlavor {
                margin-top: 2px;
                display: block;
                /* margin-left: 5px; */
                span {
                  font-weight: bold;
                  display: block;
                  margin-left: 5px;
                }
                :first-child {
                  font-weight: normal;
                  margin-left: 0px;
                }
              }
              .orderItemAdditional {
                cursor: pointer;
                .orderItemAdditionalContent {
                  margin-top: 2px;
                  display: block;
                  margin-left: 5px;
                  span {
                    font-weight: bold;
                    display: block;
                    margin-left: 5px;
                  }
                  :first-child {
                    font-weight: normal;
                    margin-left: 0px;
                  }
                }
              }

              .orderItemIngredientsRestrictions {
                margin-top: 2px;
                display: block;
                margin-left: 5px;
                span {
                  font-weight: bold;
                  display: block;
                  margin-left: 5px;
                }
                :first-child {
                  font-weight: normal;
                  margin-left: 0px;
                }
              }

            }

            .statusPayment {
              vertical-align: baseline;
              border-radius: .25em;
              display: inline;
              /*color: #fff;*/
              padding: 2px;
            }

            .orderStatus {
              ul {
                display: flex;
                //  margin-left: 10px;
                //  margin-right: 10px;
                padding-right: 25px;

                padding-left: 0;

                @media screen and (max-width: 500px) {
                  flex-flow: row wrap;
                }


                .liFirst {
                  width: 100px;
                }
                li {
                  display: table-cell;
                  position: relative;
                  width: 100%;
                  @media screen and (max-width: 500px) {
                    width: 100px;
                  }

                  cursor: pointer;
                  .lineContent {
                    display: block;
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    top: 20px;
                    padding-right: 43px;
                    .line {
                      height: 3px;
                      width: 100%;
                      background-color: #a4a4a4;
                      box-shadow: 2px 1px 3px -1px rgba(0, 0, 0, 0.4), 1px 3px 7px 0 rgba(0, 0, 0, 0.3), 5px 5px 12px 0px rgba(0, 0, 0, 0.3);
                    }
                  }
                  .button {
                    float: right;
                    a {
                      border-radius: 25px;
                      height: 43px;
                      width: 43px;
                      border: 2px solid #a4a4a4;
                      display: block;
                      text-align: center;
                      background-color: #fff;
                      color: #a4a4a4;
                      float: right;
                      margin-left: 100%;
                      padding: 10px 15px;
                      box-shadow: 5px 3px 4px -1px rgba(0, 0, 0, 0.2), 6px 6px 7px 0 rgba(0, 0, 0, 0.14), 5px 5px 12px 0 rgba(0, 0, 0, 0.12);
                    }
                    span {
                      display:block;
                      // font-size: 12px;
                      text-align: center;
                      width: 50px;
                      margin-right: -30px;
                    }
                    .statusName {
                      margin-top: 54px;
                    }
                    .statusTime {
                      margin-top: 7px;
                    }
                  }
                }
                .active a b {
                  color: #ffffff;
                }
                .active, .active a {
                  cursor: initial;
                }
              }
            }

            .orderItems {

              table, th, td {
                border: 1px solid #e7ecf1;
                padding: 8px;
              }
              table {
                border-collapse: collapse;
              }

              thead {
                font-size: 18px;
                font-weight: bold;
              }
              tbody {
                background-color: #FBFCFD;
              }


            }
          }
        }
      }
    }
  }
</style>





















