<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainLayout from '@components/layout/company/main-layout'
  import ChildComponent from '@components/company/history_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainLayout, ChildComponent},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    watch: {
      currentCompany(value, oldValue)  {
        if(value && value.id) {
          if(oldValue && oldValue.id && oldValue.id===value.id) {
            return;
          }
          this.itemId = value.id;
        }
        else {
          this.itemId = null;
        }
      }
    },
    beforeMount: function() {
      if(this.currentCompany) {
        this.itemId = this.currentCompany.id;
      }
    },
    mounted(){
    },
    methods: {
    },
  }
</script>

<template>
  <MainLayout>
    <ChildComponent
      v-model="itemId"
    >
    </ChildComponent>
  </MainLayout>
</template>

<style lang="scss" module>
  @import '@design';

</style>
