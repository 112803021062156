<script>
  /* eslint-disable vue/no-v-html */
  import {appConfig} from '@src/config';

  export default {
    components: {},
    data() {
      return {
        appConfig: appConfig,
        tabSelected: 'tabFlexibility'
      }
    },
    methods: {
      changeTab(tab) {
        this.tabSelected = tab;
      }
    }
  }
</script>

<template>
  <div :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.tabMenuContainer">
        <div :class="[$style.tabMenuContainerItem, tabSelected==='tabFlexibility'?$style.tabMenuContainerItemSelected:'']" @click="changeTab('tabFlexibility')">
          <div>{{$t('home.tabFlexibility').toUpperCase()}}</div>
        </div>
        <div :class="[$style.tabMenuContainerItem, tabSelected==='tabCare'?$style.tabMenuContainerItemSelected:'']" @click="changeTab('tabCare')">
          <div>{{$t('home.tabCare').toUpperCase()}}</div>
        </div>
        <div :class="[$style.tabMenuContainerItem, tabSelected==='tabDiscounts'?$style.tabMenuContainerItemSelected:'']" @click="changeTab('tabDiscounts')">
          <div>{{$t('home.tabDiscounts').toUpperCase()}}</div>
        </div>
      </div>

      <div :class="[$style.tabContainer, tabSelected==='tabFlexibility'?'':$style.tabContainerHidden]">
        <div :class="$style.tabContainerItem">
          <div :class="$style.contentLeft">
            <div :class="$style.bodyLeft">
              <div :class="$style.flexibilityTxt1" v-html="$t('home.flexibilityTxt1')"></div>
              <div :class="$style.flexibilityTxt2" v-html="$t('home.flexibilityTxt2')"></div>
              <div :class="$style.flexibilityTxt3" v-html="$t('home.flexibilityTxt3')"></div>
            </div>
          </div>
          <div :class="$style.contentRight">
            <div :class="$style.bodyRight">
              <img :class="$style.featuredImage" :src="require('@assets/images/home/right-content-image@3x.png')" />
            </div>
          </div>
        </div>
      </div>

      <div :class="[$style.tabContainer, tabSelected==='tabCare'?'':$style.tabContainerHidden]">
        <div :class="$style.tabContainerItem">
          <div :class="$style.contentLeft">
            <div :class="$style.bodyLeft">
              <div :class="$style.flexibilityTxt1" v-html="$t('home.careTxt1')"></div>
              <div :class="$style.flexibilityTxt2" v-html="$t('home.careTxt2')"></div>
              <div :class="$style.flexibilityTxt3" v-html="$t('home.careTxt3')"></div>
            </div>
          </div>
          <div :class="$style.contentRight">
            <div :class="$style.bodyRight">
              <img :class="$style.featuredImage" :src="require('@assets/images/home/right-content-image-care@3x.png')" />
            </div>
          </div>
        </div>
      </div>

      <div :class="[$style.tabContainer, tabSelected==='tabDiscounts'?'':$style.tabContainerHidden]">
        <div :class="$style.tabContainerItem">
          <div :class="$style.contentLeft">
            <div :class="$style.bodyLeft">
              <div :class="$style.flexibilityTxt1" v-html="$t('home.discountsTxt1')"></div>
              <div :class="$style.flexibilityTxt2" v-html="$t('home.discountsTxt2')"></div>
              <div :class="$style.flexibilityTxt3" v-html="$t('home.discountsTxt3')"></div>
            </div>
          </div>
          <div :class="$style.contentRight">
            <div :class="$style.bodyRight">
              <img :class="$style.featuredImage" :src="require('@assets/images/home/right-content-image-total@3x.png')" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>


<style lang="scss" module>
  @import '@design';
  .box {
    width: 100%;
    background-color: $color-bg-white;
    color: #000000;
    .container {
      max-width: 1052px;
      margin: 0 auto;
      display: flex;
    //  min-height: 672px;
      padding: 16px 0 0 0;
      flex-direction: column;
      justify-content: flex-start;

      .tabMenuContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @media screen and (max-width: 980px) {
          display: none;
        }
        .tabMenuContainerItem {
          font-family: OpenSans, sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: center;
          cursor: pointer;
          height: 80px;
          margin: 0 16px 0 16px;

          flex: 1 0 auto;
          align-items: center;
          justify-content: center;
          display: flex;
        }
        .tabMenuContainerItemSelected {
          font-weight: 600;
          border-bottom: #44c7f3 solid 4px;
        }
      }

      .tabContainerHidden {
        display: none;
        @media screen and (max-width: 980px) {
          display: block;
        }
      }

      .tabContainer {
      //  min-height: 672px;
        .tabContainerItem {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 0 26px 0 26px;
          @media screen and (max-width: 980px) {
            flex-direction: column;
          }
          .contentLeft {
            padding-right: 16px;
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 980px) {
              padding: 35px 0 0 0;
            }

            .bodyLeft {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              max-width: 448px;

              .flexibilityTxt1, .flexibilityTxt2, .flexibilityTxt3 {
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                width: 100%;

                @media screen and (max-width: 980px) {
                  text-align: center;
                }
              }

              .flexibilityTxt1 {
                font-family: $font-montserrat;
                font-size: 28px;
                font-weight: 600;
                line-height: 1.71;
                color: #1f2633;
                @include typography-interpolate(
                    'font-size',
                    250px,
                    16px,
                    500px,
                    28px
                );

              }
              .flexibilityTxt2 {
                font-family: $font-montserrat;
                font-size: 40px;
                font-weight: 800;
                line-height: 1.2;
                color: #44c7f3;
                @include typography-interpolate(
                    'font-size',
                    250px,
                    20px,
                    500px,
                    40px
                );
              }
              .flexibilityTxt3 {
                margin-top: 24px;

                font-family: $font-openSans;
                font-size: 16px;
                font-weight: normal;
                line-height: 2;
                color: #444444;
                @include typography-interpolate(
                    'font-size',
                    250px,
                    14px,
                    500px,
                    16px
                );
              }

              .orderedDifferent {
                margin-top: 10px;
                max-width: 352px;
                text-align: center;
                font-family: OpenSans, sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: -0.8px;
                color: white;
              }
              .download {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;

                .downloadPlayStore, .downloadAppleStore {
                  width: 120px;
                  height: 40px;
                  object-fit: contain;
                  cursor: pointer;
                  margin: 10px;
                }
              }
            }
          }
          .contentRight {
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 15px;

            .bodyRight {
              .featuredImage {
                max-height: 672px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
</style>
