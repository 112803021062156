<script>
  import Locale from '@components/layout/locale'
  import { authComputed } from '@state/helpers'

export default {
  components: { Locale },
  data() {
    return {
    }
  },
  computed: {
    ...authComputed,
    routeName: function () {
      return this.$router.currentRoute.name
    }
  },
  methods: {
    goToCompany: function (event) {
      this.$router.push({ name: 'company/login' })
    }
  },
}
</script>

<template>
  <nav :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.logo">
        <RouterLink :class="$style.logoRouter" to="/" v-bind="$attrs">
          <img :src="require('@assets/images/logo/logo-blue@3x.png')" alt="logo" />
        </RouterLink>
      </div>
      <div :class="$style.containerLinks">
        <div>
          <div :class="[$style.itemlink, $style.menuActionsNot]">
            <RouterLink :class="$style.logoRouter" to="/company/login" v-bind="$attrs">
              <BaseButton class="md-primary md-raised md-lg" type="text">
                {{$t('navBar.accessCompany')}}
              </BaseButton>
            </RouterLink>
          </div>
          <div :class="[$style.itemlink]">
            <Locale />
          </div>
        </div>
      </div>
    </div>
  </nav>

</template>

<style lang="scss" module>
@import '@design';
  .box {
    background-color: $color-bg-white;
    width: 100%;
    .container {
      padding: 0px;
      height: 96px;
      max-width: $size-nav-bar-width-max;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      .logo {
        flex: 0;
        display: flex;
        min-width: 194px;
        margin-left: 72px;
        flex-direction: column;
        img {
          align-self: center;
          max-height: 96px;
        }
      }
      .containerLinks {
        display: flex;
        margin-right: 56px;
        div {
          flex-wrap: wrap;
          align-self: center;
          display: flex;
          div {
            align-self: center;
            /*margin-left: 30px;*/
            BaseButton {

            }
          }
          .itemlink {
            margin-left: 10px;
          }
          .menuActionsNot {
            display: block;
          }
        }
      }
    }
  }

@media screen and (max-width: $size-menu-actions+px) {
  .box {
    .container {
      .logo {
        flex: 3 1 auto;
        margin-left: 90px;
      }
      .containerLinks {
        margin-right: 25px;
        div {
          .menuActionsNot {
            display: none!important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .box {
    .container {
      .logo {
        flex: 3 1 auto;
        margin-left: 10px;
      }
      .containerLinks {
        margin-right: 25px;
        div {
          .menuActionsNot {
            display: none!important;
          }
        }
      }
    }
  }
}
</style>
