<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        defaultForm: {
          description: '',
          deleted: false,
          imgUrl: null,
          order: 0,
          search: '',
          subCategoryId: null,
          value: 0,
          active: true,
          companyId: null,
          visible: true,
        },

        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        imageSrc: null,
        uploadProgress: 0,
        imageBlob: null,

        form: {
          description: '',
          deleted: false,
          imgUrl: null,
          order: 0,
          search: '',
          subCategoryId: null,
          value: 0,
          active: true,
          companyId: null,
          visible: true,
        },

        isLoading: true,
        isNew: true,
      }
    },
    validations: {
      form: {
      }
    },
    computed: {
      ...authComputed,
    },
    watch: {
      itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value')
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          this.loadForm()
        }
        else {
          this.form = _.cloneDeep(this.defaultForm)
        }

      }
    },
    mounted: async function() {
      this.isLoading = false
    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        let obj = await firebase.firestore().collection('companiesCategories').doc(this.itemId).get();
        if(obj && obj.data()) {
          this.form = { ...this.defaultForm, ...obj.data()};
          this.isNew = false
        }
        else {
          this.isNew = true;
          this.form = _.cloneDeep(this.defaultForm)
        }
        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')}
          return
        }
        this.hasError = null
        this.tryingToUpdate = true
        if(!this.form.companyId) {
          this.form.companyId = this.currentCompany.id
        }
        this.form.order = parseInt(this.form.order || 0) || 0;
        if(this.form.name) {
          this.form.name = firebase.firestore.FieldValue.delete();
        }

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('companiesCategory/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              this.form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          this.form.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          await firebase.firestore().collection('companiesCategories').doc(this.itemId).set(this.form,{merge: true})
          this.$showSuccess();
          this.tryingToUpdate = false;
          this.$emit('changeModel', null);
          return
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName]
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            }
          }
        }
        return {'md-invalid':false}
      }
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <!--
                    <div :class="$style.imagePreview" @click="tryChangeImage()" >
                      <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
                      <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
                      <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
                    </div>
                    <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                               type-content="modal"
                               :max-width="180"
                               :max-height="180"
                               :aspect-ratio="0"
                               @changeBlob="changeBlob"
                    ></FcmEditor> -->
          <div :class="$style.body">
            <BaseInputText v-model="form.description" :label="$t('product.description')" :class="[getValidationClass('description'), 'formItemText']" maxlength="200">
            </BaseInputText>
            <div class="formItemSubSection"></div>
            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.active')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.visible')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.visible" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 500px;
      .content {
        max-width: 400px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {

          }
        }
      }
    }
  }
</style>





















