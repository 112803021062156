<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
 // import OrderEdit from "@components/products/order_edit";
  const stateList = require ('@common/status');

  export default {
    name: 'OrderClient',
    components: {OrderEdit: () => import('@components/products/order_edit')},
  //  components: {OrderEdit},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: { // clientId
        type: String,
        default: null,
      },
      orderId: {
        type: String,
        default: null,
      },
      companyId: {
        type: String,
        default: null,
      },
      isSuperAdmin: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        isLoading: false,

        form: {

        },
        defaultForm: {

        },
        orderResumes: [],
        orderSelectedDialog: false,
        orderSelected: null,
        companies: [],
        companySelected: null,

      }
    },
    computed: {
      ...authComputed,
      orderResumesCompany: {
        get() {
          if(this.companySelected) {
            return this.orderResumes.filter((a)=>a.companyId===this.companySelected.id);
          }
          else {
            return this.orderResumes;
          }
        }
      },
      sumValue: {
        get() {
          return this.orderResumesCompany.reduce((accumulator, item) => accumulator + parseFloat(item.status!==stateList.OrderStatus.canceled?item.totalValue:0), 0);
        }
      },
      sumValueIndividual: {
        get() {
          return this.orderResumesCompany.reduce((accumulator, item) => accumulator + parseFloat(item.status!==stateList.OrderStatus.canceled?item.totalValueIndividual:0), 0);
        }
      },
      sumQtd: {
        get() {
          return this.orderResumesCompany.reduce((accumulator, item) => accumulator + (item.status!==stateList.OrderStatus.canceled?1:0), 0);
        }
      },
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      this.isLoading = false;
      if(this.itemId) {
        await this.loadForm();
      }
    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
        this.$emit('fcm-close', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        this.orderResumes.splice(0);
        this.companies.splice(0);
        this.companySelected = null;

        let objP = firebase.firestore().collection('users').doc(this.itemId).get();
        let objOrderRef = firebase.firestore().collection('orderResume').where('lstUserIdsArray', 'array-contains', this.itemId);
        if(this.companyId && !this.isSuperAdmin) {
          objOrderRef = objOrderRef.where('companyId', '==', this.companyId);
        }
        objOrderRef = objOrderRef.orderBy('transformedInOrderDate');
        let objOrderP = objOrderRef.get();

        let obj = await objP;
        if(obj && obj.data()) {
          this.form = { ..._.cloneDeep(this.defaultForm), ...obj.data()};
          this.isNew = false;
        }
        else {
          this.isNew = true;
          this.form = _.cloneDeep(this.defaultForm)
        }
        (await objOrderP).forEach((objOrder)=>{
          const item = {...objOrder.data(), id: objOrder.id};
          if(item.groupCode && item.paymentMethod && item.paymentMethod[this.itemId]) {
            item.totalValueIndividual = item.paymentMethod[this.itemId].groupUserTotalValue || 0;
          }
          else {
            item.totalValueIndividual = item.totalValue;
          }
          this.orderResumes.push(item);
          if(item.company && !this.companies.find((a)=>item.companyId===a.id)) {
            this.companies.push({...item.company, id:item.companyId});
          }
        });

        console.log('loaded form', this.form);
        this.isLoading = false;
      },
      showOrder(id) {
        this.orderSelected = id;
        this.orderSelectedDialog = true;
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.content">
      <div :class="[$style.closeButton]" @click="cancel"><MdIcon>close</MdIcon></div>
      <BaseDev>{{itemId}}</BaseDev>
      <br>
      <div :class="$style.head">
        <br>
        <v-select
            v-if="isSuperAdmin"
            v-model="companySelected"
            :items="companies"
            :item-value="(item)=>item"
            :item-text="(item)=>item.name"
            :label="$t('history.selectCompany')"
            :clearable="true"
        ></v-select>
        <br>
        <table>
          <tr>
            <td>{{$t('history.clientName')}}</td><td>{{form.name+' '+form.lastName}}</td>
          </tr>
          <tr>
            <td>{{$t('history.date')}}</td><td>{{form.createdAt?moment(form.createdAt.toDate()).format($t('format.dataHour')):''}}</td>
          </tr>
          <tr>
            <td>{{$t('history.clientEnv')}}</td><td>{{form.createdEnv}}</td>
          </tr>
          <tr>
            <td>{{$t('history.qtdOrders')}} {{$t('history.notCanceled')}}</td><td>{{sumQtd}}</td>
          </tr>
          <tr>
            <td>{{$t('history.valueOrders')}} {{$t('history.notCanceled')}}</td><td>{{$n(sumValue, 'currency')}}</td>
          </tr>
          <tr>
            <td>{{$t('history.valueOrdersIndividual')}} {{$t('history.notCanceled')}}</td><td>{{$n(sumValueIndividual, 'currency')}}</td>
          </tr>
        </table>
      </div>

      <br>
      <div v-if="companyId && !isSuperAdmin">
        {{$t('history.orderOnCompany')}}
      </div>
      <div v-else>
        {{$t('history.orderOnGotap')}}
      </div>
      <MdTable :class="$style.orderTable" >
        <MdTableRow>
          <md-table-head>{{$t('history.company')}}</md-table-head>
          <md-table-head md-numeric>{{$t('history.totalValue')}}</md-table-head>
          <md-table-head md-date>{{$t('history.date')}}</md-table-head>
          <md-table-head>{{$t('history.status')}}</md-table-head>
          <md-table-head v-if="isSuperAdmin" md-numeric>{{$t('history.companyName')}}</md-table-head>
          <md-table-head md-numeric>{{$t('history.orderCode')}}</md-table-head>
          <md-table-head md-numeric>{{$t('history.orderNumber')}}</md-table-head>
        </MdTableRow>

        <MdTableRow v-for="(item) in orderResumesCompany" :key="item.id" :class="$style.orderRow" @click="showOrder(item.id)" >
          <md-table-cell>{{item.company?item.company.name:''}}</md-table-cell>
          <md-table-cell md-numeric>{{item.totalValueIndividual===item.totalValue?$n(item.totalValue, 'currency'):($n(item.totalValueIndividual, 'currency')+ ' ('+$n(item.totalValue, 'currency')+')')}}</md-table-cell>
          <md-table-cell md-date>{{moment(item.transformedInOrderDate.toDate()).format($t('format.data'))}}</md-table-cell>
          <md-table-cell>{{$t('orderStatus.'+item.status)}}</md-table-cell>
          <md-table-cell v-if="isSuperAdmin" md-numeric>{{(item.company || {}).name}}</md-table-cell>
          <md-table-cell md-numeric>{{item.orderCode}}</md-table-cell>
          <md-table-cell md-numeric>{{item.orderNumber}}</md-table-cell>
        </MdTableRow>
        <MdTableRow v-if="!orderResumes || !orderResumes.length" :class="$style.orderRow" >
          <md-table-cell colspan="100">{{$t('history.emptyLabel')}}</md-table-cell>
        </MdTableRow>

      </MdTable>
    </div>
    <v-dialog
        v-model="orderSelectedDialog"
        :fullscreen="$vuetify.breakpoint.xs"
    >
      <OrderEdit
          v-if="orderSelectedDialog"
          v-model="orderSelected"
          :is-modal="true"
          :is-super-admin="isSuperAdmin"
          @fcm-close="orderSelectedDialog = false"
      ></OrderEdit>
    </v-dialog>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    background-color: white;
    .content {
      padding: 10px 20px 30px 10px;
      position: relative;
      .head {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        table {
          border-collapse: collapse;
          text-align: left;
          td {
            border: 1px solid #e7ecf1;
            padding: 8px;
          }
        }
      }

      .closeButton {
        color: #bb2f2c;
        cursor: pointer;
        top: 9px;
        left: 9px;
        position: absolute;
        i {
          color: #bb2f2c;
        }
      }

      .orderRow {
        cursor: pointer;
      }
    }
  }
</style>





















