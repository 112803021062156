<script>
  import {appConfig} from '@src/config';
  import Layout from '@components/layout/ong/main-visitant'
  import { authMethods } from '@state/helpers'
  import { required, minLength, email } from 'vuelidate/lib/validators'

  export default {
    page: {
      title: 'Registro',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {Layout},
    data() {
      return {
        form: {
          name: '',
          email: '',
          password: ''
        },
        updateError: null,
        tryingToRegister: false
      }
    },
    validations: {
      form: {
        ongName: {
          required,
          minLength: minLength(4)
        },
        name: {
          required,
          minLength: minLength(4)
        },
        email: {
          required,
          minLength: minLength(4),
          email
        },
        password: {
          required,
          minLength: minLength(6)
        }
      }
    },
    watch: {
      logged() {
        if (this.$store.getters['auth/loggedIn'] && !this.tryingToRegister) {
          console.log('Redirecionar usuário logado')
          this.$router.push({ name: 'ong/profile' })
        }
      }
    },
    methods: {
      ...authMethods,
      tryToRegister() {

        let trace = this.$performance().trace('ongRegister');
        trace.start();

        this.$v.$touch()

        if (this.$v.$invalid) {
          this.updateError = {message:'invalid input form'}
          return
        }
        this.tryingToRegister = true
        // Reset the updateError if it existed.
        this.updateError = null
        return this.ongRegister(this.form)
        .then((token) => {
          this.tryingToRegister = false
          trace.stop();

          // Redirect to the originally requested page, or to the home page
          this.$router.push(this.$route.query.redirectFrom || { name: 'ong/profile' })
        })
        .catch((error) => {
          console.error('Erro ao tentar registrar', error);
          this.tryingToRegister = false
          this.updateError = error
          trace.stop();
        })


      },
      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName]
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            }
          }
        }
        return {'md-invalid':false}
      },
      goToLogin() {
        this.$router.push({ name: 'ong/login' });
      }
    }
  }
</script>


<template>
  <Layout>
    <div :class="$style.box">
      <div :class="$style.container">
        <div :class="$style.content" >
          <div :class="$style.cadastreSeuRestaurante">{{$t('register.singInOng')}}</div>

          <div :class="$style.body">
            <form :class="$style.form" @submit.prevent="tryToRegister">
              <BaseInputText
                      v-model="form.ongName"
                      :label="$t('form.ongName')"
                      :class="getValidationClass('ongName')"
              >
                <span v-if="!$v.form.ongName.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.ongName.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              </BaseInputText>
              <BaseInputText
                      v-model="form.name"
                      :label="$t('form.name')"
                      :class="getValidationClass('name')"
              >
                <span v-if="!$v.form.name.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.name.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              </BaseInputText>
              <BaseInputText
                      v-model="form.email"
                      :label="$t('form.email')"
                      :class="getValidationClass('email')"
              >
                <span v-if="!$v.form.email.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.email.invalid" class="md-error" >{{$t('form.error.email')}}</span>
              </BaseInputText>
              <BaseInputText
                      v-model="form.password"
                      type="password"
                      :label="$t('form.password')"
                      :class="getValidationClass('password')"
              >
                <span v-if="!$v.form.password.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.password.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              </BaseInputText>
              <p v-if="updateError" :class="$style.error">
                {{$t('form.error.update')}}
              </p>
              <BaseButton
                      :class="['md-primary','md-raised','md-lg', $style.fullwidth ]"
                      :disabled="tryingToRegister"
                      type="submit"
                      :fullwidth="true"
              >
                <BaseIcon v-if="tryingToRegister" name="sync" spin />
                <span v-else>
                  {{$t('form.continue')}}
                </span>
              </BaseButton>

              <div
                :class="['md-primary','md-raised','md-lg', $style.loginText]"
                :disabled="tryingToRegister"
                @click="goToLogin"
              >
                <BaseIcon v-if="tryingToRegister" name="sync" spin />
                <span v-else>
                  {{$t('login.goLogin')}}
                </span>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>



  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.error {
  color: $color-input-error;
}

.box {
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    padding: 80px 10px 20px 10px;

    @media screen and (max-width: 980px) {
      flex-direction: column-reverse;
      padding-top: 28px;
    }

    .chamada {
      width: 55%;
      text-align: left;
      color: $color-chamada-txt;
      align-self: center;
      .aumentesuasvendas {
        font-size: 64px;
        @media screen and (max-width: 980px) {
          padding-top: 28px;
        }

        @include typography-interpolate(
            'font-size',
            300px,
            30px,
            1100px,
            64px
        );

        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: -0.5px;
      }
      p {
        max-width: 352px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: -0.8px;
        margin-bottom: 40px;
      }
      .saibamais {
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.15;
        letter-spacing: normal;
      }
    }
    .content {
      width: 95%;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      align-self: center;
      max-width: 500px;
      .cadastreSeuRestaurante {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .jaPossuiConta {
        font-family: $font-secondary-text;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #9b9b9b;

      }
      .criaUmaNovaConta {
        font-family: $font-secondary-text;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $color-primary;
      }
      .body {
        font-family: $font-input;
        .fullwidth {
          width: 100%;
        }
        BaseButton {
          font-family: $font-primary-text;
        }

        .loginText {
          margin-bottom: 5px;
          margin-top: 10px;
          width: 100%;
          color: #4872c2;
          cursor: pointer;
          padding: 7px;
          align-content: center;
          text-align: center;
        }
      }
    }
  }
}



</style>





















