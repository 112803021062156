<script>
  import { authComputed } from '@state/helpers'
  import SearchPick from '@components/diversos/searchPick'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'
  import {gmapApi} from 'vue2-google-maps'
  import {userService} from '@common/services';
  const timeUtils = require ('@common/time_utils');
  let {languages} = require('@i18n');

  export default {
    components: {SearchPick},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        imageBlob: null,
        languages: languages,
        tryingToUpload: false,
        isLoading: true,

        form: {
          areas: [],
          name: {},
          active: false,
          utcOffset: '',
          users1: [],
          users2: []
        },
        defaultForm: {
          areas: [],
          name: {},
          active: false,
          utcOffset: '',
          users1: [],
          users2: []
        },

        markers: [],
        center: {lat:0, lng:0},
        areas: [],
        infoWindowPos: null,
        infoContent: '',
        infoWinOpen: false,
        areaToDelete: false,
        hasAddress: true,


        customTokens: {
          mask: 'M##:##',
          tokens: {
            'M': {pattern: /[-+]{0,1}/},
            'Y': {pattern: /[0-9]/},
            '#': {pattern: /\d/},
            'X': {pattern: /[0-9a-zA-Z]/},
            'S': {pattern: /[a-zA-Z]/},
            'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
            'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
            '!': {escape: true}
          }
        },


      }
    },
    validations: {

    },
    computed: {
      ...authComputed,
      google: gmapApi
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }
      }
    },
    mounted: async function() {
      if(this.itemId) {
        await this.loadForm();
      }
      this.isLoading = false;
      this.loadMapDrawingManager();
    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        this.hasAddress = false;
        this.areas = [];
        this.center = {lat: -20.4695185, lng: -54.6203906};

        let obj = await firebase.firestore().collection('regions').doc(this.itemId).get();
        if(obj && obj.data()) {
          let region = obj.data() ||{};
          this.form = { ..._.cloneDeep(this.defaultForm), ...region};

          if(region.areas) {
            this.areas = region.areas;
          }
        }
        else {
          this.form = _.cloneDeep(this.defaultForm)
        }

        if(this.form.box && this.form.box.lat && this.form.box.lng) {
          this.center = {
            lat: this.form.box.lat,
            lng: this.form.box.lng
          };
        }

        this.form.utcOffset = timeUtils.minutes2field(this.form.utcOffset);

        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;

        form.areas = this.areas;
        this.active = !!this.active;

        form.box = {
          minLat: 200,
          minLng: 200,
          maxLat: -200,
          maxLng: -200
        };
        form.utcOffset = timeUtils.field2minutes(form.utcOffset);

        for(const area of this.areas) {
          for(const p of area.paths) {
            if(form.box.maxLat<p.lat) {
              form.box.maxLat=p.lat;
            }
            if(form.box.minLat>p.lat) {
              form.box.minLat=p.lat;
            }
            if(form.box.maxLng<p.lng) {
              form.box.maxLng=p.lng;
            }
            if(form.box.minLng>p.lng) {
              form.box.minLng=p.lng;
            }
          }
        }
        form.box.lat = form.box.minLat + ((form.box.maxLat - form.box.minLat)/2);
        form.box.lng = form.box.minLng + ((form.box.maxLng - form.box.minLng)/2);

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('regions/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          await firebase.firestore().collection('regions').doc(this.itemId).set(form,{merge: true});
          this.$showSuccess();
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      async addPolygon(event) {
        let pathsTmp = event.overlay.getPath().getArray();

        const {value: areaName} = await this.$swal({
          title: this.$t('form.getRegionName'),
          input: 'text',
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            return !value && 'You need to write something!'
          }
        });

        if(!areaName) {
          event.overlay.setMap(null);
          throw this.$t('form.error.regionAreaName')
        }
        let id = firebase.firestore().collection('companies').doc().id;

        let paths = [];

        for (var j = 0; j < pathsTmp.length; j++) {
          paths.push({
            lat: pathsTmp[j].lat(),
            lng: pathsTmp[j].lng()
          })
        }
        event.overlay.setMap(null);
        this.areas.push({name:areaName, paths: paths, id: id });

      },

      loadMapDrawingManager() {
        let self = this;
        this.$refs.map.$mapPromise.then((mapObject) => {
          const drawingManager = new this.google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
              position: this.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [this.google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: {
              fillColor: '#e9342c',
              fillOpacity: 0.7,
              strokeColor: '#AA2143',
              strokeWeight: 4,
              editable: true,
              draggable: true
            }
          });
          drawingManager.setMap(mapObject);

          this.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
            // Get overlay paths
            // let paths = event.overlay.getPaths().getArray();
            // Remove overlay from map
            // event.overlay.setMap(null);

            // Disable drawingManager
            // drawingManager.setDrawingMode(null);

            // Create Polygon
            self.addPolygon(event);
          });
          /*
                    if(this.areas && 1===2) {
                      for(let i in this.areas) {
                        console.log('Add palygon')
                        var o = new this.google.maps.Polygon({
                          paths: this.areas[i].paths,
                          fillColor: '#e9342c',
                          fillOpacity: 0.7,
                          strokeColor: '#AA2143',
                          strokeWeight: 4,
                          editable: true,
                          draggable: true
                        });


                        o.setMap(mapObject);

                       // addListenerPolygon(o, key);
                      }
                    } */

        });
      },
      pathChange (mvcArray, area) {
        for (let i=0; i<mvcArray.getLength(); i++) {
          let path = [];
          for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
            let point = mvcArray.getAt(i).getAt(j);
            path.push({lat: point.lat(), lng: point.lng()});
          }
          area.paths = path;
        }
      },
      showDescPolygon(event, area) {
        this.infoWindowPos = event.latLng;
        this.infoContent = this.$t('maps.areaDeliveryInfo')+ ': '+area.name+' ';
        this.infoWinOpen = true;
        this.areaToDelete = area
      },
      deleteArea() {
        if(this.areaToDelete) {
          let index = _.findIndex(this.areas, (o) => { return o.id === this.areaToDelete.id })
          if(index>=0) {
            this.areas.splice(index, 1);
          }
          this.infoWinOpen = false;
          this.areaToDelete = false;
        }
      },
      async getUsers(input) {
        return userService.getUsers({
          search: input.search,
          size: input.size,
          from: input.from,
          lang: this.$i18n.locale
        });
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <p>Atenção, ao atualizar a área da região, é necessário executar os jobs fixRegionsUsers e fixRegionsCompanies</p>
          <!--
                    <div :class="$style.imagePreview" @click="tryChangeImage()" >
                      <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
                      <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
                      <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
                    </div>
                    <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                               type-content="modal"
                               :max-width="180"
                               :max-height="180"
                               :aspect-ratio="0"
                               @changeBlob="changeBlob"
                    ></FcmEditor> -->
          <div :class="$style.body">
            <MdTabs v-if="!isLoading" >
              <MdTab v-for="(item, index) in languages"  :key="index+item.database"  :md-label="item.name" >
                <BaseInputText
                  v-model="form.name[item.database]"
                  :label="$t('form.name')"
                >
                </BaseInputText>
              </MdTab>
            </MdTabs>
            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.active')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <MdField :class="[$style.schedulesItens]">
              <label>{{$t('all.regionUtcOffset')}}</label>
              <MdInput
                v-model="form.utcOffset"
                v-mask="customTokens"
                type="text"
              >
              </MdInput>
            </MdField>
            <br>



            <GmapMap
              ref="map"
              :center="center"
              :zoom="11"
              map-type-id="terrain"
              :class="$style.map"
              :options="{
                 fullscreenControl: false,
               }"
            >
              <GmapMarker
                v-for="(m, index) in markers"
                :key="index"
                :ref="'mapMakerRef'+index"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                :icon="{
                    url: require('@assets/images/map/img_map_pin_store.png'),
                    scaledSize: {height: 57, width: 36},
                    origin: {x:0, y:0},
                    anchor: {x:18, y:52}}"
                @click="center=m.position"
              />
              <GmapInfoWindow
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
              >
                <div :class="$style.infoBox">
                  <div :class="$style.infoText">{{infoContent}}</div>
                  <div :class="$style.infoButton">
                    <MdButton :class="['md-primary', 'md-raised']" @click="deleteArea()">
                      {{$t('form.delete')}}
                    </MdButton>
                  </div>
                </div>
              </GmapInfoWindow>
              <GmapPolygon
                v-for="(area, index ) in areas" :key="index+'map'"
                :options="{fillColor: '#e9342c',
                    fillOpacity: 0.7,
                    strokeColor: '#AA2143',
                    strokeWeight: 4}"
                :draggable="true"
                :editable="true"
                :paths="area.paths"
                :deep-watch="true"
                @paths_changed="pathChange($event, area)"
                @click="showDescPolygon($event, area)"



              >

              </GmapPolygon>
            </GmapMap>

            <div :class="$style.areasContent">
              <table>
                <tr v-for="(value) in areas" :key="value.id">
                  <td>{{value.name}}</td>
                  <td></td>
                  <td @click="deleteAreaById(value.id)"><MdIcon :class="$style.deleteArea">delete</MdIcon></td>
                </tr>
              </table>
            </div>

            <SearchPick v-model="form.users1"
                        :get-items="getUsers"
                        :label="$t('form.selectUsersOrderNotifications1')"
                        :show-img="false"
                        :filter-region="true"
                        :limit="0"
            >
              <template #item="slotProp" >
                <div>{{slotProp.item.name+(slotProp.item.lastName?'_'+slotProp.item.lastName:'')}}</div>
              </template>
              <template #selected="slotProp" >
                <div>{{slotProp.item.name+(slotProp.item.lastName?'+'+slotProp.item.lastName:'')}}</div>
              </template>
            </SearchPick>

            <SearchPick v-model="form.users2"
                        :get-items="getUsers"
                        :label="$t('form.selectUsersOrderNotifications2')"
                        :show-img="false"
                        :filter-region="true"
                        :limit="0"
            >
              <template #item="slotProp" >
                <div>{{slotProp.item.name+(slotProp.item.lastName?'_'+slotProp.item.lastName:'')}}</div>
              </template>
              <template #selected="slotProp" >
                <div>{{slotProp.item.name+(slotProp.item.lastName?'+'+slotProp.item.lastName:'')}}</div>
              </template>
            </SearchPick>


          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 600px;
      .content {
        max-width: 980px;
        padding: 20px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            .roundAt {
              width: 200px;
            }
            .restrictionBox {
              display: flex;
              .formItemText {
                margin-left: 10px;
                flex: 1;
              }
            }

            .schedulesItens {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }

            .schedulesItensTitle {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }

            .infoBox {
              text-align: center;
              .infoText {
                font-size: 19px;
                padding: 10px;
              }
              .infoButton {
                padding: 10px;
              }
            }
            .map {
              width: 100%; height: 700px
            }

            .areasContent {
              margin-top: 20px;
              .deleteArea {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
</style>





















