<script>
  /* eslint-disable vue/no-v-html */
import Layout from '@components/layout/visitant/main-layout'
import {appConfig} from '@src/config';
import firebase from 'firebase/app'

export default {
  page: {
    title: '',
    meta: [
      {name: 'description', content: `${appConfig.title}`}
    ],
  },
  components: { Layout },
  data() {
    return {
      itemId: null,
      slug: null,
      form: {
        name: {},
        imgUrl: null,
        order: 0,
        active: true,
        txt: {},
        abstract: {},
      },
      defaultForm: {
        name: {},
        imgUrl: null,
        order: 0,
        active: true,
        txt: {},
        abstract: {},
      },
      notFound: false,
      isLoading: true,
    }
  },
  computed: {
  },
  watch: {

  },
  mounted: async function () {
    if(this.$route.params.id) {
      this.slug = this.$route.params.id;
      this.loadForm(this.slug);
    }
    else {
      this.loadForm();
    }
  },
  methods: {
    async loadForm(slug) {
      this.form = _.cloneDeep(this.defaultForm);

      if(!slug) {
        document.title = appConfig.title;
        this.notFound = true;
        return;
      }
      let objs = await firebase.firestore().collection('pages').where('slug', '==', this.slug).get();

      this.notFound = true;
      objs.forEach((obj)=>{
        if(obj && obj.data()) {
          this.notFound = false;
          this.form = { ..._.cloneDeep(this.defaultForm), ...obj.data()};
          if(typeof this.form.name !== 'object') {
            this.form.name = {};
          }
        }
      });
      document.title = (this.$trdb(this.form.name)?this.$trdb(this.form.name)+ ' | ':'')+appConfig.title;

      console.log('loaded form', this.form);
      this.isLoading = false;
    },
  },


}
</script>

<template>
  <Layout>
    <div :class="$style.container">
      <div :class="$style.content" >
        <div :class="$style.title">{{$trdb(form.name)}}</div>
        <div :class="$style.image">
          <img :src="form.imgUrl" />
        </div>

        <div :class="$style.body" v-html="$trdb(form.txt)">
        </div>
        <div v-if="notFound">
          {{$t('blog.notFound')}}
        </div>

      </div>

    </div>

  </Layout>
</template>


<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 300px;
    padding-bottom: 30px;
    .content {
      width: 100%;
      padding: 25px 10px 15px;
      text-align: left;
     // background-color: $color-form-bg;
      margin: 0 auto;
   //   max-width: $size-content-width-max;
      .title {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .image img {
        border-radius: 8px;
      }
      .body {
        margin-top: 20px;
        font-family: $font-input;
      }
    }
  }


</style>
