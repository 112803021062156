module.exports = {
  order: {
    title: "Order",
    order: "Order",
    date: "Date/Time",
    via: "Via",
    paymentTitle: "Payment",
    paymentOnTakeout: "Withdrawal Payment",
    clientName: "Client",
    clientPhone: "telephone",
    clientDocumentID: "CPF",
    product: "Product",
    qtd: "Qtd",
    viaOption: {
      "0": "Box / Counter",
      "1": "Internal / Kitchen",
      "2": "Deliveryman"
    },
    subTotal: "Subtotal",
    total: "Total",
    credits: "Credits",
    deliveryTax: "Delivery",
    changeFor: "Change for",
    change: "Change",
    address: "Address",
    paymentType: "Type of payment",
    type: "Type",
    deliveryAddress: "Address",
    deliveryType: "Type of order",
    value: "Value",
    itemName: "Product",
    itemQtd: "Amount",
    itemValueTotal: "Total",
    payment: "Payment",
    status: "State",
    time: "Date",
    newOrderNotificationTitle: "New request",
    newOrderNotificationBody: "A new request has just been received",
    changeForStatus: "Change to",
    number: "Request number",
    code: "Request code",
    numberSlim: "Number",
    codeSlim: "Code",
    distance: "Distance",
    itemObservation: "Obs",
    obsOrder: "Obs request",
    ingredientsRestrictions: "Ingredient Restriction",
    successOrderCode: "Success updating order code",
    successStatus: "Success updating order state",
    cancelOrder: "Cancel Order",
    successCancel: "Success canceling order",
    youAreSure: "Are you sure?",
    youAreWantCancelOrder: "Do you want to cancel this order?",
    youAreYes: "Yes, I want to cancel the order",
    youAreNo: "No, I do not want to cancel the order",
    appNameTitle: "Go Tap",
    noOrder: "No Orders",
    onlyRestaurantHaveAccess: "Only the restaurant has access to this action",
    deliveryTxValue: "Delivery fee",
    cancelReason: "Reason for cancellation",
    tableNameEmpty: "Place",
    tableSelectEmpty: "No location selected",
    tableSelectError: "Invalid selected location"
  },
  format: {
    data: "MM/DD/YYYY",
    dataHour: "MM/DD/YYYY HH:mm"
  },
  deliveryType: {
    delivery: "Delivery",
    pickup: "Withdraw in store",
    onSite: "On Request"
  },
  message: {
    hello: "hello world2"
  },
  login: {
    access: "Access Restaurant",
    accessCompany: "Access Restaurant",
    errorCompany: "No company associated with this email",
    login: "Log In",
    goReset: "I forgot my password",
    goLogin: "Return to login",
    accessClient: "Customer Access",
    accessAdmin: "Admin access",
    facebook: "Sign in with Facebook",
    google: "Enter with Google",
    apple: "Sign in with Apple",
    phone: "Login with Phone",
    facebookRegister: "Facebook Registration",
    googleRegister: "Signup with Google",
    appleRegister: "Apple Registration",
    phoneRegister: "Telephone Registration",
    emailRegister: "Email Registration",
    getPhoneNumber: "Enter your phone number",
    getPhoneCode: "Enter your verification code sent to your mobile phone",
    resetCompany: "Reset Password",
    reset: "Send email",
    error: {
      generic: "There was an error logging in",
      phoneEmpty: "Empty phone number",
      phoneCodeEmpty: "Empty verification code",
      loginTitle: "Error Logging In",
      providerTitle: "Error adding provider"
    },
    providerCode: {
      "auth/invalid-email": "Invalid email",
      "auth/user-disabled": "Account disabled",
      "auth/user-not-found": "User not found",
      "auth/wrong-password": "Incorrect Password",
      "auth/credential-already-in-use": "Credential already used",
      "auth/too-many-requests": "Too many attempts, try again later",
      "auth/operation-not-allowed": "Login type not set, try another form of login"
    },
    addFacebook: "Add Facebook",
    addGoogle: "Add Google",
    addApple: "Add Apple",
    providers: "Authentication Methods",
    remove: "Remove",
    providerId: "provider",
    email: "email",
    uid: "uid",
    changePassword: "Change Password",
    addPassword: "Add Password",
    resetClient: "Reset Password",
    register: "Register",
    registerClient: "Register",
    resetPassword: "Reset Password",
    makeRegister: "Sign up",
    accessOng: "Access Ong"
  },
  all: {
    restaurants: "Restaurants",
    restaurant: "Restaurant",
    next: "Next",
    knowMore: "Learn More",
    notImplemented: "Not implemented",
    attendance: "Attendance",
    orders: "Orders",
    reviews: "Reviews",
    promotions: "Promotions",
    settings: "Settings",
    products: "Products",
    delivery: "Delivery",
    schedule: "Schedule",
    information: "Information",
    informations: "Information",
    performance: "Performance",
    history: "History",
    demographics: "Demographics",
    of: "of",
    step: "Stage",
    category: "Category",
    subCategory: "Sub Category",
    profile: "Profile",
    categories: "Categories",
    users: "Users",
    paymentOptions: "Payment",
    otherOptions: "Others",
    conclude: "Conclude",
    close: "Close",
    cancel: "Cancel",
    nothingToList: "Empty List",
    search: "Search",
    productsCategories: "Categories",
    productsSubCategories: "Sub Categories",
    additionals: "Additional",
    createProduct: "Create Product",
    editCategory: "Edit Category",
    editProduct: "Edit Product",
    editSubCategory: "Edit Subcategory",
    settingsProducts: "Menu Settings",
    companiesTags: "Company Tags",
    productsNutritional: "Nutritional",
    productsNags: "Product Tags",
    vdTotal: "VD total",
    categoriesProducts: "Product Categories",
    productsTags: "Product Tags",
    productsIngredients: "Ingredients",
    cards: "Cards",
    companyConfigs: "Settings",
    edit: "To edit",
    create: "New",
    clients: "Customers",
    regions: "Regions",
    region: "Region",
    regionUtcOffset: "UtcOffset",
    jobs: "Jobs",
    tags: "Tags",
    tagAssociate: "Associated Tag",
    company_tags: "Tags Company",
    product_nutritional: "Nutritional",
    product_tags: "Tags Product",
    onSite: "On-site Consumption",
    takeout: "Takeout",
    blog: "Blog",
    faq: "Faq",
    pages: "Pages",
    blogCategories: "Blog Categories",
    paymentConfigs: "Payment settings",
    adminDeliveryOptions: "Delivery options",
    ongs: "Ongs",
    ong: "Ong",
    yes: "Yes",
    no: "No",
    contactSupport: "Contact support",
    iuguAccounts: "Iugu Sub Accounts",
    exports: "Export",
    changeImage: "Change image",
    companies: "Restaurants",
    notifications: "Notifications",
    hubs: "Hubs",
    adCards: "AdCards"
  },
  form: {
    error: {
      required: "This field is required",
      minLength: "The field is too short",
      maxLength: "The field is too big",
      update: "There was some error updating the data",
      email: "Invalid email",
      image: {
        type: "Tipo de arquivo inválido, esperado: PNG, JPEG",
        upload: "Erro ao fazer ulpload da imagem, tente novamente mais tarde!"
      },
      formInvalid: "Invalid field",
      uploadImage: "Error while uploading image",
      closeEditor: "Please complete or cancel the editor before proceeding",
      showOnMenu: "Show in menu",
      regionAreaName: "Error getting region area name",
      schedule: "Error reducing number of scales",
      scheduleMax: "Maximum number of scales reached",
      tagType: "Tag must apply to products and or businesses",
      companyNeedApproved: "You must approve the company to enable",
      needOnePayment: "You must select at least one accepted payment method (via App or Delivery)",
      NeedRegion: "It is necessary to define a region",
      changeOnlyAdmin: "Please contact support to perform this action"
    },
    update: "Update",
    companyName: "Name of restaurant",
    corporateName: "Corporate name",
    updateSuccess: "Saved data",
    ownerName: "Owner name",
    email: "Email",
    password: "Password",
    continue: "Continue",
    cnpj: "CNPJ",
    cpf: "CPF",
    name: "Name",
    prevStep: "Previous step",
    nextStep: "Next step",
    conclude: "Conclude",
    close: "Close",
    cancel: "Cancel",
    changePrimaryColor: "Change primary color",
    changeSecondaryColor: "Change secondary color",
    emailResetSuccess: "In a moment you will receive an email to reset your password",
    emailResetError: "There was an error trying to send password reset email",
    changePassword: "Change Password",
    passwordNew: "New password",
    passwordOld: "Current Password",
    info: "Information of the restaurant",
    address: {
      city: "City",
      complement: "Complement",
      country: "Country",
      district: "District",
      number: "Number",
      search: "Search",
      state: "State",
      street: "Street",
      zipcode: "Zip code",
      fieldWarning: "Error fetching address",
      emptyResult: "No address found"
    },
    contact: "Contact",
    description: "Description",
    phone: "Telephone",
    delete: "Delete",
    edit: "Edit",
    add: "Add",
    permission: "Permission",
    active: "Active",
    inactive: "Inactive",
    create: "Create",
    isLiquid: "It is liquid",
    isMandatory: "Mandatory",
    numberOfChoices: "Maximum of choices (0 without limit)",
    print: "Print",
    showIds: "Show Ids",
    visible: "Visible",
    required: "Required",
    nutritionalUnit: "Unit of measurement",
    showOnMenu: "Show in menu",
    isCash: "And money?",
    getDeliveryAreaName: "Delivery Area Name",
    getRegionName: "Region Area Name",
    isProduct: "Applicable on products",
    isCompany: "Applicable in companies",
    isRestrictions: "It is Food Restriction",
    uniqueName: "Unique identifier",
    slug: "Url",
    title: "Title",
    subTitle: "Subtitle",
    bodyText: "Text",
    abstract: "Resume",
    response: "Answer",
    category: "Category",
    ongName: "Ong name",
    availableForIugu: "Enable iugu account creation",
    approved: "Approved Restaurant",
    isAdvanced: "Advanced editing",
    alert: {
      emailAddSuccess: "Email added successfully"
    },
    region: "Region",
    isTest: "Show on test",
    isNew: "New restaurant",
    regionAll: "All Regions",
    selectUser: "Select the user",
    selectUsers: "Select users",
    selectCompany: "Select the company",
    selectCompanies: "Select companies",
    selectProduct: "Select product",
    selectProducts: "Select products",
    link: "Link",
    selectUsersOrderNotifications1: "Users notified in 5 min delayed orders",
    selectUsersOrderNotifications2: "Users notified in 10 min overdue orders",
    deliveryHubDenied: "Block delivery in this region?",
    regionAreaInfo: "BLOCKING area for receiving orders for DELIVERY",
    search: "Search"
  },
  register: {
    increaseSails: "INCREASE THE SALES OF YOUR RESTAURANT",
    discoverAdvantages: "Discover the advantages and increase your sales with a Go Tap! account",
    singIn: "Register your restaurant!",
    ifAccountExist: "If you already have an account, please login below",
    orSingIn: "or create a new account to get started.",
    singInOng: "Register the NGO"
  },
  navBar: {
    singIn: "Become a partner",
    logIn: "Enter",
    logOut: "Exit",
    isOpen: "Open",
    isClose: "Closed",
    isAuto: "Automatic",
    isManual: "Manual",
    acceptDelivery: "Delivery",
    acceptTakeout: "Withdrawal",
    acceptOnSite: "On site",
    noAcceptConfig: "Enter the settings and enable some way to make products available (Delivery, Takeout, On Site)",
    accessCompany: "Restaurant access",
    nextAutoChange: "Next automatic change",
    manual: "manual",
    auto: "automatic"
  },
  image: {
    crop: "Crop",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    rotateLeft: "Rotate Left",
    rotateRight: "Rotate Right",
    mirrorY: "Mirror Y",
    mirrorX: "Mirror X"
  },
  profile: {
    title: {
      client: "Information",
      admin: "Information",
      company: "Company Information",
      user: "User Information"
    }
  },
  promotion: {
    step: "Stage",
    of: "of",
    createPromotion: "Create promotion",
    editPromotion: "Edit promotion",
    infoSub: "Promotion Information",
    line1: "Line1",
    line2: "Line2",
    description: "Promotion Description",
    behaviorSub: "Promotion operation",
    behaviorAll: "Apply to all orders",
    behaviorAllDesc: "Apply promotion to all orders from any customer.",
    behaviorFirst: "Apply only to the customer&#39;s first order",
    behaviorFirstDesc: "Apply promotion only upon the first request of a new customer.",
    behaviorCoupon: "Apply with promotional code",
    behaviorCouponDesc: "Apply promotion by means of a promotional code.",
    typeSub: "Promotion type",
    typeDelivery: "Delivery free",
    typeDeliveryDesc: "Exemption from the rate of delivery of orders via delivey.",
    typePercent: "Percent discount",
    typePercentDesc: "Apply a percentage discount on the value of the products.",
    typeValue: "Discount amount",
    typeValueDesc: "Discard a given value at the end of the order.",
    typeGift: "Toast",
    typeGiftDesc: "Include one or more products for free upon request.",
    applyInSub: "Scope of promotion",
    applyInAll: "Full menu",
    applyInAllDesc: "The promotion will apply to all items on your menu.",
    applyInCategories: "Selected Categories",
    applyInCategoriesDesc: "Select one or more categories from the menu to apply the promotion.",
    applyInProducts: "Selected Products",
    applyInProductsDesc: "Select specific products to which the promotion applies.",
    configPromotion: "Set up promotion",
    durationSub: "Duration and validity",
    isValidity: "Duration determined",
    isValidityDesc: "Determine a date to end the promotion automatically.",
    isRecurrent: "Recurring Promotion",
    isRecurrentDesc: "Schedule the promotion to automatically repeat itself.",
    restrictionsSub: "Promotion Restrictions",
    isQuantity: "Limit amount",
    isQuantityDesc: "Determine an amount of requests to end the promotion.",
    isTakeOut: "Take-out",
    isTakeOutDesc: "Promotion applies to products withdrawn from the store.",
    isDelivery: "Delivery",
    isDeliveryDesc: "Promotion applies to products delivered via delivery.",
    isMinValue: "Minimum value",
    isMinValueDesc: "Promotion applies to orders with a minimum value.",
    detailsPromotion: "Promotion Details",
    discountValueSub: "Discount amount",
    discountPercent: "Percent discount",
    discountValue: "Discount amount",
    recurrentNot: "Do not repeat the promotion",
    recurrentNotDesc: "Promotion will not be repeated",
    recurrentSub: "Promotion recurrence",
    recurrentWeekly: "Weekly",
    recurrentWeeklyDesc: "The promotion will be repeated at the same time once a week",
    recurrentDaily: "Daily",
    recurrentDailyDesc: "The promotion will be repeated every day at the same time",
    recurrentMonthly: "Monthly",
    recurrentMonthlyDesc: "The promotion will be repeated on the same day and time every month",
    limitQuantity: "Order limit",
    minValue: "Minimum value",
    dateBegin: "Start date",
    dateEnd: "End date",
    hourBegin: "Start Time",
    hourEnd: "End time",
    recurrentWeeklySub: "Select the days of the week",
    recurrentMonthlySub: "Select the day of the month",
    duration: "Duration of promotion",
    active: "Active Promotion"
  },
  calendar: {
    week: {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    }
  },
  access: {
    title: "Access",
    admin: "Administrator",
    editor: "Editor",
    masterEditor: "Master Editor",
    attendant: "Clerk",
    delivery: "Deliveryman"
  },
  tools: {
    title: "Tool"
  },
  deliveryOptions: {
    deliveryConfig: "Delivery configuration",
    deliveryErrorLatlng: "Set the business address first",
    kmValue: "Delivery value per km",
    minValue: "Minimum value",
    roundAt: "Round to each",
    precedence: "Precedence of Value",
    precedenceDistance: "Applicable up to (km)",
    precedenceValue: "Amount to apply",
    deliveryBy: {
      title: "Who will deliver?",
      company: "The restaurant",
      big: "Go Tap"
    },
    region: "Region",
    precedenceDistanceFrom: "From (km)",
    precedenceDistanceTo: "Up to (km) (0 infinity)",
    precedenceBaseValue: "Base value",
    precedenceKmValue: "Value per Km",
    asOf: "from",
    km: "Km",
    lstPrecedence: "List of precedence",
    valueAutomatic: "Automatically generated value"
  },
  otherOptions: {
    acceptDelivery: "Allows delivery orders",
    acceptDeliveryDesc: "Accept deliveries",
    acceptPaymentOutApp: "Accepts payment outside the app",
    acceptPaymentOutAppDesc: "Accepts payment outside the App",
    acceptSails: "Accepts in-app sales",
    acceptSailsDesc: "Accepts sales",
    acceptTakeAtEstablishment: "Allows requests to be removed at the establishment",
    acceptTakeAtEstablishmentDesc: "Accept Takeout",
    otherOptionsSub: "Other configurations",
    showOnlyCategoryOnMenu: "Show only Subcategories in Menu",
    showOnlyCategoryOnMenuDesc: "One-level menu",
    acceptTakeout: "Accepts Takeout",
    acceptOnSite: "Accepts pick up at store",
    timeForDelivery: "Average Delivery Time",
    timeForDeliveryMin: "In",
    timeForDeliveryMax: "Up until",
    timeForPickupAndOnSite: "Average Withdrawal Time",
    timeForPickupAndOnSiteMin: "In",
    timeForPickupAndOnSiteMax: "Up until",
    minValueForDelivery: "Minimum value for Delivery",
    maxValueForDelivery: "Maximum value for Delivery",
    maxValueForOnSiteAndTakeout: "Maximum amount for Withdrawal and Consumption on site",
    print: "Printing Options (Over the Counter is always printed)",
    printKitchen: "Print via the kitchen",
    printDelivery: "Print via delivery",
    disableAutoIsOpen: "Disable Auto Open/Close",
    printMargin: "Printing margin (cm)",
    minValueForPickup: "Minimum Amount for Withdrawal",
    minValueForOnSite: "Minimum amount for on-site consumption",
    maxValueForPickup: "Maximum Amount for Withdrawal",
    maxValueForOnSite: "Maximum value for on-site consumption",
    tableActive: "Show choice of table",
    tableActiveSub: "For on-site consumption only",
    tableRequire: "Mandatory table choice",
    tableRequireSub: "For on-site consumption only",
    tables: "Tables/places",
    addTable: "Add table",
    addTableSequence: "Add String",
    tableName: "Table/place name",
    tablesSequenceName: "Base Name",
    tablesSequenceBegin: "Start",
    tablesSequenceEnd: "End",
    tablesSequenceAdd: "Add",
    addTableSequenceTitle: "Add Sequence",
    cancelTableSequence: "Cancel",
    addTableSequenceButton: "Add",
    onSiteTableName: "Option name"
  },
  product: {
    nutritionalName: "Name",
    nutritionalValue: "Value",
    nutritionalPortion: "Portion",
    nutritionalValuePortion: "Value Portion",
    nutritionalValueVd: "VD %",
    vdDaily: "VD Daily",
    description: "description",
    name: "Name",
    nutritional: "Nutritional",
    portion: "Portion",
    value: "Price",
    valueOriginal: "Original Price",
    placeholderTags: "Choose Tags",
    placeholderNutritional: "Choose nutritional items",
    placeholderIngredients: "Choose the ingredients",
    notVisible: "Not visible",
    category: "Category",
    variantName: "Variation",
    variantValue: "",
    variantValueOriginal: "Original",
    productHasError: "Product with error",
    additionalsHeader: "Variations applicable for that product",
    additionalsFromCategory: "(sub category configuration)",
    placeholderTagsRestrictions: "Restricted tags",
    subCategory: "Sub Category",
    promotion: "Promotion"
  },
  multiSelect: {
    selectLabel: "Press enter to select",
    selectGroupLabel: "Press enter to select group",
    selectedLabel: "Selected",
    deselectLabel: "Press enter to remove",
    deselectGroupLabel: "Press enter to deselect group",
    placeholder: "Select option"
  },
  additional: {
    additionals: "Additional",
    editAdditional: "Edit Additional",
    freeText: "Options",
    itemTitle: "Option name",
    itemValue: "Option value",
    placeholder: "Applicable Categories",
    title: "Additional",
    type: "Type",
    variation: "Variants",
    inputTitle: "Title",
    flavor: "Multiple flavors",
    allVariations: "All variations"
  },
  listItems: {
    noGroup: "Uncategorized Product"
  },
  orderStatus: {
    canceled: "Order Canceled Request",
    confirmed: "Order Confirmed",
    deliveryReady: "Delivery Completed",
    takeoutReady: "Order Completed",
    inPreparation: "In preparation",
    makingOrder: "Riding Order",
    waitDelivery: "Awaiting Delivery",
    onDelivery: "In delivery",
    waitConfirm: "Waiting confirmation",
    waitPayment: "Awaiting payment",
    waitTakeout: "Waiting for Withdrawal"
  },
  orderType: {
    takeout: "Takeout",
    undefined: "Not defined"
  },
  paymentStatus: {
    confirm: "Payment confirmed",
    undefined: "Payment not defined",
    invalidPaymentMethod: "Invalid payment method",
    invalidPaymentCard: "Card error",
    invalidPaymentUser: "Invalid user",
    invalidPaymentUnknown: "Unknown error",
    invalidPaymentAlreadyEarly: "Payment already made",
    invalidPaymentLimitValue: "Sorry, but we have a limit of R$ 500.00 per card purchase",
    iuguNotApproved: "Transaction denied",
    iuguResponseUnknown: "Error processing payment",
    iuguPaymentError: "Error processing payment!",
    iuguPaymentErrorCatch: "Error processing payment!!",
    iuguRefundErrorCatch: "Error reversing payment",
    iuguRefundErrorResult: "Error reversing payment!"
  },
  apiErrorCode: {
    statusError: "Invalid state",
    permissionDenied: "User does not have permission to do this action",
    unknown: "Unknown error",
    paymentError: "Error making payment",
    bagError: "Is there a mistake with the bag",
    inputError: "Parameter error",
    exhaustedAttempts: "Too many attempts",
    paymentInvalidValue: "Invalid payment amount",
    notFound: "Not found",
    bagEmptyAddress: "Order without address",
    bagEmptyProduct: "Order without product",
    bagStatusError: "Order canceled or in an invalid state"
  },
  companyInfo: {
    title: "Company Info",
    das: "From",
    as: "At",
    isClose: "Closed",
    attendanceTitle: "Service Hours",
    paymentTitle: "Accepted payment methods",
    deliveryTip: "Delivery",
    onSiteTip: "On site",
    takeoutTip: "Withdrawal"
  },
  timeElapsed: {
    "6": "6 hours",
    "12": "12 hours",
    "24": "24 hours",
    "48": "2 days",
    "120": "5 days",
    "240": "10 days",
    "720": "30 days",
    "8784": "1 year",
    label: "Order Period"
  },
  notifications: {
    orderTitle: {
      canceled: "Order Canceled",
      confirmed: "Order Confirmed",
      deliveryReady: "Delivery Complete",
      takeoutReady: "Order Completed",
      inPreparation: "In Preparation",
      waitDelivery: "Awaiting Delivery",
      onDelivery: "In Delivery",
      waitConfirm: "Waiting confirmation",
      waitPayment: "Awaiting payment",
      waitTakeout: "Awaiting Takeout"
    },
    orderBody: {
      canceled: "Your order has been canceled",
      confirmed: "Your Order Confirmed",
      deliveryReady: "Your request has been Delivered",
      takeoutReady: "Order Completed",
      inPreparation: "Your order is being prepared",
      waitDelivery: "Your order is waiting to be delivered",
      onDelivery: "Your order has just shipped out",
      waitConfirm: "Awaiting Restaurant Confirmation",
      waitPayment: "Order awaited",
      waitTakeout: "Your order can now be takeout"
    }
  },
  rules: {
    adm: {
      adm: "Administrator",
      sell: "Salesman",
      analy: "Analytics",
      sup: "Support"
    },
    comp: {
      adm: "Administrator",
      edit: "Editor",
      mEdit: "Master Editor",
      deli: "Delivery",
      att: "Clerk"
    }
  },
  router: {
    accessDenied: "Access denied"
  },
  maps: {
    areaDeliveryInfo: "Delivery Area"
  },
  company: {
    alertClosed: "Attention, Restaurant Closed, Time is set to open",
    verifyStatus: "Restaurant status"
  },
  verifyStatus: {
    approved: "Approved",
    pending: "Pending",
    waitApproved: "Submitted for approval"
  },
  companyHome: {
    browserUpgrade: "Update your ",
    titleCall: " - We are in Go Tap"
  },
  groupInvite: {
    titleCall: "Go Tap",
    description: "Your friends are waiting for you on Go Tap",
    bodyCall: "Install Go Tap right now to order with your friends"
  },
  paymentInfo: {
    OnApp: "Payment made by the App",
    whenReceive: "Payment upon receipt",
    changeFor: "Change to"
  },
  emailReset: {
    subject: "Reset Password",
    head: "Password Reset Email",
    body: "",
    link: "Reset Password"
  },
  blog: {
    title: "",
    notFound: "Post Not Found"
  },
  page: {
    title: "",
    notFound: "Page Not Found"
  },
  paymentOptions: {
    bankCheck: "Payment in check",
    creditCard: "Credit Card Payment",
    debitCard: "Debit Card Payment",
    money: "Cash payment",
    paymentOptionsSub: "Payment options",
    acceptPaymentOnApp: "Accept payment on the App",
    acceptCashPayment: "Accept cash",
    acceptPaymentOnDelivery: "Payment accepted on delivery",
    placeholderCards: "Add accepted cards",
    auto_withdraw: "Automatic withdrawal",
    auto_advance: "Automatic Anticipation.",
    iuguInfo: "Payment account information",
    createIuguVerify: "Submit data for validation",
    alertNotAvailableForIugu: "This company was not able to create a payment account",
    createIugu: "Create payment account",
    notAvailableForIugu: "This company was not able to create a payment account, please contact support.",
    createIuguVerifyAccept: "Account Accepted",
    createIuguVerifyBank: "Check Bank",
    infoTitle: "Contact Information",
    createIuguTitle: "Create Receiving Account",
    formTitle: "Form",
    bankTitle: "Create or update bank",
    createIuguPrevious: "Account already created",
    needCreateIugu: "You must create a receipt subaccount first",
    receiveInSubAccount: "Change to subaccount receipt",
    receiveInMasterAccount: "Change to receive via Go Tap"
  },
  iugu: {
    name: "Company Name",
    is_verified: "Checked?",
    can_receive: "Can you receive?",
    last_verification_request_status: "Last scan status",
    last_verification_request_data: "Data from the last scan",
    last_verification_request_feedback: "Last check feedback",
    change_plan_type: "Plan",
    balance: "Balance",
    balance_in_protest: "Protest balance",
    balance_available_for_withdraw: "Balance available for delivery",
    protected_balance: "Protected balance",
    payable_balance: "Balance to pay",
    receivable_balance: "Balance receivable",
    commission_balance: "Commission balance",
    volume_last_month: "Last month",
    volume_this_month: "This month",
    taxes_paid_last_month: "Fees paid in the last month",
    taxes_paid_this_month: "Fees paid this month",
    has_bank_address: "Has bank address",
    commissions_percent: "Go Tap Fee",
    configuration: {
      auto_withdraw: "Automatic withdrawal"
    },
    accountVerification: {
      title: "Account verification",
      price_range: "Maximum sale amount (&#39;Up to R $ 100.00&#39;, &#39;Between R $ 100.00 and R $ 500.00&#39;, &#39;More than R $ 500.00&#39;)",
      physical_products: "Physical products?",
      business_type: "Describe your business",
      person_type: "Type of registration",
      person_typeFisica: "Physical person",
      person_typeJuridica: "Legal person",
      automatic_transfer: "Automatic transfer (yes recommended)",
      name: "Name",
      company_name: "Company Name",
      cpf: "CPF",
      cnpj: "CNPJ",
      resp_name: "Responsible name",
      resp_cpf: "CPF of the responsible",
      telephone: "telephone",
      account_type: "Account Type",
      bank: "Bank",
      account_typeCorrente: "Corrente",
      account_typePoupanca: "Poupança",
      bank_ag: "Agency (9999-9)",
      bank_cc: "Account (999999-9)",
      document: "Bank voucher (The bank address voucher is any document that contains the bank account information (Name of the Bank, Branch, Account) as well as the company name or CNPJ for Legal Entity)"
    }
  },
  inputError: {
    name: "Fill in the name",
    companyName: "Fill in your company name",
    cnpj: "Fill in the CNPJ",
    priceRange: "Fill in the value range",
    businessType: "Fill in the type of business",
    completeAddress: "Finish filling in the address",
    completeBank: "Finish filling in your bank information",
    phone: "Fill the phone",
    previousSend: "Previously sent",
    needCanReceive: "Payment account required"
  },
  footer: {
    copyright: "Copyright Go Tap 2020 - All rights reserved.",
    home: "Home",
    about: "About Go Tap",
    download: "Download the app",
    faq: "Doubts center",
    accessCompany: "Access restaurant",
    accessOng: "Access Ong"
  },
  home: {
    letsAllEatTogether: "Let's all eat together",
    orderedDifferent: "<b>Order differently</b> with <b><i>Go Tap!</i></b> and get out of sameness with a delivery or takeout through the app!",
    tabFlexibility: "Flexibility",
    tabCare: "Go Tap! Care",
    tabDiscounts: "Total control",
    flexibilityTxt1: "Delivery or take-out?",
    flexibilityTxt2: "It's your choice!",
    flexibilityTxt3: "No matter how hungry you are, with <b><i>Go Tap</i></b> you have access to the most varied restaurants in your city through the app on your phone.",
    careTxt1: "Avoiding waste,",
    careTxt2: "Eradicating hunger!",
    careTxt3: "A significant part of <b><i>Go Tap</i></b> revenue will be used to develop actions to eradicate hunger and food waste.",
    discountsTxt1: "Find what you're looking for,",
    discountsTxt2: "leave it your way.",
    discountsTxt3: "Have all the information of the items in your order and customize the dishes your way, adding or removing as you wish.",
    downloadTxt: "<b>Download the free app</b> <br> and play differently.",
    learnMore1_1: "Your restaurant",
    learnMore1_2: "don't need",
    learnMore1_3: "have another partner",
    learnMore2: "Discover how to increase your sales <br> restaurant in a smart way.",
    learnMoreButton: "Know more",
    haveMore: {
      title1: "Do you think it's over?",
      title2: "Have more!",
      item1: {
        title: "Avoid queues",
        desc: "In addition to delivery and order withdrawal, you can also order and pay for Go Tap! when in restaurants to skip the lines in the past."
      },
      item2: {
        title: "Order together and split the bill",
        desc: "Join the crowd and ordering together! This way, you save on delivery and you can still divide the account between everyone the way you prefer."
      },
      item3: {
        title: "Orders your way",
        desc: "Customize the dishes, create variations of your favorite snacks and save your previous orders to create a menu that is just yours!"
      },
      item4: {
        title: "Follow your order",
        desc: "Follow each step of preparing your order through the application and be notified when everything is ready!"
      },
      item5: {
        title: "Dietary restrictions",
        desc: "Identify and select only the dishes that suit your lifestyle, diet or dietary restrictions."
      },
      item6: {
        title: "Integrated payment",
        desc: "Make the payment of the order by the application itself, using any of the several options available integrated into the platform."
      }
    }
  },
  exports: {
    dateBegin: "start",
    dateEnd: "end",
    exportUsers: "Export users",
    exportOrders: "Export orders",
    exportOngs: "Export Ongs",
    exportCompanies: "Export Companies",
    exportOrdersAll: "Export All Orders",
    exportPayments: "Export payments",
    selectCompanies: "Select companies (max 10)"
  },
  list: {
    error: {
      reorderOnFiltered: "It is not possible to sort with some filter enabled"
    }
  },
  history: {
    volume: "Billed",
    receivable: "Receivable",
    dateBegin: "start",
    dateEnd: "end",
    orderCode: "Code",
    orderNumber: "number",
    lstUsersTxt: "customers",
    totalValue: "Value",
    date: "Date",
    id: "Id",
    status: "Order status",
    orderClose: "Close",
    notCanceled: "valid",
    qtdOrders: "Quantity of orders",
    valueOrders: "Order values",
    qtdPayments: "Amount of payments",
    emptyData: "No data found for the selected period",
    emptyLabel: "No data for the selected period",
    emptyDescription: "No data for the selected period",
    companyName: "Company",
    selectHubs: "Select Hubs (max 10) Not available",
    selectRegions: "Select Regions (max 10)",
    selectCompanies: "Select Companies (max 10)",
    errorSelect: "Invalid filters, only 1 filter can have more than 1 option selected",
    total: {
      payByApp: "Paid by the app",
      payByAppGoTap: "Paid by Go Tap account",
      payByAppSubAccount: "Paid on restaurant bill",
      payByOnReceive: "Paid on receipt",
      payByOnReceiveCash: "Paid in cash",
      payByOnReceiveCard: "Paid by card",
      all: "Total orders",
      android: "Android",
      ios: "iOS",
      envUndefined: "System not defined",
      male: "Man",
      female: "Woman",
      sexUndefined: "Gender not defined",
      ages: {
        _0000: "Age not defined",
        _0012: "Up to 12 years",
        _1218: "Up to 18 years",
        _1824: "Up to 24 years",
        _2436: "Up to 36 years",
        _3642: "Up to 42 years",
        _4250: "Up to 50 years",
        _5000: "Over 50 years"
      },
      orderCount: "Total order quantity",
      orderCountValid: "Total number of valid orders"
    },
    totalQtd: {
      payByApp: "Paid by the app",
      payByAppGoTap: "Paid by Go Tap account",
      payByAppSubAccount: "Paid on restaurant bill",
      payByOnReceive: "Paid on receipt",
      payByOnReceiveCash: "Paid in cash",
      payByOnReceiveCard: "Paid by card",
      all: "Total payments",
      android: "Android",
      ios: "iOS",
      envUndefined: "System not defined",
      male: "Man",
      female: "Woman",
      sexUndefined: "Gender not defined",
      ages: {
        _0000: "Age not defined",
        _0012: "Up to 12 years",
        _1218: "Up to 18 years",
        _1824: "Up to 24 years",
        _2436: "Up to 36 years",
        _3642: "Up to 42 years",
        _4250: "Up to 50 years",
        _5000: "Over 50 years"
      },
      orderCount: "Total order quantity",
      orderCountValid: "Total number of valid orders"
    }
  },
  notification: {
    type: {
      title: "Type",
      company: "Company",
      product: "Product",
      link: "Link",
      search: "Search"
    },
    sendToType: {
      title: "Send to",
      region: "Region",
      selected: "Selected users",
      all: "All"
    },
    search: {
      search: "Search term"
    }
  },
  paginate: {
    prev: "Prev",
    next: "Next"
  },
  _404: {
    notFoundHeader: "Page not found, try reloading the page.",
    timeout: "The page timed out while loading. Are you sure you're still connected to the Internet?"
  },
  jobs: {
    type: "Type",
    name: "Name",
    arg: "arguments"
  },
  adCards: {
    type: {
      title: "Type",
      link: "Link",
      search: "Search"
    },
    showOn: {
      title: "Show where?",
      mainScreen: "Main screen"
    },
    search: "Search text",
    link: "Link"
  }
}
;
