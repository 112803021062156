<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import databaseUtils from '@utils/database_utils'

  import {gmapApi} from 'vue2-google-maps'

  export default {
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      },
      isGotap: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        imageSrc: null,
        imageBlob: null,
        uploadProgress: 0,
        isLoading: true,

        form: {
          kmValue: 0.5,
          minValue: null,
          roundAt: 0.5,
          precedence: {},
          lstPrecedence: [],
          isAdvanced: false,
        },
        defaultForm: {
          kmValue: 0.5,
          minValue: null,
          roundAt: 0.5,
          precedence: {},
          lstPrecedence: [],
          isAdvanced: false,
        },

        markers: [],
        center: {lat:0, lng:0},
        deliveryAreas: [],
        infoWindowPos: null,
        infoContent: '',
        infoWinOpen: false,
        areaToDelete: false,
        hasAddress: true,
        regionsList: [],
        mapStarted: false

      }
    },
    validations: {
      form: {
        kmValue: {
          required,
          decimal,
          minValue: minValue(0)
        },
        minValue: {
          required,
          decimal,
          minValue: minValue(0)
        },
      }
    },
    computed: {
      ...authComputed,
      google: gmapApi
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      if(this.itemId) {
        await this.loadForm();
      }
      if(this.isGotap) {
        let regionsObjP = firebase.firestore().collection('regions').get();
        let regionsList = [];
        (await regionsObjP).forEach((categoryObj) => {
          regionsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
        });
        this.regionsList = regionsList;
      }
      this.isLoading = false;
    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        this.center = {lat: -20.4695185, lng: -54.6203906};
        let ref = firebase.firestore().collection('companies').doc(this.itemId);
        let isGotap = this.isGotap;
        if(isGotap) {
          ref = firebase.firestore().collection('gotapDelivery').doc(this.itemId);
        }
        this.hasAddress = false;
        this.deliveryAreas = [];

        let obj = await ref.get();
        if(obj && obj.data()) {
          if(isGotap) {
            let company = obj.data() || {};
            let formTmp = {..._.cloneDeep(this.defaultForm), ...company};
            if (!formTmp.precedence) {
              formTmp.precedence = {};
            }
            this.form = formTmp;

            if(this.form.center) {
              this.center = {lat: this.form.center.lat || -20.4695185, lng: this.form.center.lng || -54.6203906};
            }


            if (company.deliveryAreas) {
              this.deliveryAreas = _.cloneDeep(company.deliveryAreas)
            }
          }
          else {
            let company = obj.data() || {};
            let formTmp = {..._.cloneDeep(this.defaultForm), ...company.deliveryOptions};
            if (!formTmp.precedence) {
              formTmp.precedence = {};
            }
            this.form = formTmp;

            if (company.address && company.address.latitude && company.address.longitude) {
              this.hasAddress = true;
              this.markers = [{
                position: {lat: company.address.latitude, lng: company.address.longitude}
              }];
              this.center = {lat: company.address.latitude, lng: company.address.longitude}
            }

            if (company.deliveryAreas) {
              this.deliveryAreas = _.cloneDeep(company.deliveryAreas)
            }
          }
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }
        if(!this.form.lstPrecedence) {
          this.form.lstPrecedence = [];
        }

        if(this.form.lstPrecedence.length<=0) {
          this.form.lstPrecedence.push({});
        }


        console.log('loaded form', this.form);
        this.loadMapDrawingManager();
        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);
        let ref = firebase.firestore().collection('companies').doc(this.itemId);
        let itemId = this.itemId;
        let isGotap = this.isGotap;
        if(isGotap) {
          ref = firebase.firestore().collection('gotapDelivery').doc(this.itemId)
        }
        form.isAdvanced = !!form.isAdvanced;

        if(!form.isAdvanced) {
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.hasError = {message:this.$t('form.error.formInvalid')};
            return;
          }
        }

        this.hasError = null;
        this.tryingToUpdate = true;

        const box = {
          minLat: 200,
          minLng: 200,
          maxLat: -200,
          maxLng: -200
        };
        for(const area of this.deliveryAreas) {
          for(const p of area.paths) {
            if(box.maxLat<p.lat) {
              box.maxLat=p.lat;
            }
            if(box.minLat>p.lat) {
              box.minLat=p.lat;
            }
            if(box.maxLng<p.lng) {
              box.maxLng=p.lng;
            }
            if(box.minLng>p.lng) {
              box.minLng=p.lng;
            }
          }
        }
        box.lat = box.minLat + ((box.maxLat - box.minLat)/2);
        box.lng = box.minLng + ((box.maxLng - box.minLng)/2);

        form.box = box;

        if(form.precedence) {
          form.precedence.distance = parseFloat(form.precedence.distance || 0);
          form.precedence.value = parseFloat(form.precedence.value || 0);

          if(!form.precedence.distance) {
            form.precedence = null;
          }
        }
        else {
          form.precedence = null;
        }

        let deleteIds = [];
        if(form.lstPrecedence && form.isAdvanced) {
          for(let i in form.lstPrecedence) {
            if(i>0) {
              form.lstPrecedence[i].distanceFrom = parseFloat(form.lstPrecedence[i-1].distanceTo || 0);
              form.lstPrecedence[i].distanceTo = parseFloat(form.lstPrecedence[i].distanceTo || 0);
              form.lstPrecedence[i].baseValue = parseFloat(form.lstPrecedence[i].baseValue || 0);
            //  form.lstPrecedence[i].baseValue = parseFloat(form.lstPrecedence[i-1].baseValue) +((parseFloat(form.lstPrecedence[i-1].distanceTo)-parseFloat(form.lstPrecedence[i-1].distanceFrom))*parseFloat(form.lstPrecedence[i-1].kmValue));
              form.lstPrecedence[i].kmValue = parseFloat(form.lstPrecedence[i].kmValue || 0);
            }
            else {
              form.lstPrecedence[i].distanceFrom = 0;
              form.lstPrecedence[i].distanceTo = parseFloat(form.lstPrecedence[i].distanceTo || 0);
              form.lstPrecedence[i].baseValue = parseFloat(form.lstPrecedence[i].baseValue || 0);
              form.lstPrecedence[i].kmValue = parseFloat(form.lstPrecedence[i].kmValue || 0);
            }

            if(form.lstPrecedence[i].distanceFrom===0 && form.lstPrecedence[i].distanceTo===0 && form.lstPrecedence[i].baseValue===0 && form.lstPrecedence[i].kmValue===0) {
              deleteIds.push(i);
            }
            if(form.lstPrecedence[i].distanceFrom>form.lstPrecedence[i].distanceTo && form.lstPrecedence[i].distanceTo!==0) {
              deleteIds.push(i);
            }
          }
        }
        else {
          form.lstPrecedence = [];
        }
        for(let i=deleteIds.length;i>0;i--) {
          form.lstPrecedence.slice(deleteIds[i],1);
        }



        let toUpdate = {
          deliveryAreas: this.deliveryAreas,
          deliveryOptions: form
        };

        toUpdate.deliveryOptions.kmValue = parseFloat(toUpdate.deliveryOptions.kmValue || 0);
        toUpdate.deliveryOptions.minValue = parseFloat(toUpdate.deliveryOptions.minValue || 0);
        toUpdate.deliveryOptions.roundAt = parseFloat(toUpdate.deliveryOptions.roundAt || 0.5);





        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile((isGotap?'gotapDelivery':'companiesDelivery/')+itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          if(isGotap) {
          //  let toUpdate2 = {};
           // toUpdate2[itemId] = toUpdate;
            await ref.set({
              ...(toUpdate.deliveryOptions),
              name: this.form.name || '',
              active: !!this.form.active,
              deliveryAreas: this.deliveryAreas,
              regionId: this.form.regionId,
            }, {merge: true});
          }
          else {
            await ref.set(toUpdate, {merge: true});
          }
          this.$showSuccess();
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      async addPolygon(event) {
        let pathsTmp = event.overlay.getPath().getArray();

        const {value: areaName} = await this.$swal({
          title: this.$t('form.getDeliveryAreaName'),
          input: 'text',
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            return !value && 'You need to write something!'
          }
        });

        if(!areaName) {
          event.overlay.setMap(null);
          throw this.$t('form.error.deliveryAreaName')
        }
        let id = firebase.firestore().collection('companies').doc().id;

        let paths = [];

        for (var j = 0; j < pathsTmp.length; j++) {
          paths.push({
            lat: pathsTmp[j].lat(),
            lng: pathsTmp[j].lng()
          })
        }
        event.overlay.setMap(null);
        this.deliveryAreas.push({name:areaName, paths: paths, id: id });

      },

      loadMapDrawingManager() {
        if(this.mapStarted) {
          return;
        }
        this.mapStarted = true;
        let self = this;
        this.$refs.map.$mapPromise.then((mapObject) => {
          const drawingManager = new this.google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
              position: this.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [this.google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: {
              fillColor: '#e9342c',
              fillOpacity: 0.7,
              strokeColor: '#AA2143',
              strokeWeight: 4,
              editable: true,
              draggable: true
            }
          });
          drawingManager.setMap(mapObject);

          this.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event) {
            // Get overlay paths
            // let paths = event.overlay.getPaths().getArray();
            // Remove overlay from map
            // event.overlay.setMap(null);

            // Disable drawingManager
            // drawingManager.setDrawingMode(null);

            // Create Polygon
            self.addPolygon(event);
          });
          /*
                    if(this.deliveryAreas && 1===2) {
                      for(let i in this.deliveryAreas) {
                        console.log('Add palygon')
                        var o = new this.google.maps.Polygon({
                          paths: this.deliveryAreas[i].paths,
                          fillColor: '#e9342c',
                          fillOpacity: 0.7,
                          strokeColor: '#AA2143',
                          strokeWeight: 4,
                          editable: true,
                          draggable: true
                        });


                        o.setMap(mapObject);

                       // addListenerPolygon(o, key);
                      }
                    } */

        });
      },
      pathChange (mvcArray, area) {
        for (let i=0; i<mvcArray.getLength(); i++) {
          let path = [];
          for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
            let point = mvcArray.getAt(i).getAt(j);
            path.push({lat: point.lat(), lng: point.lng()});
          }
          area.paths = path;
        }
      },
      showDescPolygon(event, area) {
        this.infoWindowPos = event.latLng;
        this.infoContent = this.$t('maps.areaDeliveryInfo')+ ': '+area.name+' ';
        this.infoWinOpen = true;
        this.areaToDelete = area
      },
      deleteArea() {
        if(this.areaToDelete) {
          let index = _.findIndex(this.deliveryAreas, (o) => { return o.id === this.areaToDelete.id })
          if(index>=0) {
            this.deliveryAreas.splice(index, 1);
          }
          this.infoWinOpen = false;
          this.areaToDelete = false;
        }
      },
      deleteAreaById(id) {
        console.log(id);
        if(id) {
          let index = _.findIndex(this.deliveryAreas, (o) => { return o.id === id })
          if(index>=0) {
            this.deliveryAreas.splice(index, 1);
          }
          this.infoWinOpen = false;
        }
      },
      deleteItemClick() {
        if(this.form.lstPrecedence) {
          this.form.lstPrecedence.pop();
        }
      },
      addItemClick() {
        if(!this.form.lstPrecedence) {
          this.form.lstPrecedence = [];
        }
        this.form.lstPrecedence.push({});
      },

      changeTo(index, value) {
        this.form.lstPrecedence[index].distanceFrom = value;
      },
      changeBase(index, value) {
        this.form.lstPrecedence[index].baseValue = value;
      },
      valueAutomatic() {
        this.$showError({title: this.$t('deliveryOptions.valueAutomatic')});
      }
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <!--
                    <div :class="$style.imagePreview" @click="tryChangeImage()" >
                      <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
                      <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
                      <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
                    </div>
                    <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                               type-content="modal"
                               :max-width="180"
                               :max-height="180"
                               :aspect-ratio="0"
                               @changeBlob="changeBlob"
                    ></FcmEditor> -->
          <div :class="$style.body">
            <div v-if="isGotap">
              <div class="formItem formItemFirst">
                <div class="formItemDesc">
                  <div class="formItemHead">{{$t('form.active')}}</div>
                  <div class="formItemSub"></div>
                </div>
                <div class="formItemAction">
                  <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                  <div class="formItemControl"></div>
                </div>
              </div>
              <BaseInputText v-model="form.name" :label="$t('product.name')" :class="['formItemText']" maxlength="200">
              </BaseInputText>
              <MdField :class="$style.formItemText">
                <label >{{$t('deliveryOptions.region')}}</label>
                <MdSelect v-model="form.regionId" name="access">
                  <MdOption v-for="(value) in regionsList" :key="value.id" :value="value.id">{{value.nameView}}</MdOption>
                </MdSelect>
              </MdField>
              <br><br>
            </div>

            <div class="formItem ">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.isAdvanced')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.isAdvanced" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <br>
            <div v-if="form.isAdvanced">
              <div :class="$style.lstPrecedence">
                {{$t('deliveryOptions.lstPrecedence')}}
                <span :class="$style.addPrecedence" @click="addItemClick()">
                  <MdIcon class="md-small-size-90" >add</MdIcon>
                </span>
                <div :class="$style.restrictionBox">
                  <MdField :class="$style.formItemText">
                    <label >{{$t('deliveryOptions.roundAt')}}</label>
                    <MdSelect v-model="form.roundAt" name="access">
                      <MdOption key="1.00" :value="1">R$ 1,00</MdOption>
                      <MdOption key="0.50" :value="0.5">R$ 0,50</MdOption>
                    </MdSelect>
                  </MdField>
                </div>
                <div v-if="form.lstPrecedence && form.lstPrecedence.length>0" :class="$style.precedenceItem">
                  <MdField :class="[$style.formItemText]">
                    <label>{{$t('deliveryOptions.precedenceDistanceFrom')}}</label>
                    <MdInput :value="0" type="number" disabled ></MdInput>
                  </MdField>
                  <MdField :class="[$style.formItemText]">
                    <label>{{$t('deliveryOptions.precedenceDistanceTo')}}</label>
                    <MdInput v-model="form.lstPrecedence[0].distanceTo"  type="number"></MdInput>
                  </MdField>
                  <MdField :class="[$style.formItemText]">
                    <label>{{$t('deliveryOptions.precedenceBaseValue')}}</label>
                    <span class="md-prefix">R$</span>
                    <MdInput v-model="form.lstPrecedence[0].baseValue" type="number"></MdInput>
                  </MdField>
                  <MdField :class="[$style.formItemText]">
                    <label>{{$t('deliveryOptions.precedenceKmValue') + ((form.lstPrecedence[0].distanceFrom>0)?` ${$t('deliveryOptions.asOf')} ${form.lstPrecedence[0].distanceFrom} ${$t('deliveryOptions.km')}`:'')}}</label>
                    <span class="md-prefix">R$</span>
                    <MdInput v-model="form.lstPrecedence[0].kmValue" type="number"></MdInput>
                  </MdField>
                  <div :class="$style.deletePrecedence" @click="deleteItemClick(0)">
                    <MdIcon class="md-small-size-90" >delete</MdIcon>
                  </div>
                </div>
                <div v-for="(value, index) in form.lstPrecedence" :key="value.id">
                  <div v-if="index>0" :class="$style.precedenceItem">
                    <MdField :class="[$style.formItemText]" @click="valueAutomatic()">
                      <label>{{$t('deliveryOptions.precedenceDistanceFrom')}}</label>
                      <MdInput :value="form.lstPrecedence[index-1].distanceTo || 0" type="number" disabled @input="changeTo(index, $event)"></MdInput>
                    </MdField>

                    <MdField :class="[$style.formItemText]">
                      <label>{{$t('deliveryOptions.precedenceDistanceTo')}}</label>
                      <MdInput v-model="form.lstPrecedence[index].distanceTo" type="number"></MdInput>
                    </MdField>
                    <!--  <MdField :class="[getValidationClass('minValue'), $style.formItemText]">
                        <label>{{$t('deliveryOptions.precedenceBaseValue')}}</label>
                        <span class="md-prefix">R$</span>
                        <MdInput
                          :value="parseFloat(form.lstPrecedence[index-1].baseValue||0) +((parseFloat(form.lstPrecedence[index-1].distanceTo||0)-parseFloat(form.lstPrecedence[index-1].distanceFrom||0))*parseFloat(form.lstPrecedence[index-1].kmValue||0))"
                          type="number" disabled @input="changeBase(index, $event)"></MdInput>
                      </MdField> -->
                    <MdField :class="[$style.formItemText]">
                      <label>{{$t('deliveryOptions.precedenceBaseValue')}}</label>
                      <span class="md-prefix">R$</span>
                      <MdInput
                        v-model="form.lstPrecedence[index].baseValue"
                        type="number" ></MdInput>
                    </MdField>
                    <MdField :class="[$style.formItemText]">
                      <label>{{$t('deliveryOptions.precedenceKmValue') + ((form.lstPrecedence[index].distanceFrom>0)?` ${$t('deliveryOptions.asOf')} ${form.lstPrecedence[index].distanceFrom} ${$t('deliveryOptions.km')}`:'')}}</label>
                      <span class="md-prefix">R$</span>
                      <MdInput v-model="form.lstPrecedence[index].kmValue" type="number"></MdInput>
                    </MdField>

                    <div :class="$style.deletePrecedence" @click="deleteItemClick(value.id)">
                      <MdIcon class="md-small-size-90" >delete</MdIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else >
              <div :class="$style.restrictionBox">
                <MdField :class="[getValidationClass('kmValue'), $style.formItemText]">
                  <label>{{$t('deliveryOptions.kmValue')}}</label>
                  <span class="md-prefix">R$</span>
                  <MdInput v-model="form.kmValue" type="number"></MdInput>
                  <span v-if="!$v.form.kmValue.required" class="md-error" >{{$t('form.error.required')}}</span>
                  <span v-else-if="!$v.form.kmValue.decimal" class="md-error" >{{$t('form.error.decimal')}}</span>
                  <span v-else-if="!$v.form.kmValue.minValue" class="md-error" >{{$t('form.error.minValueZero')}}</span>
                </MdField>
                <MdField :class="[getValidationClass('minValue'), $style.formItemText]">
                  <label>{{$t('deliveryOptions.minValue')}}</label>
                  <span class="md-prefix">R$</span>
                  <MdInput v-model="form.minValue" type="number"></MdInput>
                  <span v-if="!$v.form.minValue.required" class="md-error" >{{$t('form.error.required')}}</span>
                  <span v-else-if="!$v.form.minValue.decimal" class="md-error" >{{$t('form.error.decimal')}}</span>
                  <span v-else-if="!$v.form.minValue.minValue" class="md-error" >{{$t('form.error.minValueZero')}}</span>
                </MdField>
              </div>
              <br />
              {{$t('deliveryOptions.precedence')}}
              <div :class="$style.restrictionBox">
                <MdField :class="[$style.formItemText]">
                  <label>{{$t('deliveryOptions.precedenceDistance')}}</label>
                  <MdInput v-model="form.precedence.distance" type="number"></MdInput>
                </MdField>
                <MdField :class="[$style.formItemText]">
                  <label>{{$t('deliveryOptions.precedenceValue')}}</label>
                  <span class="md-prefix">R$</span>
                  <MdInput v-model="form.precedence.value" type="number"></MdInput>
                </MdField>
              </div><br />
            </div>

            <br>






            <GmapMap
              ref="map"
              :center="center"
              :zoom="13"
              map-type-id="terrain"
              :class="$style.map"
              :options="{
                 fullscreenControl: false,
               }"
            >
              <GmapMarker
                v-for="(m, index) in markers"
                :key="index"
                :ref="'mapMakerRef'+index"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                :icon="{
                    url: require('@assets/images/map/img_map_pin_store.png'),
                    scaledSize: {height: 57, width: 36},
                    origin: {x:0, y:0},
                    anchor: {x:18, y:52}}"
                @click="center=m.position"
              />
              <GmapInfoWindow
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
              >
                <div :class="$style.infoBox">
                  <div :class="$style.infoText">{{infoContent}}</div>
                  <div :class="$style.infoButton">
                    <MdButton :class="['md-primary', 'md-raised']" @click="deleteArea()">
                      {{$t('form.delete')}}
                    </MdButton>
                  </div>
                </div>
              </GmapInfoWindow>
              <GmapPolygon
                v-for="(area, index ) in deliveryAreas" :key="index+'map'"
                :options="{fillColor: '#e9342c',
                    fillOpacity: 0.7,
                    strokeColor: '#AA2143',
                    strokeWeight: 4}"
                :draggable="true"
                :editable="true"
                :paths="area.paths"
                :deep-watch="true"
                @paths_changed="pathChange($event, area)"
                @click="showDescPolygon($event, area)"
              >

              </GmapPolygon>
            </GmapMap>

            <div :class="$style.areasContent">
              <table>
                <tr v-for="(value) in deliveryAreas" :key="value.id">
                  <td>{{value.name}}</td>
                  <td></td>
                  <td @click="deleteAreaById(value.id)"><MdIcon :class="$style.deleteArea">delete</MdIcon></td>
                </tr>
              </table>
            </div>


          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
         <!--   <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton> -->
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 600px;
      .content {
        max-width: 980px;
        padding: 20px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            .roundAt {
              width: 200px;
            }
            .restrictionBox {
              display: flex;
              .formItemText {
                margin-left: 10px;
                flex: 1;
              }
            }

            .schedulesItens {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }

            .schedulesItensTitle {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }

            .infoBox {
              text-align: center;
              .infoText {
                font-size: 19px;
                padding: 10px;
              }
              .infoButton {
                padding: 10px;
              }
            }
            .map {
              width: 100%; height: 700px
            }

            .lstPrecedence {
              .precedenceItem {
                display: flex;
                .formItemText {
                  margin-left: 10px;
                  flex: 1;
                }
                .deletePrecedence {
                  display: flex;
                  cursor: pointer;
                }
              }

            }



            .addPrecedence {
              cursor: pointer;
            }



            .areasContent {
              margin-top: 20px;
              .deleteArea {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
</style>





















