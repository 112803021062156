<script>
  // import NavBar from '@components/nav-bar'
  import NavBar from '@components/layout/company/nav-bar'
  import { authComputed } from '@state/helpers'
  import Sidebar from '@components/layout/company/app-sidebar'
  import Version from '@components/layout/version'

  export default {
    components: { NavBar, Sidebar, Version },
    props: {
      styleOrder: {
        type: [Boolean],
        default: false,
      }
    },
    data() {
      return {
        isShowMenuMobile: false,
        lock: false
      }
    },
    computed: {
      ...authComputed
    },
    mounted: async function () {
      this.$eventHub.$on('menuClicked', ()=>{
        this.isShowMenuMobile = false;
      });
    },
    beforeDestroy(){
      this.$eventHub.$off('menuClicked');
    },
    methods: {
      changeMenu() {
        this.isShowMenuMobile = !this.isShowMenuMobile
      },
      awayShow() {
        this.isShowMenuMobile = false;
      }
    }
  }
</script>

<template>
  <div :class="$style.box">
    <Version v-model="lock" />
    <NavBar/>
    <div v-if="!lock" :class="styleOrder?$style.sidebarMenuButtonOrder:$style.sidebarMenuButtonPrimary">
      <MdButton class="md-fab md-mini md-primary md-elevation-10" @click="changeMenu()">
        <MdIcon>list</MdIcon>
      </MdButton>
    </div>
    <nav v-if="!lock" :class="[$style.sidebarDesktop, styleOrder?$style.sidebarDesktopOrder:$style.sidebarDesktopPrimary]">
      <Sidebar :class="$style.sidebarContent" />
    </nav>
    <div v-if="isShowMenuMobile" :class="$style.outside" @click="awayShow()"></div>
    <nav v-if="!lock" v-show="isShowMenuMobile" :class="[$style.sidebarMobile, 'md-elevation-10']">
      <Sidebar :class="$style.sidebarContent" />
    </nav>
    <div v-if="!lock" :class="$style.container">
      <div :class="[$style.content, styleOrder?$style.withSidebarOrder:$style.withSidebarPrimary]" >
        <slot />
      </div>
    </div>

  </div>
</template>

<style lang="scss" module>
  @import '@design';
  html {
    background-color: white!important;
  }

  .box {
    width: 100%;
    min-height: 100%;
    position: relative;
    background-color: $color-company-bg;

    .outside {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 9;
    }

    .sidebarDesktop {
      display: block;
      top: 118px;
      position: absolute;
      z-index: 10;
      /* top: 118px; */
      left: 0;
      /* bottom: 0; */

      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.05), 0 4px 20px 0px rgba(0, 0, 0, 0.15);
    }


    .sidebarDesktopPrimary {
      @media screen and (max-width: $size-company-menu-max+px) {
        display: none;
      }
      width: $size-company-sidebar+px;
    }
    .sidebarDesktopOrder {
      @media screen and (max-width: $size-company-order-menu-max+px) {
        display: none;
      }
      width: $size-company-order-sidebar+px;
    }

    .sidebarMobile {
     /* top: 190px; */
      background-color: white;
      position: absolute;
      z-index: 10;
      left: 0;

      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.05), 0 4px 20px 0px rgba(0, 0, 0, 0.15);
    }

    .sidebarMenuButtonPrimary {
      display: none;
      position: absolute;
      z-index: 11;
      top: 66px;
      left: 0;
      @media screen and (max-width: $size-company-menu-max+px) {
        display: block;
      }
    }

    .sidebarMenuButtonOrder {
      display: none;
      position: absolute;
      z-index: 11;
      top: 66px;
      left: 0;
      @media screen and (max-width: $size-company-order-menu-max+px) {
        display: block;
      }
    }

    .container {
      /* display: flex; */
      width: 100%;
      .menu {
        /*
        flex: 1;
        align-self: baseline; */
        margin: 0 auto;
      }

      .content {
        /*
        flex: 1;
        align-self: center; */
        margin: 0 auto;
        position: relative;
      }
      .withSidebarPrimary {
        margin-left: $size-company-sidebar+px;
        @media screen and (max-width: $size-company-menu-max+px) {
          margin-left: 0px;
        }
      }
      .withSidebarOrder {
        margin-left: $size-company-sidebar+px;
        @media screen and (max-width: $size-company-order-menu-max+px) {
          margin-left: 0px;
        }
      }
    }
  }


</style>
