/*
Tag deve seguir o IETF's BCP 47, https://www.w3.org/International/articles/language-tags/
 */
"use strict";
// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};

toExport.i18nConfig = {
  fallbackLocale: 'pt-BR',
  dateTimeFormats: {
    'en-US': {
      numeric: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric', hour12: false
      },
      veryLong: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
      }
    },
    'pt-BR': {
      numeric: {
        year: 'numeric', month: 'numeric', day: 'numeric'
      },
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
      long: {
        year: 'numeric', month: 'short', day: 'numeric',
        hour: 'numeric', minute: 'numeric', hour12: false
      },
      veryLong: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
      }
    }
  },
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol'
      }
    },
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol'
      }
    }
  },
  silentTranslationWarn: true,
  silentFallbackWarn: true
};


toExport.languages = {
  'en-US': {
    flag: 'us',
    name: 'English',
    database: 'en',
    hreflang: 'en_US',
  },
  'pt-BR': {
    flag: 'br',
    name: 'Português',
    database: 'ptbr',
    hreflang: 'pt_BR',
  }
};

toExport.languagesFiles = {
  'en-US': require('./en-US.js'),
  'pt-BR': require('./pt-BR.js')
};



toExport.getLocale = function (inputLocale) {
  return toExport.fcmBestAvailableLocale(Object.keys(toExport.languages), toExport.i18nConfig.fallbackLocale,
    inputLocale,
    typeof window!=='undefined'?window.localStorage.getItem('locale'):[],
    typeof navigator!=='undefined'?(navigator.languages || [ navigator.language ]):[])
};


toExport.getLocalePriority = function (inputLocale) {
  return toExport.fcmBestAvailableLocale(Object.keys(toExport.languages), toExport.i18nConfig.fallbackLocale,
    ...inputLocale,
    typeof window!=='undefined'?window.localStorage.getItem('locale'):[],
    typeof navigator!=='undefined'?(navigator.languages || [ navigator.language ]):[])
};

toExport.setLocale =  function (locale) {
  console.log('setLocale', locale);
  if(locale && typeof window!=='undefined') {
    window.localStorage.setItem('locale', locale)
  }
};

// Baseado na BestAvailableLocale abstract operation
// https://tc39.github.io/ecma402/#sec-bestavailablelocale
toExport.fcmBestAvailableLocale = function (availableLocales, fallback, ...localePriority) {
  try {
    if(!Array.isArray(availableLocales)) {
      if(availableLocales) {
        availableLocales = [availableLocales]
      }
      else {
        return fallback
      }
    }
    availableLocales = availableLocales.map((locale) =>
      locale
        .split("-")
        .map((localePart) => localePart.toLowerCase())
        .join("-")
    );

    let last = 0;
    for (let i = 0; i < localePriority.length; i++) {
      if(!Array.isArray(localePriority[i])) {
        if(localePriority[i]) {
          localePriority[i] = [localePriority[i]]
        }
        else {
          localePriority[i] = [];
          continue;
        }
      }
      localePriority[i] = localePriority[i].map((locale) =>
        locale
          .split("-")
          .map((localePart) => localePart.toLowerCase())
          .join("-")
      );

      for(let j = 0; j<localePriority[i].length; j++) { // buscando locale identico
        if(availableLocales.includes(localePriority[i][j])) {
          return Intl.getCanonicalLocales(localePriority[i][j])[0];
        }
      }

      for(let j = 0; j<localePriority[i].length; j++) { // buscando locale parecido
        let subLocale = localePriority[i][j];
        while(subLocale && subLocale.length>1) {
          for(let k = 0; k<availableLocales.length;k++) {
            if(availableLocales[k].indexOf(subLocale)===0) {
              return Intl.getCanonicalLocales(availableLocales[k])[0];
            }
          }
          last = subLocale.lastIndexOf('-');
          if(last===-1) {
            break;
          }
          subLocale = subLocale.substr(0, last);
        }
      }
    }
  }catch(err) {
    console.error('invalid availableLocales',err);
  }
  return fallback
};




/*
  Operação de tradução para o node server, para o Vue utilizar o plugin vue-i18n já devidamente configurado no Vue
 */

toExport.translate = function(locale, key) {
  let res = key.split('.').reduce(function(o, k) {
    return o && o[k]
  }, toExport.languagesFiles[locale]);

  if(typeof res!=='undefined') {
    return res;
  }

  res = key.split('.').reduce(function(o, k) {
    return o && o[k];
  }, toExport.languagesFiles[toExport.i18nConfig.fallbackLocale]);

  if(typeof res!=='undefined') {
    return res;
  }
  return key;
};

toExport.translateDb = function(locale, obj) {
  if(toExport.languages[locale] && obj && obj[toExport.languages[locale].database]) {
    return obj[toExport.languages[locale].database];
  }
};




toExport.formatCurrency = function(locale, input) {
  return Number(input).toLocaleString(locale, {style: 'currency', currency:toExport.i18nConfig.numberFormats[locale].currency.currency, currencyDisplay: toExport.i18nConfig.numberFormats[locale].currency.currencyDisplay} )
};


toExport.getLocalEnvironment = function(req, extraLang) {
  let lang = [];
  let isDark = false;
  if(extraLang) {
    if ('string' === typeof extraLang) {
      lang = [extraLang];
    }
    else if (Array.isArray(extraLang)) {
      lang = extraLang;
    }
  }
  if(req) {
    if(req.query && req.query.lang) {
      lang.push(req.query.lang);
    }
    if(req.params && req.params.lang) {
      lang.push(req.params.lang);
    }
    if(req.acceptsLanguages) {
      const tmp = req.acceptsLanguages();
      lang = lang.concat(tmp);
    }
    if(req.query && req.query.isDark) {
      isDark = JSON.parse(req.query.isDark) || false;
    }
  }
  const locale = toExport.getLocalePriority(lang);
  return {
    t: function(key) {
      return toExport.translate(locale, key)
    },
    trdb: function(key) {
      return toExport.translateDb(locale, key)
    },
    formatCurrency: function(input) {
      return toExport.formatCurrency(locale, input);
    },
    locale: locale,
    locale_: locale.replace(/-/g, '_'),
    isDark: isDark
  };
};


module.exports = toExport;

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }



