<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'

  export default {
    components: {},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        uploadProgress: 0,
        imageSrc: null,
        imageBlob: null,
        isLoading: true,

        form: {
          print: {
            margin: 0.2
          },
        },
        defaultForm: {
          print: {
            margin: 0.2
          },
        },
        printers: [],
        printer: null,

      }
    },
    validations: {

    },
    computed: {
      ...authComputed
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      if(this.itemId) {
        await this.loadForm();
      }
      if(process.env.IS_ELECTRON) {
        const electron = window.require("electron");
        if(electron && electron.remote && electron.remote.getCurrentWindow) {
          const win = electron.remote.getCurrentWindow();
          if(win && win.webContents) {
            this.printers = win.webContents.getPrinters();
          }
        }
      }
      console.log('this.printers', this.printers);
      this.isLoading = false;
    },
    beforeMount: async function() {


    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        let obj = await firebase.firestore().collection('companies').doc(this.itemId).get();
        if(obj && obj.data()) {
          let company = obj.data() ||{};
          let form = { ..._.cloneDeep(this.defaultForm), ...company};

          form.print = form.print || {};
          form.print.margin = parseFloat(form.print.margin || 0.2);

          this.form = form;
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }
        this.printer = JSON.parse(window.localStorage.getItem('printerSelected')) || null;


        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.hasError = null;
        this.tryingToUpdate = true;

        form.print = form.print || {};
        form.print.margin = parseFloat(form.print.margin || 0.2);

        let toUpdate = {
          print: form.print || {},
        };

        window.localStorage.setItem('printerSelected', JSON.stringify(this.printer, function(k, v) {
          if(typeof v ==='object' && v && (v.wa==='FieldValue.serverTimestamp' || v.fa==='FieldValue.serverTimestamp') || k==='updatedAt') {
            return null;
          }
          return v;
        }))

        try {
          await firebase.firestore().collection('companies').doc(this.itemId).set(toUpdate,{merge: true});
          this.$showSuccess();
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <div :class="$style.body">
            <v-select
                v-if="printers.length"
                v-model="printer"
                :items="printers"
                :label="$t('otherOptions.selectPrinter')"
                :item-value="(item)=>item"
            ></v-select>
            <div v-else >{{$t('otherOptions.noPrinters')}}</div>

            <div class="formItemSubSection">{{$t('otherOptions.print')}}</div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.printKitchen')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.print.printKitchen" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.printDelivery')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.print.printDelivery" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <MdField :class="['formItemText']">
                <label>{{$t('otherOptions.printMargin')}}</label>
                <MdInput v-model="form.print.margin" type="number"></MdInput>
              </MdField>
            </div>
          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
       <!--     <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton> -->
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 600px;
      .content {
        max-width: 600px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            .schedulesItens {
              width: 160px;
              display: inline-block;
              margin-right: 30px;
            }

            .schedulesItensTitle {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }

            .itensOp {
              width: 100%;
              max-width: 350px;
              .itemOp {
                margin-left: 13px;
              }

              .itemHead {
                display: flex;
                button {
                  align-self: center;
                }
                .field {
                  margin-top: 7px;
                  margin-bottom: 5px;
                }
              }
              .actions {
                display: flex;
                button {

                }
              }
              .tablesSequence {
                padding: 7px;
                .tablesSequenceBeginEnd {
                  display: flex;
                  .tablesSequenceEnd {
                    margin-left: 8px;
                  }
                }
                .actionsSequence {
                  display: flex;
                  margin-top: -10px;
                  button {

                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>





















