<script>
import Layout from '@components/layout/company/main-visitant'
import { authMethods, authComputed } from '@state/helpers'
import {appConfig} from '@src/config';
import defaults from '@/src/router/defaults'
import firebase from 'firebase/app'

export default {
  page: {
    title: 'Log in',
    meta: [
      {name: 'description', content: `Log in to ${appConfig.title}`}
    ],
  },
  components: { Layout },
  data() {
    return {
      email: '',
      fakeMail: '',
      password: '',
      updateError: null,
      tryingToLogIn: false,
      isReset: false
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    loggedIn() {
      if (this.$store.getters['auth/loggedIn'] && !this.tryingToLogIn) {
        console.log('Redirecionar usuário logado')
        this.$router.push({ name: defaults.routeLogged[this.$store.getters['auth/loggedIn']] })
      }
    }
  },
  methods: {
    ...authMethods,
    async tryToLogIn() {
      this.updateError = null
      if(!this.password) {
        return;
      }

      if(!this.email || !this.password) {
        this.updateError = true
        return
      }
    //  this.fakeMail = await this.getAdminMailByFakeMail({email: this.email })
      this.fakeMail = 'ua_'+this.email.replace('@', '_')+'@adminusers.gotap.com.br';
      if(!this.fakeMail) {
        this.updateError = true
        return
      }

      this.tryingToLogIn = true
      try {
        await this.logIn({
          email: this.fakeMail,
          password: this.password,
        });
        this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged['ADMIN'] })
      } catch(error) {
        console.error( this.$t('login.error.loginTitle'), this.$t('login.providerCode.'+error.code));
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'error',
          title: this.$t('login.error.loginTitle'),
          text: this.$t('login.providerCode.'+error.code),
          showConfirmButton: false,
          timer: 20000
        });
        this.tryingToLogIn = false
        this.updateError = {message:this.$t('login.providerCode.'+error.code)}
      }
    },
    async tryReset() {
      if(!this.email) {
        this.updateError = true
        return
      }

      this.fakeMail = 'ua_'+this.email.replace('@', '_')+'@adminusers.gotap.com.br';
      this.tryingToLogIn = true
      try {
        let result = await firebase.functions().httpsCallable('auth-resetPassword')({email:this.fakeMail, workspace:'ADMIN'})
        console.log('result', result);
        if(result && result.data && result.data.status) {
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'success',
            title: this.$t('form.emailResetSuccess'),
            showConfirmButton: false,
            timer: 15000
          })
        }
        else {
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'error',
            title: this.$t('form.emailResetError'),
            showConfirmButton: false,
            timer: 20000
          })
        }
      } catch(error) {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'error',
          title: this.$t('form.emailResetError'),
          showConfirmButton: false,
          timer: 20000
        })
      }
      this.tryingToLogIn = false
    },
    submitForm() {
      if(this.isReset) {
        this.tryReset()
      }
      else {
        this.tryToLogIn()
      }
    },
    async switchReset () {
      this.isReset = !this.isReset
    },
    async focusPassword () {
      this.$refs.password.$el.querySelector('input').focus();
    }
  },
}
</script>

<template>
  <Layout>



    <div :class="$style.container">
      <div :class="$style.content" >
        <div :class="$style.title">{{$t(isReset?'login.resetAdmin':'login.accessAdmin')}}</div>

        <div :class="$style.body">
          <form :class="$style.form" @submit.prevent="submitForm">
            <BaseInputText
                    v-model="email"
                    label="Email"
                    @keyup.enter="focusPassword"
            />
            <BaseInputText
                    v-if="!isReset"
                    ref="password"
                    v-model="password"
                    type="password"
                    label="Senha"
            />
            <p v-if="updateError" :class="$style.error">
              {{updateError.message?updateError.message:$t('login.error.generic')}}
            </p>
            <BaseButton
                    :class="['md-primary','md-raised','md-lg', $style.loginButton]"
                    :disabled="tryingToLogIn"
                    type="submit"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t(isReset?'login.reset':'login.login')}}
              </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="switchReset"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t(isReset?'login.goLogin':'login.goReset')}}
              </span>
            </BaseButton>
          </form>
        </div>

      </div>

    </div>

  </Layout>
</template>


<style lang="scss" module>
  @import '@design';
  .error {
    color: $color-input-error;
  }
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    padding-top: 80px;
    padding-bottom: 30px;
    .content {
      width: 90%;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      margin: 0 auto;
      max-width: 400px;
     // max-height: 550px;
      .title {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .body {
        font-family: $font-input;
        .loginButton {
          margin-bottom: 5px;
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }


</style>
