<script>
  import Locale from '@components/layout/locale'
  import {authComputed} from '@state/helpers'
  export default {
    components: { Locale },
    props: {
    },
    data() {
      return {
      }
    },computed: {
      ...authComputed,
    },
    methods: {
      notImplemented() {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'info',
          title: this.$t('all.notImplemented'),
          showConfirmButton: false,
          timer: 1500
        })
      },
      logout: function (event) {
        this.$router.push({ name: 'logout' })
      },
      onClick(event) {
        this.$eventHub.$emit('menuClicked', event);
      }
    }
  }
</script>

<template>
  <ul class="box">
    <li class="bloco menuActions">
      <ul class="items">
        <li>
          <div v-if="loggedIn" class="itemlink">
            <BaseButton
              class="md-accent md-raised md-lg"
              type="text"
              @click="logout"
            >
              {{$t('navBar.logOut')}}
            </BaseButton>
          </div>
        </li>
        <li>
          <div class="itemlink">
            <Locale />
          </div>
        </li>
      </ul>
    </li>
    <li class="bloco">
      <span class="title">{{$t('all.attendance').toUpperCase()}}</span>
      <ul class="items">
        <RouterLink tag="li" class="item" to="/company/orders" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-orders@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-orders_2@3x.png')" >
          {{$t('all.orders')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/reviews" :event="''" @click.native="notImplemented">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-reviews@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-reviews_2@3x.png')" >
          ! {{$t('all.reviews')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/promotions" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-promotions@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-promotions_2@3x.png')" >
          {{$t('all.promotions')}}
        </RouterLink>
      </ul>
    </li>

    <li v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" class="bloco">
      <span class="title">{{$t('all.settings').toUpperCase()}}</span>
      <ul class="items">
        <RouterLink v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" tag="li" class="item" to="/company/profile" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-restaurant@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-restaurant_2@3x.png')" >
          {{$t('all.restaurant')}}
        </RouterLink>

        <RouterLink v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" tag="li" class="item" to="/company/users" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          {{$t('all.users')}}
        </RouterLink>

        <RouterLink v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" tag="li" class="item" to="/company/delivery_options" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-delivery@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-delivery_2@3x.png')" >
          {{$t('all.delivery')}}
        </RouterLink>

        <RouterLink v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" tag="li" class="item" to="/company/schedule" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-time@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-time_2@3x.png')" >
          {{$t('all.schedule')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" tag="li" class="item" to="/company/other_options" @click.native="onClick">
          <MdIcon class="icon onInative" >settings</MdIcon>
          <MdIcon class="icon onActive" >settings</MdIcon>
          {{$t('all.companyConfigs')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" tag="li" class="item" to="/company/payment_options" @click.native="onClick">
          <MdIcon class="icon onInative" >settings</MdIcon>
          <MdIcon class="icon onActive" >settings</MdIcon>
          {{$t('all.paymentConfigs')}}
        </RouterLink>
        <RouterLink v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" tag="li" class="item" to="/company/printer_options" @click.native="onClick">
          <MdIcon class="icon onInative" >print</MdIcon>
          <MdIcon class="icon onActive" >print</MdIcon>
          {{$t('all.printerOptions')}}
        </RouterLink>
      </ul>
    </li>

    <li class="bloco">
      <span class="title">{{$t('all.settingsProducts').toUpperCase()}}</span>
      <ul class="items">
        <RouterLink tag="li" class="item" to="/company/products" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.products')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/products_categories" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.productsCategories')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/products_sub_categories" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('all.productsSubCategories')}}
        </RouterLink>
        <RouterLink tag="li" class="item" :to="{ name: 'company/additionals', params: { type: 'additionals' }}" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('additional.additionals')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/additionals/variation" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('additional.variation')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/additionals/freeText" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('additional.freeText')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/additionals/flavor" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('additional.flavor')}}
        </RouterLink>
        <RouterLink tag="li" class="item" to="/company/additionals" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-products@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-products_2@3x.png')" >
          {{$t('additional.allVariations')}}
        </RouterLink>


      </ul>
    </li>

    <li v-if="currentUser && currentUser.rule && (currentUser.rule.adm ||currentUser.rule.edit)" class="bloco">
      <span class="title">{{$t('all.informations').toUpperCase()}}</span>
      <ul class="items">
        <RouterLink tag="li" class="item" to="/company/performance" :event="''" @click.native="notImplemented">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-performance@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-performance_2@3x.png')" >
          ! {{$t('all.performance')}}
        </RouterLink>

        <RouterLink tag="li" class="item" to="/company/history" @click.native="onClick">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-history@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-history_2@3x.png')" >
          {{$t('all.history')}}
        </RouterLink>

        <RouterLink tag="li" class="item" to="/company/demographics" :event="''" @click.native="notImplemented">
          <img class="icon onInative" :src="require('@assets/images/company/ic-sidebar-demographics@3x.png')" >
          <img class="icon onActive" :src="require('@assets/images/company/ic-sidebar-demographics_2@3x.png')" >
          ! {{$t('all.demographics')}}
        </RouterLink>
      </ul>
    </li>

  </ul>
</template>




<style lang="scss" scoped>
  @import '@design';

  .menuActions {
    display: none;
    @media screen and (max-width: $size-menu-actions+px) {
      display: block;
    }
  }

  .box {
    /*width: 250px;*/
    /*height: 100%;*/
    text-align: left;
    list-style-type: none;
    padding-inline-start: 0px;

    .bloco {
      padding-bottom: 35px;
      padding-top: 15px;
      padding-left: 20px;
      .title {

      }
      .items {
        list-style-type: none;
        padding-inline-start: 4px;

        .onActive {
          display: none;
        }
        .onInative {
          display: unset;
        }

        .router-link-active .onActive {
          display: unset;
        }
        .router-link-active .onInative {
          display: none;
        }
        .item {
          cursor: pointer;
          margin-top: 20px;
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 24px;
          }
          .onActive {
            color: $color-menu-active;
          }
          .onInative {
            color: $color-menu-inactive;
          }

        }
      }
    }
  }
</style>
