<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/ong/main_tabs'
  import ListItens from '@components/list_itens'
  import firebase from 'firebase/app'
  import UserEdit from '@components/ong/user_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListItens, UserEdit},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    mounted(){
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
        firebase.firestore().collection('ongsUsers').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
        this.itemId = firebase.firestore().collection('ongsUsers').doc().id;
      },
      makeSearch(el) {
        return (el.name?el.name:'')+' '+(el.email?el.email:'');
      }
    },
  }
</script>

<template>
  <MainTabs>
    <template #left>
      <ListItens
        v-model="itemId"
        resource="ongsUsers"
        :actions="['edit', 'enable']"
        :ong-id="currentOng?currentOng.id:null"
        :disable-order="true"
        :make-search="makeSearch"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
        <template #item="slotProp" >
          <div class="formItemHead">{{slotProp.item.name}}</div>
          <div class="formItemSub">{{slotProp.item.email}}</div>
          <div class="formItemSub">(
            <span v-for="(lixo, rule, index) in slotProp.item.rule" :key="rule">{{(index?', ':'')+$t('rules.comp.'+rule)}}</span>
            )</div>
        </template>
      </ListItens>
    </template>
    <template #right>
      <UserEdit
        v-model="itemId"
      >
      </UserEdit>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }

  .box {
    background-color: #ffffff;
    .container {
      min-height: 600px;
      margin: 5px 0px 30px 0px;

      .head {
        text-align: right;
      }
      .tableList {
        text-align: left;
      }
    }
  }
</style>





















