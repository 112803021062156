<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import databaseUtils from '@utils/database_utils'
  import productUtils from '@common/product'
  import FcmEditor from "@/src/components/image/fcm-editor";
  import firebase from 'firebase/app'
  import Multiselect from 'vue-multiselect'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {FcmEditor, Multiselect},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        defaultForm: {
          name: '',
          description: '',
          imgUrl: null,
          order: 0,
          search: '',
          subCategoryId: null,
          value: 0,
          valueOriginal: 0,
          active: true,
          companyId: null,
          isLiquid: false,
          portion: 0,
          variationData: [],
          variationDataOriginal: [],
          hasVariation: false,
          visible: true,
        },

        hasError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        tryingToUpload: false,
        imageBlob: null,

        form: {
          name: '',
          description: '',
          imgUrl: null,
          order: 0,
          search: '',
          subCategoryId: null,
          value: 0,
          valueOriginal: 0,
          active: true,
          companyId: null,
          isLiquid: false,
          portion: 0,
          variationData: [],
          variationDataOriginal: [],
          hasVariation: false,
          visible: true,
        },
        starting: null,
        isLoading: true,
        isNew: true,
        subCategories: [],
        inputTag: '',
        tagList: [],
        tags: [],
        nutritionalList: [],
        nutritional: [],
        nutritionalRemoved: {},
        ingredientsList: [],
        ingredients: [],
        additionals: [],
        additionalsFromProduct: false,
        variantProduct: null,

        additionalsProduct: [],
        additionalsProductDisabled: [],
        promotions: [],
      //  nutritionalPortion: [],
      //  changeSemaphore: null
        promotion: null,
      }
    },
    validations: {
      form: {
        value: {
          decimal,
          minValue: minValue(0)
        },
      }
    },
    computed: {
      ...authComputed
    },
    watch: {
      async itemId(value, oldValue) {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        this.promotion = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }
      },
    },
    mounted: async function() {
      this.starting = this.loadInitialData();

    },
    methods: {
      async loadInitialData() {
        let tagsObjP = firebase.firestore().collection('tags').where('isProduct', '==', true).get();
        let nutritionalObjP = firebase.firestore().collection('productsNutritional').get();
        let ingredientsObjP = firebase.firestore().collection('productsIngredients').get();
        let additionalsObjP = firebase.firestore().collection('productsConfigs').where("companyId", "==", this.currentCompany.id).get();
        let subCategoriesObjP = firebase.firestore().collection('companiesSubCategories').where("companyId", "==", this.currentCompany.id).get();
        let promotionsObjP = firebase.firestore().collection('companiesPromotions').where("companyId", "==", this.currentCompany.id).where("active", "==", true).get();

        let tmp = [];
        (await tagsObjP).forEach((categoryObj) => {
          tmp.push({id:categoryObj.id, name:categoryObj.data().name, nameView:this.$trdb((categoryObj.data()||{}).name||{})})
        });
        this.tagList = _.sortBy(tmp, 'nameView');

        let additionalsTmp = [];
        let promisesText = [];
        (await additionalsObjP).forEach((additionalObj) => {
          let item = {...additionalObj.data(), id:additionalObj.id};
          additionalsTmp.push(item);
        });
        await Promise.all(promisesText);
        this.additionals = additionalsTmp;

        let tmpNutritional = [];
        (await nutritionalObjP).forEach((categoryObj) => {
          tmpNutritional.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
        });
        this.nutritionalList = _.sortBy(tmpNutritional, 'nameView');

        let tmpIngredients = [];
        (await ingredientsObjP).forEach((categoryObj) => {
          tmpIngredients.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
        });
        this.ingredientsList = _.sortBy(tmpIngredients, 'nameView');

        let tmpSubCategories = [];
        (await subCategoriesObjP).forEach((subCategoriesObj) => {
          tmpSubCategories.push({...subCategoriesObj.data(), id:subCategoriesObj.id});
        });
        this.subCategories = tmpSubCategories;

        let tmpPromotions = [];
        (await promotionsObjP).forEach((promotionsObj) => {
          tmpPromotions.push({...promotionsObj.data(), id:promotionsObj.id});
        });
        this.promotions = tmpPromotions;
        console.log('data finish load');
      },
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        await this.starting;
        console.log('starting load product');

        let objP = firebase.firestore().collection('companiesProducts').doc(this.itemId).get();
        let objNutritionalP = firebase.firestore().collection('companiesProducts').doc(this.itemId).collection('nutritional').get();

        let obj = await objP;
        let form = _.cloneDeep(this.defaultForm);
        this.hasError = null;
        this.tryingToUpdate= false;
        this.imageSrc= null;
        this.uploadProgress= 0;
        this.tryingToUpload= false;
        this.imageBlob= null;
        this.promotion= null;

        if(obj && obj.data()) {
          form = { ...form, ...obj.data()};
          this.isNew = false;
        } else {
          this.isNew = true;
        }

        let tmp = [];
        for(let tagId of form.tagsId || [] ) {
          let tag = this.tagList.find(a=>a.id===tagId);
          if(tag) {
            tmp.push({...tag, nameView: this.$trdb(tag.name)});
          }
        }
        this.tags = tmp;

        let tmpIngredients = [];
        for(let tagId of form.ingredientsId || [] ) {
          let tag = this.ingredientsList.find(a=>a.id===tagId);
          if(tag) {
            tmpIngredients.push({...tag, nameView: this.$trdb(tag.name)});
          }
        }
        this.ingredients = tmpIngredients;

        let tmpNutritional = [];
        (await objNutritionalP).forEach((snapshot)=>{
          tmpNutritional.push({...snapshot.data(), id:snapshot.id, nameView: this.$trdb(snapshot.data().name)});
        });
        this.nutritional = tmpNutritional;
        this.nutritionalRemoved = {};
        this.additionalsFromProduct = false;

        this.additionalsProductDisabled = [];
        this.additionalsProduct = this.additionals.filter((a)=> a.productArray && a.productArray.includes(this.itemId));
        if(!this.additionalsProduct || this.additionalsProduct.length === 0) {
          this.additionalsProduct = this.additionals.filter((a)=> a.subCategoryArray && a.subCategoryArray.includes(form.subCategoryId));
        }
        else {
          this.additionalsFromProduct = true;
          this.additionalsProductDisabled = this.additionals.filter((a)=> a.subCategoryArray && a.subCategoryArray.includes(form.subCategoryId));
        }
        if(!this.additionalsProduct) {
          this.additionalsProduct = [];
        }

        this.variantProduct = null;
        form.hasVariation = false;
        this.variantProduct = this.additionalsProduct.find((a)=> a.type==='variation');
        if(this.variantProduct && this.variantProduct.itemsText) {
          let variationDataOriginal = [];
          form.variationDataOriginal = form.variationDataOriginal || [];
          for(let text of this.variantProduct.itemsText) {
            variationDataOriginal.push({
              id: text.id,
              value: (form.variationDataOriginal.find(a=>a.id===text.id) || {}).value || 0
            });
          }
          form.variationDataOriginal = variationDataOriginal;
          form.hasVariation = true;
        }

        this.promotion = productUtils.getPromotion(this.promotions, {...form, id: this.itemId});

        console.log('this.promotion', this.promotion);
        this.form = form;
        console.log('this.form', this.form, this.variantProduct);
        this.isLoading = false;
      },

      async conclude() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          return;
        }
        this.hasError = null;
        this.tryingToUpdate = true;
        let form = _.cloneDeep(this.form);

        if(!form.companyId) {
          form.companyId = this.currentCompany.id;
        }

        form.subCategory = null;
        if(form.subCategoryId) {
          let subCategory = this.subCategories.find(a=>a.id===form.subCategoryId);
          if(subCategory) {
/*            form.subCategory = {
              active: subCategory.active,
              description: subCategory.description,
              id: subCategory.id,
              visible: subCategory.visible,
              categoryId: subCategory.categoryId,
            }; */
            form.subCategory = _.cloneDeep(subCategory);
            delete form.subCategory.search;
            delete form.subCategory.updatedAt;
          }
        }

        form.search = (form.name ||'')+ ' ' + (form.description|| '').toLowerCase().trim();
        form.hasError = false;

        if(form.hasVariation) {
          form.valueOriginal = 0;
          let first = true;
          if(form.variationDataOriginal) {
            for(let vd in form.variationDataOriginal) {
              form.variationDataOriginal[vd].id = String(form.variationDataOriginal[vd].id || '');
              form.variationDataOriginal[vd].value = parseFloat(form.variationDataOriginal[vd].value || 0);
              if(first && form.variationDataOriginal[vd].value>0) {
                form.valueOriginal = form.variationDataOriginal[vd].value;
                first = false;
              }
              else if (form.valueOriginal>form.variationDataOriginal[vd].value && form.variationDataOriginal[vd].value>0){
                form.valueOriginal = form.variationDataOriginal[vd].value;
              }
            }
          }
        }
        else {
          if(!form.valueOriginal || form.valueOriginal<=0) {
            form.valueOriginal = 0;
          }
        }
        form.valueOriginal = parseFloat(form.valueOriginal);

        form.value = form.valueOriginal;
        form.variationData = _.cloneDeep(form.variationDataOriginal);

        form.hasPromotion = false;
        form.promotionId = '';
        this.promotion = productUtils.getPromotion(this.promotions, {...this.form, id: this.itemId});
        if(this.promotion) {
          const value = productUtils.calcDiscount(form.valueOriginal, this.promotion);
          if(!value || value<0) {
            form.hasError = true;
          }
          if(form.valueOriginal>value) {
            form.hasPromotion= true;
            form.promotionId= this.promotion.id;
            form.value = value;
            for(let vd in form.variationDataOriginal || []) {
              const valueTmp = productUtils.calcDiscount(form.variationDataOriginal[vd].value, this.promotion);
              if(!valueTmp || valueTmp<=0) {
                form.hasError = true;
              }
              else {
                form.variationData[vd].value = valueTmp;
              }
            }
          }
        }

        if(form.hasError) {
          form.active = false;
        }


        firebase.analytics().logEvent('Salvou info Product');

        form.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

        form.active = !!form.active;
        form.isLiquid = !!form.isLiquid;
        form.hasVariation = !!form.hasVariation;
        form.visible = !!form.visible;
        form.order = parseInt(form.order || 0) || 0;
        delete form.scoreSearch;
        delete form.deleted;

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('companiesProducts/'+this.currentCompany.id+'/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = this.hasError = {message:this.$t('form.error.uploadImage')};
            this.$showError({title: this.$t('form.error.image.upload')});
          }
        }

        try {
          let promises = [];
          form.tags = [];
          form.tagsId = [];
          form.ingredients = [];
          form.ingredientsId = [];
          form.nutritionals = [];
          form.nutritionalsId = [];

          for(let tag of this.tags) {
            form.tagsId.push(tag.id);
            form.tags.push({id: tag.id,name:tag.name || '',imgUrl:tag.imgUrl || '', showOnMenu: tag.showOnMenu || false});
          }

          for(let tag of this.ingredients) {
            form.ingredientsId.push(tag.id);
            form.ingredients.push({id: tag.id,name:tag.name || '',imgUrl:tag.imgUrl || ''});
          }

          for(let tagId in this.nutritionalRemoved) {
            // eslint-disable-next-line eqeqeq
            if(!this.nutritional.find(a=>a.id==tagId)) {
              promises.push(firebase.firestore().collection('companiesProducts').doc(this.itemId).collection('nutritional').doc(tagId).delete());
            }
          }
          for(let tag of this.nutritional) {
            let tmpNutritional = {
              name:tag.name,
              id:tag.id,
              unit:tag.unit,
              vdTotal:parseFloat(tag.vdTotal) || 0,
              value:parseFloat(tag.value) || 0,
              vd: (100*(parseFloat(tag.value) || 0))/(parseFloat(tag.vdTotal))
            };
            form.nutritionalsId.push(tag.id);
            form.nutritionals.push(tmpNutritional);
            promises.push(firebase.firestore().collection('companiesProducts').doc(this.itemId).collection('nutritional').doc(tag.id).set(tmpNutritional,{merge: true}));
          }

          promises.push(firebase.firestore().collection('companiesProducts').doc(this.itemId).set(form,{merge: true}));
          await Promise.all(promises);
          this.$showSuccess();
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }

        this.tryingToUpdate = false;
        this.tryingToUpload = false;
        this.$emit('changeModel', null);
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            }
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      removeNutritional(option) {
        this.nutritionalRemoved[option.id] = true;
       /* if(!option.required) {
          this.tagsRemoved[option.id] = true;
        }
        else {
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'error',
            title: this.$t('form.error.nutritionalRequired'),
            showConfirmButton: false,
            timer: 2000
          })
        } */
      },
      changeSubCategory() {
        console.log('changeSubCategory');

        this.additionalsFromProduct = false;

        this.additionalsProductDisabled = [];
        this.additionalsProduct = this.additionals.filter((a)=> a.productArray && a.productArray.includes(this.itemId));
        if(!this.additionalsProduct || this.additionalsProduct.length === 0) {
          this.additionalsProduct = this.additionals.filter((a)=> a.subCategoryArray && a.subCategoryArray.includes(this.form.subCategoryId));
        }
        else {
          this.additionalsFromProduct = true;
          this.additionalsProductDisabled = this.additionals.filter((a)=> a.subCategoryArray && a.subCategoryArray.includes(this.form.subCategoryId));
        }
        if(!this.additionalsProduct) {
          this.additionalsProduct = [];
        }

        this.variantProduct = null;
        this.form.hasVariation = false;
        this.variantProduct = this.additionalsProduct.find((a)=> a.type==='variation');
        if(this.variantProduct && this.variantProduct.itemsText) {
          let variationDataOriginal = [];
          this.form.variationDataOriginal = this.form.variationDataOriginal || [];
          let isFirst = true;
          for(let text of this.variantProduct.itemsText) {
            let valueTmp = (this.form.variationDataOriginal.find(a=>a.id===text.id) || {}).value || 0;
            variationDataOriginal.push({
              id: text.id,
              value: valueTmp
            });
            if(valueTmp<this.form.valueOriginal || isFirst) {
              this.form.valueOriginal = valueTmp;
              isFirst = false;
            }
          }
          this.form.variationDataOriginal = variationDataOriginal;
          this.form.hasVariation = true;
        }

        this.promotion = productUtils.getPromotion(this.promotions, {...this.form, id: this.itemId});
      }
    },
  }
</script>

<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded">
          <div v-if="isNew" :class="$style.contentHeaderTitle">{{$t('all.createProduct').toUpperCase()}}</div>
          <div v-if="!isNew" :class="$style.contentHeaderTitle">{{$t('all.editProduct').toUpperCase()}}</div>
          <BaseDev>{{itemId}}</BaseDev>

          <div :class="$style.imagePreviewContent">
            <div :class="$style.imagePreview" @click="tryChangeImage()" >
              <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
              <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
              <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
            </div>
          </div>

          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     type-content="block"
                     :max-width="525"
                     :max-height="375"
                     :aspect-ratio="525/375"
                     @changeBlob="changeBlob"
          ></FcmEditor>
          <div :class="$style.body">
            <BaseInputText v-model="form.name" :label="$t('product.name')" :class="[getValidationClass('name'), 'formItemText']" maxlength="100">
            </BaseInputText>
            <BaseInputText v-model="form.description" :label="$t('product.description')" :class="[getValidationClass('description'), 'formItemText']" maxlength="350">
            </BaseInputText>
            <div class="formItemSubSection">Opções</div>
            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.active')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.active" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.visible')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.visible" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
        <!--    <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('product.hasVariant')}}</div>
                <div class="formItemSub">{{$t('product.hasVariantDesc')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.hasVariation" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
              <div class="formItemHelp">
                <BaseHelp
                  title="Variant"
                >
                  Você pode adicionar Variantes do produto no menu Adicionais (ex: grande, médio, pequeno)
                  <BaseLink tag="div" class="" name="company/additionals" :params="{type:'variation'}">
                    {{$t('all.goto')}} {{$t('all.variant')}}
                  </BaseLink>
                </BaseHelp>
              </div>
            </div>  -->

            <div v-if="!form.hasVariation" :class="['formItem', $style.priceContent]">
              <MdField :class="[getValidationClass('value'), 'formItemNumber']">
                <label>{{$t('product.valueOriginal')}}</label>
                <span class="md-prefix">R$</span>
                <MdInput v-model="form.valueOriginal" type="number"></MdInput>
                <span v-if="!$v.form.value.required" class="md-error" >{{$t('form.error.required')}}</span>
                <span v-else-if="!$v.form.value.decimal" class="md-error" >{{$t('form.error.decimal')}}</span>
                <span v-else-if="!$v.form.value.minValue" class="md-error" >{{$t('form.error.minValueZero')}}</span>
              </MdField>
              <div v-if="promotion" :class="$style.promotion">
                ({{promotion.discountType==='value'?(form.valueOriginal-promotion.discountValue):''}}{{promotion.discountType==='percent'?(form.valueOriginal*((100-promotion.discountPercent)/100)):''}})
              </div>
            </div>
            <div v-if="form.hasVariation && variantProduct && variantProduct.itemsText" :class="['formItem', $style.variant]">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('product.variantName')+': '+variantProduct.title}}</div>
              </div>
              <div :class="$style.variantItems">
                <div v-for="(item, index) in variantProduct.itemsText" :key="item.id" :class="$style.priceContent">
                  <MdField v-if="item.active" :class="[getValidationClass('value'), 'formItemNumber']">
                    <label>{{$t('product.variantValueOriginal')+': '+item.title}}</label>
                    <span class="md-prefix">R$</span>
                    <MdInput v-model="form.variationDataOriginal[index].value" type="number"></MdInput>
                    <span v-if="!$v.form.value.required" class="md-error" >{{$t('form.error.required')}}</span>
                    <span v-else-if="!$v.form.value.decimal" class="md-error" >{{$t('form.error.decimal')}}</span>
                    <span v-else-if="!$v.form.value.minValue" class="md-error" >{{$t('form.error.minValueZero')}}</span>
                  </MdField>
                  <div v-if="promotion" :class="$style.promotion">
                    ({{promotion.discountType==='value'?(form.variationDataOriginal[index].value-promotion.discountValue):''}}{{promotion.discountType==='percent'?(form.variationDataOriginal[index].value*((100-promotion.discountPercent)/100)):''}})
                  </div>
                </div>
              </div>
            </div>
            <div v-if="promotion" class="formItem">
              {{$t('product.promotion')}}: {{promotion.description}}
              ({{promotion.discountType==='value'?'-'+$n(promotion.discountValue, 'currency'):''}}
              {{promotion.discountType==='percent'?'-'+promotion.discountPercent+'%':''}}
              )
            </div>


            <div v-if="subCategories" class="formItem">
              <MdField >
                <label >{{$t('product.subCategory')}}</label>
                <MdSelect v-model="form.subCategoryId" @md-selected="changeSubCategory">
                  <MdOption v-for="item of subCategories" :key="item.id" :value="item.id">{{item.description}}</MdOption>
                </MdSelect>
              </MdField>
            </div>

            <div class="formItem">
              <Multiselect v-model="tags" :options="tagList" track-by="id" label="nameView" :multiple="true"
               :placeholder="$t('product.placeholderTags')"
               :select-label="$t('multiSelect.selectLabel')"
               :select-group-label="$t('multiSelect.selectGroupLabel')"
               :selected-label="$t('multiSelect.selectedLabel')"
               :deselect-label="$t('multiSelect.deselectLabel')"
               :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
              >
              </Multiselect>
            </div>

            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('form.isLiquid')}}</div>
                <div class="formItemSub"></div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.isLiquid" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>

            <div class="formItem">
              <MdField >
                <label >{{$t('product.portion')}}</label>
                <MdInput v-model="form.portion" :value="form.portion" type="number"></MdInput>
              </MdField>
            </div>

            <div class="formItem">
              <Multiselect v-model="ingredients" :options="ingredientsList" track-by="id" label="nameView" :multiple="true"
                           :placeholder="$t('product.placeholderIngredients')"
                           :select-label="$t('multiSelect.selectLabel')"
                           :select-group-label="$t('multiSelect.selectGroupLabel')"
                           :selected-label="$t('multiSelect.selectedLabel')"
                           :deselect-label="$t('multiSelect.deselectLabel')"
                           :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
              >
              </Multiselect>
            </div>
            <div class="formItem">
              <Multiselect v-model="nutritional" :options="nutritionalList" track-by="id" label="nameView" :multiple="true"
                           :placeholder="$t('product.placeholderNutritional')"
                           :select-label="$t('multiSelect.selectLabel')"
                           :select-group-label="$t('multiSelect.selectGroupLabel')"
                           :selected-label="$t('multiSelect.selectedLabel')"
                           :deselect-label="$t('multiSelect.deselectLabel')"
                           :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
                           @remove="removeNutritional"
              >
              </Multiselect>
            </div>
            <MdTable v-if="(nutritional && nutritional.length)" v-model="nutritional" md-card>
              <MdTableToolbar>
                <h1 class="md-title">{{$t('product.nutritional')}}</h1>
              </MdTableToolbar>

              <MdTableRow slot="md-table-row" slot-scope="{ item, index }">
                <MdTableCell :md-label="$t('product.nutritionalName')" md-sort-by="name.ptbr">{{ item.name.ptbr }}</MdTableCell>
                <MdTableCell :md-label="$t('product.vdDaily')" md-sort-by="vdDaily">{{ item.vdTotal +''+item.unit }}</MdTableCell>
<!--                  <MdTableCell :md-label="$t('product.nutritionalValue')+' 100'+(form.isLiquid?'ml':'g')" md-sort-by="value">
                  <MdField >
                    <MdInput v-model="nutritional[index].value" :class="$style.nutritionalValue" :value="nutritional[index].value" type="number"></MdInput>
                  </MdField>
                </MdTableCell> -->

                <MdTableCell :md-label="$t('product.nutritionalValue')" md-sort-by="value">
                  <MdField>
                    <MdInput v-model="nutritional[index].value" :class="$style.nutritionalValue" :value="nutritional[index].value" type="number"></MdInput>
                  </MdField>
                </MdTableCell>
                <MdTableCell :md-label="$t('product.nutritionalValueVd')" md-sort-by="value">
                  {{parseFloat(((100*(parseFloat(nutritional[index].value)))/(parseFloat(item.vdTotal))).toFixed(2))}}
                </MdTableCell>
              </MdTableRow>
            </MdTable>

            <div class="formItem">
              <div>
                <div :class="$style.additionalsHeader">{{$t('product.additionalsHeader')}}</div>
                <div v-for="(item) in additionalsProductDisabled" :key="'additionalsProductDisabled'+item.id" :class="$style.additionalsItemDisabled">
                  <RouterLink :to="'/company/additionals/'+item.type+'/'+item.id" >{{item.title+' ('+$t('additional.'+item.type)+')'+(additionalsFromProduct?' '+$t('product.additionalsFromCategory'):'')}}</RouterLink>
                </div>
                <div v-for="(item) in additionalsProduct" :key="'additionalsProduct'+item.id" :class="$style.additionalsItem">
                  <RouterLink :to="'/company/additionals/'+item.type+'/'+item.id" >{{item.title+' ('+$t('additional.'+item.type)+')'+(additionalsFromProduct?'':' '+$t('product.additionalsFromCategory'))}}</RouterLink>
                </div>
              </div>
            </div>

          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
  @import '@design';

  .fcmEditor {
    @media screen and (max-width: 570px) {
      max-width: 80%;
    }
  }

  .box {
    @extend %defaultBoxF1;
    .container {
      .content {
        .contentLoaded {
          .imagePreviewContent {
            max-width: #{$previewProductW*$previewProductScale}px;
            .imagePreview {
              width: 100%;
              padding-bottom: #{((100*$previewProductH*$previewProductScale)/($previewProductW*$previewProductScale))+'%'};
              img {
                position:absolute;
              }
            }
          }

          .body {
            .formItemNutritional {
              display: block;
            }
            .nutritionalValue {
              max-width: 50px;
            }
            .variant {
              flex-direction: column;
              padding-left: 20px;
            }
            .variantItems {
              padding-left: 20px;
            }
            .additionalsItem {
              margin-left: 10px;
              margin-top: 4px;
              a {
                color: blue;
              }
            }
            .additionalsItemDisabled {
              margin-left: 10px;
              margin-top: 4px;

              text-decoration: line-through;
              a {
                color: #929292;
              }
            }

            .priceContent {
              display: flex;
              align-items: center;
              .promotion {
                margin-left: 15px;
                color: $color-primary;
              }
            }

          }
        }
      }
    }
  }
</style>





















