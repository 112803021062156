"use strict";

let toExport = {};


toExport.getPromotion = function(promotions, product) {
  if(!promotions || !promotions.length) {return null;}
  if(!product) {return null;}

  let value = product.valueOriginal;
  let promotionRet = null;
  for(const promotion of promotions || []) {
    if( (product.id && promotion.applyIn==='products' && (promotion.productsIds ||[]).includes(product.id) )
    || (product.subCategoryId && promotion.applyIn==='categories' && (promotion.subCategoriesIds ||[]).includes(product.subCategoryId) )
    || (promotion.applyIn==='all')
    ) {
      if(product.valueOriginal) {
        const valueTmp = toExport.calcDiscount(product.valueOriginal, promotion);
        if(value>valueTmp) {
          promotionRet = promotion;
          value = valueTmp;
        }
      }
      else {
        promotionRet = promotion;
        break;
      }
    }
  }
  return promotionRet;
};



toExport.calcDiscount = function (value, promotion) {
  if(promotion.discountType ==='percent') {
    if(!promotion.discountPercent) {
      console.error('ERROR, promotion.discountPercent invalid:', promotion.discountPercent);
      return value;
    }
    const ret = parseFloat(value) * ((100-parseFloat(promotion.discountPercent))/100);
    return ret>0?ret:value;
  }
  else if(promotion.discountType ==='value') {
    if(!promotion.discountValue) {
      console.error('ERROR, promotion.discountValue invalid:', promotion.discountValue);
      return value;
    }
    const ret = parseFloat(value)-parseFloat(promotion.discountValue);
    return ret>0?ret:value;
  }
  console.error('ERROR, promotion.discountType invalid:', promotion.discountType);
  return value;
};


/*
toExport.calcPercent = function (value, percent) {
  const ret = parseFloat(value) * ((100-parseFloat(percent))/100);
  return ret>0?ret:0;
};
toExport.calcValue = function(value, percent) {
  const ret = parseFloat(value)-parseFloat(percent);
  return ret>0?ret:0;
}; */

module.exports = toExport;


















