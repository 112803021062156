module.exports = {
  order: {
    title: "Pedido",
    order: "Pedido",
    date: "Data/Hora",
    via: "Via",
    paymentTitle: "Pagamento",
    paymentOnTakeout: "Pagamento na Retirada",
    clientName: "Cliente",
    clientPhone: "Telefone",
    clientDocumentID: "CPF",
    product: "Produto",
    qtd: "Qtd",
    viaOption: {
      "0": "Caixa / Balcão",
      "1": "Interna / Cozinha",
      "2": "Entregador"
    },
    subTotal: "SubTotal",
    total: "Total",
    credits: "Créditos",
    deliveryTax: "Entrega",
    changeFor: "Troco para",
    change: "Troco",
    address: "Endereço",
    paymentType: "Tipo de pagamento",
    type: "Tipo",
    deliveryAddress: "Endereço",
    deliveryType: "Tipo de pedido",
    value: "Valor",
    itemName: "Produto",
    itemQtd: "Quantidade",
    itemValueTotal: "Total",
    payment: "Pagamento",
    status: "Estado",
    time: "Data",
    newOrderNotificationTitle: "Novo pedido",
    newOrderNotificationBody: "Acaba de ser recebido um novo pedido",
    changeForStatus: "Alterar para",
    number: "Número do pedido",
    code: "Código do pedido",
    numberSlim: "Número",
    codeSlim: "Código",
    distance: "Distância",
    itemObservation: "Obs",
    obsOrder: "Obs pedido",
    ingredientsRestrictions: "Restrição de ingredientes",
    successOrderCode: "Sucesso ao atualizar código do pedido",
    successStatus: "Sucesso ao atualizar estado do pedido",
    cancelOrder: "Cancelar pedido",
    successCancel: "Sucesso ao cancelar pedido",
    youAreSure: "Você tem certeza?",
    youAreWantCancelOrder: "Você quer cancelar esse pedido?",
    youAreYes: "Sim, quero cancelar o pedido",
    youAreNo: "Não, eu não quero cancelar o pedido",
    appNameTitle: "Go Tap",
    noOrder: "Nenhum Pedido",
    onlyRestaurantHaveAccess: "Apenas o restaurante tem acesso a essa ação",
    deliveryTxValue: "Taxa de entrega",
    cancelReason: "Motivo do cancelamento",
    tableNameEmpty: "Local",
    tableSelectEmpty: "Não foi selecionado um local",
    tableSelectError: "Local selecionado inválido"
  },
  format: {
    data: "DD/MM/YYYY",
    dataHour: "DD/MM/YYYY HH:mm"
  },
  deliveryType: {
    delivery: "Entrega",
    pickup: "Retirar na loja",
    onSite: "Pedido no estabelecimento"
  },
  message: {
    hello: "Olá mundo2"
  },
  login: {
    access: "Acessar Restaurante",
    accessCompany: "Acessar Restaurante",
    errorCompany: "Nenhuma empresa associada a esse email",
    login: "Entrar com Email",
    goReset: "Esqueci minha senha",
    goLogin: "Retornar ao login",
    accessClient: "Acesso Cliente",
    accessAdmin: "Acesso Admin",
    facebook: "Entrar com Facebook",
    google: "Entrar com Google",
    apple: "Entrar com Apple",
    phone: "Entrar com Telefone",
    facebookRegister: "Cadastro com Facebook",
    googleRegister: "Cadastro com Google",
    appleRegister: "Cadastro com Apple",
    phoneRegister: "Cadastro com Telefone",
    emailRegister: "Cadastro com Email",
    getPhoneNumber: "Insira seu número de telefone",
    getPhoneCode: "Insira seu código de verificação enviado ao seu celular",
    resetCompany: "Resetar Senha",
    reset: "Enviar email",
    error: {
      generic: "Houve algum erro ao efetuar login",
      phoneEmpty: "Número de telefone vazio",
      phoneCodeEmpty: "Código de verificação vazio",
      loginTitle: "Erro ao efetuar login",
      providerTitle: "Erro ao adicionar provider"
    },
    providerCode: {
      "auth/invalid-email": "Email inválido",
      "auth/user-disabled": "Conta desabilitada",
      "auth/user-not-found": "Usuário não encontrado",
      "auth/wrong-password": "Senha incorreta",
      "auth/credential-already-in-use": "Credencial já utilizada",
      "auth/too-many-requests": "Muitas tentativas, tente novamente mais tarde",
      "auth/operation-not-allowed": "Tipo de login não configurado, tente outra forma de login"
    },
    addFacebook: "Adicionar Facebook",
    addGoogle: "Adicionar Google",
    addApple: "Adicionar Apple",
    providers: "Métodos de autenticação",
    remove: "Remover",
    providerId: "provider",
    email: "email",
    uid: "uid",
    changePassword: "Alterar senha",
    addPassword: "Adicionar Senha",
    resetClient: "Resetar senha",
    register: "Registrar",
    registerClient: "Registrar",
    resetPassword: "Resetar senha",
    makeRegister: "Efetuar cadastro",
    accessOng: "Acessar Ong"
  },
  all: {
    restaurants: "Restaurantes",
    restaurant: "Restaurante",
    next: "Próximo",
    knowMore: "Saiba Mais",
    notImplemented: "Não implementado",
    attendance: "Atendimento",
    orders: "Pedidos",
    reviews: "Avaliações",
    promotions: "Promoções",
    settings: "Configurações",
    products: "Cardápio",
    delivery: "Entregas",
    schedule: "Horários",
    information: "Informação",
    informations: "Informações",
    performance: "Performance",
    history: "Histórico",
    demographics: "Público",
    of: "de",
    step: "Etapa",
    category: "Categoria",
    subCategory: "Sub Categoria",
    profile: "Perfil",
    categories: "Categorias",
    users: "Usuários",
    paymentOptions: "Pagamento",
    otherOptions: "Diversos",
    conclude: "Concluir",
    close: "Fechar",
    cancel: "Cancelar",
    nothingToList: "Lista vazia",
    search: "Pesquisar",
    productsCategories: "Categorias",
    productsSubCategories: "Sub Categorias",
    additionals: "Adicionais",
    createProduct: "Criar Produto",
    editCategory: "Editar Categoria",
    editProduct: "Editar Produto",
    editSubCategory: "Editar Subcategoria",
    settingsProducts: "Configuração do Cardápio",
    companiesTags: "Tags Empresa",
    productsNutritional: "Nutricional",
    productsNags: "Tags Produto",
    vdTotal: "VD total",
    categoriesProducts: "Categorias de Produtos",
    productsTags: "Tags de Produtos",
    productsIngredients: "Ingredientes",
    cards: "Cartões",
    companyConfigs: "Configurações",
    edit: "Editar",
    create: "Novo",
    clients: "Clientes",
    regions: "Regiões",
    region: "Região",
    regionUtcOffset: "UtcOffset",
    jobs: "Jobs",
    tags: "Tags",
    tagAssociate: "Tag associada",
    company_tags: "Tags Empresa",
    product_nutritional: "Nutricional",
    product_tags: "Tags Produto",
    onSite: "Consumo no local",
    takeout: "Retirada",
    blog: "Blog",
    faq: "Faq",
    pages: "Páginas",
    blogCategories: "Blog Categorias",
    paymentConfigs: "Configurações de pagamento",
    adminDeliveryOptions: "Opções de delivery",
    ongs: "Ongs",
    ong: "Ong",
    yes: "Sim",
    no: "Não",
    contactSupport: "Entre em contato com o suporte",
    iuguAccounts: "Subcontas Iugu",
    exports: "Exportar",
    changeImage: "Alterar imagem",
    companies: "Restaurantes",
    notifications: "Notificações",
    hubs: "Hubs",
    adCards: "AdCards",
    printerOptions: "Impresora",
    errorElectronPrinter: "Erro ao imprimir",
    successElectronPrinter: "Impresso",
  },
  form: {
    error: {
      required: "Esse campo é requerido",
      minLength: "O campo é muito curto",
      maxLength: "O campo é muito longo",
      update: "Houve algum erro",
      email: "Email inválido",
      image: {
        type: "Tipo de arquivo inválido, esperado: PNG, JPEG",
        upload: "Erro ao fazer ulpload da imagem, tente novamente mais tarde!"
      },
      formInvalid: "Campo inválido",
      uploadImage: "Erro ao fazer upload de imagem",
      closeEditor: "Conclua ou cancele o editor antes de proseguir",
      showOnMenu: "Mostrar no menu",
      regionAreaName: "Erro ao obter nome da área da região",
      schedule: "Erro a reduzir número de escalas",
      scheduleMax: "Número máximo de escalas atingido",
      tagType: "A tag deve ser aplicável a produtos e ou empresas",
      companyNeedApproved: "É preciso aprovar a empresa para habilitar",
      needOnePayment: "É necessário selecionar pelo menos uma forma de pagamento aceita(Pelo App ou na Entrega)",
      NeedRegion: "É necessário definir uma região",
      changeOnlyAdmin: "Entre em contato com o suporte para efetuar essa ação"
    },
    update: "Atualizar",
    companyName: "Nome do restaurante",
    corporateName: "Razão social",
    updateSuccess: "Dados salvos",
    ownerName: "Nome do proprietário",
    email: "Email",
    password: "Senha",
    continue: "Continuar",
    cnpj: "CNPJ",
    cpf: "CPF",
    name: "Nome",
    prevStep: "Etapa Anterior",
    nextStep: "Próxima etapa",
    conclude: "Concluir",
    close: "Fechar",
    cancel: "Cancelar",
    changePrimaryColor: "Alterar cor primária",
    changeSecondaryColor: "Alterar cor secundária",
    emailResetSuccess: "Em instantes você receberá um email para redefinir a sua senha",
    emailResetError: "Houve um erro ao tentar enviar email de redefinição de senha",
    changePassword: "Alterar Senha",
    passwordNew: "Nova Senha",
    passwordOld: "Senha atual",
    info: "Informação do restaurante",
    address: {
      city: "Cidade",
      complement: "Complemento",
      country: "Pais",
      district: "Bairro",
      number: "Número",
      search: "Buscar Endereço",
      state: "Estado",
      street: "Rua",
      zipcode: "CEP",
      fieldWarning: "Erro ao buscar endereço",
      emptyResult: "Nenhum endereço encontrado"
    },
    contact: "Contato",
    description: "Descrição",
    phone: "Telefone",
    delete: "Deletar",
    edit: "Editar",
    add: "Adicionar",
    permission: "Permissão",
    active: "Ativo",
    inactive: "Inativo",
    create: "Criar",
    isLiquid: "É líquido",
    isMandatory: "Obrigatório",
    numberOfChoices: "Máximo de escolhas (0 sem limite)",
    print: "Imprimir",
    showIds: "Mostrar Ids",
    visible: "Visísvel",
    required: "Obrigatório",
    nutritionalUnit: "Unidade de medida",
    showOnMenu: "Mostrar no menu",
    isCash: "É dinheiro?",
    getDeliveryAreaName: "Nome da área de entrega",
    getRegionName: "Nome da área da região",
    isProduct: "Aplicável em produtos",
    isCompany: "Aplicável em empresas",
    isRestrictions: "É Restrição Alimentar",
    uniqueName: "Identificador único",
    slug: "Url",
    title: "Título",
    subTitle: "Legenda",
    bodyText: "Texto",
    abstract: "Resumo",
    response: "Resposta",
    category: "Categoria",
    ongName: "Nome da ong",
    availableForIugu: "Habilitar criação de conta no iugu",
    approved: "Restaurante Aprovado",
    isAdvanced: "Edição avançada",
    alert: {
      emailAddSuccess: "Email adicionado com sucesso"
    },
    region: "Região",
    isTest: "Mostrar em teste",
    isNew: "Restaurante novo",
    regionAll: "Todas as Regiões",
    selectUser: "Selecione o usuário",
    selectUsers: "Selecione os usuários",
    selectCompany: "Selecione a empresa",
    selectCompanies: "Selecione as empresas",
    selectProduct: "Selecione o produto",
    selectProducts: "Selecione os produtos",
    link: "Link",
    selectUsersOrderNotifications1: "Usuários notificados em pedidos atrasados em 5 min",
    selectUsersOrderNotifications2: "Usuários notificados em pedidos atrasados em 10 min",
    deliveryHubDenied: "Bloquear delivery nessa região?",
    regionAreaInfo: "Área para BLOQUEAR recebimento de pedidos para ENTREGA",
    search: "Buscar",
    showOrders: "Mostrar pedidos",
  },
  register: {
    increaseSails: "AUMENTE AS VENDAS DO SEU RESTAURANTE",
    discoverAdvantages: "Descubra as vantagens e aumente suas vendas com uma conta Go Tap!",
    singIn: "Cadastre seu restaurante!",
    ifAccountExist: "Se já possui uma conta, faça login aqui embaixo",
    orSingIn: "ou crie uma nova conta para começar.",
    singInOng: "Cadastre a ONG"
  },
  navBar: {
    singIn: "Torne-se um parceiro",
    logIn: "Entrar",
    logOut: "Sair",
    isOpen: "Aberto",
    isClose: "Fechado",
    isAuto: "Automático",
    isManual: "Manual",
    acceptDelivery: "Entrega",
    acceptTakeout: "Retirada",
    acceptOnSite: "No Local",
    noAcceptConfig: "Entre nas configurações e habilite alguma forma de disponibilizar produtos (Entrega, Retirada, No Local)",
    accessCompany: "Acesso restaurante",
    nextAutoChange: "Próxima alteração automática",
    manual: "manual",
    auto: "automático"
  },
  image: {
    crop: "Crop",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    rotateLeft: "Girar Esquerda",
    rotateRight: "Girar Direita",
    mirrorY: "Espelhar Y",
    mirrorX: "Espelhar X"
  },
  profile: {
    title: {
      client: "Informações",
      admin: "Informações",
      company: "Informações do Restaurante",
      user: "Informações do usuário"
    }
  },
  promotion: {
    step: "Etapa",
    of: "de",
    createPromotion: "Criar promoção",
    editPromotion: "Editar promoção",
    infoSub: "Informações da promoção",
    line1: "Linha1",
    line2: "Linha2",
    description: "Descrição da promoção",
    behaviorSub: "Funcionamento da promoção",
    behaviorAll: "Aplicar a todos os pedidos",
    behaviorAllDesc: "Aplicar promoção a todos os pedidos de qualquer cliente.",
    behaviorFirst: "Aplicar apenas ao primeiro pedido do cliente",
    behaviorFirstDesc: "Aplicar promoção apenas ao primeiro pedido de um novo cliente.",
    behaviorCoupon: "Aplicar com código promocional",
    behaviorCouponDesc: "Aplicar promoção por meio de código promocional.",
    typeSub: "Tipo de promoção",
    typeDelivery: "Delivery grátis",
    typeDeliveryDesc: "Isenção da taxa de entrega dos pedidos via delivey.",
    typePercent: "Porcentagem de desconto",
    typePercentDesc: "Aplique um percentual de desconto sobre o valor dos produtos.",
    typeValue: "Valor de desconto",
    typeValueDesc: "Desconte um valor determinado ao final do pedido.",
    typeGift: "Brinde",
    typeGiftDesc: "Inclua um ou mais produtos gratuitamente ao pedido.",
    applyInSub: "Abrangência da promoção",
    applyInAll: "Cardápio completo",
    applyInAllDesc: "A promoção se aplicará a todos os itens do seu cardápio.",
    applyInCategories: "Categorias selecionadas",
    applyInCategoriesDesc: "Selecione uma ou mais categorias do cardápio para aplicar a promoção.",
    applyInProducts: "Produtos selecionados",
    applyInProductsDesc: "Selecione produtos específicos aos quais a promoção se aplica.",
    configPromotion: "Configurar promoção",
    durationSub: "Duração e validade",
    isValidity: "Duração determinada",
    isValidityDesc: "Determine uma data para encerrar a promoção automaticamente.",
    isRecurrent: "Promoção recorrente",
    isRecurrentDesc: "Programe a promoção para que se repita automaticamente.",
    restrictionsSub: "Restrições da promoção",
    isQuantity: "Limitar quantidade",
    isQuantityDesc: "Determine uma quantidade de pedidos para encerrar a promoção.",
    isTakeOut: "Retirada",
    isTakeOutDesc: "A promoção se aplica aos produtos retirados na loja.",
    isDelivery: "Delivery",
    isDeliveryDesc: "A promoção se aplica aos produtos entregues via delivery.",
    isMinValue: "Valor mínimo",
    isMinValueDesc: "A promoção se aplica aos pedidos com um valor mínimo.",
    detailsPromotion: "Detalhes da promoção",
    discountValueSub: "Valor do desconto",
    discountPercent: "Porcentagem de desconto",
    discountValue: "Valor de desconto",
    recurrentNot: "Não repetir a promoção",
    recurrentNotDesc: "A promoção não será repetida",
    recurrentSub: "Recorrência da promoção",
    recurrentWeekly: "Semanalmente",
    recurrentWeeklyDesc: "A promoção será repetida no mesmo horário uma vez por semana",
    recurrentDaily: "Diariamente",
    recurrentDailyDesc: "A promoção será repetida todos os dias no mesmo horário",
    recurrentMonthly: "Mensalmente",
    recurrentMonthlyDesc: "A promoção será repetida no mesmo dia e horário todos os meses",
    limitQuantity: "Limite de pedidos",
    minValue: "Valor Mínimo",
    dateBegin: "Data de início",
    dateEnd: "Data de término",
    hourBegin: "Hora de início",
    hourEnd: "Hora de termino",
    recurrentWeeklySub: "Selecione os dias da semana",
    recurrentMonthlySub: "Selecione o dia do mês",
    duration: "Duração da promoção",
    active: "Promoção Ativa"
  },
  calendar: {
    week: {
      "0": "Domingo",
      "1": "Segunda",
      "2": "Terça",
      "3": "Quarta",
      "4": "Quinta",
      "5": "Sexta",
      "6": "Sábado"
    }
  },
  access: {
    title: "Acesso",
    admin: "Administrador",
    editor: "Editor",
    masterEditor: "Master Editor",
    attendant: "Atendente",
    delivery: "Entregador"
  },
  tools: {
    title: "Ferramenta"
  },
  deliveryOptions: {
    deliveryConfig: "Configuração de entrega",
    deliveryErrorLatlng: "Configure o endereço da empresa primeiro",
    kmValue: "Valor da entrega por km",
    minValue: "Valor mínimo",
    roundAt: "Arredondar a cada",
    precedence: "Precedência de valor",
    precedenceDistance: "Aplicável até (km)",
    precedenceValue: "Valor a ser aplicado",
    deliveryBy: {
      title: "Quem vai fazer a entrega?",
      company: "O Restaurante",
      big: "Go Tap"
    },
    region: "Região",
    precedenceDistanceFrom: "A partir de (km)",
    precedenceDistanceTo: "Até (km)(0 infinito)",
    precedenceBaseValue: "Valor base",
    precedenceKmValue: "Valor por Km",
    asOf: "a partir de",
    km: "Km",
    lstPrecedence: "Lista de precendência",
    valueAutomatic: "Valor gerado automaticamente"
  },
  otherOptions: {
    acceptDelivery: " Permite pedidos para entrega",
    acceptDeliveryDesc: "Aceita entregas",
    acceptPaymentOutApp: "Aceita pagamento fora do app",
    acceptPaymentOutAppDesc: "Aceita pagamento fora do App",
    acceptSails: "Aceita vendas no app",
    acceptSailsDesc: "Aceita vendas",
    acceptTakeAtEstablishment: "Permite pedidos a retirar no estabelecimento",
    acceptTakeAtEstablishmentDesc: "Aceita Takeout",
    otherOptionsSub: "Outras configurações",
    showOnlyCategoryOnMenu: "Mostrar apenas Subcategorias no Menu",
    showOnlyCategoryOnMenuDesc: "Menu de um nível",
    acceptTakeout: "Aceita Retirada",
    acceptOnSite: "Aceita consumo no local",
    timeForDelivery: "Tempo médio de delivery",
    timeForDeliveryMin: "De",
    timeForDeliveryMax: "Até",
    timeForPickupAndOnSite: "Tempo médio de retirada",
    timeForPickupAndOnSiteMin: "De",
    timeForPickupAndOnSiteMax: "Até",
    minValueForDelivery: "Valor mínimo para Delivery",
    maxValueForDelivery: "Valor máximo para Delivery",
    maxValueForOnSiteAndTakeout: "Valor máximo para Retirada e Consumo no local",
    print: "Opções de impressão (via do balcão é sempre impressa)",
    printKitchen: "Imprimir via da cozinha",
    printDelivery: "Imprimir via do entregador",
    disableAutoIsOpen: "Desabilitar Abertura/Fechamento automático",
    printMargin: "Margem de impressão(cm)",
    minValueForPickup: "Montante mínimo para retirada",
    minValueForOnSite: "Quantia mínima para consumo no local",
    maxValueForPickup: "Montante máximo para retirada",
    maxValueForOnSite: "Valor máximo para consumo no local",
    tableActive: "Mostrar escolha de mesa",
    tableActiveSub: "Apenas para consumo no local",
    tableRequire: "Escolha de mesa obrigatória",
    tableRequireSub: "Apenas para consumo no local",
    tables: "Mesas/locais",
    addTable: "Adicionar mesa",
    addTableSequence: "Adicionar Sequência",
    tableName: "Nome da mesa/local",
    tablesSequenceName: "Nome Base",
    tablesSequenceBegin: "Inicio",
    tablesSequenceEnd: "Fim",
    tablesSequenceAdd: "Adicionar",
    addTableSequenceTitle: "Adicionar Sequência",
    cancelTableSequence: "Cancelar",
    addTableSequenceButton: "Adicionar",
    onSiteTableName: "Nome da opção",
    selectPrinter: "Selecione a impressora",
    noPrinters: "Nenhuma impressora disponível",
  },
  product: {
    nutritionalName: "Nome",
    nutritionalValue: "Valor",
    nutritionalPortion: "Porção",
    nutritionalValuePortion: "Valor Porção",
    nutritionalValueVd: "VD %",
    vdDaily: "VD Diário",
    description: "Descrição",
    name: "Nome",
    nutritional: "Nutricional",
    portion: "Porção",
    value: "Preço",
    valueOriginal: "Preço Original",
    placeholderTags: "Escolha as Tags",
    placeholderNutritional: "Escolha os itens Nutricionais",
    placeholderIngredients: "Escolha os ingredientes",
    notVisible: "Não visível",
    category: "Categoria",
    variantName: "Variação",
    variantValue: "",
    variantValueOriginal: "Original",
    productHasError: "Produto com erro",
    additionalsHeader: "Variações aplicáveis para esse produto",
    additionalsFromCategory: "(configuração da sub categoria)",
    placeholderTagsRestrictions: "Tags restringidas",
    subCategory: "Sub Categoria",
    promotion: "Promoção",
  },
  multiSelect: {
    selectLabel: "Pressione enter para selecionar",
    selectGroupLabel: "Pressione enter para selecionar o grupo",
    selectedLabel: "Selecionado",
    deselectLabel: "Pressione enter para remover",
    deselectGroupLabel: "Pressione enter para cancelar a seleção do grupo",
    placeholder: "Selecione a opção"
  },
  additional: {
    additionals: "Adicionais",
    editAdditional: "Editar Adicional",
    freeText: "Opcionais",
    itemTitle: "Nome da opção",
    itemValue: "Valor da opção",
    placeholder: "Categorias Aplicáveis",
    title: "Adicional",
    type: "Tipo",
    variation: "Variantes",
    inputTitle: "Título",
    flavor: "Múltiplos sabores",
    allVariations: "Todas as variações"
  },
  listItems: {
    noGroup: "Produto sem categoria"
  },
  orderStatus: {
    canceled: "Pedido Cancelado",
    confirmed: "Pedido Confirmado",
    deliveryReady: "Entrega Concluída",
    takeoutReady: "Pedido Concluído",
    inPreparation: "Em Preparação",
    makingOrder: "Montando Pedido",
    waitDelivery: "Aguardando Entrega",
    onDelivery: "Em Entrega",
    waitConfirm: "Aguardando Confirmação",
    waitPayment: "Aguardando Pagamento",
    waitTakeout: "Aguardando Retirada",
  },
  orderType: {
    takeout: "Retirada",
    undefined: "Não definido"
  },
  paymentStatus: {
    confirm: "Pagamento Confirmado",
    undefined: "Pagamento não definido",
    invalidPaymentMethod: "Método de pagamento Inválido",
    invalidPaymentCard: "Erro com o cartão",
    invalidPaymentUser: "Usuário inválido",
    invalidPaymentUnknown: "Erro de pagamento desconhecido",
    invalidPaymentAlreadyEarly: "Pagamento já realizado",
    invalidPaymentLimitValue: "Desculpe, mas temos um limite de R$ 500,00 por compra no cartão.",

    iuguNotApproved: "Transação negada",
    iuguResponseUnknown: 'Erro ao processar o pagamento',
    iuguPaymentError: "Erro ao processar o pagamento!",
    iuguPaymentErrorCatch: "Erro ao processar o pagamento!!",
    iuguRefundErrorCatch: 'Erro ao estornar pagamento',
    iuguRefundErrorResult: 'Erro ao estornar pagamento!',
  },
  apiErrorCode: {
    statusError: "Estado inválido",
    permissionDenied: "O usuário não tem permissão para executar essa ação",
    unknown: "Erro desconhecido",
    paymentError: "Erro ao efetuar o pagamento",
    bagError: "Há algum erro com a sacola",
    inputError: "Erro nos parâmetros",
    exhaustedAttempts: "Muitas tentativas",
    paymentInvalidValue: "Valor de pagamento inválido",
    notFound: "Não encontrado",
    bagEmptyAddress: "Pedido sem endereço",
    bagEmptyProduct: "Pedido vazio",
    bagStatusError: 'Pedido cancelado ou em estado inválido',
  },
  companyInfo: {
    title: "Informações da empresa",
    das: "Das",
    as: "Às",
    isClose: "Fechado",
    attendanceTitle: "Horários de atendimento",
    paymentTitle: "Formas de pagamento aceitas",
    deliveryTip: "Entrega",
    onSiteTip: "No local",
    takeoutTip: "Retirada"
  },
  timeElapsed: {
    "6": "6 horas",
    "12": "12 horas",
    "24": "24 horas",
    "48": "2 dias",
    "120": "5 dias",
    "240": "10 dias",
    "720": "30 dias",
    "8784": "1 ano",
    label: "Período dos pedidos"
  },
  notifications: {
    orderTitle: {
      canceled: "Pedido Cancelado",
      confirmed: "Pedido Confirmado",
      deliveryReady: "Entrega Concluída",
      takeoutReady: "Pedido Concluído",
      inPreparation: "Em Preparação",
      waitDelivery: "Aguardando Entrega",
      onDelivery: "Em Entrega",
      waitConfirm: "Aguardando Confirmação",
      waitPayment: "Aguardando Pagamento",
      waitTakeout: "Aguardando Retirada"
    },
    orderBody: {
      canceled: "O seu pedido foi Cancelado",
      confirmed: "O seu pedido foi Confirmado",
      deliveryReady: "O seu pedido foi Entregue",
      takeoutReady: "Pedido Concluído",
      inPreparation: "O seu pedido está sendo preparado",
      waitDelivery: "O seu pedido está aguardando para ser entregue",
      onDelivery: "O seu pedido acabou de sair para entrega",
      waitConfirm: "Aguardando a confirmação do restaurante",
      waitPayment: "Aguardado o pagamento do pedido",
      waitTakeout: "O seu pedido já pode ser retirado"
    }
  },
  rules: {
    adm: {
      adm: "Administrador",
      sell: "Vendedor",
      analy: "Analytics",
      sup: "Suporte"
    },
    comp: {
      adm: "Administrador",
      edit: "Editor",
      mEdit: "Master Editor",
      deli: "Delivery",
      att: "Atendente"
    }
  },
  router: {
    accessDenied: "Acesso Negado"
  },
  maps: {
    areaDeliveryInfo: "Área de entrega"
  },
  company: {
    alertClosed: "Atenção, Restaurante Fechado, O horário está configurado para aberto",
    verifyStatus: "Estado do restaurante"
  },
  verifyStatus: {
    approved: "Aprovado",
    pending: "Pendentente",
    waitApproved: "Submetido para aprovação"
  },
  companyHome: {
    browserUpgrade: "Atualize o seu navegador",
    titleCall: " - Estamos no Go Tap"
  },
  groupInvite: {
    titleCall: "Go Tap",
    description: "Seus amigos estão te esperando no Go Tap",
    bodyCall: "Instale o Go Tap agora mesmo para fazer pedido com seus amigos"
  },
  paymentInfo: {
    OnApp: "Pagamento efetuado pelo App",
    whenReceive: "Pagamento no recebimento",
    changeFor: "Troco para"
  },
  emailReset: {
    subject: "Resetar senha",
    head: "Email de redefinição de senha",
    body: "",
    link: "Resetar senha"
  },
  blog: {
    title: "",
    notFound: "Post Não Encontrado"
  },
  page: {
    title: "",
    notFound: "Página Não Encontrado"
  },
  paymentOptions: {
    bankCheck: "Pagamento em Cheque",
    creditCard: "Pagamento em Cartão de Crédito",
    debitCard: "Pagamento em Cartão de Débito",
    money: "Pagamento em Dinheiro",
    paymentOptionsSub: "Opções de pagamento",
    acceptPaymentOnApp: "Aceita pagamento no App",
    acceptCashPayment: "Aceita dinheiro",
    acceptPaymentOnDelivery: "Aceita pagamento na entrega",
    placeholderCards: "Adicione os cartões aceitos",
    auto_withdraw: "Saque automático",
    auto_advance: "Antecipação Automática.",
    iuguInfo: "Informações da conta de pagamento",
    createIuguVerify: "Enviar dados para validação",
    alertNotAvailableForIugu: "Essa empresa não foi habilitada para criar conta de pagamento",
    createIugu: "Criar conta de pagemnto",
    notAvailableForIugu: "Essa empresa não foi habilitada para criar conta de pagamento, entre em contato com o suporte.",
    createIuguVerifyAccept: "Conta Aceita",
    createIuguVerifyBank: "Verificar Banco",
    infoTitle: "Informações",
    createIuguTitle: "Criar Conta de recebimento",
    formTitle: "Formulário",
    bankTitle: "Criar ou atualizar banco",
    createIuguPrevious: "Conta já criada",
    needCreateIugu: "É necessário criar suconta de recebimento primeiro",
    receiveInSubAccount: "Alterar para recebimento em subconta",
    receiveInMasterAccount: "Alterar para recebimento através do Go Tap"
  },
  iugu: {
    name: "Nome da empresa",
    is_verified: "Verificado?",
    can_receive: "Pode receber?",
    last_verification_request_status: "Estado da última verificação",
    last_verification_request_data: "Dados da última verificação",
    last_verification_request_feedback: "Feedback da última verificação",
    change_plan_type: "Plano",
    balance: "Saldo",
    balance_in_protest: "Saldo em protesto",
    balance_available_for_withdraw: "Saldo disponível para entrega",
    protected_balance: "Saldo protected",
    payable_balance: "Saldo a pagar",
    receivable_balance: "Saldo a receber",
    commission_balance: "Saldo de comissão",
    volume_last_month: "Último mês",
    volume_this_month: "Esse mês",
    taxes_paid_last_month: "Taxas pagas no último mês",
    taxes_paid_this_month: "Taxas pagas esse mês",
    has_bank_address: "Tem endereço bancário",
    commissions_percent: "Taxa Go Tap",
    configuration: {
      auto_withdraw: "Saque automático"
    },
    accountVerification: {
      title: "Verificação de conta",
      price_range: "Valor máximo da venda ('Até R$ 100,00', 'Entre R$ 100,00 e R$ 500,00', 'Mais que R$ 500,00')",
      physical_products: "Produtos físicos?",
      business_type: "Descreva o seu negócio",
      person_type: "Tipo de cadastro",
      person_typeFisica: "Pessoa Física",
      person_typeJuridica: "Pessoa Jurídica",
      automatic_transfer: "Transferência automática(recomendado sim)",
      name: "Nome",
      company_name: "Nome da empresa",
      cpf: "CPF",
      cnpj: "CNPJ",
      resp_name: "Nome do responsável",
      resp_cpf: "CPF do responsável",
      telephone: "Telefone",
      account_type: "Tipo de conta",
      bank: "Banco",
      account_typeCorrente: "Corrente",
      account_typePoupanca: "Poupança",
      bank_ag: "Agência (9999-9)",
      bank_cc: "Conta (999999-9)",
      document: "Comprovante bancário(O comprovante de domicílio bancário é algum documento que contenha as informações da conta bancária (Nome do Banco, Agência, Conta) bem como o nome da empresa ou CNPJ para Pessoa Jurídica)"
    }
  },
  inputError: {
    name: "Preencha o nome",
    companyName: "Preencha o nome da empresa",
    cnpj: "Preencha o cnpj",
    priceRange: "Preencha o intervalo de valor",
    businessType: "Preencha o tipo de negócio",
    completeAddress: "Termine de preencher o endereço",
    completeBank: "Termine de preencher as informações bancárias",
    phone: "Preencha o telefone",
    previousSend: "Enviado anteriormente",
    needCanReceive: "É necessário ter a conta de pagamento aprovada"
  },
  footer: {
    copyright: "Copyright Go Tap 2020 - Todos os direitos reservados.",
    home: "Home",
    about: "Sobre o Go Tap",
    download: "Baixe o app",
    faq: "Central de dúvidas",
    accessCompany: "Acessar restaurante",
    accessOng: "Acessar Ong",
    accessAdmin: "Acessar Admin",
  },
  home: {
    letsAllEatTogether: "Vamos todos comer juntos",
    orderedDifferent: "<b>Peça diferente</b> com o <b><i>Go Tap!</i></b> e saia da mesmice com um delivery ou takeout pelo app!",
    tabFlexibility: "Flexibilidade",
    tabCare: "Go Tap! Care",
    tabDiscounts: "Controle total",
    flexibilityTxt1: "Delivery ou take-out?",
    flexibilityTxt2: "A escolha é sua!",
    flexibilityTxt3: "Não importa o tamanho da sua fome, com o <b><i>Go Tap</i></b> você tem acesso aos mais variados restaurantes da sua cidade pelo app no seu celular.",
    careTxt1: "Evitando o desperdício,",
    careTxt2: "erradicando a fome!",
    careTxt3: "Uma parte significativa do faturamento do <b><i>Go Tap</i></b> será destinada para o desenvolvimento de ações para a erradicação da fome e do desperdício de alimentos.",
    discountsTxt1: "Encontre o que procura,",
    discountsTxt2: "deixe do seu jeito.",
    discountsTxt3: "Tenha todas as informações dos itens do seu pedido e personalize os pratos do seu jeito, adicionando ou retirando conforme a sua vontade.",
    downloadTxt: "<b>Baixe o app grátis</b><br> e peça diferente.",
    learnMore1_1: "Seu restaurante",
    learnMore1_2: "não precisa",
    learnMore1_3: "ter mais um sócio",
    learnMore2: "Descubra como aumentar as vendas do seu <br>restaurante de um jeito inteligente.",
    learnMoreButton: "Saiba Mais",
    haveMore: {
      title1: "Acha que acabou?",
      title2: "Tem mais!",
      item1: {
        title: "Evite filas",
        desc: "Além de delivery e retirada dos pedidos, você também pode pedir e pagar pelo Go Tap! quando estiver em restaurantes para deixar as filas no passado."
      },
      item2: {
        title: "Peça junto e divida a conta",
        desc: "Junte a galera e peça junto! Assim, vocês economizam na entrega e ainda podem dividir a conta entre todos da maneira que preferirem."
      },
      item3: {
        title: "Pedidos do seu jeito",
        desc: "Personalize os pratos, monte variações dos seus lanches favoritos e salve seus pedidos anteriores para criar um cardápio que é só seu!"
      },
      item4: {
        title: "Acompanhe seu pedido",
        desc: "Acompanhe cada etapa do preparo do seu pedido pelo aplicativo e seja notificado quando estiver tudo pronto!"
      },
      item5: {
        title: "Restrições alimentares",
        desc: "Identifique e selecione apenas os pratos que se adequam ao seu estilo de vida, dieta ou restrições alimentares."
      },
      item6: {
        title: "Pagamento integrado",
        desc: "Realize o pagamento do pedido pelo próprio aplicativo, utilizando qualquer uma das diversas opções disponíveis integradas à plataforma."
      }
    }
  },
  exports: {
    dateBegin: "inicio",
    dateEnd: "fim",
    exportUsers: "Exportar usuários",
    exportOrders: "Exportar pedidos",
    exportOngs: "Exportar Ongs",
    exportCompanies: "Exportar Empresas",
    exportOrdersAll: "Exportar Todos os pedidos",
    exportPayments: "Exportar pagamentos",
    selectCompanies: "Selecione as empresas (max 10)"
  },
  list: {
    error: {
      reorderOnFiltered: "Não é possível ordenar com algum filtro habilitado"
    }
  },
  history: {
    volume: "Faturado",
    receivable: "Recebível",
    dateBegin: "Inicio",
    dateEnd: "Fim",
    orderCode: "Código",
    orderNumber: "número",
    lstUsersTxt: "clientes",
    totalValue: "Valor",
    date: "Data",
    id: "Id",
    status: 'Estado do pedido',
    orderClose: 'Fechar',
    notCanceled: 'válidos',
    qtdOrders: 'Quantidade de pedidos',
    valueOrders: 'Valores dos pedidos',
    qtdPayments: 'Quantidade de pagamentos',

    emptyData: 'Nenhum dado encontrado para o período selecionado',
    emptyLabel: 'Nenhum dado para o período selecionado',
    emptyDescription: 'Nenhum dado para o período selecionado',
    companyName: "Empresa",
    selectHubs: "Selecione os Hubs (max 10) Não disponível",
    selectRegions: "Selecione as Regiões (max 10)",
    selectCompanies: "Selecione as Empresas (max 10)",
    selectCompany: "Selecione a Empresa",
    errorSelect: "Filtros inválidos, somente 1 filtro pode ter mais de 1 opção selecionada",
    total: {
      payByApp: 'Pago pelo aplicativo',
      payByAppGoTap: 'Pago pela conta do Go Tap',
      payByAppSubAccount: 'Pago na conta do restaurante',

      payByOnReceive: "Pago no recebimento",
      payByOnReceiveCash: 'Pago em dinheiro',
      payByOnReceiveCard: 'Pago em cartão',

      all: 'Total de pedidos',
      android: 'Android',
      ios: 'iOS',
      envUndefined: 'Sistema não definido',
      male: 'Homem',
      female: 'Mulher',
      sexUndefined: 'Sexo não definido',
      ages: {
        _0000: 'Idade não definida',
        _0012: 'Até 12 anos',
        _1218: 'Até 18 anos',
        _1824: 'Até 24 anos',
        _2436: 'Até 36 anos',
        _3642: 'Até 42 anos',
        _4250: 'Até 50 anos',
        _5000: 'Mais de 50 anos'
      },
      orderCount: 'Quantidade total de pedidos',
      orderCountValid: 'Quantidade total de pedidos válidos',
    },
    totalQtd: {
      payByApp: 'Pago pelo aplicativo',
      payByAppGoTap: 'Pago pela conta do Go Tap',
      payByAppSubAccount: 'Pago na conta do restaurante',

      payByOnReceive: "Pago no recebimento",
      payByOnReceiveCash: 'Pago em dinheiro',
      payByOnReceiveCard: 'Pago em cartão',

      all: 'Total de pagamentos',
      android: 'Android',
      ios: 'iOS',
      envUndefined: 'Sistema não definido',
      male: 'Homem',
      female: 'Mulher',
      sexUndefined: 'Sexo não definido',
      ages: {
        _0000: 'Idade não definida',
        _0012: 'Até 12 anos',
        _1218: 'Até 18 anos',
        _1824: 'Até 24 anos',
        _2436: 'Até 36 anos',
        _3642: 'Até 42 anos',
        _4250: 'Até 50 anos',
        _5000: 'Mais de 50 anos'
      },
      orderCount: 'Quantidade total de pedidos',
      orderCountValid: 'Quantidade total de pedidos válidos',
    },
    orderOnCompany: "Pedidos na empresa",
    orderOnGotap: "Pedidos no Go Tap",
    company: "Restaurante",
    clientName: "Nome",
    clientEnv: "Criado no",
    valueOrdersIndividual: "Valor individual dos pedidos válidos",

  },
  notification: {
    type: {
      title: "Tipo",
      company: "Empresa",
      product: "Produto",
      link: "Link",
      search: "Busca"
    },
    sendToType: {
      title: "Enviar para",
      region: "Região",
      selected: "Usuários selecionados",
      all: "Todos"
    },
    search: {
      search: "Termo da busca"
    }
  },
  paginate: {
    prev: "Prev",
    next: "Next"
  },
  _404: {
    notFoundHeader: "Página não encontrada, tente recarregar a página.",
    timeout: "A página atingiu o tempo limite durante o carregamento. Tem certeza de que ainda está conectado à Internet?"
  },
  jobs: {
    type: 'Tipo',
    name: 'Nome',
    arg: 'argumentos'
  },
  adCards: {
    type: {
      title: "Tipo",
      link: "Link",
      search: "Busca"
    },
    showOn: {
      title: "Mostrar onde?",
      mainScreen: "Tela principal",
    },
    search: 'Texto para a busca',
    link: "Link"
  },

}
;










