<script>
  import {appConfig} from '@src/config';
  import Layout from '@components/layout/admin/main-layout'
  import { authMethods } from '@state/helpers'
  import localeEnUS from '@i18n/en-US'
  import localePtBR from '@i18n/pt-BR'
  import firebase from 'firebase/app'
  import stringifyObjec from 'stringify-object'
  import axios from 'axios'
  let {languages} = require('@i18n');

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {Layout},
    props: {
    },
    data() {
      return {
        id: false,
        updateError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        tryingToUpload: false,
        imageBlob: null,
        isLoading: true,
        defaultForm: {
          name: {}
        },
        form: {
          name: {},
        },
        languages: languages,
        fields:[],
        fields_teste: [
          {
            key: 'algo.assim',
            l1: 'algo assim',
            l2: 'anything that'
          }

        ],
        showOnlyEmpty: false,
        outFormat: 'jsObject',   // [ json | jsObject | keyValue ]
        inputFormat: 'keyValue',   // [ json | keyValue | jsObject ]
        keyValueSplice: ':',
        keyCote: '',
        keyValueCote: '"',
        keyEndLine: '',
        googleTranslateKey: '',
        googleTranslateLanguageL1: 'pt',
        googleTranslateLanguageL2: 'en',


      }
    },
    validations: {
    },
    computed: {
    },
    watch: {
    },
    mounted: async function() {
      if(this.$route.params.id) {
        this.id = this.$route.params.id

        let obj = await firebase.firestore().collection('categories').doc(this.id).get()
        if(obj && obj.data()) {
          this.form = { ...this.defaultForm, ...obj.data()}

          if(typeof this.form.name !== 'object') {
            this.form.name = {}
          }
          this.isNew = false
        }
      }
      else {
        this.id = firebase.firestore().collection('categories').doc().id
      }
      this.isLoading = false
    },

    methods: {
      ...authMethods,

      async doUpdate() {


        return ''
      },
      load() {
        console.log('localePtBR', localePtBR)
        this.superLoad(localePtBR, localeEnUS)
      },
      download(opt) {
        let dataStrTMP = '';

        if(this.outFormat==='keyValue') {
          dataStrTMP = this.fields.reduce((output, line)=>{
            return output+
              this.keyCote +
                line.key+
              this.keyCote+
              this.keyValueSplice+
              this.keyValueCote+
              (line[opt] || '')+
              this.keyValueCote +
              this.keyEndLine +
              '\n'
          }, '')

        }
        else {
          let out = {};
          for(let field of this.fields) {
            let res = field.key.split('.')
            let outTmp = out
            let last = null
            let lastKey = null
            for(let r of res){
              if(!outTmp[r]) {
                outTmp[r] = {}
              }
              last = outTmp
              lastKey = r
              outTmp = outTmp[r]
            }
            if(lastKey && last) {
              last[lastKey] = field[opt] || ''
            }
          }
          console.log('out', out)


          if(this.outFormat==='jsObject') {
            dataStrTMP = stringifyObjec(out, {
              indent: '  ',
              singleQuotes: false
            })
          }
          else {
            dataStrTMP = JSON.stringify(out, '\n', 2)
          }
        }




        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(dataStrTMP);
        let dlAnchorElem = this.$refs.downloadAnchorElem;
        dlAnchorElem.setAttribute("href",     dataStr     );
        dlAnchorElem.setAttribute("download", "scene.json");
        dlAnchorElem.click();

      },
      superLoad(l1, l2, path='') {
        let keys = [];
        if(typeof l1 ==='object' && l1) {
          keys = _.union(keys, Object.keys(l1))
        }
        if(typeof l2 ==='object' && l2) {
          keys = _.union(keys, Object.keys(l2))
        }

       // console.log('keys', keys)
        for(let key of keys) {
          if((l1 && typeof l1[key]==='object') || (l2 && typeof l2[key]==='object')) {
            this.superLoad(l1?l1[key]:false, l2?l2[key]:false, path+key+'.')
          }
          else {
            let index = _.findIndex(this.fields, (o) => { return o.key === path+key })
            if(index>=0) {
              if(l1 && l1[key]) {
                this.fields[index].l1 = l1[key]
              }
              if(l2 && l2[key]) {
                this.fields[index].l2 = l2[key]
              }
              this.fields[index].hasEmpty = !this.fields[index].l1 || !this.fields[index].l2
            }
            else {
              this.fields.push({
                key: path+key,
                l1: l1?l1[key]:'',
                l2: l2?l2[key]:'',
                hasEmpty: ((!l1 || !l1[key]) || (!l2 || !l2[key]))
              })
            }
          }
        }


      },
      addField() {
        this.fields.push({
          key: '',
          l1: '',
          l2: '',
          hasEmpty: true
        })
      },
      inputFile(event, opt) {
        console.log('event', event)


        var files = event.target.files;
        var file = files[0];
        var reader = new FileReader();
        reader.onload = (event2) => {
          console.log(event2.target.result);

          let inputObj = false
          if(this.inputFormat==='keyValue') {
            inputObj = event2.target.result.split('\n')
            .reduce((obj, line) => {
              let index = line.indexOf(this.keyValueSplice);
              let cols = [line.slice(0,index), line.slice(index+1)];
             // let cols = line.split(this.keyValueSplice);
              if (cols.length >= 2 && cols[0] && cols[1]) {
                obj[cols[0].trim()] = cols[1].trim().replace(/(^")|("$)/g, '').replace(/(^')|('$)/g, '');
              }
              return obj;
            }, {});
          }
          else if(this.inputFormat==='jsObject'){ // json
// eslint-disable-next-line no-eval
            inputObj = eval('(' + event2.target.result + ')');
          }
          else { // json
            inputObj = JSON.parse(event2.target.result)
          }
          if(opt==='l1') {
            this.superLoad(inputObj, null)
          }
          else {
            this.superLoad(null, inputObj)
          }


          console.log('inputObj', inputObj)



        }
        reader.readAsText(file)

      },
      removeField(index) {
        this.fields.splice(index, 1);
      },
      async translateAll() {
        if(!this.googleTranslateLanguageL1 || !this.googleTranslateLanguageL2 || !this.googleTranslateKey) {
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'warning',
            title: 'Dados incompletos para traduzir, você precisa da chave de tradução do google e dos códigos das linguagens',
            showConfirmButton: false,
            timer: 1500
          })
        }


        for(let r of this.fields) {
          if(!r.l1 && r.l2) {
            console.log('translate '+r.key+': '+this.googleTranslateLanguageL2 + ' to '+this.googleTranslateLanguageL1)
            r.l1 = await this.translate(r.l2, this.googleTranslateLanguageL2, this.googleTranslateLanguageL1)
          }
          if(r.l1 && !r.l2) {
            console.log('translate '+r.key+': '+this.googleTranslateLanguageL1 + ' to '+this.googleTranslateLanguageL2)
            r.l2 = await this.translate(r.l1, this.googleTranslateLanguageL1, this.googleTranslateLanguageL2)
          }
        }
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'success',
          title: 'Tradução completa',
          showConfirmButton: false,
          timer: 1500
        })

      },
      async translate(q, source, target) {
        let result = await axios.get('https://translation.googleapis.com/language/translate/v2',
          {
            params: {
              q, source, target,
              key: this.googleTranslateKey
            }
          })


        if(result && result.data && result.data.data && result.data.data.translations && result.data.data.translations[0] && result.data.data.translations[0].translatedText) {
          return result.data.data.translations[0].translatedText;
        }
        else {
          console.log('result not match', result)
        }


        return ''
      }
    },
  }
</script>


<template>
  <Layout>
    <div :class="$style.box">
      <div :class="$style.container">
        <div :class="$style.content">
          <div v-show="isLoading" >
            <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
          </div>

          <div :class="$style.info">{{$t('tools.title')}}</div>

          <div :class="$style.body">
            <form v-if="!isLoading" :class="$style.form" @submit.prevent="doUpdate">

              <!--<MdTabs >
                <MdTab v-for="(item, index) in languages"  :key="index+item.database"  :md-label="item.name" >
                  <BaseInputText
                    v-model="form.name[item.database]"
                    :label="$t('form.name')"
                  >
                  </BaseInputText>
                </MdTab>
              </MdTabs>-->

              <a ref="downloadAnchorElem" style="display:none"></a>

              <MdButton :class="['md-primary', 'md-raised']" @click="download('l1')">
                download L1
              </MdButton>
              <MdButton :class="['md-primary', 'md-raised']" @click="download('l2')">
                download L2
              </MdButton>
              <MdButton :class="['md-primary', 'md-raised']" @click="addField()">
                add field
              </MdButton>
              <MdButton :class="['md-primary', 'md-raised']" @click="translateAll()">
                Translate all empty
              </MdButton>
              <MdField>
                <label>output</label>
                <MdSelect v-model="outFormat" name="outFormat">
                  <MdOption key="outFormat_jsObject" value="jsObject">jsObject</MdOption>
                  <MdOption key="outFormat_json" value="json">json</MdOption>
                  <MdOption key="outFormat_keyValue" value="keyValue">keyValue</MdOption>
                </MdSelect>
              </MdField>
              <MdField>
                <label>Input</label>
                <MdSelect v-model="inputFormat" name="inputFormat">
                  <MdOption key="inputFormat_keyValue" value="keyValue">keyValue</MdOption>
                  <MdOption key="inputFormat_json" value="json">json</MdOption>
                  <MdOption key="inputFormat_jsObject" value="jsObject">jsObject</MdOption>
                </MdSelect>
              </MdField>
              <br>
              <MdField  >
                <label>keyValueSplice (input/output)</label>
                <MdInput v-model="keyValueSplice" type="text" ></MdInput>
              </MdField>
              <MdField >
                <label>keyValueCote (output)</label>
                <MdInput v-model="keyValueCote" type="text" ></MdInput>
              </MdField>
              <MdField >
                <label>keyCote (output)</label>
                <MdInput v-model="keyCote" type="text" ></MdInput>
              </MdField>
              <MdField >
                <label>keyEndLine (output)</label>
                <MdInput v-model="keyEndLine" type="text" ></MdInput>
              </MdField>



              <br>

              <MdField >
                <label>key Google Translate </label>
                <MdInput v-model="googleTranslateKey" type="text" ></MdInput>
              </MdField>
              <MdField >
                <label>locale L1 </label>
                <MdInput v-model="googleTranslateLanguageL1" type="text" ></MdInput>
              </MdField>
              <MdField >
                <label>locale L2 </label>
                <MdInput v-model="googleTranslateLanguageL2" type="text" ></MdInput>
              </MdField>



              inputL1<input ref='inputL1' type="file" name="inputFile1" @change="inputFile($event, 'l1')" />
              inputL2<input ref='inputL1' type="file" name="inputFile2" @change="inputFile($event, 'l2')" />

              <MdSwitch v-model="showOnlyEmpty" class="md-primary" :value="true">Mostrar apenas vazios</MdSwitch>


              <table style="min-width: 900px;">
                <tr>
                  <td style="width: 100px;">key</td><td>L1({{googleTranslateLanguageL1}})</td><td>L2({{googleTranslateLanguageL2}})</td><td style="width: 15px;">key</td>
                </tr>


                <tr v-for="(field, index) in fields" v-show="!showOnlyEmpty || (showOnlyEmpty && field.hasEmpty) " :key="index" :class="field.hasEmpty?'hasEmpty':''">
                  <td>
                    <MdField  >
                      <MdInput v-model="field.key" type="text" ></MdInput>
                    </MdField>
                  </td>
                  <td>
                    <MdField >
                      <MdTextarea v-model="field.l1"></MdTextarea>
                    </MdField>
                  </td>
                  <td>
                    <MdField >
                      <MdTextarea v-model="field.l2"></MdTextarea>
                    </MdField>
                  </td>
                  <td>
                    <MdButton class="md-fab md-mini md-primary" @click="removeField(index)">
                      <MdIcon>delete</MdIcon>
                    </MdButton>
                  </td>
                </tr>

              </table>


              <br><br>

              <MdButton :class="['md-primary', 'md-raised']" @click="load()">
                Load from app
              </MdButton>

              <MdButton :class="['md-primary', 'md-raised']" @click="download('l1')">
                download L1
              </MdButton>
              <MdButton :class="['md-primary', 'md-raised']" @click="download('l2')">
                download L2
              </MdButton>
              <MdButton :class="['md-primary', 'md-raised']" @click="addField()">
                add field
              </MdButton>



              <p v-if="updateError" :class="$style.error">
                {{$t('form.error.update')}}
              </p>

            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" >
  @import '@design';

  .hasEmpty {
    background-color: #edeeed;
  }



</style>



<style lang="scss" module>
  @import '@design';

  #tab-home {
    height: 300px;
  }

  .error {
    color: $color-input-error;
  }

  .box {
    background-color: #ffffff;
    /*min-height: 1000px;*/
    .container {
      /* min-width: $size-content-width-min;
       max-width: $size-content-width-max; */

      min-height: 600px;
      margin: 80px 8px 30px 8px;

      .content {
        /*flex: 1;*/
        /* width: 45%; */
        max-width: 1000px;
        padding: 49px;
        text-align: left;
        background-color: $color-form-bg;
        box-shadow: 2px 2px 15px 5px #888888;

        margin: 0 auto;

        .image {
          @extend %defaultPreview;
          position: relative;
          height: 100px;
          width: 100px;
          cursor: pointer;
          border-radius: 300px;
          img {
            height: 100px;
            width: 100px;
            margin-bottom: 20px;
            border-radius: 300px;
          }
          .changeImage {
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;
            margin-top: 60px;
            color: white;
            background-color: rgba(0, 0, 0, 0.71);
            text-align: center;
            display: none;
          }
        }
        .image:hover .changeImage {
          display: block;
        }


        .restaurantName {
          font-size: 32px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #333333;
          margin-bottom: 20px;
        }
        .info {
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 3.2;
          letter-spacing: 0.3px;
          color: #707070;
        }
        .body {
          font-family: $font-input;
          BaseButton {
            font-family: $font-primary-text;
            float: right;
          }

          .progressBar {
            margin-bottom: 20px;
          }
        }
      }
    }
  }



</style>





















