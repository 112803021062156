<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      body: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      showDialog: false
    })
  }

  // class="md-simple md-success md-lg"
</script>

<template>
  <div>
    <div :class="$style.button" @click="showDialog = true;">
      <MdIcon class="md-small-size-90" >help_outline</MdIcon>
    </div>
    <md-dialog :class="$style.dialog" :md-active.sync="showDialog">
      <md-dialog-title>{{title}}</md-dialog-title>
      <slot>
        {{body}}
      </slot>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false;">{{$t('all.close')}}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>

</template>

<style lang="scss" module>
  @import '@design';

  .button {
    cursor: pointer;
  }

  .dialog {
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
  }


</style>
