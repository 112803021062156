<script>
import Layout from '@components/layout/visitant/main-layout'

export default {
  page: {
    title: '404',
    meta: [{ name: 'description', content: '404' }],
  },
  components: { Layout },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <Layout>
    <h3 :class="$style.title">
      404
      <template v-if="resource">
        {{ resource }}
      </template>
      {{$t('_404.notFoundHeader')}}
    </h3>
  </Layout>
</template>

<style lang="scss" module>
.title {
  text-align: center;
  padding: 45px 10px 15px;
  max-width: 800px;
  margin: 0 auto;
}
</style>
