"use strict";

// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};

const firebase = require('firebase/app');
const timeUtils = require('@common/time_utils');

/*


  let updateList = [];
  updateList.push({...data, id:'adfjhsdjf'})

  databaseUtils.databaseSliceUpdate(firebase.firestore().collection(this.resource), updateList);

 */


toExport.databaseSliceUpdate = async function(ref, updatesAll, slice=100, time=0) {
  if(!slice) {
    slice = 100;
  }
  if(!time) {
    time = 0;
  }
  if(!Array.isArray(updatesAll)) {
    console.error('sliceUpdate need array on first parameter');
    return Promise.reject(new Error('sliceUpdate need array on first parameter'));
  }
  let batch = firebase.firestore().batch();

  let iAtual = 0;
  let iLocal = 0;
  let hasError = false;
  for(iAtual=0;iAtual<updatesAll.length;iAtual++) {
    let id = updatesAll[iAtual].id;
    let value = _.cloneDeep(updatesAll[iAtual]);
    delete value.id;
    batch.set(ref.doc(id), value, {merge:true});
    if(iLocal>=slice) {
      iLocal = 0;

      try {
        console.log('Commit parcial', iAtual);
        await batch.commit();
        if(time) {
          await timeUtils.delay(time);
        }
        //   console.log('Commit end', iAtual);
      }catch(error) {
        hasError = true;
        console.error('Error, await batch.commit();', error);
      }
      batch = firebase.firestore().batch();
    }
    else {
      iLocal++;
    }
  }
  try {
    console.log('Commit final', iAtual);
    await batch.commit();
  }catch(error) {
    hasError = true;
    console.error('Error, await batch.commit();', error);
  }


  if(hasError) {
    return Promise.reject(new Error('Error, await batch.commit();'));
  }
  return 'ok';
};

toExport.uploadFile = function(path, file, progressFn, setUploadTask) {
  return new Promise(function (resolve, reject) {
    let storageRef = firebase.storage().ref(path);
    let uploadTask = storageRef.put(file);

    if(setUploadTask) {
      setUploadTask(uploadTask);
    }

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        if (progressFn) {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progressFn(progress, snapshot.bytesTransferred, snapshot.totalBytes);
        }
      }, function (error) {
        reject(error);
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function(url){
          resolve(url);
        });
      });
  });
};

toExport.removeFile = function(path) {
  return new Promise(function (resolve, reject) {

    let storageRef = firebase.storage().ref(path);
    if(storageRef) {
      return storageRef.delete().then(function(){
        resolve();
      });
    }
    else {
      resolve();
    }
  });
};

toExport.companySmall = function(company) {
  return {
    name: company.name || null,
    primaryColor: company.primaryColor || null,
    secondaryColor: company.secondaryColor || null,
    logoImgUrl: company.logoImgUrl || null,
    categoryId: company.categoryId || null,
    category: company.category || null,
    regionId: (company.address ||{}).regionId || '',
  };
};

toExport.unsubscribe = function(fns) {
  if(fns) {
    for(let fn of fns) {
      if (typeof fn === 'function') {
        fn();
      }
    }
  }
  return [];
};



class MyUploadAdapter {
  constructor( loader ) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.isAbort = false;
    this.uploadTask = null;
  }

  // loader.uploadTotal = evt.total;
  // loader.uploaded = evt.loaded;

  // Starts the upload process.
  upload() {
    this.isAbort = false;
    this.uploadTask = null;
    return new Promise((resolve, reject)=>{
      let id = firebase.firestore().collection('ckeditorImages').doc().id;
      return this.loader.file.then((file)=>{
        return toExport.uploadFile(
          'ckeditorImages/'+id,
          file,
          (progress, transferred, total)=>{
            this.loader.uploadTotal = total;
            this.loader.uploaded = transferred;
          },
          (uploadTask)=>{
            this.uploadTask = uploadTask;
          }).then((url)=>{
            if(this.isAbort) {
              return reject(new Error('Abort'));
            }
            return resolve({default: url});
          }).catch((error)=>{
            console.error(error);
            return reject(new Error('Error'));
          });
        }).catch((error)=>{
          console.error(error);
          return reject(new Error('Error'));
        });
    });


  }

  // Aborts the upload process.
  async abort(teste) {
    this.isAbort = true;
    if(this.uploadTask) {
      this.uploadTask.cancel();
    }
  }
}


toExport.fcmUploadAdapterPlugin = function(editor) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter( loader );
  };
};




export default toExport;

/*


exports.default = exports;
if(typeof module!=='undefined') {
  module.exports = exports;
}
*/
