<script>
import Layout from '@components/layout/company/main-visitant'
import { authMethods, authComputed } from '@state/helpers'
import {appConfig} from '@src/config';
import defaults from '@/src/router/defaults'
import firebase from 'firebase/app'

export default {
  page: {
    title: 'Log in',
    meta: [
      {name: 'description', content: `Log in to ${appConfig.title}`}
    ],
  },
  components: { Layout },
  data() {
    return {
      isLoading: true,
      updateError: null,
      tryingToLogIn: false,


      name: '',
      form: {
        resetId: false,
        workspace: false,
        id: false,
        password: ''
      }

    }
  },
  computed: {
    ...authComputed,
  },
  mounted: async function() {
    if(this.$route.query.resetId && this.$route.query.workspace) {
      this.form.resetId = this.$route.query.resetId
      this.form.workspace = this.$route.query.workspace

      console.log({resetId:this.form.resetId, workspace:this.form.workspace})

      let obj = await firebase.functions().httpsCallable('auth-getUserResetPassword')({resetId:this.form.resetId, workspace:this.form.workspace})
      if(obj && obj.data.result) {
        this.form = {...this.form, ...obj.data.result}
      }
    }
    else {
      console.error('not found query', this.$route)
    }
    this.isLoading = false
  },
  methods: {
    ...authMethods,
    async tryReset() {
      this.updateError = null
      if(!this.form.id || !this.form.password) {
        this.updateError = true
        return
      }

      this.tryingToLogIn = true
      try {
        let result = await firebase.functions().httpsCallable('auth-setUserResetPassword')(this.form)
        console.log('result', result);

        this.tryingToLogIn = false
        if(result.data.result) {
          return this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged[this.form.workspace] })
        }

        this.updateError = this.$t('form.error.generic')
      } catch(error) {
        console.error('Erro ao efetuar login', error)
        this.tryingToLogIn = false
        this.updateError = error
      }
    }
  },
}
</script>

<template>
  <Layout>

    <div :class="$style.container">
      <div :class="$style.content" >
        <div :class="$style.title">{{$t('login.resetPassword')}}</div>

        <div :class="$style.body">
          <form :class="$style.form" @submit.prevent="tryReset()">
            <BaseInputText
                    v-model="form.password"
                    type="password"
                    label="Senha"
            />
            <p v-if="updateError" :class="$style.error">
              {{$t('login.error.generic')}}
            </p>
            <BaseButton
                    :class="['md-primary','md-raised','md-lg']"
                    :disabled="tryingToLogIn"
                    type="submit"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('login.makeResetPassword')}}
              </span>
            </BaseButton>
          </form>
        </div>

      </div>

    </div>

  </Layout>
</template>


<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    padding-top: 80px;
    padding-bottom: 30px;
    .content {
      width: 90%;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      margin: 0 auto;
      max-width: 400px;
      max-height: 450px;
      .title {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .body {
        font-family: $font-input;
        .fullwidth {
          width: 100%;
        }
        BaseButton {
          font-family: $font-primary-text;
        }
      }
    }
  }


</style>
