<script>
  export default {
    props: {
    },
  }

  // class="md-simple md-success md-lg"
</script>

<template>

  <MdButton
         md-theme="buttonprimary"
         :class="$style.button"
         v-on="$listeners"
  >
    <slot />
  </MdButton>
</template>

<style lang="scss" module>
@import '@design';

.button {
  margin: 0;
  padding-left: 44px;
  padding-right: 44px;
}


</style>
