<script>
import Layout from '@components/layout/ong/main-visitant'
import { authMethods, authComputed } from '@state/helpers'
import {appConfig} from '@src/config';
import defaults from '@/src/router/defaults'
import firebase from 'firebase/app'

export default {
  page: {
    title: 'Log in',
    meta: [
      {name: 'description', content: `Log in to ${appConfig.title}`}
    ],
  },
  components: { Layout },
  data() {
    return {
      email: '',
      password: '',
      updateError: null,
      tryingToLogIn: false,
      ongs: false,
      fakeMail: false,
      ongsNotFound: false,
      isReset: false
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    loggedIn() {
      if (this.$store.getters['auth/loggedIn'] && !this.tryingToLogIn) {
        console.log('Redirecionar usuário logado')
        this.$router.push({ name: defaults.routeLogged[this.$store.getters['auth/loggedIn']] })
      }
    }
  },
  methods: {
    ...authMethods,
    async tryToLogIn() {
      this.updateError = null;
      this.ongsNotFound = null;
      if(!this.email || !this.fakeMail || !this.password) {
        this.updateError = true;
        return
      }

      this.tryingToLogIn = true;
      try {
        await this.logIn({
          email: this.fakeMail,
          password: this.password,
        });
        this.tryingToLogIn = false;
        this.$router.push(this.$route.query.redirectFrom || { name: defaults.routeLogged['ONG'] })
      } catch(error) {
        console.error( this.$t('login.error.loginTitle'), this.$t('login.providerCode.'+error.code));
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'error',
          title: this.$t('login.error.loginTitle'),
          text: this.$t('login.providerCode.'+error.code),
          showConfirmButton: false,
          timer: 20000
        });
        this.tryingToLogIn = false;
        this.updateError = {message:this.$t('login.providerCode.'+error.code)}
      }
      this.tryingToLogIn = false;
    },
    async tryListOngs() {
      this.tryingToLogIn = true;
      this.updateError = null;
      this.ongsNotFound = null;
      try {
        let listOngs = await this.listOngs({
          email: this.email
        });
        this.tryingToLogIn = false;
        if(listOngs) {
          let ongsTmp = Object.values(listOngs);
          this.ongs = _.sortBy(ongsTmp, [function(o) { return (o.ong ||{}).name; }]);
          let first = _.find(listOngs) || {};
          this.fakeMail = first.fakeMail || false;
        }
        else {
          this.ongsNotFound = true;
        }
        try {
          setTimeout(()=>{
            this.$refs.password.$el.querySelector('input').focus();
          }, 100);
        }
        catch(error) {
          console.error('Error on focus password input', error);
        }
      } catch(error) {
        console.error('Erro ao buscar lista', error);
        this.tryingToLogIn = false;
        this.updateError = error;
      }
    },
    resetOngs() {
      this.ongs = false;
      this.ongsNotFound = false;
    },
    submitForm() {
      if(!this.password) {
        return;
      }
      if(this.isReset) {
        this.tryReset();
      }
      else {
        if(this.ongs) {
          this.tryToLogIn();
        }
        else {
          this.tryListOngs();
        }
      }
    },
    async tryReset() {
      if(!this.fakeMail) {
        this.updateError = true;
        return;
      }
      try {
        this.tryingToLogIn = true;
        let result = await firebase.functions().httpsCallable('auth-resetPassword')({email:this.fakeMail, workspace:'ONG'});
        console.log('result', result);
        if(result && result.data && result.data.status) {
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'success',
            title: this.$t('form.emailResetSuccess'),
            showConfirmButton: false,
            timer: 15000
          });
        }
        else {
          this.$swal({
            toast: true,
            position: 'top-end',
            type: 'error',
            title: this.$t('form.emailResetError'),
            showConfirmButton: false,
            timer: 20000
          });
        }
      } catch(error) {
        this.$swal({
          toast: true,
          position: 'top-end',
          type: 'error',
          title: this.$t('form.emailResetError'),
          showConfirmButton: false,
          timer: 20000
        });
      }
      this.tryingToLogIn = false;
    },
    async switchReset () {
      this.isReset = !this.isReset;
    },
    goToRegister() {
      this.$router.push({ name: 'ong/register' });
    }
  },
}
</script>

<template>
  <Layout>



    <div :class="$style.container">
      <div :class="$style.content" >
        <div :class="$style.title">{{$t(isReset?'login.resetOng':'login.accessOng')}}</div>

        <div :class="$style.body">
          <form :class="$style.form" @submit.prevent="submitForm">
            <BaseInputText
                    v-model="email"
                    label="Email"
                    @change="resetOngs"
                    @input="resetOngs"
                    @keyup.enter="tryListOngs"
            />

            <BaseButton v-if="!ongs"
                    :class="['md-primary','md-raised', 'md-lg', $style.loginButton ]"
                    :disabled="tryingToLogIn"
                    @click="tryListOngs"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t('all.next')}}
              </span>
            </BaseButton>

            <MdField v-if="ongs">
              <label >{{$t('all.ongs')}}</label>
              <MdSelect v-model="fakeMail" name="fakeMail" >
                <MdOption v-for="(item) in ongs" :key="item.fakeMail" :value="item.fakeMail">{{item.ong.name}}</MdOption>
              </MdSelect>
            </MdField>

            <BaseInputText
              v-if="!isReset"
              v-show="ongs"
              ref="password"
              v-model="password"
              type="password"
              label="Senha"
            />
            <p v-if="updateError" :class="$style.error">
              {{updateError.message?updateError.message:$t('login.error.generic')}}
            </p>
            <p v-if="ongsNotFound" :class="$style.error">
              {{$t('login.errorOng')}}
            </p>
            <BaseButton
                    v-if="ongs"
                    :class="['md-primary','md-raised','md-lg', $style.loginButton]"
                    :disabled="tryingToLogIn"
                    type="submit"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t(isReset?'login.reset':'login.login')}}
              </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.loginPhone, $style.loginButton]"
              :disabled="tryingToLogIn"
              @click="switchReset"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                {{$t(isReset?'login.goLogin':'login.goReset')}}
              </span>
            </BaseButton>
            <div
              :class="['md-primary','md-raised','md-lg', $style.loginText]"
              :disabled="tryingToLogIn"
              @click="goToRegister"
            >
              <BaseIcon v-if="tryingToLogIn" name="sync" spin />
              <span v-else>
                  {{$t('login.register')}}
                </span>
            </div>
          </form>
        </div>

      </div>

    </div>

  </Layout>
</template>


<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }
  .container {
    min-width: $size-content-width-min;
    max-width: $size-content-width-max;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    padding-top: 80px;
    padding-bottom: 30px;
    .content {
      width: 90%;
      padding: 49px;
      text-align: left;
      background-color: $color-form-bg;
      margin: 0 auto;
      max-width: 400px;
     // max-height: 550px;
      .title {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: -0.4px;
        margin-bottom: 10px;
      }
      .body {
        font-family: $font-input;
        .loginButton {
          margin-bottom: 5px;
          margin-top: 10px;
          width: 100%;
        }
        .loginText {
          margin-bottom: 5px;
          margin-top: 10px;
          width: 100%;
          color: #4872c2;
          cursor: pointer;
          padding: 7px;
          align-content: center;
          text-align: center;
        }
      }
    }
  }


</style>
