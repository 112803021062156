<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue, email } from 'vuelidate/lib/validators'
  import databaseUtils from '@utils/database_utils'
  import FcmEditor from "@/src/components/image/fcm-editor";
  import firebase from 'firebase/app'
  import Multiselect from 'vue-multiselect'
  const stateList = require ('@common/status');
  let {languages} = require('@i18n');

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {FcmEditor, Multiselect},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        id: false,
        updateError: null,
        hasError: null,
        tryingToUpdate: false,
        imageSrc: null,
        uploadProgress: 0,
        tryingToUpload: false,
        imageBlob: null,
        isLoading: true,
        changePassword: false,
        passwordOld: '',
        password: '',
        emailOriginal: '',
        defaultForm: {
          name: '',
          governmentDocumentId: '',
          email: '',
          showId: false,
          active: false
        },
        form: {
          name: '',
          governmentDocumentId: '',
          email: '',
          showId: false,
          active: false
        },
        languages: languages,
        isNew: true,
        stateList: stateList,
        listRules: [],
        ruleTmp: []
      }
    },
    validations() {
      return {
        form: {
          governmentDocumentId: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(50)
          },
          email: {
            required,
            email
          },
          name: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(50)
          },
        },
        password: {
          required: this.changePassword? required: false,
          minLength: minLength(4),
          maxLength: maxLength(50)
        },
        passwordOld: {
          required: (this.changePassword && this.selfEdit)? required: false,
        }
      }
    },
    computed: {
      ...authComputed,
      selfEdit() {
        return this.currentUser && this.itemId===this.currentUser.id
      }
    },
    watch: {
      async itemId(value, oldValue) {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.ruleTmp = [];
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }
      },
    },
    mounted: async function() {
      for(let r of this.stateList.rules.comp) {
        this.listRules.push({id: r, name: this.$t('rules.comp.'+r)});
      }
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = {...this.defaultForm};

        let obj = await firebase.firestore().collection('ongsUsers').doc(this.itemId).get();
        if(obj && obj.data()) {
          this.form = { ...this.defaultForm, ...obj.data()};
          this.isNew = false;
        } else {
          this.isNew = true;
          this.form.active = true;
          this.form.ongId =  this.currentOng.id;
        }

        this.ruleTmp = [];
        for(let r of this.listRules) {
          if(this.form.rule && this.form.rule[r.id]) {
            this.ruleTmp.push(r);
          }
        }
        this.emailOriginal = this.form.email.toLowerCase();

        console.log('this.form', this.form);
        this.isLoading = false
      },

      async conclude() {
        this.$v.$reset();

        this.$v.form.governmentDocumentId.$touch()
        this.$v.form.email.$touch()
        this.$v.form.name.$touch()
        if(this.changePassword) {
          this.$v.password.$touch()
          if(this.selfEdit) {
            this.$v.passwordOld.$touch()
          }
        }
        this.form.email = this.form.email.toLowerCase();

        if (this.$v.$invalid) {
          this.updateError = {message:'invalid input form'};
          console.log('Error invalid input form', this.$v);
          return
        }

        this.form.rule = {};
        for(let r of this.ruleTmp) {
          this.form.rule[r.id] = true;
        }

        this.updateError = null;
        this.tryingToUpdate = true;
        let fakeMail = 'on_'+this.currentOng.id+'_'+this.form.email.replace('@', '_')+'@ongsusers.gotap.com.br';
        fakeMail = fakeMail.toLowerCase();
        try {

          if(!this.selfEdit) {
            if(this.isNew) {
              await firebase.functions().httpsCallable('auth-createUser')({id: this.itemId, workspace:'ONG', email: fakeMail, password: this.password, ongId: this.currentOng.id})
            }
            else {
              if(this.changePassword) {
                await firebase.functions().httpsCallable('auth-changePassword')({id: this.itemId, workspace:'ONG', password: this.password})
              }

              if(this.emailOriginal!==this.form.email) {
                await firebase.functions().httpsCallable('auth-changeEmail')({id: this.itemId, workspace:'ONG', email: fakeMail});
                this.form.fakeMail = fakeMail
              }
            }

          }
          else {
            if(this.changePassword) {
              await firebase.auth().signInWithEmailAndPassword( this.form.fakeMail, this.passwordOld);
              await firebase.auth().currentUser.updatePassword(this.password)
              await firebase.auth().signInWithEmailAndPassword(this.form.fakeMail, this.password)
              this.passwordOld = this.password;
            }
            if(this.emailOriginal!==this.form.email) {
              await firebase.auth().signInWithEmailAndPassword(this.form.fakeMail, this.passwordOld)
              await firebase.auth().currentUser.updateEmail(fakeMail);
              await firebase.auth().signInWithEmailAndPassword(fakeMail, this.passwordOld)
              this.form.fakeMail = fakeMail
            }
          }
          if(!this.form.fakeMail) {
            this.form.fakeMail = fakeMail
          }

          if(this.imageSrc && this.imageBlob) {
            this.uploadProgress = 0;
            this.tryingToUpload = true
            try {
              let url = await databaseUtils.uploadFile('ongsUsers/'+this.itemId, this.imageBlob, (progress) => {
                this.uploadProgress = progress
              });
              if(url) {
                this.form.img = url;
              }
            } catch(error) {
              this.updateError = error
              this.$showError({title: this.$t('form.error.image.upload')});
            }
          }
          await firebase.firestore().collection('ongsUsers').doc(this.itemId).set(this.form,{merge: true});
          this.$showSuccess();
          this.tryingToUpdate = false
          this.tryingToUpload = false

          this.$emit('changeModel', null);
        } catch(error) {
          console.error(error);
          if(error.code) {
            this.$showError({text: this.$t('providerCode.'+error.code)});
            this.updateError = this.$t('providerCode.'+error.code);
          }
          else {
            this.$showError();
            this.updateError = (error && error.message)?error.message:error;
          }
        }
        this.tryingToUpdate = false
        this.tryingToUpload = false
        this.$emit('changeModel', null);
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            }
          }
        }
        return {'md-invalid':false};
      },
      getValidationClassNotForm (fieldName) {
        if(this.$v) {
          const field = this.$v[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.img = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
    },
  }
</script>

<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-show="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>

          <div v-show="!isLoading" :class="$style.imagePreview" @click="tryChangeImage()" >
            <img :src="imageSrc || form.img || require('@assets/images/placeholder/user.png')" alt="Imagem principal"/>
            <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
            <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>
          </div>

          <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                     type-content="modal"
                     :max-width="180"
                     :max-height="180"
                     @changeBlob="changeBlob"
          ></FcmEditor>

          <div :class="$style.body">
            <BaseInputText
              v-model="form.governmentDocumentId"
              :label="$t('form.cpf')"
              :class="getValidationClass('governmentDocumentId')"
            >
              <span v-if="!$v.form.governmentDocumentId.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.governmentDocumentId.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              <span v-else-if="!$v.form.governmentDocumentId.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
            </BaseInputText>

            <BaseInputText
              v-model="form.name"
              :label="$t('form.name')"
              :class="getValidationClass('name')"
            >
              <span v-if="!$v.form.name.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.name.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              <span v-else-if="!$v.form.name.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
            </BaseInputText>
            <BaseInputText
              v-model="form.email"
              :label="$t('form.email')"
              :class="getValidationClass('email')"
            >
              <span v-if="!$v.form.email.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.form.email.email" class="md-error" >{{$t('form.error.email')}}</span>
            </BaseInputText>

            <div class="formItem">
              <Multiselect v-model="ruleTmp" :options="listRules" track-by="id" label="name" :multiple="true"
                           :placeholder="$t('multiSelect.placeholder')"
                           :select-label="$t('multiSelect.selectLabel')"
                           :select-group-label="$t('multiSelect.selectGroupLabel')"
                           :selected-label="$t('multiSelect.selectedLabel')"
                           :deselect-label="$t('multiSelect.deselectLabel')"
                           :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
              >
              </Multiselect>
            </div>

            <MdSwitch v-model="form.showId" class="md-primary" :value="true">{{$t('form.showIds')}}</MdSwitch><br>
            <MdSwitch v-model="form.active" class="md-primary" :value="true">{{$t('form.active')}}</MdSwitch><br>
            <MdSwitch v-if="!isNew" v-model="changePassword" class="md-primary" :value="true">{{$t('form.changePassword')}}</MdSwitch>

            <BaseInputText v-if="isNew"
                           v-model="password"
                           type="password"
                           :label="$t('form.password')"
                           :class="getValidationClassNotForm('password')"
            >
              <span v-if="!$v.password.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.password.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              <span v-else-if="!$v.password.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
            </BaseInputText>

            <BaseInputText v-if="(changePassword && selfEdit) || (!isNew && emailOriginal!==form.email && selfEdit)"
                           v-model="passwordOld"
                           type="password"
                           :label="$t('form.passwordOld')"
                           :class="getValidationClassNotForm('passwordOld')"
            >
              <span v-if="!$v.passwordOld.required" class="md-error" >{{$t('form.error.required')}}</span>
            </BaseInputText>
            <BaseInputText v-if="changePassword"
                           v-model="password"
                           type="password"
                           :label="$t('form.passwordNew')"
                           :class="getValidationClassNotForm('password')"
            >
              <span v-if="!$v.password.required" class="md-error" >{{$t('form.error.required')}}</span>
              <span v-else-if="!$v.password.minLength" class="md-error" >{{$t('form.error.minLength')}}</span>
              <span v-else-if="!$v.password.maxLength" class="md-error" >{{$t('form.error.maxLength')}}</span>
            </BaseInputText>
          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton>
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';
  .box {
    @extend %defaultBoxF1;
    .container {
      .content {
        .contentLoaded {
          .imagePreview {
            @extend %defaultPreview;
            width: #{$previewProfileW*$previewProfileScale}px;
            height: #{$previewProfileH*$previewProfileScale}px;

            border-radius: 300px;
            img {
              height: 100px;
              width: 100px;
              margin-bottom: 20px;
              border-radius: 300px;
            }
          }
          .body {

          }
        }
      }
    }
  }
</style>





















