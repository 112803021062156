<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/company/main_tabs'
  import ListOrders from '@components/products/list_orders'
 // import firebase from 'firebase/app'
  import OrderEdit from '@components/products/order_edit'
  import AlertIsClose from '@components/layout/company/alert_is_close'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListOrders, OrderEdit, AlertIsClose},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    mounted(){
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
        // firebase.firestore().collection('companiesProducts').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
      //  this.itemId = firebase.firestore().collection('companiesProducts').doc().id;
      },
    },
  }
</script>

<template>
  <MainTabs :style-order="true" >
    <template #left >
      <AlertIsClose />
      <ListOrders
        v-model="itemId"
        :class="[$style.list]"
        resource="userOrders"
        :company-id="currentCompany?currentCompany.id:null"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
      </ListOrders>
    </template>
    <template #right>
      <OrderEdit
        v-model="itemId"
      >
      </OrderEdit>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }

  .list {
   // box-shadow: 6px 2px 4px -1px rgba(0,0,0,.2), 6px 4px 7px 0 rgba(0,0,0,.14), 6px 1px 12px 0 rgba(0,0,0,.12);

/*    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2);
    box-shadow: 0 5px 8px 0 rgba(0,0,0,.14);
    box-shadow: 0 1px 14px 0 rgba(0,0,0,.12); */
    background-color: #f4f6fa;
  }
</style>





















