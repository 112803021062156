<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/admin/main_tabs'
  import ListOrders from '@components/products/list_orders'
 // import firebase from 'firebase/app'
  import ChildComponent from '@components/products/order_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListOrders, ChildComponent},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    mounted(){
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
       // firebase.firestore().collection('cards').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
       // this.itemId = firebase.firestore().collection('cards').doc().id;
      },
      makeSearch(el) {
        if(!el.name) {return '';}
        let search= '';
        for(let lang in el.name) {
          search += ' '+el.name[lang];
        }
        return search;
      }
    },
  }
</script>

<template>
  <MainTabs>
    <template #left >
      <ListOrders
        v-model="itemId"
        resource="userOrders"
        :class="[$style.list]"
        :actions="['edit', 'enable']"
        :make-search="makeSearch"
        :is-super-admin="true"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
        <template #item="slotProp" >
          <div class="formItemHead">{{$trdb(slotProp.item.name)}}</div>
        </template>
      </ListOrders>
    </template>
    <template #right>
      <ChildComponent
        v-model="itemId"
        :is-super-admin="true"
      >
      </ChildComponent>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

  .list {
    // box-shadow: 6px 2px 4px -1px rgba(0,0,0,.2), 6px 4px 7px 0 rgba(0,0,0,.14), 6px 1px 12px 0 rgba(0,0,0,.12);

    /*    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2);
        box-shadow: 0 5px 8px 0 rgba(0,0,0,.14);
        box-shadow: 0 1px 14px 0 rgba(0,0,0,.12); */
    background-color: #f4f6fa;
  }
  .box {
    background-color: #ffffff;
    .container {
      min-height: 600px;
      margin: 5px 0px 30px 0px;

      .head {
        text-align: right;
      }
      .tableList {
        text-align: left;
      }
    }
  }

</style>





















