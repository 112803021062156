<script>
  import { authComputed } from '@state/helpers'
// eslint-disable-next-line no-unused-vars
  import { required, minLength, maxLength, decimal, minValue } from 'vuelidate/lib/validators'
  import firebase from 'firebase/app'
  import Multiselect from 'vue-multiselect'
  import databaseUtils from '@utils/database_utils'
  const timeUtils = require ('@common/time_utils');

  export default {
    components: {Multiselect},
    model: {
      prop: 'itemId',
      event: 'changeModel'
    },
    props: {
      itemId: {
        type: String,
        default: null,
      }
    },
    data() {
      return {
        hasError: null,
        tryingToUpdate: false,
        tryingToUpload: false,
        uploadProgress: 0,
        imageSrc: null,
        imageBlob: null,
        isLoading: true,

        form: {
          acceptTakeout: false,
          acceptDelivery: false,
          showOnlyCategoryOnMenu: false,

          validPaymentMethods: [],
          validPaymentMethodsIds: [],
          print: {
            margin: 0.2
          },
        },
        defaultForm: {
          acceptTakeoutEnable: false,
          acceptDeliveryEnable: false,
          acceptOnSiteEnable: false,
          showOnlyCategoryOnMenu: false,

          validPaymentMethods: [],
          validPaymentMethodsIds: [],
          print: {
            margin: 0.2
          },

        },
        cardsList: [],
        gotapDeliveryList: [],
        cards: [],
        startLoading: false,
        timeForDeliveryMin: 0,
        timeForDeliveryMax: 0,
        timeForPickupAndOnSiteMin: 0,
        timeForPickupAndOnSiteMax: 0,
        optionsSequence: {
          show: false,
          name: '',
          begin: '',
          end: '',
        },

      }
    },
    validations: {

    },
    computed: {
      ...authComputed
    },
    watch: {
      async itemId(value, oldValue)  {
        if(value===oldValue) {
          console.warn('New value === old value');
          return;
        }
        this.imageBlob = null;
        this.imageSrc = null;
        if(value) {
          this.isLoading = true;
          await this.loadForm();
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }

      }
    },
    mounted: async function() {
      if(this.itemId) {
        await this.loadForm();
      }
      this.isLoading = false;
    },
    beforeMount: async function() {
      let cardsObjP = firebase.firestore().collection('cards').get();
      let gotapDeliveryP = firebase.firestore().collection('gotapDelivery').where("active", "==", true).get();
      this.cardsList = [];
      (await cardsObjP).forEach((categoryObj) => {
        this.cardsList.push({...categoryObj.data(), id:categoryObj.id, nameView:this.$trdb(categoryObj.data().name)});
      });


      this.gotapDeliveryList = [];
      (await gotapDeliveryP).forEach((categoryObj) => {
        let delivery = categoryObj.data();
        delivery.id = categoryObj.id;
        delivery.nameView = categoryObj.data().name;
        this.gotapDeliveryList.push(delivery);
      });

    },
    beforeDestroy: function() {
    },
    methods: {
      cancel() {
        this.$emit('changeModel', null);
      },
      async loadForm() {
        this.isLoading = true;
        this.form = _.cloneDeep(this.defaultForm);
        let obj = await firebase.firestore().collection('companies').doc(this.itemId).get();
        if(obj && obj.data()) {
          let company = obj.data() ||{};
          let form = { ..._.cloneDeep(this.defaultForm), ...company};

          form.print = form.print || {};
          form.print.margin = parseFloat(form.print.margin || 0.2);

          this.form = form;
          this.timeForDeliveryMin = timeUtils.minutes2field(this.form.timeForDeliveryMin);
          this.timeForDeliveryMax = timeUtils.minutes2field(this.form.timeForDeliveryMax);
          this.timeForPickupAndOnSiteMin = timeUtils.minutes2field(this.form.timeForPickupAndOnSiteMin);
          this.timeForPickupAndOnSiteMax = timeUtils.minutes2field(this.form.timeForPickupAndOnSiteMax);
        }
        else {
          this.form = _.cloneDeep(this.defaultForm);
        }
        console.log('loaded form', this.form);
        this.isLoading = false;
      },

      async conclude() {
        let form = _.cloneDeep(this.form);

        this.$v.$touch();
        if (this.$v.$invalid) {
          this.hasError = {message:this.$t('form.error.formInvalid')};
          this.$showError({title:this.$t('form.error.formInvalid')});
          return;
        }
        if(!this.form.acceptPaymentOnDelivery && !this.form.acceptPaymentOnApp) {
          this.hasError = {message:this.$t('form.error.needOnePayment')};
          this.$showError({title:this.$t('form.error.needOnePayment')});
          return;
        }

        this.hasError = null;
        this.tryingToUpdate = true;

        let validPaymentMethodsIds = [];
        for(let payment of this.form.validPaymentMethods || []) {
          validPaymentMethodsIds.push(payment.id);
        }
        form.print = form.print || {};
        form.print.margin = parseFloat(form.print.margin || 0.2);

        let toUpdate = {
          acceptTakeoutEnable: this.form.acceptTakeoutEnable || false,
          acceptDeliveryEnable: this.form.acceptDeliveryEnable || false,
          acceptOnSiteEnable: this.form.acceptOnSiteEnable || false,

          showOnlyCategoryOnMenu: this.form.showOnlyCategoryOnMenu || false,

          validPaymentMethods: this.form.validPaymentMethods || [],
          validPaymentMethodsIds: validPaymentMethodsIds,
          acceptPaymentOnApp: this.form.acceptPaymentOnApp || false,
          acceptPaymentOnDelivery: this.form.acceptPaymentOnDelivery || false,
          acceptCashPayment: this.form.acceptCashPayment || false,

          timeForDeliveryMin: timeUtils.field2minutes(this.timeForDeliveryMin),
          timeForDeliveryMax: timeUtils.field2minutes(this.timeForDeliveryMax),
          timeForPickupAndOnSiteMin: timeUtils.field2minutes(this.timeForPickupAndOnSiteMin),
          timeForPickupAndOnSiteMax: timeUtils.field2minutes(this.timeForPickupAndOnSiteMax),


          minValueForDelivery: parseFloat(this.form.minValueForDelivery || 0),
          maxValueForDelivery: parseFloat(this.form.maxValueForDelivery || 0),
          maxValueForOnSiteAndTakeout: parseFloat(this.form.maxValueForOnSiteAndTakeout || 0),

          minValueForPickup: parseFloat(this.form.minValueForPickup || 0),
          minValueForOnSite: parseFloat(this.form.minValueForOnSite || 0),
          maxValueForPickup: parseFloat(this.form.maxValueForPickup || 0),
          maxValueForOnSite: parseFloat(this.form.maxValueForOnSite || 0),
          deliveryBy: this.form.deliveryBy || '',

          print: form.print || {},
          tableActive: this.form.tableActive || false,
          tableRequire: this.form.tableRequire || false,
          tables: this.form.tables || [],
          onSiteTableName: this.form.onSiteTableName || '',
        };

        if(this.imageSrc && this.imageBlob) {
          this.uploadProgress = 0;
          this.tryingToUpload = true;
          try {
            let url = await databaseUtils.uploadFile('otherOptions/'+this.itemId, this.imageBlob, (progress) => {
              this.uploadProgress = progress;
            });
            if(url) {
              form.imgUrl = url;
            }
          } catch(error) {
            this.hasError = error;
            this.$showError({title: this.$t('form.error.image.upload')});
          }
          this.tryingToUpload = false;
        }

        try {
          await firebase.firestore().collection('companies').doc(this.itemId).set(toUpdate,{merge: true});
          this.$showSuccess();
        } catch(error) {
          this.hasError = error;
          this.$showError();
        }
        this.tryingToUpdate = false;
        this.tryingToUpload = false;
      },

      getValidationClass (fieldName) {
        if(this.$v && this.$v.form) {
          const field = this.$v.form[fieldName];
          if (field) {
            return {
              'md-invalid': field.$invalid && field.$dirty
            };
          }
        }
        return {'md-invalid':false};
      },
      changeBlob (blob) {
        this.imageBlob = blob;
      },
      tryChangeImage () {
        this.$refs.fcmEditor.insertImage();
      },
      removeImage() {
        this.form.imgUrl = null;
        this.imageSrc = null;
        this.imageBlob = null;
      },
      addOption() {
        if(!this.form.tables) {
          this.form.tables = [];
          this.form = {...this.form};
        }
        this.form.tables.push({id:firebase.firestore().collection('companyPlaces').doc().id, name:''});
      },
      removeOption(indexOp) {
        if(!this.form.tables) {
          this.form.tables = [];
          this.form = {...this.form};
        }
        this.form.tables.splice(indexOp, 1);
      },
      addTableSequence() {
        let begin = parseInt(this.optionsSequence.begin);
        let end = parseInt(this.optionsSequence.end);
        if(!begin || !end || begin>=end) {
          this.$showError();
          console.error("begin e end inválido", begin, end);
          return ;
        }
        if(!this.form.tables) {
          this.form.tables = [];
          this.form = {...this.form};
        }
        for(let i = begin; i<= end;i++) {
          this.form.tables.push({id:firebase.firestore().collection('companyPlaces').doc().id, name: this.optionsSequence.name + ''+i});
        }
        this.optionsSequence.show = false;
      }
    },
  }
</script>


<template>
  <div v-show="itemId" :class="$style.box">
    <div :class="$style.container">
      <div :class="$style.content">
        <div v-show="isLoading" >
          <MdProgressSpinner class="md-primary" md-mode="indeterminate"></MdProgressSpinner>
        </div>
        <div v-if="!isLoading" :class="$style.contentLoaded">
          <BaseDev>{{itemId}}</BaseDev>
          <!--
                    <div :class="$style.imagePreview" @click="tryChangeImage()" >
                      <img :src="imageSrc || form.imgUrl || require('@assets/images/placeholder/product.png')" alt="Imagem principal"/>
                      <div :class="$style.changeImage"><span>{{$t('all.changeImage')}}</span></div>
                      <div :class="$style.removeImage" @click.stop="removeImage()" ><i class="material-icons">close</i></div>

                    </div>
                    <FcmEditor ref="fcmEditor" v-model="imageSrc" :class="$style.fcmEditor"
                               type-content="modal"
                               :max-width="180"
                               :max-height="180"
                               :aspect-ratio="0"
                               @changeBlob="changeBlob"
                    ></FcmEditor> -->
          <div :class="$style.body">
            <div class="formItemSubSection">{{$t('paymentOptions.paymentOptionsSub').toUpperCase()}}</div>
            <div class="formItem formItemFirst">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('paymentOptions.acceptPaymentOnApp')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.acceptPaymentOnApp" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('paymentOptions.acceptPaymentOnDelivery')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.acceptPaymentOnDelivery" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div v-if="form.acceptPaymentOnDelivery" class="formItem">
              <Multiselect v-model="form.validPaymentMethods" :options="cardsList" track-by="id" label="nameView" :multiple="true"
                           :placeholder="$t('paymentOptions.placeholderCards')"
                           :select-label="$t('multiSelect.selectLabel')"
                           :select-group-label="$t('multiSelect.selectGroupLabel')"
                           :selected-label="$t('multiSelect.selectedLabel')"
                           :deselect-label="$t('multiSelect.deselectLabel')"
                           :deselect-group-label="$t('multiSelect.deselectGroupLabel')"
              >
              </Multiselect>
            </div>


            <div class="formItemSubSection">{{$t('otherOptions.otherOptionsSub').toUpperCase()}}</div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.showOnlyCategoryOnMenu')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.showOnlyCategoryOnMenu" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.acceptTakeout')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.acceptTakeoutEnable" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.acceptOnSite')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.acceptOnSiteEnable" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>

            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.acceptDelivery')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.acceptDeliveryEnable" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <MdField :class="$style.formItemText">
              <label >{{$t('deliveryOptions.deliveryBy.title')}}</label>
              <MdSelect v-model="form.deliveryBy" name="access">
                <MdOption key="company" value="company">{{$t('deliveryOptions.deliveryBy.company')}}</MdOption>
                <MdOption v-for="(value) in gotapDeliveryList" :key="value.id" :value="value.id">{{value.name}}</MdOption>
              </MdSelect>
            </MdField>


            <div class="formItemSubSection">{{$t('otherOptions.timeForDelivery')}}</div>
            <MdField :class="[$style.schedulesItens]">
              <label>{{$t('otherOptions.timeForDeliveryMin')}}</label>
              <MdInput
                v-model="timeForDeliveryMin"
                v-mask="'##:##'"
                type="text"
              >
              </MdInput>
            </MdField>
            <MdField :class="[$style.schedulesItens]">
              <label>{{$t('otherOptions.timeForDeliveryMax')}}</label>
              <MdInput
                v-model="timeForDeliveryMax"
                v-mask="'##:##'"
                type="text"
              >
              </MdInput>
            </MdField>

            <div class="formItemSubSection">{{$t('otherOptions.timeForPickupAndOnSite')}}</div>
            <MdField :class="[$style.schedulesItens]">
              <label>{{$t('otherOptions.timeForPickupAndOnSiteMin')}}</label>
              <MdInput
                v-model="timeForPickupAndOnSiteMin"
                v-mask="'##:##'"
                type="text"
              >
              </MdInput>
            </MdField>
            <MdField :class="[$style.schedulesItens]">
              <label>{{$t('otherOptions.timeForPickupAndOnSiteMax')}}</label>
              <MdInput
                v-model="timeForPickupAndOnSiteMax"
                v-mask="'##:##'"
                type="text"
              >
              </MdInput>
            </MdField>
            <div class="formItem">
              <MdField :class="['formItemText']">
                <label>{{$t('otherOptions.minValueForDelivery')}}</label>
                <span class="md-prefix">R$</span>
                <MdInput v-model="form.minValueForDelivery" type="number"></MdInput>
              </MdField>
            </div>
            <div class="formItem">
              <MdField :class="['formItemText']">
                <label>{{$t('otherOptions.maxValueForDelivery')}}</label>
                <span class="md-prefix">R$</span>
                <MdInput v-model="form.maxValueForDelivery" type="number"></MdInput>
              </MdField>
            </div>
            <div class="formItem">
              <MdField :class="['formItemText']">
                <label>{{$t('otherOptions.maxValueForOnSiteAndTakeout')}}</label>
                <span class="md-prefix">R$</span>
                <MdInput v-model="form.maxValueForOnSiteAndTakeout" type="number"></MdInput>
              </MdField>
            </div>


            <div class="formItemSubSection">{{$t('otherOptions.print')}}</div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.printKitchen')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.print.printKitchen" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.printDelivery')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.print.printDelivery" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <MdField :class="['formItemText']">
                <label>{{$t('otherOptions.printMargin')}}</label>
                <MdInput v-model="form.print.margin" type="number"></MdInput>
              </MdField>
            </div>

            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.tableActive')}}</div>
                <div class="formItemSub">{{$t('otherOptions.tableActiveSub')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.tableActive" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>
            <div class="formItem">
              <div class="formItemDesc">
                <div class="formItemHead">{{$t('otherOptions.tableRequire')}}</div>
                <div class="formItemSub">{{$t('otherOptions.tableRequireSub')}}</div>
              </div>
              <div class="formItemAction">
                <MdSwitch v-model="form.tableRequire" class="md-primary" :value="true"></MdSwitch>
                <div class="formItemControl"></div>
              </div>
            </div>

            <div class="formItem">
              <div v-if="form.tableActive" :class="$style.itensOp">
                <div>
                  {{$t('otherOptions.tables')}}
                </div>
                <MdField :class="['formItemText']">
                  <label>{{$t('otherOptions.onSiteTableName')}}</label>
                  <MdInput v-model="form.onSiteTableName" type="text"></MdInput>
                </MdField>

                <div v-if="form.tables" >
                  <div v-for="(itemOp, indexOp) of form.tables" :key="indexOp+'_'+itemOp.id" :class="$style.itemOp">
                    <div :class="$style.itemHead">
                      <BaseInputText
                        v-model="form.tables[indexOp].name"
                        :label="$t('otherOptions.tableName')"
                        :class="$style.field"
                      >
                      </BaseInputText>
                      <MdButton class="md-icon-button" @click="removeOption(indexOp)">
                        <MdIcon>delete</MdIcon>
                      </MdButton>
                    </div>
                  </div>
                </div>
                <div :class="$style.actions">
                  <MdButton :class="['md-primary','md-plain', 'md-mini']" @click="addOption()">
                    <MdIcon>add</MdIcon>
                    {{$t('otherOptions.addTable')}}
                  </MdButton>
                  <MdButton :class="['md-primary','md-plain', 'md-mini']" @click="optionsSequence.show = !optionsSequence.show;">
                    <MdIcon>toc</MdIcon>
                    {{$t('otherOptions.addTableSequence')}}
                  </MdButton>
                </div>
                <div v-if="optionsSequence && optionsSequence.show" :class="['md-elevation-1', $style.tablesSequence]">
                  {{$t('otherOptions.addTableSequenceTitle')}}
                  <MdField :class="$style.tablesSequenceName">
                    <label>{{$t('otherOptions.tablesSequenceName')}}</label>
                    <MdInput v-model="optionsSequence.name" type="text" ></MdInput>
                  </MdField>
                  <div :class="$style.tablesSequenceBeginEnd">
                    <MdField :class="$style.tablesSequenceBegin">
                      <label>{{$t('otherOptions.tablesSequenceBegin')}}</label>
                      <MdInput v-model="optionsSequence.begin" type="number" ></MdInput>
                    </MdField>
                    <MdField :class="$style.tablesSequenceEnd">
                      <label>{{$t('otherOptions.tablesSequenceEnd')}}</label>
                      <MdInput v-model="optionsSequence.end" type="number" ></MdInput>
                    </MdField>
                  </div>
                  <div :class="$style.actionsSequence">
                    <MdButton :class="['md-primary','md-plain', 'md-mini']" @click="addTableSequence()">
                      <MdIcon>add</MdIcon>
                      {{$t('otherOptions.addTableSequenceButton')}}
                    </MdButton>
                    <MdButton :class="['md-primary','md-plain', 'md-mini']" @click="optionsSequence.show = false;">
                      {{$t('otherOptions.cancelTableSequence')}}
                    </MdButton>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <p v-if="hasError" :class="$style.error">
            {{$t('form.error.update')}}<br>{{hasError.message? hasError.message:''}}
          </p>
          <MdProgressBar v-if="typeof tryingToUpload!=='undefined' && typeof uploadProgress!=='undefined' && tryingToUpload" :class="[$style.progressBar, 'md-primary']" md-mode="determinate" :md-value="uploadProgress"></MdProgressBar>
          <div :class="$style.actions">
       <!--     <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.leftButton ]"
              :disabled="tryingToUpdate"
              @click="cancel"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.cancel')}}
                </span>
            </BaseButton> -->
            <BaseButton
              :class="['md-primary','md-raised','md-lg', $style.rightButton ]"
              :disabled="tryingToUpdate"
              @click="conclude"
            >
              <BaseIcon v-if="tryingToUpdate" name="sync" spin />
              <span v-else>
                  {{$t('form.conclude')}}
                </span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" module>
  @import '@design';

  .box {
    @extend %defaultBoxF1;
    .container {
      min-height: 600px;
      .content {
        max-width: 600px;
        .contentLoaded {
          .imagePreview {
            width: #{$previewCategoryW*$previewCategoryScale}px;
            height: #{$previewCategoryH*$previewCategoryScale}px;
            border-radius: 300px;
            img {
              border-radius: 300px;
            }
          }
          .body {
            .schedulesItens {
              width: 160px;
              display: inline-block;
              margin-right: 30px;
            }

            .schedulesItensTitle {
              width: 100px;
              display: inline-block;
              margin-right: 30px;
            }

            .itensOp {
              width: 100%;
              max-width: 350px;
              .itemOp {
                margin-left: 13px;
              }

              .itemHead {
                display: flex;
                button {
                  align-self: center;
                }
                .field {
                  margin-top: 7px;
                  margin-bottom: 5px;
                }
              }
              .actions {
                display: flex;
                button {

                }
              }
              .tablesSequence {
                padding: 7px;
                .tablesSequenceBeginEnd {
                  display: flex;
                  .tablesSequenceEnd {
                    margin-left: 8px;
                  }
                }
                .actionsSequence {
                  display: flex;
                  margin-top: -10px;
                  button {

                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>





















