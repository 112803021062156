<script>
  import {appConfig} from '@src/config';
  import { authComputed } from '@state/helpers'
  import MainTabs from '@components/layout/admin/main_tabs'
  import ListItens from '@components/list_itens'
  import firebase from 'firebase/app'
  import UserEdit from '@components/client/user_edit'

  export default {
    page: {
      title: 'Home',
      meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {MainTabs, ListItens, UserEdit},
    props: {
    },
    data() {
      return {
        itemId: null
      }
    },
    computed: {
      ...authComputed
    },
    mounted(){
    },
    methods: {
      editItem(id) {
        this.itemId = id;
      },
      enableItem({id, value}) {
        firebase.firestore().collection('users').doc(id).set({active:value || false, updatedAt: firebase.firestore.FieldValue.serverTimestamp()},{merge: true})
      },
      addItem() {
        this.itemId = firebase.firestore().collection('users').doc().id;
      },
      makeSearch(el) {
        return (el.name?el.name:'')+(el.lastName?' '+el.lastName:'')+(el.email?' '+el.email:'')+(el.phone?' '+el.phone:'');
      }
    },
  }
</script>

<template>
  <MainTabs>
    <template #left >
      <ListItens
        v-model="itemId"
        resource="users"
        :actions="['edit', 'enable']"
        :disable-order="true"
        :make-search="makeSearch"
        :hide-active="true"
        @edit="editItem"
        @enable="enableItem"
        @add="addItem"
      >
        <template #item="slotProp" >
          <div class="formItemHead">{{slotProp.item.name+(slotProp.item.lastName?' '+slotProp.item.lastName:'')}}</div>
          <div class="formItemSub">{{slotProp.item.email}}</div>
          <div class="formItemSub">{{slotProp.item.phone}}</div>
        </template>
      </ListItens>
    </template>
    <template #right>
      <UserEdit
        v-model="itemId"
        :is-super-admin="true"
      >
      </UserEdit>
    </template>
  </MainTabs>
</template>

<style lang="scss" module>
  @import '@design';

  .error {
    color: $color-input-error;
  }

  .box {
    background-color: #ffffff;
    .container {
      min-height: 600px;
      margin: 5px 0px 30px 0px;

      .head {
        text-align: right;
      }
      .tableList {
        text-align: left;
      }
    }
  }
</style>





















