"use strict";
// import firebase from 'firebase/app'

// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};


toExport.teste = (input) => {
  console.log('teste ', input);
};





module.exports = toExport;
